import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { changePage } from '../../Components/Navigation/actions/page';
import menuMapping from './menuMapping';
import AddCampaignContainer from './render/AddCampaignContainer';
import { NetworkRequestPost } from '../../Helpers/NetworkRequest';
import { openSnackbar } from '../../Widgets/actions/widgets';
import { prefillCloneValues } from './prefillCloneValues';
import { changeNonField, resetAddCampaign } from './actions/addCampaign';
import { errorsRender } from '../../Widgets/Snackbar/Snackbar';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  displayNone: {
    display: 'none',
  },
}));

export default function CloneCampaign() {
  const { id: campaignID } = useParams();
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const getCampaignInfo = async () => {
    dispatch(changeNonField('cloneLoading', true));
    const {
      data: {
        success, data, message, errorList,
      },
    } = await NetworkRequestPost('cloneCampaign', { campaign_id: campaignID });
    if (success && data) {
      prefillCloneValues(data, dispatch);
      dispatch(changeNonField('clonedOptimization', data.budget_optimization));
      dispatch(changeNonField('isClone', true));
      dispatch(changeNonField('currentStep', 0));
    } else {
      dispatch(openSnackbar(errorsRender(errorList || message, 'We\'re sorry. We encountered an error trying to clone this campaign. Please try again later, or contact support.'), 'error'));
    }
    dispatch(changeNonField('cloneLoading', false));
  };

  useEffect(() => {
    getCampaignInfo();
    return () => dispatch(resetAddCampaign());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(changePage('add campaign', menuMapping));
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <AddCampaignContainer />
    </div>
  );
}

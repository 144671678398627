import Typography from '@mui/material/Typography';
import { Input } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Slider from '../../../../../../Widgets/Forms/Slider';
import RenderSection from '../../../RenderSection';
import { changeNonField } from '../../../../actions/addCampaign';
import { validAgeTargeting } from '../Validation/validationFunctions';

const useStyles = makeStyles()(() => ({
  singleSelectInputRoot: {
    padding: 0,
    height: '55px !important',
    marginLeft: 20,
    display: 'flex',
    alignItems: 'center',
  },
  sliderContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'row',
  },
  slider: {
    width: '100%',
    marginLeft: '1.5rem',
    marginRight: '1.5rem',
  },
  error: {
    color: 'red',
    width: '100%',
    marginTop: 8,
    textAlign: 'center',
  },
}));

const marks = [
  {
    value: 15,
    label: '15',
  }, {
    value: 20,
    label: '20',
  }, {
    value: 25,
    label: '25',
  }, {
    value: 30,
    label: '30',
  }, {
    value: 35,
    label: '35',
  }, {
    value: 40,
    label: '40',
  }, {
    value: 45,
    label: '45',
  }, {
    value: 50,
    label: '50',
  }, {
    value: 55,
    label: '55',
  }, {
    value: 60,
    label: '60',
  }, {
    value: 65,
    label: '65',
  },
];

export default () => {
  const { classes } = useStyles();
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const { newAdSet } = useSelector((store) => store.addCampaign);
  const {
    ageTargeting,
  } = newAdSet;
  const [ages, setAges] = useState(ageTargeting);

  useEffect(() => {
    if (ages[0] !== ageTargeting[0] || ages[1] !== ageTargeting[1]) {
      setAges(ageTargeting);
    }
  }, [ageTargeting]);

  const updateSingleValues = (name, value) => {
    const valid = validAgeTargeting(value);
    setError(valid ? '' : 'Please ensure that your age targeting is between 13 - 65');
    dispatch(changeNonField('newAdSet', {
      ...newAdSet,
      [name]: value,
    }));
  };
  const ageInputChange = (value, name) => {
    let newValue;
    if (name === 'min') {
      if (value < ages[1]) {
        newValue = [value, ages[1]];
      }
    } else if (value > ages[0]) {
      newValue = [ages[0], value];
    }
    if (newValue) {
      setAges(newValue);
      updateSingleValues('ageTargeting', newValue);
    }
  };

  return (
    <RenderSection>
      <Typography id="age-title" gutterBottom>
        Age Targeting
      </Typography>
      <div className={classes.sliderContainer}>
        <Input
          size="small"
          value={ages[0]}
          onChange={({ target: { value } }) => ageInputChange(value, 'min')}
          inputProps={{
            min: 13,
            max: 64,
            type: 'number',
            'aria-labelledby': 'age-title',
          }}
          style={{ minWidth: 40 }}
        />
        <div className={classes.slider}>
          <Slider
            onChange={(event, newValue) => setAges(newValue)}
            onBlur={() => updateSingleValues('ageTargeting', ages)}
            defaultValue={[20, 65]}
            step={1}
            min={13}
            max={65}
            marks={window.innerWidth >= 950 ? marks : true}
            value={ages}
            labeledBy="age-title"
          />
        </div>
        <Input
          size="small"
          value={ages[1]}
          onChange={({ target: { value } }) => ageInputChange(value, 'max')}
          inputProps={{
            min: 14,
            max: 65,
            type: 'number',
            'aria-labelledby': 'age-title',
          }}
          style={{ minWidth: 40 }}
        />
      </div>
      {error && (
        <div className={classes.error}>
          {error}
        </div>
      )}
    </RenderSection>
  );
};

import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';
import { changePage } from '../../Components/Navigation/actions/page';
import { menuMapping } from './menuMapping';
import RenderTables from '../../Widgets/Tables/TableContainer/RenderTables';
import { campaignsTable } from './CampaignTable';
import { conditionalMenuRender } from '../../Helpers/conditionalMenuRender';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
}));

export default function Campaigns() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // useEffect(() => {
  // dispatch(updateRefreshedAt());
  // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    let conditionalMenu = { ...menuMapping() };
    if (!isMobile) {
      conditionalMenu = conditionalMenuRender(conditionalMenu, 'linkSections', { title: 'title', find: 'Pages' });
    }
    dispatch(changePage('campaigns', conditionalMenu));
  }, [dispatch, isMobile]);

  return (
    <div className={classes.container}>
      <RenderTables
        pageReducer="campaigns"
        tables={() => ({ campaigns: campaignsTable() })}
        filtersKeys={['user', 'weekDay', 'status', 'account_id', 'sub_account_id']}
      />
    </div>
  );
}

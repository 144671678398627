import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object.isRequired,
};

export default ErrorBoundary;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  bool,
  element,
  oneOfType,
  string,
} from 'prop-types';

const useStyles = makeStyles()(() => ({
  toolTipText: {
    fontSize: 15,
    padding: 6,
    textAlign: 'center',
  },
}));

export default function HelpOverlay(
  {
    title,
    children,
    placement,
    allowHover,
  },
) {
  const { classes } = useStyles();
  const [hoveredOver, setHoveredOver] = useState(false);
  const { open: showOverlay } = useSelector((state) => state.widgets.helpOverlay);

  function renderTooltipText() {
    return (
      <div className={classes.toolTipText}>
        {title}
      </div>
    );
  }

  return (!showOverlay && !allowHover)
    ? children
    : (
      <Tooltip
        arrow
        open={showOverlay || hoveredOver}
        title={renderTooltipText()}
        placement={placement}
        onMouseEnter={() => setHoveredOver(true)}
        onMouseOut={() => setHoveredOver(false)}
        onBlur={() => setHoveredOver(false)}
      >
        {children}
      </Tooltip>
    );
}

HelpOverlay.propTypes = {
  children: oneOfType([element, string]).isRequired,
  title: string.isRequired,
  allowHover: bool,
  placement: string,
};

HelpOverlay.defaultProps = {
  allowHover: false,
  placement: 'bottom',
};

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Filter from '../render/Filter';
import { NetworkRequestPost } from '../../../../../Helpers/NetworkRequest';
import { openSnackbar } from '../../../../../Widgets/actions/widgets';

const failedOptions = [
  { title: 'All Accounts', value: 'all' },
];

const useStyles = makeStyles()(() => ({
  filterContainer: {
    padding: '10px 0',
    paddingLeft: '25px',
  },
}));

export default function AccountFilter() {
  const [options, setOptions] = useState([]);
  const [showFilter, toggleFilter] = useState(false);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  async function getAccounts() {
    let accounts = [];
    toggleFilter(false);
    try {
      const { data = { success: false, data: [], message: '' } } = await NetworkRequestPost('getAccounts');
      if (data && data.success && data.data.length > 0) {
        data.data.forEach((acc) => {
          if (acc && acc.name && acc.id) {
            accounts.push({ title: acc.name, value: acc.id });
          }
        });
        if (accounts.length > 1) {
          accounts = [{ title: 'All Accounts', value: 'all' }, ...accounts];
          toggleFilter(true);
        } else {
          accounts = failedOptions;
        }
      } else {
        accounts = failedOptions;
        dispatch(openSnackbar(data.message || 'An error has occurred. Please try again and reach out to support is the problem persists.', 'error'));
      }
    } catch (error) {
      accounts = failedOptions;
    }
    setOptions(accounts);
  }
  useEffect(() => {
    getAccounts();
    // eslint-disable-next-line
  }, []);
  return showFilter && (
    <div className={classes.filterContainer}>
      <Filter name="account_id" label="Account Filter" filterKey="account_id" options={options} tempOptions={failedOptions} />
    </div>
  );
}

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppBar, Toolbar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ToggleMenu from './ToggleMenu';
import LogoHome from './LogoHome';
import PageName from './PageName';
import UserName from './RenderUserName';
import PageNavigation from './PageNavigation';
import RefreshButton from '../SideNavigation/Filters/RefreshButton/RefreshButton';
import { NetworkRequestPost } from '../../../Helpers/NetworkRequest';
import { changeUserSetting } from '../actions/page';
import { openSnackbar } from '../../../Widgets/actions/widgets';

const useStyles = makeStyles()((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export default function TopNavigation() {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const getUserSettings = async () => {
    const { data } = await NetworkRequestPost('getUserSettings', {});
    if (data && data.success) {
      const {
        campaign_new_tab: campaignNewTab,
      } = data.data;
      dispatch(changeUserSetting('openCampaignNewTab', !!+campaignNewTab));
    } else {
      dispatch(openSnackbar(data.message || 'There is a problem of getting user settings.', 'error'));
    }
  };

  useEffect(() => {
    getUserSettings();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <ToggleMenu />
          <LogoHome />
          <PageName />
          <PageNavigation />
          <div className={classes.grow} />
          <RefreshButton />
          <UserName />
        </Toolbar>
      </AppBar>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { isEqual } from 'lodash';
import { NetworkRequestDelete, NetworkRequestPost, NetworkRequestPut } from '../../../../Helpers/NetworkRequest';
import LinearProgress from '../../../../Widgets/Loaders/LinearProgress';
import { closeRightDrawer, openDialog, openSnackbar } from '../../../../Widgets/actions/widgets';
import SubmitButton from '../../../../Widgets/Forms/Buttons/SubmitButton';
import AddSettings from './AddSettings';
import SettingsTable from './SettingsTable';
import { formatDataForBE, takeDataAndOrganizeIt } from './helper';

const useStyles = makeStyles()(() => ({
  loadingContainer: {
    height: 300,
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: 700,
    textAlign: 'center',
  },
  removeAllButton: {
    display: 'block',
    margin: '0 auto',
    marginBottom: '3rem',
  },
}));

export default function Dayparting() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { view, campaignID, adSetID } = useSelector((state) => state.campaignDetails);
  const { page } = useSelector((state) => state.page);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [removeSubmitting, setRemoveSubmitting] = useState(false);
  const [settings, setSettings] = useState({});
  const [originalSettings, setOriginalSettings] = useState({});
  const daypartingEndpoint = view === 'campaign' ? 'dayparting' : 'daypartingAdSet';
  const baseParams = {
    campaign_id: campaignID,
  };
  if (view === 'adSet') {
    baseParams.adset_id = adSetID;
  }

  const pullList = async () => {
    const { data } = await NetworkRequestPost(daypartingEndpoint, baseParams, 'Campaign');
    if (data.success) {
      const formattedSettings = takeDataAndOrganizeIt(data.data || []);
      setSettings(formattedSettings);
      setOriginalSettings(formattedSettings);
    } else {
      dispatch(openSnackbar(data.message || 'There is a problem of getting dayparting data.', 'error'));
    }
    setLoading(false);
  };

  useEffect(() => {
    pullList();
    // eslint-disable-next-line
  }, []);

  function handleResponse(data) {
    setSubmitting(false);
    setRemoveSubmitting(false);
    if (data.success && data.message) {
      dispatch(openSnackbar(data.message, 'success'));
      dispatch(closeRightDrawer());
    } else {
      const message = (data.errorList && data.errorList.join('\n')) || data.message;
      dispatch(openSnackbar(message || 'There is a problem of storing dayparting data.', 'error'));
    }
    return data.success;
  }

  async function requestToRemove() {
    setRemoveSubmitting(true);
    const { data } = await NetworkRequestDelete(daypartingEndpoint, baseParams);
    return handleResponse(data);
  }

  const removeAll = async () => {
    const usedItemWord = view === 'campaign' ? 'campaign' : 'ad set';
    const message = page === 'add campaign'
      ? `You have selected to clear all dayparting settings for this ${usedItemWord}.`
      : `You have selected to clear all dayparting settings for this ${usedItemWord}. Please click "Submit" when you're ready to save this change.`;
    dispatch(openDialog(
      'Are you sure?',
      `Please confirm that you would like to clear all dayparting settings for this ${usedItemWord}.`,
      () => {
        setSettings({});
        dispatch(openSnackbar(message, 'success'));
      },
      true,
    ));
  };

  const submit = async () => {
    setSubmitting(true);
    const formattedSettings = formatDataForBE(settings);
    if (formattedSettings.length < 1) {
      return requestToRemove();
    }
    const { data } = await NetworkRequestPut(daypartingEndpoint, {
      ...baseParams,
      dayparting: formattedSettings,
    });
    return handleResponse(data);
  };

  return (loading)
    ? <div className={classes.loadingContainer}><LinearProgress /></div>
    : (
      <div className={classes.container}>
        <AddSettings settings={settings} setSettings={setSettings} loading={loading} />
        <SettingsTable settings={settings} setSettings={setSettings} />
        {(Object.keys(settings).length > 0) && (
          <Button
            variant="contained"
            className={classes.removeAllButton}
            onClick={removeAll}
            disabled={removeSubmitting}
          >
            Clear All Dayparting Settings
          </Button>
        )}
        <SubmitButton
          isLoading={submitting}
          disabled={submitting || isEqual(settings, originalSettings)}
          handleSubmit={submit}
        />
      </div>
    );
}

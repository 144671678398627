import { userLogTable } from './LoggingTable';
import { adsReport } from './AdsReport';
import { ageReport } from './AgeReport';
import { genderReport } from './GenderReport';
import { stateReport } from './StateReport';
import { adSetReport } from './AdSetReport/AdSetReport';

export const tables = (props = {}) => ({
  ads: adsReport({ ...props }),
  age: ageReport({ ...props }),
  state: stateReport({ ...props }),
  adSet: adSetReport({ ...props }),
  gender: genderReport({ ...props }),
  userLog: userLogTable({ ...props }),
});

import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import { colors } from '../../../../theme';
import { changeCampaignDetails } from '../../actions/campaignDetails';

const useStyles = makeStyles()(() => ({
  link: {
    color: colors.blue,
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    outline: 'none',
    padding: 0,
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'left',
    overflow: 'hidden',
    width: '100%',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default function (
  {
    name,
    adSetID,
    adID,
  },
) {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const changeView = () => {
    if (adID) {
      dispatch(changeCampaignDetails('menuLabel', `${name}`));
      dispatch(changeCampaignDetails('table', 'age'));
      dispatch(changeCampaignDetails('view', 'ad'));
      dispatch(changeCampaignDetails('adSetID', adSetID));
      dispatch(changeCampaignDetails('adID', adID));
    } else {
      dispatch(changeCampaignDetails('menuLabel', `${name}`));
      dispatch(changeCampaignDetails('table', 'ads'));
      dispatch(changeCampaignDetails('view', 'adSet'));
      dispatch(changeCampaignDetails('adSetID', adSetID));
    }
  };
  return (
    <button
      type="button"
      className={classes.link}
      onClick={() => changeView()}
    >
      {name}
    </button>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { getColumnWidths } from './TableUtilities/getColumnWidths';
import { getColumnCompare, getDefaultSortingOrder } from './TableUtilities/columnFormatter';
import Table from './Table';
import { NetworkRequestPost } from '../../../Helpers/NetworkRequest';
import { usePrevious } from '../../../Helpers/CustomHooks';
import { refreshData } from '../../../Components/Navigation/SideNavigation/Filters/RefreshButton/actions/refresh';
import {
  formattedColumns,
  formattedTableData, modifyDataHelper, modifyMultiLaterDataHelper,
  setFormattedFilters,
} from './RenderTablesUtilities';
import TableOptionsBar from './TableOptionsBar';
import { openSnackbar } from '../../actions/widgets';
import { notTestUser } from '../../../Pages/AddCampaign/render/Links/Links';

const useStyles = makeStyles()(() => ({
  container: {
    margin: '5px',
  },
  tableContainer: {
    minHeight: '700px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    '&>div': {
      width: '100%!important',
    },
  },
}));

const RenderTables = React.memo(({ pageReducer, tables, filtersKeys }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  // Redux
  const {
    table,
    campaignID = false,
    adSetID,
    adID,
  } = useSelector((state) => state[pageReducer]);
  const { startDate, endDate, dateRange } = useSelector((state) => state.dateRange);
  const filters = useSelector((state) => state.filters);
  const { refreshAllData } = useSelector((state) => state.refresh);
  const [customFilter, setCustomFilter] = useState('');
  const { budgetOptimization } = useSelector((state) => state.campaignDetails);
  const { dataGridCustomization } = useSelector((state) => state.widgets);

  // State
  const [rows, setRows] = useState({ [table]: [] });
  const [hideBlocked, setHideBlocked] = useState('-1');
  const [loading, setLoading] = useState(true);
  const requestTime = useRef(0);
  // Filters
  const formattedFilters = setFormattedFilters(
    table,
    filtersKeys,
    filters,
  );
  const formattedStartDate = format(startDate, 'yyyy-MM-dd');
  const formattedEndDate = format(endDate, 'yyyy-MM-dd');

  const triggers = {
    formattedStartDate, formattedEndDate, table, campaignID, ...formattedFilters,
  };
  const previousProps = usePrevious(triggers);

  const getData = async (action) => NetworkRequestPost(action, {
    campaign_id: campaignID,
    adset_id: adSetID || undefined,
    adset_ids: adSetID ? [adSetID] : undefined,
    ad_id: adID || undefined,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    date_range: dateRange,
    ...formattedFilters,
  });

  const getRequestTime = () => requestTime.current;

  const getTableData = async () => {
    const time = (new Date()).getTime();
    requestTime.current = time;
    setLoading(true);
    setHideBlocked('-1');
    dispatch(refreshData(false));
    try {
      setRows([{ [table]: [] }]);
      const { action } = tables()[table];
      const { data } = await getData(action);
      if (getRequestTime() === time) {
        if (data.success) {
          const tableData = { [table]: data.data || [] };
          setRows(tableData);
        } else {
          dispatch(openSnackbar(data.message || 'There is a problem of getting table data.', 'error'));
        }
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function makeRequest() {
      if (!isEqual(triggers, previousProps) || refreshAllData) {
        if (notTestUser()) {
          await getTableData();
        } else {
          setLoading(false);
        }
      }
      return true;
    }
    makeRequest();
    // eslint-disable-next-line
  }, [triggers, previousProps, refreshAllData]);

  const modifyData = (newRows) => modifyDataHelper(rows, newRows, table, setRows);
  const modifyDeepLevelData = (params) => modifyMultiLaterDataHelper(params, rows, table, setRows);

  const {
    columns,
    localStorage: localStorageName,
    renderCell,
    title,
    rowDetail,
    helpLink,
    leftColumns = [],
    totalsMapping = {},
  } = tables()[table] || tables().ads;
  const data = formattedTableData(table, rows, hideBlocked, customFilter, (dataGridCustomization.filters || {})[localStorageName] || []);
  let finalColumns = [];
  if (typeof columns === 'function') {
    finalColumns = columns(budgetOptimization === 'ad_set');
  } else {
    finalColumns = columns;
  }

  return (
    <Grid item xs={12} className={classes.container}>
      <Paper>
        <TableOptionsBar
          title={title}
          helpLink={helpLink}
          table={table}
          data={data}
          columns={finalColumns}
          hideBlocked={hideBlocked}
          setHideBlocked={setHideBlocked}
          setCustomFilter={setCustomFilter}
          localStorage={localStorageName}
        />
        <div className={classes.tableContainer}>
          <Table
            height={700}
            allowedPageSizes={[0, 25, 50, 75, 100]}
            rows={data}
            columns={formattedColumns(table, finalColumns)}
            loading={loading}
            cellComponent={(cell) => (
              renderCell(cell, data, modifyData, campaignID, dispatch, modifyDeepLevelData)
            )}
            defaultColumnWidths={(
              getColumnWidths(formattedColumns(table, finalColumns))
            )}
            getColumnCompare={(
              getColumnCompare(formattedColumns(table, finalColumns))
            )}
            defaultSortingOrder={(
              getDefaultSortingOrder(formattedColumns(table, finalColumns))
            )}
            tableColumnLocalStorageName={localStorageName}
            renderRowDetail={typeof rowDetail === 'function' ? () => rowDetail(modifyData, data, dispatch, modifyDeepLevelData) : rowDetail}
            leftColumns={leftColumns}
            totalsMapping={totalsMapping}
          />
        </div>
      </Paper>
    </Grid>
  );
});

RenderTables.propTypes = {
  pageReducer: PropTypes.string.isRequired,
  tables: PropTypes.func.isRequired,
  filtersKeys: PropTypes.arrayOf(PropTypes.string),
};

RenderTables.defaultProps = {
  filtersKeys: [],
};

export default RenderTables;

import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from './actions/login';

/**
 * @return {boolean}
 */
export default function Logout() {
  const dispatch = useDispatch();
  const navitage = useNavigate();

  useEffect(() => {
    dispatch(logout(() => navitage('/login')));
    // eslint-disable-next-line
  }, []);

  return false;
}

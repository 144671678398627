import React from 'react';
import {
  Menu, MenuItem, IconButton,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  bool, func, instanceOf, oneOfType, string,
} from 'prop-types';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { changeFilter } from '../../Components/Navigation/SideNavigation/Filters/actions/filters';

const useStyles = makeStyles()((theme) => ({
  menu: {
    '&>div>ul': {
      padding: 0,
    },
  },
  selectedItem: {
    borderLeft: '2px solid',
    borderLeftColor: theme.palette.colors.blue,
    backgroundColor: theme.variantColor('rgba(0,0,0,0.04)', 'rgba(255,255,255,0.08)'),
  },
  statusName: {
    marginLeft: 6,
  },
  inputDiv: {
    fontSize: 16,
    color: theme.palette.colors.blue,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    borderRadius: 20,
    padding: 7,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    transition: 'all ease-in-out .25s',
    '&:hover': {
      opacity: '0.8',
    },
  },
  editedDiv: {
    color: 'white',
    backgroundColor: theme.palette.colors.blue,
    marginLeft: 10,
  },
  closeIcon: {
    marginLeft: 22,
    color: 'white',
  },
  titleItem: {
    height: 40,
    color: 'white',
    backgroundColor: theme.palette.colors.blue,
    fontWeight: 600,
    display: 'inline-flex',
    alignItems: 'center',
    width: '13rem',
    padding: 8,
    marginBottom: 6,
  },
}));

export default function FilterRowFilter(
  {
    filterTitle,
    filterList,
    filterName,
    customOnChange,
    customValue,
    filterCallback,
  },
) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [edited, setEdited] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { [filterName]: value } = useSelector((state) => state.filters);
  const dynamicCustomValue = () => customValue || value;
  const toggleList = (anchor = null) => setAnchorEl(anchor);
  const handleClick = (itemValue) => {
    setEdited(true);
    if (customOnChange && typeof customOnChange === 'function') {
      customOnChange(itemValue);
    } else {
      dispatch(changeFilter(filterName, itemValue));
      // dispatch(updateRefreshedAt());
    }
    filterCallback(itemValue);
    toggleList();
  };
  const renderCurrentStatus = () => {
    const matchingFilters = (
      filterList.filter((status) => status.itemValue === dynamicCustomValue())
    );
    if (matchingFilters && matchingFilters.length > 0) {
      return matchingFilters[0].title;
    }
    return '';
  };
  const renderMenu = () => (
    <Menu
      className={classes.menu}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={() => toggleList()}
    >
      <div className={classes.titleItem}>
        {filterTitle}
        <IconButton onClick={() => toggleList()} className={classes.closeIcon}>
          <Close />
        </IconButton>
      </div>
      {filterList.map(({ itemValue, title }) => (
        <MenuItem
          key={title}
          className={
            itemValue === dynamicCustomValue()
              ? classes.selectedItem
              : classes.unselectedItem
          }
          onClick={() => handleClick(itemValue)}
        >
          {title}
        </MenuItem>
      ))}
    </Menu>
  );
  const renderFilterButton = () => (
    // eslint-disable-next-line
    <div
      className={`${classes.inputDiv} ${edited ? classes.editedDiv : ''}`}
      onClick={(event) => toggleList(event.currentTarget)}
    >
      {filterTitle}
      :
      {' '}
      <span className={classes.statusName}>
        {renderCurrentStatus()}
      </span>
    </div>
  );
  return (
    <>
      {renderFilterButton()}
      {renderMenu()}
    </>
  );
}

FilterRowFilter.propTypes = {
  filterList: instanceOf(Object).isRequired,
  filterTitle: string.isRequired,
  filterName: string.isRequired,
  customOnChange: oneOfType([func, bool]).isRequired,
  customValue: string.isRequired,
};

import { makeStyles } from 'tss-react/mui';
import React from 'react';
import Input from '../../../../../../../Widgets/Forms/Fields/Input';
import SelectField from '../../../../../../../Widgets/Forms/Fields/Select';

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    background: 'white',
    borderBottom: 'solid 1px #e0e0e0',
    padding: '12px',
  },
  filters: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    paddingBottom: '1rem',
  },
  selectContainer: {
    paddingTop: '1rem',
  },
  inputContainer: {
    paddingTop: '12px',
    width: 'calc(33% - 1rem)',
    marginRight: '1rem',
  },
  title: {
    fontSize: '1.2rem',
    marginBottom: '-12px',
  },
  select: {
    height: '55px',
  },
}));

const typeOptions = [
  { title: 'All', value: 'all' },
  { title: 'Image', value: 'image' },
  { title: 'Video', value: 'video' },
];

export default function ({ filters, setFilters }) {
  const { classes } = useStyles();

  const onChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };
  return (
    <div className={classes.container}>
      <div className={classes.title}>Filters</div>
      <div className={classes.filters}>
        <div className={classes.inputContainer}>
          <Input
            name="name"
            label="Name"
            onChange={onChange}
            multiline
            rows="2"
          />
        </div>
        <div className={classes.inputContainer}>
          <Input
            name="primaryText"
            label="Primary Text"
            onChange={onChange}
            multiline
            rows="2"
          />
        </div>
        <div className={classes.inputContainer}>
          <Input
            name="title"
            label="Headline"
            placeholder="headline includes"
            onChange={onChange}
            multiline
            rows="2"
          />
        </div>
        <div className={classes.inputContainer}>
          <Input
            name="body"
            label="Description"
            placeholder="Description includes"
            onChange={onChange}
            multiline
            rows="2"
          />
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.selectContainer}>
            <SelectField
              name="type"
              label="Type"
              value={filters.type}
              onChange={onChange}
              tempOptions={async () => typeOptions}
              options={async () => typeOptions}
              fullWidth
              className={classes.select}
              noMargin
            />
          </div>
        </div>
      </div>
    </div>
  );
}

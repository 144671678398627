export const calculateTotals = (rows, totalMapping, callback) => {
  const totals = {};
  if (rows && Array.isArray(rows) && rows.length > 0 && rows[0] !== undefined) {
    rows.forEach((valueObj) => {
      Object.keys(valueObj).forEach((objKey) => {
        const value = valueObj[objKey] ? valueObj[objKey].toString().replace(/[^\d.-]/g, '') : '';
        if (totalMapping[objKey] && totalMapping[objKey].type && totalMapping[objKey].type !== 'header') {
          if (!totalMapping[objKey].type.toString().includes('average') && !totalMapping[objKey].type.toString().includes('percent')) {
            Object.assign(totals, {
              [objKey]: (totals[objKey] || 0) + (value ? parseFloat(value) : 0),
            });
          }
        } else {
          Object.assign(totals, { [objKey]: false });
        }
      });
    });
  }

  Object.keys(totalMapping).forEach((key) => {
    let dividend = typeof totals[totalMapping[key].dividend] !== 'undefined' ? totals[totalMapping[key].dividend] : null;
    let divisor = typeof totals[totalMapping[key].divisor] !== 'undefined' ? totals[totalMapping[key].divisor] : null;
    const sum = (accumulator, currentValue) => accumulator + currentValue;

    if (dividend === null && totalMapping[key].dividend
      && totalMapping[key].dividend.constructor === Array) {
      const aux = totalMapping[key].dividend.map((dividendKey) => totals[dividendKey]);
      dividend = aux.reduce(sum);
    }
    if (divisor === null && totalMapping[key].divisor
      && totalMapping[key].divisor.constructor === Array) {
      const aux = totalMapping[key].divisor.map((divisorKey) => totals[divisorKey]);
      divisor = aux.reduce(sum);
    }
    if (divisor === null && totalMapping[key].divisor
      && typeof totalMapping[key].divisor === 'function') {
      divisor = totalMapping[key].divisor(rows);
    }
    if (dividend === null && totalMapping[key].dividend
      && typeof totalMapping[key].dividend === 'function') {
      dividend = totalMapping[key].dividend(rows);
    }
    if (totalMapping[key] && totalMapping[key].type.toString().includes('average')) {
      if (divisor === 0) {
        Object.assign(totals, { [key]: 0 });
      } else {
        Object.assign(totals, { [key]: (dividend / divisor) });
      }
    } else if (totalMapping[key] && totalMapping[key].type.toString().includes('margin')) {
      if (divisor === 0) {
        Object.assign(totals, { [key]: 0 });
      } else {
        Object.assign(totals, { [key]: ((dividend / divisor) * 100) - 100 });
      }
    } else if (totalMapping[key] && totalMapping[key].type.toString().includes('percent')) {
      if (divisor === 0) {
        Object.assign(totals, { [key]: 0 });
      } else {
        const sign = (dividend * divisor) < 0 ? -1 : 1;
        const result = (Math.abs(dividend / divisor) * 100 > 100
          ? 100
          : Math.abs(dividend / divisor) * 100);
        Object.assign(totals, {
          [key]: sign * result,
        });
      }
    } else if (totalMapping[key] && totalMapping[key].type.toString().includes('per_mile')) {
      if (divisor === 0) {
        Object.assign(totals, { [key]: 0 });
      } else {
        Object.assign(totals, { [key]: (dividend / divisor) * 1000 });
      }
    }
  });

  callback({ ...totals, timestamp: (new Date()).getTime() });
};

import React from 'react';
import { Table as TableContainer } from './index';

export default function Table(props) {
  return (
    <TableContainer
      allowedPageSizes={[10, 25, 50, 100]}
      rows={props.rows}
      columns={props.columns}
      loading={props.loading}
      cellComponent={props.cellComponent}
      defaultColumnWidths={props.defaultColumnWidths}
      getColumnCompare={props.getColumnCompare}
      rightColumns={props.rightColumns}
      tableColumnLocalStorageName={props.tableColumnLocalStorageName}
      defaultSortingOrder={props.defaultSortingOrder}
      mobileHeight={props.rows.length > 5 ? 500 : 250}
      {...props}
    />
  );
}

Table.defaultProps = {
  cellComponent: () => {},
  defaultColumnWidths: [],
  tableColumnLocalStorageName: 'table',
  remotePagination: false,
  loading: false,
  rows: [],
  rightColumns: [],
  columns: [],
  defaultSortingOrder: [],
};

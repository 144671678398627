import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { startCase } from 'lodash';
import { updateRefreshedAt } from '../SideNavigation/Filters/DateRange/actions/dateRange';

const useStyles = makeStyles()((theme) => ({
  pageNavigation: {
    position: 'relative',
    margin: '2px 8px',
    padding: 15,
    backgroundColor: 'transparent',
    textAlign: 'center',
    border: 'none',
    borderRadius: '4px',
    fontSize: 16,
    color: 'white!important',
    cursor: 'pointer',
    transition: 'color ease-in-out .25s, background-color ease-in-out .25s',
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
      margin: '2px 4px',
      padding: '15px 4px',
    },
    '&:hover': {
      backgroundColor: theme.variantColor('rgba(0, 0, 0, 0)', 'rgba(230, 229, 232, 0)'),
      '&::after': {
        visibility: 'visible',
        width: '50%',
        left: '50%',
        marginLeft: '-25%',
        transform: 'scaleX(1)',
      },
    },
    '&:focus': {
      outline: 'none',
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '2px',
      bottom: '0px',
      left: '0',
      backgroundColor: '#ffffff',
      visibility: 'hidden',
      transform: 'scaleX(0)',
      transition: 'all 0.3s ease-in-out 0s',
    },
  },
  selected: {
    color: '#2c8fff!important',
    fontWeight: 'bold',
  },
}));

const pages = [
  { title: 'campaigns', link: '/campaigns' },
  { title: 'add campaign', link: '/campaigns/add' },
  // { title: 'creatives', link: '/creatives' },
];

const PageNavigation = React.memo(() => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { page } = useSelector((state) => state.page);

  const renderNavigationButton = (title, link) => (
    <button
      key={title}
      type="button"
      onClick={() => {
        navigate(link);
        dispatch(updateRefreshedAt());
      }}
      disabled={page === title}
      className={`
              ${classes.pageNavigation} ${page === title && classes.selected}
            `}
    >
      {startCase(title)}
    </button>
  );

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return !isMobile && (
    <div className={classes.sectionItems}>
      {pages.map(({ title, link }) => (renderNavigationButton(title, link)))}
    </div>
  );
});

export default PageNavigation;

import React from 'react';
import { capitalize } from 'lodash';
import { Grid, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NetworkRequestPost } from '../../Helpers/NetworkRequest';
import { login } from './actions/login';
import { openRightDrawer, openSnackbar } from '../../Widgets/actions/widgets';
import Input from '../../Widgets/Forms/Fields/Input';
import SubmitButton from '../../Widgets/Forms/Buttons/SubmitButton';
import ForgotPassword from './ForgotPassword';
import * as validation from './validation';
import loginBG from '../../assets/images/loginBG.jpeg';
import maximusLogoLight from '../../assets/images/maximus_icon.png';
import maximusLogoDark from '../../assets/images/Maximus_Social_Logo_White.png';

const maximusIconDark = `${process.env.PUBLIC_URL}/assets/maximus_icon.png`;

const useStyles = makeStyles()((theme) => ({
  root: {
    height: '100vh',
  },
  rightGrid: {
    height: '100%',
    position: 'relative',
  },
  loginContainer: {
    display: 'inline-flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  links: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    bottom: '1rem',
  },
  link: {
    paddingRight: '1rem',
    paddingLeft: '1rem',
  },
  logoGrid: {
    height: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logoContainer: {
    backgroundImage: `url(${loginBG})`,
    backgroundColor: '#333',
    height: '100%',
    boxShadow: '0 0 12px -1px',
  },
  logo: {
    margin: '0 auto',
    width: '30rem',
    maxWidth: '80%',
  },
  helmet: {
    margin: '0 auto',
    width: '8rem',
    marginBottom: '2rem',
  },
  loginFormContainer: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  loginForm: {
    marginBottom: '1rem',
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: '20rem',
  },
  forgotPasswordButton: {
    textAlign: 'center',
  },
}));

function Login() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState({ value: '', error: '' });
  const [password, setPassword] = React.useState({ value: '', error: '' });
  const { loggedIn } = useSelector((state) => state.login);
  const [submitting, setSubmitting] = React.useState(false);
  const [darkModeActive] = React.useState(localStorage.getItem('setDarkMode') === 'true');

  const handleLogin = async () => {
    setSubmitting(true);
    const { data: { data = {}, success = false, message } } = await NetworkRequestPost('login', { email: email.value, password: password.value });

    if (success && data.token) {
      localStorage.setItem('token', data.token);
      localStorage.setItem('email', email.value);
      dispatch(login());
      navigate('/campaigns');
      setSubmitting(false);
    } else {
      setSubmitting(false);
      dispatch(openSnackbar(message || 'Your login was incorrect. Please try again or click the Forgot Password below. ', 'error'));
    }
  };

  const showForgotPassword = () => dispatch(
    openRightDrawer(
      'Forgot Password?',
      <ForgotPassword />,
    ),
  );

  const renderLoginButton = () => (
    <Grid item xs={12}>
      <SubmitButton
        handleSubmit={handleLogin}
        isLoading={submitting}
        disabled={email.error || password.error}
        content="Login"
      />
    </Grid>
  );

  const renderInput = (name, value, updateFunction, validFunc) => (
    <Grid item xs={12}>
      <Input
        name={name}
        type={name}
        label={capitalize(name)}
        value={value.value}
        onChange={(val, state, error) => updateFunction({ value: val, error })}
        className={classes.textField}
        validationFunc={validFunc}
        helperText={value.error}
        error={value.error}
      />
    </Grid>
  );

  const renderLogo = () => (
    <Grid item xs={12}>
      <img alt="" src={(darkModeActive && maximusIconDark) || maximusLogoLight} className={classes.helmet} />
    </Grid>
  );

  const renderMaximusLogo = () => (
    <Grid item xs={12}>
      <img alt="" src={maximusLogoDark} className={classes.logo} />
    </Grid>
  );

  const renderForgotPasswordButton = () => (
    <div className={classes.forgotPasswordButton}>
      <Button onClick={showForgotPassword}>
        Forgot Password?
      </Button>
    </div>
  );

  const renderLoginRight = () => (
    <Grid className={classes.rightGrid} item xs={12} md={6}>
      <div className={classes.loginContainer}>
        <Grid container justify="center" alignItems="center" className={classes.loginFormContainer}>
          {renderLogo()}
          <form className={classes.loginForm} onSubmit={(e) => e.preventDefault()}>
            {renderInput('email', email, setEmail, validation.emailValidation)}
            {renderInput('password', password, setPassword)}
            {renderLoginButton()}
            {renderForgotPasswordButton()}
          </form>
        </Grid>
      </div>
      <div className={classes.links}>
        <a
          className={classes.link}
          href="/privacy"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Privacy opens in new window"
        >
          Privacy
        </a>
        <a
          className={classes.link}
          href="/terms"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Terms opens in new window"
        >
          Terms Of Service
        </a>
      </div>
    </Grid>
  );

  const renderLoginLeft = () => (
    <Grid item xs={12} md={6} className={classes.logoGrid}>
      <Grid container justify="center" alignItems="center" className={classes.logoContainer}>
        <Grid item xs={12}>
          {renderMaximusLogo()}
        </Grid>
      </Grid>
    </Grid>
  );

  if (loggedIn) return (navigate('/'));

  return (
    <Grid container justify="center" alignItems="center" className={classes.root}>
      {renderLoginLeft()}
      {renderLoginRight()}
    </Grid>
  );
}

export default Login;

import {
  checkBidAmount, checkBudget, checkROASFloor, checkStartDate,
} from '../../../../Validation/validations';

export const descriptionLength = 90;
export const headlineLength = 40;
export const primaryTextLength = 125;
export const nameLength = 100;

export const validImages = (ad) => (ad.squareMedia && ad.squareMedia.url && ad.squareMedia.width === 1080 && ad.squareMedia.width === 1080)
  || (ad.portraitMedia && ad.portraitMedia.url && ad.portraitMedia.width === 1080 && ad.portraitMedia.height === 1920)
  || (ad.landscapeMedia && ad.landscapeMedia.url && ad.landscapeMedia.width === 1920 && ad.landscapeMedia.height === 1080);
export const validAdTitle = (ad) => ad.title && ad.title.length >= 1 && ad.title.trim().length <= headlineLength;
export const validPrimaryText = (ad) => ad.primaryText && ad.primaryText.length >= 1 && ad.primaryText.trim().length <= primaryTextLength;
export const validAdDescription = (ad) => ad.body && ad.body.length >= 1 && ad.body.trim().length <= descriptionLength;
export const validateName = (ad) => ad.name && typeof ad.name === 'string' && ad.name.length >= 2 && ad.name.trim().length <= nameLength;
export const validateCallToAction = (ad) => ad.callToAction;

const validateAd = (ad, callback) => {
  const errors = [];
  if (!validImages(ad)) {
    errors.push('Make sure your ads have at least one valid image.');
  }
  if (!validPrimaryText(ad)) {
    errors.push(`Make sure all of your ads primary text fields are between 1 - ${primaryTextLength} characters in length.`);
  }
  if (!validAdTitle(ad)) {
    errors.push(`Make sure all of your ad headlines are between 1 - ${headlineLength} characters in length.`);
  }
  if (!validAdDescription(ad)) {
    errors.push(`Make sure all of your ad descriptions are between 1 - ${descriptionLength} characters in length.`);
  }
  if (!validateName(ad)) {
    errors.push(`Make sure all of your ad names are between 1 - ${nameLength} characters in length.`);
  }
  if (!validateCallToAction(ad)) {
    errors.push('Make sure all of your ads have a call to action selected.');
  }
  if (errors.length) {
    callback(errors);
  }
  return errors.length;
};

export const validStartAt = (startAt) => checkStartDate(startAt) === '';
export const validGender = (genderTargeting) => genderTargeting.length;
export const validPixelID = (pixel) => pixel && pixel !== 'Select';
export const validAgeTargeting = (ageTargeting) => ageTargeting.length === 2 && ageTargeting[0] >= 13 && ageTargeting[1] <= 65;
export const validName = (name) => name && typeof name === 'string' && name.length >= 2 && name.length <= 100;

export const validateAdSetAds = (ads, callback) => {
  const adErrors = ads.map((ad) => validateAd(ad, callback));
  return adErrors.filter((adError) => adError > 0).length === 0;
};

export const validateAdSet = (newAdSet, budgetOptimization, callback, editMode = false) => {
  const {
    name,
    budget,
    bidStrategy,
    ads,
    genderTargeting,
    ageTargeting,
    startAt,
    roasFloor,
    bidAmount,
    pixelID,
  } = newAdSet;
  const errors = [];
  if (!validName(name)) {
    errors.push('Please make sure to name your ad set, and that its between 2 - 100 characters long');
  }
  if (budgetOptimization === 'ad_set') {
    const budgetError = checkBudget(budget, bidStrategy, bidAmount);
    if (budgetError) {
      errors.push(budgetError);
    }
    if (bidStrategy === 'LOWEST_COST_WITH_MIN_ROAS' && checkROASFloor(roasFloor)) {
      errors.push(checkROASFloor(roasFloor));
    }
    if (bidStrategy !== 'LOWEST_COST_WITHOUT_CAP' && checkBidAmount(bidAmount)) {
      errors.push(checkBidAmount(bidAmount));
    }
  }
  if (!editMode && !validStartAt(startAt)) {
    errors.push('Make sure your start date is not in the past');
  }
  if (!editMode && !validPixelID(pixelID)) {
    errors.push('Please select a pixel');
  }
  if (!validGender(genderTargeting)) {
    errors.push('Please select at least one gender to target');
  }
  if (!validAgeTargeting(ageTargeting)) {
    errors.push('Please check that your age targeting is set to ages between 13 - 65');
  }
  if (errors.length) {
    callback(errors);
    return false;
  }
  if (!editMode) {
    return validateAdSetAds(ads, callback);
  }
  return true;
};

import React, { useEffect } from 'react';
import { SwipeableDrawer, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import Section from './PageNavigation/Section/Section';
import { toggleMenu } from './actions/sideNavigation';

export const drawerWidth = 250;

const useStyles = makeStyles()((theme) => ({
  container: {},
  label: {
    color: 'black',
    padding: '10px 10px 10px 25px',
  },
  currentlyViewing: {
    color: '#282C34',
    fontWeight: 600,
    opacity: 0.6,
    marginBottom: 6,
  },
  drawer: {
    flexGrow: 1,
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: '1px solid',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  drawerContents: {
    height: '100%',
    width: '100%',
    paddingTop: 70,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 10,
    },
  },
  filter: {
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
    },
  },
  breakPoint: {
    margin: '15px 0',
    borderTop: 'solid 1px rgba(255,255,255,0.1)',
    borderTopColor: theme.variantColor('rgba(0,0,0,0.1)', 'rgba(255,255,255,0.1)'),
  },
}));

function SideNavigation() {
  const { open, menuMapping, menuLabel } = useSelector((state) => ({
    open: state.sideNavigation.open,
    menuMapping: state.page.menuMapping,
    menuLabel: state.campaignDetails.menuLabel,
  }), shallowEqual);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const {
    filters,
    breadCrumbs,
    linkSections,
    tables = [],
    editCampaign = [],
    defaultClose = false,
    settingsSections = [],
  } = menuMapping;

  useEffect(() => {
    if (open && defaultClose) {
      dispatch(toggleMenu(open));
    } else if (!open && !defaultClose) {
      dispatch(toggleMenu(open));
    }
    // eslint-disable-next-line
  }, [defaultClose]);

  const theme = useTheme();
  const biggerScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <SwipeableDrawer
      className={classes.drawer}
      variant={biggerScreen ? 'persistent' : 'temporary'}
      classes={{ paper: classes.drawerPaper }}
      open={open}
      onOpen={() => {}}
      onClose={biggerScreen ? () => {} : () => dispatch(toggleMenu(open))}
    >
      <div className={classes.drawerContents}>
        {menuLabel && (
          <div className={classes.label}>
            <div className={classes.currentlyViewing}>Currently Viewing</div>
            {menuLabel}
          </div>
        )}
        {breadCrumbs && breadCrumbs.length > 0 && (
        <span>
          {breadCrumbs.map((breadCrumb, index) => <Section key={`${index + 1}`} section={breadCrumb} />)}
        </span>
        )}
        {filters.length > 0 && (
        <span>
          {filters.map((filter, index) => <Section key={`${index + 1}`} section={filter} />)}
        </span>
        )}
        {linkSections.map((section, index) => <Section key={`${index + 1}`} section={section} />)}
        {tables.length > 0 && (
        <span>
          {tables.map((section, index) => <Section key={`${index + 1}`} section={section} />)}
        </span>
        )}
        {editCampaign.length > 0 && (
        <span>
          {editCampaign.map((section, index) => <Section key={`${index + 1}`} section={section} />)}
        </span>
        )}
        {settingsSections.map((section, index) => <Section key={`${index + 1}`} section={section} />)}
      </div>
    </SwipeableDrawer>
  );
}

export default (SideNavigation);

let baseURL = () => 'https://api.maximussocial.com';
const LOCALHOST = process.env.REACT_APP_LOCALHOST || '';

if (LOCALHOST) {
  baseURL = () => `http://${LOCALHOST}`;
}

export const getDataUrl = () => ({
  auth: `${baseURL()}/api/v1/`,
  login: `${baseURL()}/login`,
  initialData: `${baseURL()}/api/v1/reporting/initialData`,
  forgotPassword: `${baseURL()}/users/password/forgot`,
  changePassword: `${baseURL()}/api/v1/users/password/change`,
  campaigns: `${baseURL()}/api/v1/reporting/campaignList`,
  creatives: `${baseURL()}/api/v1/reporting/creatives`,
  campaignStatus: `${baseURL()}/api/v1/campaign/status`,
  toggleImageStatus: `${baseURL()}/api/v1/campaign/ads/status`,
  toggleAdSetStatus: `${baseURL()}/api/v1/campaign/adsets/status`,
  getAdSetStatus: `${baseURL()}/api/v1/campaign/adsets/status`,
  budget: `${baseURL()}/api/v1/campaign/budget`,
  adSetBudget: `${baseURL()}/api/v1/campaign/adsets/budget`,
  createAdSet: `${baseURL()}/api/v1/campaign/adsets/add`,
  copyAdSets: `${baseURL()}/api/v1/campaign/adsets/copy`,
  usersAdSetsList: `${baseURL()}/api/v1/campaign/adsets/`,
  campaignAdSetsList: `${baseURL()}/api/v1/campaign/adsets/map`,
  addAds: `${baseURL()}/api/v1/campaign/ads/add`,
  usersAds: `${baseURL()}/api/v1/users/ads`,
  updateCampaignName: `${baseURL()}/api/v1/campaign/name`,
  addCampaign: `${baseURL()}/api/v1/campaign/add`,
  cloneCampaign: `${baseURL()}/api/v1/campaign/clone`,
  getUserSettings: `${baseURL()}/api/v1/users/get`,
  updateSettings: `${baseURL()}/api/v1/users/change`,
  uploadMedia: `${baseURL()}/api/v1/campaign/upload`,
  uploadImage: `${baseURL()}/api/v1/campaign/ads/uploadImage`,
  uploadVideo: `${baseURL()}/api/v1/campaign/ads/uploadVideo`,
  getAdSet: `${baseURL()}/api/v1/campaign/adsets/get`,
  saveAdSet: `${baseURL()}/api/v1/campaign/adsets/set`,
  getUsersFilter: `${baseURL()}/api/v1/users/getFilterOptions`,
  getAccounts: `${baseURL()}/api/v1/users/accounts/get`,
  getSubAccounts: `${baseURL()}/api/v1/users/subAccounts/get`,
  dayparting: `${baseURL()}/api/v1/campaign/dayparting/`,
  daypartingAdSet: `${baseURL()}/api/v1/campaign/adsets/dayparting/`,
  userLog: `${baseURL()}/api/v1/reporting/user`,
  getLinks: `${baseURL()}/api/v1/campaign/links/get`,
  updateLinks: `${baseURL()}/api/v1/campaign/links/update`,
  glance: `${baseURL()}/api/v1/reporting/glance`,
  adSetsGlance: `${baseURL()}/api/v1/reporting/adsets/glance`,
  adsGlance: `${baseURL()}/api/v1/reporting/ads/glance`,
  regionDropdown: `${baseURL()}/api/v1/campaign/dropdowns/regions`,
  pixelsDropdown: `${baseURL()}/api/v1/campaign/dropdowns/pixels`,
  adsReport: `${baseURL()}/api/v1/reporting/ads`,
  ageReport: `${baseURL()}/api/v1/reporting/age`,
  genderReport: `${baseURL()}/api/v1/reporting/gender`,
  stateReport: `${baseURL()}/api/v1/reporting/state`,
  adSetReport: `${baseURL()}/api/v1/reporting/adsets`,
  fbAuth: `${baseURL()}/api/v1/fb`,
});

/* eslint-disable no-case-declarations */
import { format } from 'date-fns';
import {
  CHANGE_STEP, CHANGE_VALUE,
  CHANGE_LINK, ADD_LINK, REMOVE_LINK,
  RESET_ADD_CAMPAIGN, CHANGE_NON_FIELD,
  CHANGE_AD_FIELD, CHANGE_AD_SET_FIELD,
} from '../actions/addCampaign';

const setValueObj = (value = '', error = '') => ({ value, error });

export const newAd = () => ({
  title: '',
  body: '',
  primaryText: '',
  callToAction: '',
  name: '',
  type: '',
  squareMedia: {
    url: '',
    width: '',
    height: '',
  },
  landscapeMedia: {
    url: '',
    width: '',
    height: '',
  },
  portraitMedia: {
    url: '',
    width: '',
    height: '',
  },
});

export const newAdSet = () => ({
  name: '',
  startAt: format(new Date(), 'yyyy-MM-dd'),
  genderTargeting: ['1', '2'],
  ageTargeting: [18, 65],
  includeLocations: [],
  budget: '',
  pixelID: 'Select',
  bidStrategy: 'COST_CAP',
  roasFloor: '0',
  bidAmount: '0',
  ads: [newAd()],
});

export const today = () => new Date().toISOString().split('T');

export const initialState = {
  submitting: false,
  cloneLoading: false,
  pulledSubAccounts: false,
  isClone: false,
  allowDenyListsLoaded: false,
  dayPartingLoaded: false,
  generatingAds: false,
  currentStep: 0,
  pixelOptions: [{ value: 'Select', title: 'Please select a pixel' }],
  accountOptions: [{ value: 'Select', title: 'Please select an account' }],
  subAccountOptions: [{ value: 'Select', title: 'Please select a sub account' }],
  settings: {
    startDate: setValueObj(today()[0]),
    campaignName: setValueObj(''),
    budget: setValueObj(''),
    setupDayparting: setValueObj(false),
    budgetOptimization: setValueObj('campaign'),
    bidStrategy: setValueObj('COST_CAP'),
    roasFloor: setValueObj('0'),
    bidAmount: setValueObj('0'),
    account: setValueObj('Select'),
    subAccount: setValueObj('Select'),
  },
  landingPageLinks: {
    0: {
      url: setValueObj(''),
      weight: setValueObj(100),
    },
  },
  presellLinks: {
    0: {
      url: setValueObj(''),
      weight: setValueObj(100),
    },
  },
  links: {
    safePageLink: setValueObj(''),
    advancedTracking: setValueObj(false),
  },
  adSets: [],
  dayPartingData: {
    dayparting: [],
    settingsTable: [],
  },
  newAdSet: newAdSet(),
};

export const addCampaign = (state = initialState, action = {}) => {
  const updatedAdSet = JSON.parse(JSON.stringify(state.newAdSet));
  switch (action.type) {
    case CHANGE_STEP:
      return {
        ...state,
        currentStep: action.step,
      };
    case CHANGE_NON_FIELD:
      return {
        ...state,
        [action.key]: action.value,
      };
    case CHANGE_AD_FIELD:
      updatedAdSet.ads[action.index][action.name] = action.value;
      return {
        ...state,
        newAdSet: updatedAdSet,
      };
    case CHANGE_AD_SET_FIELD:
      updatedAdSet[action.name] = action.value;
      return {
        ...state,
        newAdSet: updatedAdSet,
      };
    case CHANGE_VALUE:
      return {
        ...state,
        [action.section]: {
          ...state[action.section],
          [action.name]: { value: action.value, error: action.error },
        },
      };
    case CHANGE_LINK:
      return {
        ...state,
        [action.section]: {
          ...state[action.section],
          [action.index]: {
            ...state[action.section][action.index],
            [action.key]: action.value,
          },
        },
      };
    case ADD_LINK:
      return {
        ...state,
        [action.section]: {
          ...state[action.section],
          [Object.keys(state[action.section]).length]: {
            url: setValueObj(),
            weight: setValueObj(100),
          },
        },
      };
    case REMOVE_LINK:
      // eslint-disable-next-line no-case-declarations
      const links = { ...state[action.section] };
      delete links[action.index];
      return {
        ...state,
        [action.section]: { ...links },
      };
    case RESET_ADD_CAMPAIGN:
      return { ...initialState };
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableHead, TableCell, IconButton, TableRow, Button, FormHelperText,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Delete } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import {
  daysMapping,
  returnRelatedDays,
  returnRemovedDataCode,
  returnRemoveOneDayTime,
  sortDayByTimes,
} from './helper';
import { closeDialog, openDialog } from '../../../../Widgets/actions/widgets';

const useStyles = makeStyles()((theme) => ({
  tableContainer: {
    border: 'solid 2px',
    borderColor: theme.palette.variantText,
    borderRadius: 8,
    overflow: 'hidden',
    marginTop: '1em',
    marginBottom: 18,
  },
  weekdayCell: {
    textTransform: 'capitalize',
  },
  nonPrimaryDay: {
    color: theme.variantColor('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.35)'),
  },
  timeCell: {
    textTransform: 'capitalize',
  },
  oneDayButton: {
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.colors.blue,
    '&:hover': {
      opacity: '0.8',
      color: theme.palette.colors.white,
      backgroundColor: theme.palette.colors.blue,
    },
  },
  allDaysButton: {
    color: theme.palette.colors.white,
    backgroundColor: '#4caf50',
    '&:hover': {
      opacity: '0.8',
      color: theme.palette.colors.white,
      backgroundColor: '#4caf50',
    },
  },
  formContainer: {
    minWidth: 500,
  },
  container: {
    width: '100%',
  },
  actions: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  dayList: {
    textTransform: 'capitalize',
  },
  helper: {
    marginLeft: '1em',
  },
}));

function RemoveDaysOption({
  settings, setSettings, day, dataCode,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const removeAllDays = () => {
    setSettings(returnRemovedDataCode(settings, dataCode));
    dispatch(closeDialog());
  };

  const removeThisOneDay = () => {
    setSettings(returnRemoveOneDayTime(settings, day, dataCode));
    dispatch(closeDialog());
  };

  const renderThisOneDay = () => (
    <Button
      className={classes.oneDayButton}
      type="submit"
      onClick={removeThisOneDay}
    >
      This One Day
    </Button>
  );

  const renderAllDays = () => (
    <Button
      className={classes.allDaysButton}
      type="button"
      onClick={removeAllDays}
    >
      All Days
    </Button>
  );

  return (
    <form onSubmit={(e) => { e.preventDefault(); }} className={classes.formContainer}>
      <div>This setting is split among these days:</div>
      <ul>
        {returnRelatedDays(settings, dataCode).map((relatedDay) => (
          <li className={classes.dayList}>{relatedDay}</li>
        ))}
      </ul>
      <div>
        Do you want to remove all days sharing this time slot, or only the time on this one day?
      </div>
      <div className={classes.actions}>
        {renderAllDays()}
        {renderThisOneDay()}
      </div>
    </form>
  );
}

export default function SettingsTable({ settings, setSettings, fullWidth }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const renderRows = () => (
    Object.values(daysMapping).map((day) => settings[day] && (
      sortDayByTimes(settings[day]).map(({
        startAt, endAt, dataCode, budget,
      }, timeIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableRow key={timeIndex}>
          <TableCell className={`${classes.weekdayCell} ${timeIndex !== 0 ? classes.nonPrimaryDay : ''}`}>{day}</TableCell>
          <TableCell className={classes.timeCell}>
            {`${startAt} - ${endAt}`}
          </TableCell>
          <TableCell className={classes.timeCell}>
            {budget.toString() !== '0' ? `$${budget}` : 'NA'}
          </TableCell>
          <TableCell className={classes.deleteCell}>
            <IconButton
              onClick={() => {
                if (returnRelatedDays(settings, dataCode).length > 1) {
                  dispatch(openDialog('Please Choose: ', <RemoveDaysOption setSettings={setSettings} settings={settings} dataCode={dataCode} day={day} />));
                } else {
                  setSettings(returnRemovedDataCode(settings, dataCode));
                }
              }}
            >
              <Delete />
            </IconButton>
          </TableCell>
        </TableRow>
      ))
    ))
  );

  function renderTable() {
    return (
      <div className={classes.tableContainer} style={fullWidth ? { width: '100%' } : {}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Day</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Budget</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRows()}
          </TableBody>
        </Table>
      </div>
    );
  }

  return Object.keys(settings).length < 1 ? false : (
    <div className={classes.container} style={fullWidth ? { width: '100%' } : {}}>
      <FormHelperText className={classes.helper}>
        This campaign will be paused during these times
      </FormHelperText>
      {renderTable()}
    </div>
  );
}

SettingsTable.propTypes = {
  settings: PropTypes.instanceOf(Object).isRequired,
  setSettings: PropTypes.func.isRequired,
};

RemoveDaysOption.propTypes = {
  settings: PropTypes.instanceOf(Object).isRequired,
  setSettings: PropTypes.func.isRequired,
  day: PropTypes.string.isRequired,
  dataCode: PropTypes.string.isRequired,
};

import React from 'react';
import { makeStyles } from 'tss-react/mui';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch, useSelector } from 'react-redux';
import Media from './Media';
import Fields from './Fields';
import { changeNonField } from '../../../../../../actions/addCampaign';

const useStyles = makeStyles()((theme) => ({
  container: {
    width: 'calc(100% - 24px)',
    borderBottom: 'solid 1px rgba(0,0,0,0.2)',
    minHeight: '10rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    height: '100%',
    padding: 12,
    position: 'relative',
  },
  removeRow: {
    color: 'red',
    background: 'none',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    transition: 'ease-in-out 200ms',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 8,
    '&:hover': {
      opacity: 0.8,
    },
  },
  cloneButton: {
    color: theme.palette.colors.blue,
    background: 'none',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    transition: 'ease-in-out 200ms',
    position: 'absolute',
    top: 0,
    left: 0,
    padding: 8,
    '&:hover': {
      opacity: 0.8,
    },
  },
  icon: {
    width: 25,
    height: 25,
  },
}));

export default function ({ index }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { newAdSet } = useSelector((store) => store.addCampaign);
  const onDuplicate = (event) => {
    event.preventDefault();
    const adSet = JSON.parse(JSON.stringify(newAdSet));
    adSet.ads.push(adSet.ads[index]);
    dispatch(changeNonField('newAdSet', adSet));
  };
  const onRemove = (event) => {
    event.preventDefault();
    const adSet = JSON.parse(JSON.stringify(newAdSet));
    adSet.ads.splice(index, 1);
    dispatch(changeNonField('newAdSet', adSet));
  };
  return (
    <div className={classes.container}>
      <Media index={index} />
      <Fields index={index} />
      <button title="Click To Duplicate" className={classes.cloneButton} onClick={onDuplicate} type="button">
        <ContentCopyIcon className={classes.icon} />
      </button>
      {index !== 0 && (
        <button title="Click To Remove" className={classes.removeRow} onClick={onRemove} type="button">
          <RemoveCircleOutlineIcon className={classes.icon} />
        </button>
      )}
    </div>
  );
}

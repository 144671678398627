import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import FormDrawer from '../../Widgets/Drawer/FormDrawer/FormDrawer';
import Input from '../../Widgets/Forms/Fields/Input';
import { NetworkRequestPut } from '../../Helpers/NetworkRequest';
import { closeRightDrawer, openSnackbar } from '../../Widgets/actions/widgets';
import { emailValidation } from './validation';

const useStyles = makeStyles()(() => ({
  heading: {
    marginTop: 20,
    marginBottom: 20,
  },
  forgotPasswordForm: {
    width: '100%',
  },
}));

export default function ForgotPassword() {
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState({ value: '', error: '' });
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const submitForgotPassword = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setSubmitting(true);
    const { data: { success, message } } = await NetworkRequestPut('forgotPassword', { email: email.value });
    if (success) {
      dispatch(openSnackbar('Success! Please check your email for your new temporary password.', 'success'));
      setSubmitting(false);
      dispatch(closeRightDrawer());
    } else {
      dispatch(openSnackbar((message && Array.isArray(message)
        && message.map((error) => <p>{error}</p>)) || 'Something went wrong. Please try again', 'error'));
      setSubmitting(false);
    }
  };

  return (
    <FormDrawer
      onSubmit={submitForgotPassword}
      submitting={submitting}
    >
      <Typography gutterBottom className={classes.heading}>
        Please enter the email address to your Maximus Social account below.
        <br />
        A new password will be sent to the email address provided.
      </Typography>
      <form className={classes.forgotPasswordForm} onSubmit={submitForgotPassword}>
        <Input
          name="email"
          type="email"
          label="Email"
          value={email.value}
          onChange={(value, name, error) => setEmail({ value, error })}
          validationFunc={emailValidation}
          helperText={email.error}
        />
      </form>
    </FormDrawer>
  );
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import { bool } from 'prop-types';
import Switch from '../../../../Widgets/Forms/Switch/Switch';
import { handleAddCampaignChange, addWeightedLink as addWeightedLinkAddCampaign } from '../../actions/addCampaign';
import { addWeightedLink, changeAdvancedTracking } from '../../../CampaignDetails/actions/campaignDetails';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 12,
  },
  title: {
    display: 'inline-block',
    marginRight: '15px',
  },
  explanationText: {
    fontSize: 12,
  },
}));

function AdvancedTrackingToggle({ campaignDetails }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const {
    advancedTracking: { value: checked }, presellLinks,
  } = useSelector((state) => (campaignDetails
    ? state.campaignDetails.links
    : state.addCampaign.links));

  function handleClick() {
    if (checked === false && presellLinks && Object.keys(presellLinks).length < 1) {
      if (campaignDetails) {
        dispatch(
          addWeightedLink('presellLinks'),
        );
      } else {
        dispatch(
          addWeightedLinkAddCampaign('presellLinks'),
        );
      }
    }
    if (!campaignDetails) {
      return dispatch(
        handleAddCampaignChange('links', 'advancedTracking', !checked),
      );
    }
    return dispatch(
      changeAdvancedTracking(!checked),
    );
  }

  return (
    <Grid item xs={12}>
      <div className={classes.container}>
        <div className={classes.title}>
          Advanced Tracking:
        </div>
        <Switch
          checked={checked}
          // eslint-disable-next-line
          onChange={handleClick} />
      </div>
      <div className={classes.explanationText}>
        Advanced Tracking allows you to rotate both presell/landing pages and offer pages.
      </div>
    </Grid>
  );
}

AdvancedTrackingToggle.propTypes = {
  campaignDetails: bool.isRequired,
};

export default AdvancedTrackingToggle;

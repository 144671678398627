import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ToggleSubmit from '../../../Widgets/Forms/ToggleSubmit';
import { NetworkRequestPost } from '../../../Helpers/NetworkRequest';
import { changeCampaignDetails } from '../actions/campaignDetails';
import { openDialog, openSnackbar } from '../../../Widgets/actions/widgets';
import { submitCampaignStatus } from './CampaignStatusSubmit';

const useStyles = makeStyles()(() => ({
  container: {
    padding: '10px 0',
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  statusHelpIcon: {
    color: '#939393',
    fontSize: '17px',
    marginLeft: '10px',
  },
}));

function ImageStatus(props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { campaignStatus } = useSelector((state) => state.campaignDetails);
  const {
    id, adStatus, changeStatus, campaignID, ads, adSetId,
  } = props;
  const status = +adStatus === 1 ? 1 : 0;
  const disabled = +adStatus === 2;

  const unpauseCampaign = async () => {
    submitCampaignStatus(campaignID, 1, campaignStatus, () => dispatch(changeCampaignDetails('campaignStatus', 1)));
  };

  const submit = async (checked) => {
    const statusVal = ((checked && 1) || 0);
    const isOtherActiveAds = ads.filter((ad) => ad.status).length > 0;
    changeStatus(statusVal !== 0 ? 1 : 0, id);
    try {
      if (+campaignStatus === 0 && statusVal === 1 && !isOtherActiveAds) {
        dispatch(openDialog('Unpause Campaign', 'Do you want to unpause this campaign?', unpauseCampaign, true));
      }
      const { data } = await NetworkRequestPost(
        'toggleImageStatus',
        {
          ad_id: id,
          campaign_id: campaignID,
          adset_id: adSetId,
          status: statusVal,
        },
      );
      if (data.success) {
        dispatch(openSnackbar(data.message, 'success'));
        return;
      }
      dispatch(openSnackbar(data.message || 'An error has happened.', 'error'));
      changeStatus(statusVal !== 0 ? 0 : 1, id);
    } catch (e) {
      changeStatus(statusVal !== 0 ? 0 : 1, id);
    }
  };

  const renderToggle = () => (
    <ToggleSubmit
      campaignID={campaignID}
      adID={id}
      onSubmit={submit}
      settingName="Image Status"
      inheritChecked={Boolean(status)}
      disabled={disabled}
      confirmMessage={(checked) => `Are you sure that you want to ${checked
        ? 'activate' : 'pause'} this image?`}
    />
  );

  const renderTooltip = () => (
    disabled
      ? (
        <Tooltip
          title="This ad has been automatically paused by Maximus Social and can not be enabled"
          placement="top"
        >
          <div>
            {renderToggle()}
          </div>
        </Tooltip>

      )
      : renderToggle()
  );

  return (
    <div className={classes.container}>
      {renderTooltip()}
    </div>
  );
}

ImageStatus.propTypes = {
  id: PropTypes.string,
  campaignID: PropTypes.string.isRequired,
  changeStatus: PropTypes.func,
  adStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ads: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

ImageStatus.defaultProps = {
  id: '',
  changeStatus: {},
  adStatus: '',
};

export default ImageStatus;

import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { HelpOutline } from '@mui/icons-material';

const useStyles = makeStyles()((theme) => ({
  tooltipIcon: {
    color: theme.palette.colors.gray,
    fontSize: 17,
    marginLeft: 10,
  },
  iconColor: {
    color: theme.palette.colors.blue,
  },
  tooltipText: {
    fontSize: 17,
    padding: 4,
    lineHeight: '1.45rem',
  },
}));

const TooltipLink = (props) => {
  const { classes } = useStyles();
  const {
    text, link, placement,
  } = props;

  const renderHelpIcon = () => {
    if (link) {
      return (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="help"
        >
          <HelpOutline className={`${classes.tooltipIcon} ${classes.iconColor}`} />
        </a>
      );
    }
    return (
      <HelpOutline className={classes.tooltipIcon} />
    );
  };

  function renderTooltipText() {
    return (
      <div className={classes.tooltipText}>
        {text}
      </div>
    );
  }

  return (
    <Tooltip
      title={renderTooltipText()}
      placement={placement}
    >
      {renderHelpIcon()}
    </Tooltip>
  );
};

TooltipLink.propTypes = {
  placement: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
};

TooltipLink.defaultProps = {
  placement: 'right-start',
  text: '',
  link: '',
};

export default TooltipLink;

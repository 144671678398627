import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel } from '@mui/material';
import { handleAddCampaignChange } from '../../actions/addCampaign';
import RenderSection from '../RenderSection';

export default function CampaignSettingsCheckboxes() {
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.addCampaign);

  const allowDaypartingCheckbox = () => (
    <RenderSection>
      <FormControlLabel
        control={(
          <Checkbox
            checked={settings.setupDayparting.value}
            name="hide_section"
            onChange={() => dispatch(handleAddCampaignChange('settings', 'setupDayparting', !settings.setupDayparting.value))}
          />
        )}
        label="Set up Dayparting for this campaign"
      />
    </RenderSection>
  );

  return (
    <>
      {allowDaypartingCheckbox()}
    </>
  );
}

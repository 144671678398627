import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Parser } from 'json2csv';

const isInt = (str) => /^[0-9]+$/.test(`${str}`);
const isNumber = (value) => !(Number.isNaN(+value));
const formatData = (data) => data.map((row) => {
  const newRow = { ...row };
  Object.keys(row).forEach((key) => {
    const currentValue = row[key];
    if (
      isNumber(currentValue)
      && !isInt(currentValue)
      && !(Number.isNaN(+parseFloat(currentValue).toFixed(2)))
    ) {
      newRow[key] = parseFloat(currentValue).toFixed(2);
    } else {
      newRow[key] = currentValue;
    }
  });
  return newRow;
});

async function downloadCSV(data, columns, params, fileTitle) {
  const parser = new Parser({ fields: columns.map(({ name }) => name) });
  const dataFormatted = formatData(data);

  let csv = parser.parse(dataFormatted);

  if (csv === null) return;

  let dateRange;

  if (params.dateRange !== 'all_time') {
    dateRange = ` (${format(params.startDate, 'yyyy-MM-dd')}  - ${format(params.endDate, 'yyyy-MM-dd')})`;
  } else {
    dateRange = ' (All Time)';
  }

  const filename = `${fileTitle + dateRange}.csv`;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  let encodedCSV = encodeURI(csv);

  columns.forEach(({ name, title }) => {
    encodedCSV = encodedCSV.replace(name, title);
  });

  const link = document.getElementById('downloadLink');
  link.setAttribute('href', encodedCSV);
  link.setAttribute('download', filename);
  link.click();
}

export const downloadCSVButtonClick = async ({
  data, columns, params, title,
}) => {
  try {
    await downloadCSV(data, columns, params, title);
  } catch {
    // eslint-disable-next-line no-console
    console.error('error downloading CSV');
  }
};

downloadCSVButtonClick.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  columns: PropTypes.instanceOf(Object).isRequired,
  params: PropTypes.instanceOf(Object).isRequired,
};

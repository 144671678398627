import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { MoreVert } from '@mui/icons-material';
import CustomMenu from '../../../Menu/Menu';
import { downloadCSVButtonClick } from './DownloadCSV';
// import { customizeDataGrid } from '../dataGridCustomizationHelper';
// import { updateTable } from '../../../actions/widgets';

const useStyles = makeStyles()(() => ({
  optionContainer: {
    flex: '0 0 auto',
  },
}));

function TableOptions({
  data, columns, title,
}) {
  const { classes } = useStyles();
  const params = useSelector(({ dateRange }) => dateRange);
  // const { dataGridCustomization } = useSelector((state) => state.widgets);
  // const dispatch = useDispatch();
  // const resetTableData = async () => {
  //   const newDataGrid = {
  //     ...dataGridCustomization,
  //     columnOrder: { ...dataGridCustomization.columnOrder },
  //   };
  //   delete newDataGrid.columnOrder[localStorage];
  //   await customizeDataGrid(newDataGrid);
  //   dispatch(updateTable(newDataGrid));
  // };
  const menuItems = [
    {
      name: 'Export CSV',
      onClick: () => downloadCSVButtonClick({
        data, columns, params, title,
      }),
    },
  ];
  // if (localStorage) {
  //   menuItems.push({ name: 'Reset Column Order', onClick: () => resetTableData() });
  // }
  return (
    <div className={classes.optionContainer}>
      <CustomMenu
        menuId="aria-account-settings"
        buttonContent={(
          <MoreVert />
        )}
        menuItems={menuItems}
        icon
      />
      <a style={{ width: 0, height: 0, display: 'hidden' }} id="downloadLink" href=" "> </a>
    </div>
  );
}

TableOptions.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  columns: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
};

export default TableOptions;

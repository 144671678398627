import {
  CLOSE_RIGHT_DRAWER,
  OPEN_RIGHT_DRAWER,
  RIGHT_DRAWER_LOADED,
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  CLOSE_DIALOG,
  OPEN_DIALOG,
  TOGGLE_OVERLAY,
  UPDATE_TABLE,
  RIGHT_DRAWER_LOADING,
  UPDATE_WIDGET,
} from '../actions/widgets';

export const initialState = {
  rightDrawer: {
    open: false,
    title: '',
    children: '',
    loading: false,
  },
  snackbar: {
    open: false,
    children: '',
    severity: 'other',
  },
  dialog: {
    open: false,
    title: '',
    children: '',
    confirmDialog: false,
    formDialog: false,
    successDialog: false,
    warningDialog: false,
    confirmText: 'Confirm',
    cancelText: 'Cancel',
    cancelAction: false,
  },
  helpOverlay: {
    open: false,
  },
  dataGridCustomization: {
    columnOrder: {},
    columnSizes: {},
    hideColumns: {},
    filters: {},
  },
  adSetNames: [{ title: 'name', value: 'value' }, { title: 'name1', value: 'value1' }, { title: 'name2', value: 'value2' }, { title: 'name3', value: 'value3' }, { title: 'name4', value: 'value4' }],
};

export const widgets = (state = initialState, action = {}) => {
  switch (action.type) {
    case CLOSE_RIGHT_DRAWER:
      return {
        ...state,
        rightDrawer: {
          ...state.rightDrawer, open: false, loading: false, children: '', title: '',
        },
      };
    case OPEN_RIGHT_DRAWER:
      return {
        ...state,
        rightDrawer: {
          open: true,
          title: action.title,
          children: action.children,
          loading: action.loading,
        },
      };
    case RIGHT_DRAWER_LOADED:
      return {
        ...state,
        rightDrawer: { ...state.rightDrawer, loading: false },
      };
    case RIGHT_DRAWER_LOADING:
      return {
        ...state,
        rightDrawer: { ...state.rightDrawer, loading: true },
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: { ...state.snackbar, open: false },
      };
    case OPEN_SNACKBAR:
      return {
        ...state,
        snackbar: {
          open: true,
          children: action.children,
          severity: action.severity,
        },
      };
    case CLOSE_DIALOG:
      return {
        ...state,
        dialog: { ...state.dialog, open: false },
      };
    case TOGGLE_OVERLAY:
      return {
        ...state,
        helpOverlay: { ...state.helpOverlay, open: action.open },
      };
    case OPEN_DIALOG:
      return {
        ...state,
        dialog: {
          open: true,
          title: action.title,
          children: action.children,
          handleAction: action.handleAction,
          confirmDialog: action.confirmDialog,
          formDialog: action.formDialog,
          warningDialog: action.warningDialog,
          confirmText: action.confirmText,
          cancelText: action.cancelText,
          cancelAction: action.cancelAction,
        },
      };
    case UPDATE_TABLE:
      return {
        ...state,
        dataGridCustomization: action.dataGridCustomization,
      };
    case UPDATE_WIDGET:
      return {
        ...state,
        [action.widget]: action.value,
      };
    default:
      return state;
  }
};

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import MuiTable from '../../../../Widgets/Tables/MuiTables/TableContainer';
import { usePrevious } from '../../../../Helpers/CustomHooks';
import RemoveAdSet from './RemoveAdSet';
import { changeNonField } from '../../actions/addCampaign';

export default function DataTable() {
  const { adSets, settings } = useSelector((store) => store.addCampaign);
  const { budgetOptimization } = settings;
  const [rows, setRows] = useState([]);
  const prevAdSets = usePrevious(adSets);
  const dispatch = useDispatch();
  useEffect(() => {
    updateRows();
  }, []);
  useEffect(() => {
    if (prevAdSets && !isEqual(adSets, prevAdSets)) {
      updateRows();
    }
  }, [adSets, prevAdSets]);
  const updateRows = () => {
    setRows(adSets.map((
      {
        name,
        budget,
        ads,
      },
    ) => (
      {
        name,
        budget,
        numberOfAds: ads.length,
      }
    )));
  };

  const onRemove = (id) => {
    const index = id - 1;
    const adSetsCopy = JSON.parse(JSON.stringify(adSets));
    adSetsCopy.splice(index, 1);
    dispatch(changeNonField('adSets', adSetsCopy));
  };

  const columns = useMemo(() => (
    [
      {
        field: 'id', headerName: '', width: 70, filterable: false, hide: true,
      },
      {
        field: 'name', headerName: 'Ad Set Name', width: 350,
      },
      {
        field: 'budget', headerName: 'Budget', type: 'number', width: 200,
      },
      {
        field: 'numberOfAds', headerName: '# Of Ads', type: 'number', width: 200,
      },
      {
        field: 'delete', headerName: 'Remove', width: 200, type: 'number', renderCell: (params) => <RemoveAdSet params={params} onRemove={onRemove} />,
      },
    ]
  ), [rows]);

  return (
    <MuiTable
      rows={rows.map((row, index) => ({ id: index + 1, ...row }))}
      columns={columns}
      pageSize={5}
      rowsPerPageOptions={[5]}
      filterToolbar
      hideQuick
      hideExport
      height="500px"
      sortModel={[{ field: 'name', sort: 'desc' }]}
      columnVisibilityModel={{ id: false, budget: budgetOptimization.value === 'ad_set' }}
    />
  );
}

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  isSameDay, isSameYear, format, endOfYear, isWeekend,
} from 'date-fns';
import { makeStyles } from 'tss-react/mui';
import { Popover, Button } from '@mui/material';
import { DateRange as DateRangeIcon } from '@mui/icons-material/';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import { PREDEFINED_PERFORMANCE_RANGES, PREDEFINED_RANGES, PREDEFINED_RANGES_NAMES } from './constants';
import { changeDateRange } from './actions/dateRange';
import { closeDialog, openDialog } from '../../../../../Widgets/actions/widgets';
import { changeFilter } from '../actions/filters';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './styles.css';

const useStyles = makeStyles()((theme) => ({
  calendarIcon: {
    marginRight: 12,
    fontSize: '1.25rem',
  },
  button: {
    backgroundColor: 'transparent',
    color: theme.palette.variantText,
    width: '100%',
    height: '100%',
    justifyContent: 'flex-start',
    margin: 0,
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 400,
    padding: '10px 0',
    paddingLeft: '25px',
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.variantText,
    },
  },
  linkButton: {
    color: '#2c8fff',
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: 16,
    cursor: 'pointer',
  },
  warningText: {
    color: theme.variantColor('rgba(0, 0, 0, 0.8)', theme.palette.colors.white),
  },
}));

export const WarningText = React.memo(() => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  return (
    <p className={classes.warningText}>
      There is no data for this date with this week day filter.
      <button
        type="button"
        onClick={() => {
          dispatch(changeFilter('weekDay', -1));
          dispatch(closeDialog());
        }}
        className={classes.linkButton}
      >
        Click to change to all days.
      </button>
    </p>
  );
});

export const checkTodayWarning = (val, dispatch, dateRange) => {
  if (dateRange === 'today') {
    if (
      (val === 'weekends' && !isWeekend(new Date()))
      || (val === 'week_days' && isWeekend(new Date()))
    ) {
      dispatch(openDialog('Warning', <WarningText />, '', false, false, true));
    }
  }
};

function DateRange({ performance = false }) {
  const [customClicks, setCustomClicks] = useState(1);
  const [openPicker, setOpenPicker] = useState(false);
  const [anchorPicker, setAnchorPicker] = useState(null);
  const { startDate, endDate, dateRange } = useSelector((state) => state.dateRange);
  const { weekDay } = useSelector((state) => state.filters);
  const dispatch = useDispatch();
  const { classes } = useStyles();

  useEffect(() => {
    if (performance) {
      const selectedRange = performance
        ? PREDEFINED_PERFORMANCE_RANGES[dateRange] : PREDEFINED_RANGES[dateRange];
      if (!selectedRange) {
        const defaultRange = performance
          ? PREDEFINED_PERFORMANCE_RANGES[PREDEFINED_RANGES_NAMES.thisMonth].range()
          : PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.today].range();
        dispatch(changeDateRange({
          startDate: defaultRange.startDate,
          endDate: defaultRange.endDate,
          dateRange: defaultRange.range || 'custom',
        }));
      }
    }
    // eslint-disable-next-line
  }, [performance]);

  const handleShowPicker = (open, event = {}) => {
    setOpenPicker(open);
    setAnchorPicker(event.currentTarget || null);
  };

  const onRangeChange = ({ selection }) => {
    dispatch(changeDateRange({ startDate: selection.startDate, endDate: selection.endDate, dateRange: selection.range || 'custom' }));
    if (selection.range || (!selection.range && customClicks === 2)) {
      setOpenPicker(false);
      setCustomClicks(1);
    } else {
      setCustomClicks(customClicks + 1);
    }
    checkTodayWarning(weekDay, dispatch, selection.range);
  };

  const renderTitle = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const rangeFormat = `MM/dd${isSameYear(start, end) ? '' : '/yyyy'}`;
    const rangeText = isSameDay(start, end)
      ? format(start, 'MM/dd/yyyy')
      : `${format(start, rangeFormat)} - ${format(end, rangeFormat)}`;
    // eslint-disable-next-line no-nested-ternary
    const rangeLabel = performance
      ? (PREDEFINED_PERFORMANCE_RANGES[dateRange]
        ? PREDEFINED_PERFORMANCE_RANGES[dateRange].label
        : PREDEFINED_PERFORMANCE_RANGES[PREDEFINED_RANGES_NAMES.thisMonth].label)
      : (PREDEFINED_RANGES[dateRange]
        ? PREDEFINED_RANGES[dateRange].label
        : PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.today].label);
    return dateRange === 'custom' ? rangeText : rangeLabel;
  };

  return (
    <div className="dateRangePickerButtonContainer">
      <Button
        onClick={(event) => handleShowPicker(true, event)}
        className={classes.button}
      >
        <DateRangeIcon className={classes.calendarIcon} />
        {renderTitle()}
      </Button>
      <Popover
        open={openPicker}
        anchorEl={anchorPicker}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        onClose={(event) => handleShowPicker(false, event)}
        style={{ top: -16 }}
      >
        <DateRangePicker
          onChange={onRangeChange}
          inputRanges={[]}
          style={{ overflow: 'auto' }}
          ranges={[{
            startDate,
            endDate,
            key: 'selection',
          }]}
          staticRanges={createStaticRanges(
            Object.values(performance ? PREDEFINED_PERFORMANCE_RANGES : PREDEFINED_RANGES),
          )}
          maxDate={endOfYear(new Date())}
        />
      </Popover>
    </div>
  );
}

DateRange.propTypes = {
  performance: PropTypes.bool,
};

DateRange.defaultProps = {
  performance: false,
};

export default DateRange;

import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import { changePage } from '../../Components/Navigation/actions/page';
import menuMapping from './menuMapping';
import AddCampaignContainer from './render/AddCampaignContainer';
import { handleAddCampaignChange, resetAddCampaign } from './actions/addCampaign';
import { today } from './reducers/addCampaign';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
}));

export default function AddCampaign() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  useEffect(() => () => {
    dispatch(resetAddCampaign());
    dispatch(handleAddCampaignChange('settings', 'startDate', today()[0]));
  }
  // eslint-disable-next-line
  , []);

  useEffect(() => {
    dispatch(changePage('add campaign', menuMapping));
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <AddCampaignContainer />
    </div>
  );
}

export const CHANGE_STEP = 'CHANGE_STEP';
export const CHANGE_VALUE = 'CHANGE_VALUE';
export const CHANGE_LINK = 'CHANGE_LINK';
export const ADD_LINK = 'ADD_LINK';
export const REMOVE_LINK = 'REMOVE_LINK';
export const REMOVE_AD = 'REMOVE_AD';
export const CHANGE_AD_TITLE = 'CHANGE_AD_TITLE';
export const CHANGE_AD_VIDEO = 'CHANGE_AD_VIDEO';
export const GENERATE_ADS = 'GENERATE_ADS';
export const RESET_ADD_CAMPAIGN = 'RESET_ADD_CAMPAIGN';
export const CHANGE_NON_FIELD = 'CHANGE_NON_FIELD';
export const UPDATE_ADS = 'UPDATE_ADS';
export const CHANGE_AD_FIELD = 'CHANGE_AD_FIELD';
export const CHANGE_AD_SET_FIELD = 'CHANGE_AD_SET_FIELD';

const setValueObj = (value = '', error = '') => ({ value, error });

export const changeStep = (step) => ({
  type: CHANGE_STEP,
  step,
});

export const changeNonField = (key, value) => ({
  type: CHANGE_NON_FIELD,
  key,
  value,
});

export const handleAddCampaignChange = (section, name, value, error = '') => ({
  type: CHANGE_VALUE,
  section,
  name,
  value,
  error,
});

export const changeWeightedLink = (key, index, value, section, error = '') => ({
  type: CHANGE_LINK,
  key,
  index,
  value: setValueObj(value, error),
  section,
});

export const generateAds = (images, titles, descriptions) => {
  const ads = [];
  images.forEach((image) => {
    titles.forEach((title) => {
      if (!title.value.trim()) {
        return;
      }
      descriptions.forEach((description) => {
        if (!description.value.trim()) {
          return;
        }
        ads.push({
          image_url: image.url,
          square_image_url: image.croppedUrl,
          title_text: title.value,
          description: description.value,
        });
      });
    });
  });
  return ({
    type: GENERATE_ADS,
    ads,
    uploadedImages: [],
    titles: [{ value: '', error: '' }],
    descriptions: [{ value: '', error: '' }],
    cropping: false,
    generatingAds: false,
  });
};

export const addWeightedLink = (section) => ({
  type: ADD_LINK,
  section,
});

export const removeWeightedLink = (index, section) => ({
  type: REMOVE_LINK,
  index,
  section,
});

export const removeAd = (index) => ({
  type: REMOVE_AD,
  index,
});

export const changeAdTitle = (value, item, index, error = '') => ({
  type: CHANGE_AD_TITLE,
  index,
  value,
  error,
  item,
});

export const changeAdSetField = (name, value) => ({
  type: CHANGE_AD_SET_FIELD,
  name,
  value,
});

export const changeAdField = (index, name, value) => ({
  type: CHANGE_AD_FIELD,
  index,
  name,
  value,
});

export const changeAdVideo = (value, index = '') => ({
  type: CHANGE_AD_VIDEO,
  index,
  value,
});

export const updateAds = (ads) => ({
  type: UPDATE_ADS,
  ads,
});

export const resetAddCampaign = () => ({
  type: RESET_ADD_CAMPAIGN,
});

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { NetworkRequestPut } from '../../Helpers/NetworkRequest';
import Input from '../../Widgets/Forms/Fields/Input';
import * as validation from '../../Pages/Login/validation';
import { closeRightDrawer, openSnackbar } from '../../Widgets/actions/widgets';
import FormDrawer from '../../Widgets/Drawer/FormDrawer/FormDrawer';
import { logout } from '../../Pages/Login/actions/login';

const useStyles = makeStyles()((theme) => ({
  changePasswordFormContainer: {
    textAlign: 'center',
  },
  changePasswordForm: {
    marginBottom: '1rem',
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: '20rem',
  },
}));

export function ChangePassword() {
  const [submitting, setSubmitting] = useState(false);
  const [password, setPassword] = useState({ value: '', error: '' });
  const [newPassword, setNewPassword] = useState({ value: '', error: '' });
  const [confirmPassword, setConfirmPassword] = useState({ value: '', error: '' });

  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChangePassword = async () => {
    const passwordvalidationError = validation.passwordValidation(
      password.value,
      newPassword.value,
    );
    const confirmvalidationError = validation.confirmPasswordValidation(
      confirmPassword.value,
      newPassword.value,
    );
    if (passwordvalidationError || confirmvalidationError) {
      setPassword({ value: confirmPassword.value, error: passwordvalidationError });
      setNewPassword({ value: confirmPassword.value, error: passwordvalidationError });
      setConfirmPassword({ value: confirmPassword.value, error: confirmvalidationError });

      return;
    }

    setSubmitting(true);

    const { data: { success, message = '' } } = await NetworkRequestPut(
      'changePassword',
      {
        password: password.value,
        newPassword: newPassword.value,
        confirmPassword: confirmPassword.value,
      },
    );
    if (success) {
      dispatch(openSnackbar(
        message || 'You have successfully updated your password. You will be logged out now.',
        'success',
      ));
      dispatch(closeRightDrawer());
      setTimeout(() => dispatch(logout(() => navigate('/login'))), 4000);
    } else {
      dispatch(openSnackbar(message || 'There was a problem updating your password.', 'error'));
    }
    setSubmitting(false);
  };
  const renderInput = (label, name, validFunc, state, handleChange) => (
    <Grid item xs={12}>
      <Input
        name={name}
        type="password"
        label={label}
        value={state.value}
        onChange={(value, fieldName, error) => handleChange({ value, error })}
        className={classes.textField}
        validationFunc={validFunc}
        helperText={state.error}
        error={state.error}
      />
    </Grid>
  );

  const renderChangePassword = () => (
    <Grid item xs={12}>
      <Typography gutterBottom>
        To change your password, please fill in all fields below.
      </Typography>
      <Grid container justify="center" alignItems="center" className={classes.changePasswordFormContainer}>
        <form
          className={classes.changePasswordForm}
          onSubmit={(e) => {
            e.preventDefault();
            handleChangePassword();
          }}
        >
          {renderInput('Current Password', 'password', validation.passwordValidation, password, setPassword)}
          {renderInput('New Password', 'newPassword', validation.passwordValidation, newPassword, setNewPassword)}
          {renderInput('Confirm Password', 'confirmPassword', validation.passwordValidation, confirmPassword, setConfirmPassword)}
          <input type="submit" style={{ display: 'none' }} />
        </form>
      </Grid>
    </Grid>
  );
  return (
    <FormDrawer
      onSubmit={handleChangePassword}
      submitting={submitting}
    >
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="center" className={classes.changePasswordFormContainer}>
          {renderChangePassword()}
        </Grid>
      </Grid>
    </FormDrawer>
  );
}

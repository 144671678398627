import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Close } from '@mui/icons-material';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button,
} from '@mui/material';
import { useSelector } from 'react-redux';
import CloneAdSetsTable from './CloneAdSetsTable';
import { NetworkRequestPost } from '../../../../../Helpers/NetworkRequest';
import { newAdSet } from '../../../reducers/addCampaign';

const useStyles = makeStyles()((theme) => ({
  title: {
    textAlign: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonContainer: {
    textAlign: 'right',
    width: '100%',
  },
  greenButton: {
    backgroundColor: '#4caf50',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#388e3c',
    },
    marginLeft: '15px',
  },
}));

const blankMedia = {
  url: '',
  height: 0,
  width: 0,
  size: '',
  thumbnail: '',
};

export const returnImage = (media, thumbnail) => {
  try {
    if (thumbnail && thumbnail.includes('[') && thumbnail.includes(']')) {
      const parsedThumbnails = JSON.parse(thumbnail);
      if (parsedThumbnails && parsedThumbnails[0] && parsedThumbnails[0].url) {
        return parsedThumbnails[0].url;
      }
    }
    if (media && media.includes('[') && media.includes(']')) {
      const parsedMedia = JSON.parse(media);
      if (parsedMedia && parsedMedia[0] && parsedMedia[0].url) {
        return parsedMedia[0].url;
      }
    }
    return blankMedia.url;
  } catch (e) {
    return blankMedia.url;
  }
};

export const returnImages = (media) => {
  try {
    if (media && media.includes('[') && media.includes(']')) {
      const parsedMedia = JSON.parse(media);
      if (parsedMedia && parsedMedia[0] && parsedMedia[0].url) {
        return parsedMedia;
      }
    } else if (typeof media === 'object') {
      if (media[0] && media[0].url) {
        return media;
      }
    }
    return [blankMedia];
  } catch (e) {
    return [blankMedia];
  }
};

const returnCorrectMedia = (medias, thumbnails, size) => {
  const media = medias.find((m) => m.size === size);
  const thumbnail = thumbnails.find((t) => t.size === size);
  if (media) {
    return {
      ...media,
      thumbnail: thumbnail ? thumbnail.url : '',
    };
  }
  return blankMedia;
};

export const returnFormattedMedias = (medias, thumbnails) => ({
  squareMedia: returnCorrectMedia(medias, thumbnails, 'square'),
  landscapeMedia: returnCorrectMedia(medias, thumbnails, 'landscape'),
  portraitMedia: returnCorrectMedia(medias, thumbnails, 'portrait'),
});

export const formatAdsForStore = (ads) => ads.map((ad) => {
  const {
    title,
    description,
    media_url,
    thumbnail_url,
    name,
    ad_name,
    primary_text,
    call_to_action,
  } = ad;

  const medias = returnImages(media_url);
  const thumbnails = returnImages(thumbnail_url);
  const type = thumbnails[0] && thumbnails[0].url ? 'video' : 'image';

  const formattedMedias = returnFormattedMedias(medias, thumbnails);
  return {
    title,
    body: description,
    primaryText: primary_text,
    callToAction: call_to_action,
    name: name || ad_name,
    type,
    ...formattedMedias,
  };
});

export const formatAdSetsForStore = (adSets) => adSets.map((adSet) => {
  const blankAdSet = newAdSet();
  const {
    name,
    budget,
    bid_strategy,
    roas_floor,
    bid_amount,
    age_min,
    age_max,
    startAt,
    includeLocations,
    genderTargeting,
    gender_targeting,
    ads,
    adset_id,
    pixel_id,
  } = adSet;
  return {
    name,
    budget,
    createdAt: startAt || blankAdSet.startAt,
    bidStrategy: bid_strategy,
    roasFloor: roas_floor,
    bidAmount: bid_amount,
    ageTargeting: [age_min, age_max],
    startAt: blankAdSet.startAt,
    includeLocations,
    genderTargeting: genderTargeting || gender_targeting || blankAdSet.genderTargeting,
    ads: formatAdsForStore(ads),
    copiedAdsetID: adset_id,
    pixelID: pixel_id,
  };
});

export default function (props) {
  const { page } = useSelector((store) => store.page);
  const { open, closeDialog, copyAds } = props;
  const { adSets } = useSelector((store) => store.addCampaign);
  const { classes } = useStyles();
  const [selectedRows, setSelectedRows] = useState([]);
  const [rows, setRows] = useState([]);
  let budgetOptimization = '';
  let subAccountId;
  if (page === 'add campaign') {
    budgetOptimization = useSelector((store) => store.addCampaign.settings.budgetOptimization.value);
    subAccountId = useSelector((store) => store.addCampaign.settings.subAccount.value);
  } else {
    subAccountId = useSelector((store) => store.campaignDetails.subAccountId);
    budgetOptimization = useSelector((store) => store.campaignDetails.budgetOptimization);
  }

  useEffect(() => {
    getRows();
  }, []);

  const close = (event, reason) => {
    if (reason !== 'backdropClick') {
      closeDialog();
      setSelectedRows([]);
    }
  };

  const getRows = async () => {
    const { data: { success, data } } = await NetworkRequestPost('usersAdSetsList', { subAccountId });
    if (success) {
      const filteredData = data.filter((adSet) => {
        if (adSet.budget_optimization === 'adset') {
          adSet.budget_optimization = 'ad_set';
        }
        return adSet.budget_optimization === budgetOptimization;
      });
      setRows(formatAdSetsForStore(filteredData));
    }
  };

  const cloneSelectedAdSets = () => {
    copyAds(selectedRows);
    close();
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle className={classes.title}>
        Clone Ad Sets
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={close}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className={classes.dialogContent}
      >
        <CloneAdSetsTable
          data={rows}
          setSelectedRows={setSelectedRows}
          adSets={adSets}
          budgetOptimization={budgetOptimization}
        />
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonContainer}>
          <Button
            onClick={close}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={cloneSelectedAdSets}
            variant="contained"
            className={classes.greenButton}
            autoFocus
            disabled={!selectedRows.length}
          >
            Clone
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

import { TextField } from '@mui/material';
import ChipSelect from '../../Widgets/Forms/Fields/ChipSelect';

const ChipSelectInput = (
  {
    name,
    value,
    label,
    options,
    onChange,
    error,
    classes,
  },
) => (
  <TextField
    fullWidth
    value={value}
    onChange={(values) => onChange(name, values)}
    placeholder="Please Select"
    name={name}
    variant="outlined"
    label={label}
    margin="normal"
    InputLabelProps={{
      shrink: true,
    }}
    InputProps={{
      inputComponent: ChipSelect,
      inputProps: {
        multi: true,
        instanceId: name,
        id: name,
        simpleValue: true,
        options,
        className: classes.selectInputRoot,
      },
    }}
    helperText={error}
    className={classes.selectInput}
  />
);

export default ChipSelectInput;

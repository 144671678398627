export const OPEN_RIGHT_DRAWER = 'OPEN_RIGHT_DRAWER';
export const CLOSE_RIGHT_DRAWER = 'CLOSE_RIGHT_DRAWER';
export const RIGHT_DRAWER_LOADED = 'RIGHT_DRAWER_LOADED';
export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const TOGGLE_OVERLAY = 'TOGGLE_OVERLAY';
export const UPDATE_TABLE = 'UPDATE_TABLE';
export const UPDATE_WIDGET = 'UPDATE_WIDGET';
export const RIGHT_DRAWER_LOADING = 'RIGHT_DRAWER_LOADING';

export const openRightDrawer = (title = '', children = '', loading = false) => ({
  type: OPEN_RIGHT_DRAWER,
  title,
  children,
  loading,
});

export const toggleOverlay = (open = false) => ({
  type: TOGGLE_OVERLAY,
  open,
});

export const closeRightDrawer = () => ({
  type: CLOSE_RIGHT_DRAWER,
});

export const rightDrawerLoaded = () => ({
  type: RIGHT_DRAWER_LOADED,
});

export const rightDrawerLoading = () => ({
  type: RIGHT_DRAWER_LOADING,
});

export const openSnackbar = (children = '', severity = '') => ({
  type: OPEN_SNACKBAR,
  children,
  severity,
});

export const closeSnackbar = () => ({
  type: CLOSE_SNACKBAR,
});

export const openDialog = (
  title = '',
  children = '',
  handleAction = () => {},
  confirmDialog = false,
  formDialog = false,
  warningDialog = false,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  cancelAction = false,
) => ({
  type: OPEN_DIALOG,
  title,
  children,
  handleAction,
  confirmDialog,
  formDialog,
  warningDialog,
  confirmText,
  cancelText,
  cancelAction,
});

export const closeDialog = () => ({
  type: CLOSE_DIALOG,
});

export const updateTable = (dataGridCustomization) => ({
  type: UPDATE_TABLE,
  dataGridCustomization,
});

export const updateWidget = (widget, value) => ({
  type: UPDATE_WIDGET,
  widget,
  value,
});

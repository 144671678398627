import { notTestUser } from './render/Links/Links';

const createAdObject = (url, ad, size) => ({
  url,
  height: ad.height,
  width: ad.width,
  size,
});

export const formatAdsForSubmit = (ads) => {
  const returnAds = [];
  ads.forEach((ad) => {
    const newAd = {
      name: ad.name,
      title: ad.title,
      body: ad.body,
      type: ad.type,
      primaryText: ad.primaryText,
      callToAction: ad.callToAction,
      mediaURLs: [],
      thumbnails: [],
    };

    Object.keys(ad).forEach((key) => {
      if (key.includes('Media') && ad[key].url) {
        const size = key.replace('Media', '');
        newAd.mediaURLs.push(createAdObject(ad[key].url, ad[key], size));
        if (ad[key].thumbnail) {
          newAd.thumbnails.push(createAdObject(ad[key].thumbnail, ad[key], size));
        }
      }
    });
    returnAds.push(newAd);
  });
  return returnAds;
};

export const formatAdSetForSubmit = (adSet, budgetOptimization, campaignBidAmount) => {
  adSet.ads = formatAdsForSubmit(adSet.ads);
  adSet.budget = budgetOptimization === 'campaign' ? undefined : adSet.budget;
  adSet.bidStrategy = budgetOptimization === 'campaign' ? undefined : adSet.bidStrategy;
  adSet.roasFloor = budgetOptimization === 'campaign' ? undefined : adSet.roasFloor;
  adSet.bidAmount = budgetOptimization === 'campaign' ? campaignBidAmount.value : adSet.bidAmount;
  return adSet;
};

export const formatAdSetsForSubmit = (adSets, budgetOptimization, bidAmount) => {
  const returnAdSets = [];
  const tempAdSets = JSON.parse(JSON.stringify(adSets));
  tempAdSets.forEach((adSet) => {
    returnAdSets.push(formatAdSetForSubmit(adSet, budgetOptimization, bidAmount));
  });
  return returnAdSets;
};

export const createAddCampaignParams = (addCampaignObject) => {
  const {
    settings,
    adSets,
    presellLinks,
    landingPageLinks,
    links: { safePageLink, advancedTracking: { value: advancedTracking } },
    dayPartingData,
  } = addCampaignObject;

  return {
    campaign_name: settings.campaignName.value,
    budget: settings.budgetOptimization.value === 'campaign' ? settings.budget.value : undefined,
    budgetOptimization: settings.budgetOptimization.value,
    start_date: settings.startDate.value,
    account: settings.account.value,
    subAccount: settings.subAccount.value,
    advancedTracking: advancedTracking ? 1 : 0,
    bidStrategy: settings.budgetOptimization.value === 'campaign' ? settings.bidStrategy.value : undefined,
    bidAmount: settings.budgetOptimization.value === 'campaign' ? settings.bidAmount.value : undefined,
    roasFloor: settings.budgetOptimization.value === 'campaign' ? settings.roasFloor.value : undefined,
    safePageLink: safePageLink.value,
    landingPageLinks: advancedTracking ? Object.keys(landingPageLinks).map((linkID) => ({
      url: landingPageLinks[linkID].url.value,
      weight: landingPageLinks[linkID].weight.value,
    })) : [],
    presellLinks: Object.keys(presellLinks).map((linkID) => ({
      url: presellLinks[linkID].url.value,
      weight: presellLinks[linkID].weight.value,
    })),
    adSets: formatAdSetsForSubmit(adSets, settings.budgetOptimization.value, settings.bidAmount),
    dayparting: settings.setupDayparting.value ? dayPartingData.dayparting.value : null,
    trafficAllowed: notTestUser(),
  };
};

import { startOfDay } from 'date-fns';
import { CHANGE_DATE, UPDATE_REFRESHED_AT } from '../actions/dateRange';

const storedFilters = localStorage.getItem(localStorage.getItem('email') || 'filters');
const initialFilters = JSON.parse(storedFilters) || {};

export const initialState = {
  startDate: startOfDay(initialFilters.startDate ? new Date(initialFilters.startDate) : new Date()),
  endDate: startOfDay(initialFilters.endDate ? new Date(initialFilters.endDate) : new Date()),
  dateRange: initialFilters.dateRange || 'today',
  refreshedAt: new Date(),
};

export const dateRange = (state = initialState, action = {}) => {
  switch (action.type) {
    case CHANGE_DATE:
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
        dateRange: action.dateRange,
        refreshedAt: new Date(),
      };
    case UPDATE_REFRESHED_AT:
      return {
        ...state,
        refreshedAt: new Date(),
      };
    default:
      return state;
  }
};

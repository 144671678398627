import { isWebUri } from 'valid-url';

export const notEmptyString = (value) => (value && value.trim().length > 0 ? '' : 'Please enter a valid value.');

const getCharacterLength = (value) => ` Currently at ${value.trim().length} characters.`;

export const validateMaxLength = (value, maxLength) => (
  (!notEmptyString(value) && value && value.trim().length <= maxLength)
    ? ''
    : `Please make sure to enter a value less than ${maxLength + 1} characters long. ${getCharacterLength(value)}`
);

export const campaignNameValidation = (value) => (value && value.trim().length >= 2 ? '' : 'Campaign Name needs to be at least 2 characters long.');

export const checkAdSetName = (value) => (
  value && value.trim().length >= 2 ? '' : 'Ad Set Name needs to be at least 2 characters long.'
);

export const checkEmptySelect = (value) => (value && value !== 'Select' ? '' : 'Please select a value.');

export const selectValidation = (value) => (value && value.length > 0 ? '' : 'Please select at least one value.');

export const validateLink = (value) => (isWebUri(value.trim().replace(/[{}]/g, '')) ? ''
  : 'Please enter a valid value for link and include http(s).');

export const numberValidation = (value) => (!/[^\d]/.test(value) && /\d+/.test(value) && !(/^0\d+/.test(value)) ? '' : 'Please enter a valid number.');

export const setErrorMessage = (value = '', name = '', validationFunc = () => {
}, errorFields = []) => {
  const errorMessage = validationFunc(value);
  if (errorMessage) {
    errorFields.push(`${name}: ${errorMessage}`);
  }
  return errorFields;
};

export const checkBudget = (budget, bidStrategy = '', bidAmount = 0) => {
  let valid = !!budget;
  let minBudget;
  if (valid) {
    switch (bidStrategy) {
      case 'LOWEST_COST_WITH_BID_CAP':
        minBudget = 5 * +bidAmount;
        valid = +budget >= minBudget;
        break;
      default:
        valid = +budget >= 2.5;
        minBudget = 2.5;
        break;
    }
  }
  return valid ? '' : `Please enter a value for Budget${minBudget ? ` that is greater than $${minBudget.toFixed(2)}` : '.'}`;
};

export const checkROASFloor = (floor) => {
  let valid = !!floor;
  if (valid) {
    valid = +floor > 0;
  }
  return valid ? '' : 'Please enter a value for ROAS Floor that is greater than $0.';
};

export const checkBidAmount = (bid) => {
  let valid = !!bid;
  if (valid) {
    valid = +bid > 0;
  }
  return valid ? '' : 'Please enter a value for Bid Amount that is greater than $0.';
};

export const checkStartDate = (value) => {
  if (value) {
    const today = new Date().toISOString().split('T');

    if (value < today[0]) {
      return 'Start date can not be in the past';
    }
  }
  return '';
};

import {
  HANDLE_CAMPAIGN_DETAILS_CHANGE, HANDLE_CAMPAIGN_DETAILS_LINK_CHANGE,
  ADD_LINK, CHANGE_LINK, REMOVE_LINK, LOAD_LINKS, CHANGE_ADVANCED_TRACKING,
  RESET_LINKS,
} from '../actions/campaignDetails';

const setValueObj = (value = '', error = '') => ({ value, error });

const setLinkValues = (links) => {
  const newLinks = {};
  links.forEach((link, i) => {
    newLinks[i] = {
      url: setValueObj(link.url),
      weight: setValueObj(link.weight),
    };
  });
  return newLinks;
};

const emptyLinkSetup = () => (
  {
    safePageLink: setValueObj(),
    advancedTracking: setValueObj(true),
    landingPageLinks: {
      0: {
        url: setValueObj(),
        weight: setValueObj(100),
      },
    },
    presellLinks: {
      0: {
        url: setValueObj(),
        weight: setValueObj(100),
      },
    },
  }
);

export const initialState = {
  campaignID: '',
  adSetID: '',
  table: localStorage.getItem('campaignDetailsTable') || 'sites',
  campaignStatus: 1,
  advancedTrackingReporting: true,
  loading: true,
  accounts: [],
  adID: false,
  siteID: false,
  previewLink: '',
  burst: 0,
  burstSpend: 0,
  budget: 0,
  burstMode: false,
  budgetOptimization: '',
  view: 'campaign',
  bidAmount: undefined,
  subAccountId: '',
  links: emptyLinkSetup(),
  stripes: [],
};

export const campaignDetails = (state = initialState, action = {}) => {
  switch (action.type) {
    case HANDLE_CAMPAIGN_DETAILS_CHANGE:
      return {
        ...state,
        [action.state]: action.value,
      };
    case HANDLE_CAMPAIGN_DETAILS_LINK_CHANGE:
      return {
        ...state,
        links: {
          ...state.links,
          safePageLink: { value: action.value, error: action.error },
        },
      };
    case CHANGE_ADVANCED_TRACKING:
      return {
        ...state,
        links: {
          ...state.links,
          advancedTracking: { value: action.value, error: action.error },
        },
      };
    case CHANGE_LINK:
      return {
        ...state,
        links: {
          ...state.links,
          [action.section]: {
            ...state.links[action.section],
            [action.index]: {
              ...state.links[action.section][action.index],
              [action.key]: action.value,
            },
          },
        },
      };
    case ADD_LINK:
      return {
        ...state,
        links: {
          ...state.links,
          [action.section]: {
            ...state.links[action.section],
            [Object.keys(state.links[action.section]).length]: {
              url: setValueObj(),
              weight: setValueObj(100),
            },
          },
        },
      };
    case REMOVE_LINK:
      // eslint-disable-next-line no-case-declarations
      const links = { ...state.links[action.section] };
      delete links[action.index];
      return {
        ...state,
        links: {
          ...state.links,
          [action.section]: { ...links },
        },
      };
    case LOAD_LINKS:
      return {
        ...state,
        links: {
          safePageLink: setValueObj(action.safePageLink),
          advancedTracking: setValueObj(((action.advancedTracking
            && +action.advancedTracking === 1))),
          landingPageLinks: setLinkValues(action.landingPageLinks),
          presellLinks: setLinkValues(action.presellLinks),
        },
      };
    case RESET_LINKS:
      return {
        ...state,
        links: emptyLinkSetup(),
      };
    default:
      return state;
  }
};

import React from 'react';
import { List } from '@mui/icons-material';
import { DefaultCell } from '../../../Widgets/Tables/TableContainer/DefaultCell';
import { columnsCommon, renderCommonColumns } from './commonColumns';

const columns = [{
  name: 'state', title: 'State', width: 150,
},
...columnsCommon,
];

const renderCell = ({ row, column: { name } }) => {
  const cell = renderCommonColumns(row, name);
  if (cell) {
    return cell;
  }
  return DefaultCell(row[name]);
};

export const stateReport = () => ({
  title: 'State',
  icon: <List className="tabIcon" />,
  localStorage: 'state',
  renderCell,
  columns,
  action: 'stateReport',
});

import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { ContentCopy } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { changeNonField } from '../../../actions/addCampaign';
import CloneAdSetsDialog from './CloneAdSetsDialog';

const useStyles = makeStyles()(() => ({
  openModalButton: {
    width: 'calc(50% - 15px)',
    padding: '1rem',
    color: 'white',
    background: '#2c8fff',
    border: 'none',
    borderRadius: 6,
    fontSize: 18,
    marginBottom: 12,
    marginRight: 15,
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    cursor: 'pointer',
    transition: 'ease-in-out 200ms',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background: '#2C8FFFD2',
    },
  },
  icon: {
    marginRight: 8,
  },
}));

export default function () {
  const { adSets } = useSelector((store) => store.addCampaign);
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const cloneSelectedAdSets = (selectedRows) => {
    dispatch(changeNonField('adSets', [...adSets, ...selectedRows]));
  };
  return (
    <>
      <button
        className={classes.openModalButton}
        type="button"
        onClick={openDialog}
      >
        <ContentCopy className={classes.icon} />
        {' '}
        Clone Ad Set
      </button>
      <CloneAdSetsDialog open={open} closeDialog={closeDialog} copyAds={cloneSelectedAdSets} />
    </>
  );
}

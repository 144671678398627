import { useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { NetworkRequestPost } from '../../../../../../../Helpers/NetworkRequest';
import Filters from './Filters';
import Pagination from './Pagination';

const useStyles = makeStyles()(() => ({
  container: {
    border: 'solid 1px #282C34',
    borderRadius: 4,
    background: '#f4f6f8',
    overflow: 'hidden',
    overflowY: 'scroll',
    maxHeight: '60rem',
  },
  imageContainer: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    position: 'relative',
    paddingBottom: '5rem',
  },
}));

export default function () {
  const { classes } = useStyles();
  const [filters, setFilters] = useState({
    size: 'all', name: '', title: '', body: '', type: 'all', primaryText: '',
  });
  const [adsList, setAdsList] = useState([]);
  const pullAds = async () => {
    const { data } = await NetworkRequestPost('usersAds', {});
    setAdsList(data.filter((ad) => ad.media_url.includes('[') && ad.media_url.includes('{')));
  };
  useEffect(() => {
    pullAds();
  }, []);
  const list = useMemo(() => {
    const {
      size, name, title, body, type, primaryText,
    } = filters;
    return adsList.filter((ad) => {
      const {
        name: adName, title: adTitle, description, size: adSize, type: adType, primary_text: primary,
      } = ad;
      if ((name && !adName) || (name && adName && !adName.toLowerCase().trim().includes(name.toLowerCase().trim()))) return false;
      if ((title && !adTitle) || (title && adTitle && !adTitle.toLowerCase().trim().includes(title.toLowerCase().trim()))) return false;
      if ((primaryText && !primary) || (primaryText && primary && !primary.toLowerCase().trim().includes(primaryText.toLowerCase().trim()))) return false;
      if ((body && !description) || (body && description && !description.toLowerCase().trim().includes(body.toLowerCase().trim()))) return false;
      if (size !== 'all' && adSize !== size) return false;
      if (type !== 'all' && adType !== type) return false;
      return true;
    });
  }, [filters, adsList]);
  return (
    <div className={classes.container}>
      <Filters filters={filters} setFilters={setFilters} />
      <div className={classes.imageContainer}>
        <Pagination list={list} />
      </div>
    </div>
  );
}

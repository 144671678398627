import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Filter from '../render/Filter';
import { NetworkRequestPost } from '../../../../../Helpers/NetworkRequest';

const failedOptions = [
  { title: 'All Sub Accounts', value: 'all' },
];

const useStyles = makeStyles()(() => ({
  filterContainer: {
    padding: '10px 0',
    paddingLeft: '25px',
  },
}));

export default function SubAccountFilter() {
  const [options, setOptions] = useState([]);
  const [showFilter, toggleFilter] = useState(false);
  const { classes } = useStyles();
  const { account_id: accountFilter } = useSelector((state) => state.filters);
  async function getSubAccounts() {
    let subAccounts = [];
    toggleFilter(false);
    try {
      const { data = { success: false, data: [], message: '' } } = await NetworkRequestPost('getSubAccounts', { accountId: accountFilter });
      if (data && data.success && data.data.length > 0) {
        data.data.forEach((account) => {
          if (account && account.name && account.id) {
            subAccounts.push({ title: account.name, value: account.id });
          }
        });
        if (subAccounts.length > 1) {
          subAccounts = [{ title: 'All Sub Accounts', value: 'all' }, ...subAccounts];
          toggleFilter(true);
        } else {
          subAccounts = failedOptions;
        }
      } else {
        subAccounts = failedOptions;
      }
    } catch (error) {
      subAccounts = failedOptions;
    }
    setOptions(subAccounts);
  }
  useEffect(() => {
    if (accountFilter !== 'all') {
      getSubAccounts();
    } else {
      toggleFilter(false);
      setOptions(failedOptions);
    }
    // eslint-disable-next-line
  }, [accountFilter]);
  return showFilter && (
    <div className={classes.filterContainer}>
      <Filter name="sub_account_id" label="Sub Account Filter" filterKey="sub_account_id" options={options} tempOptions={failedOptions} />
    </div>
  );
}

import React from 'react';
import {
  Web, Edit, TableChart, FilterList, Visibility,
} from '@mui/icons-material';
import { iconStyle } from '../../../../Helpers/menuStyleHelper';
import { changeCampaignDetails } from '../../actions/campaignDetails';
import DateRange from '../../../../Components/Navigation/SideNavigation/Filters/DateRange/DateRange';
import PreviewLink from '../../Forms/PreviewLink';
import BreadcrumbBar from '../BreadcrumbBar';
import AdSetStatusToggle from './AdSetStatusToggle';
import EditAdSetButton from './EditAdSetButton';
import AddAds from './AddAds';
import AdSetFilter from './AdSetFilter';
// import { openRightDrawer } from '../../../../Widgets/actions/widgets';
// import Dayparting from '../../Drawers/Dayparting/Dayparting';

const statusToggles = () => [
  {
    title: 'Ad Set Status', type: 'render', render: <AdSetStatusToggle />, noPadding: true,
  },
];

const renderConditionalTables = (dispatch, table) => [{
  title: 'Ads',
  selected: table === 'ads',
  type: 'action',
  action: () => dispatch(changeCampaignDetails('table', 'ads')),
}, {
  title: 'Age',
  selected: table === 'age',
  type: 'action',
  action: () => dispatch(changeCampaignDetails('table', 'age')),
}, {
  title: 'State',
  selected: table === 'state',
  type: 'action',
  action: () => dispatch(changeCampaignDetails('table', 'state')),
}, {
  title: 'Gender',
  selected: table === 'gender',
  type: 'action',
  action: () => dispatch(changeCampaignDetails('table', 'gender')),
}, {
  title: 'User Log',
  selected: table === 'userLog',
  type: 'action',
  action: () => dispatch(changeCampaignDetails('table', 'userLog')),
}];

export const menuMapping = (
  dispatch,
  table,
) => ({
  breadCrumbs: [
    {
      titleIcon: <Visibility style={iconStyle} />,
      title: 'Views',
      items: [
        {
          title: 'View Campaign',
          type: 'render',
          render: <BreadcrumbBar campaign />,
          noPadding: true,
        },
      ],
    },
  ],
  filters: [
    {
      titleIcon: <FilterList style={iconStyle} />,
      title: 'Filters',
      items: [
        {
          title: 'Date Range', type: 'render', render: <DateRange />, noPadding: true,
        },
        {
          title: 'Ad Set', type: 'render', render: <AdSetFilter />,
        },
      ],
    },
  ],
  linkSections: [
    {
      titleIcon: <Web style={iconStyle} />,
      title: 'Pages',
      items: [
        { title: 'Campaigns', type: 'page', link: '/campaigns' },
        { title: 'Add Campaign', type: 'page', link: '/campaigns/add' },
      ],
    },
  ],
  editCampaign: [
    {
      titleIcon: <Edit style={iconStyle} />,
      title: 'Ad Set Settings',
      collapsible: true,
      collapsed: false,
      items: [
        ...statusToggles(),
        {
          title: 'Edit Ad Set',
          type: 'render',
          render: <EditAdSetButton />,
          noPadding: true,
        },
        {
          title: 'Add Ads',
          type: 'render',
          render: <AddAds />,
          noPadding: true,
        },
        // {
        //   title: 'Ad Set Dayparting',
        //   type: 'action',
        //   action: () => dispatch(openRightDrawer('Ad Set Dayparting', <Dayparting />, false)),
        // },
        {
          title: 'Preview Link',
          type: 'render',
          render: <PreviewLink />,
          noPadding: false,
        },
      ],
    },
  ],
  tables: [
    {
      titleIcon: <TableChart style={iconStyle} />,
      title: 'Reporting',
      collapsible: true,
      collapsed: false,
      items: [...renderConditionalTables(dispatch, table)],
    },
  ],
});

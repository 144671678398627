import { NetworkRequestPut } from '../../../../../Helpers/NetworkRequest';
import { openSnackbar } from '../../../../../Widgets/actions/widgets';
import { checkBudget } from '../../../../AddCampaign/Validation/validations';
import { numberWithCommas } from '../../../../../Widgets/Tables/TableContainer/TableUtilities/columnFormatter';
import { changeCampaignDetails } from '../../../actions/campaignDetails';

export function submitBudget(
  props,
  campaignID,
  inputValue,
  setLoading,
  helperText,
  setHelperText,
  dispatch,
) {
  const updateBudget = async () => {
    const errorMessage = checkBudget(inputValue, props.bidStrategy, props.bidAmount);
    if (errorMessage) {
      setHelperText(errorMessage);
      return;
    }
    setHelperText('');
    setLoading(true);
    try {
      const { data: { success = false, message = '' } } = await NetworkRequestPut(
        'budget',
        {
          campaign_id: campaignID,
          value: inputValue,
          bidAmount: props.bidStrategy === 'LOWEST_COST_WITH_BID_CAP' ? props.bidAmount : undefined,
          bidStrategy: props.bidStrategy,
        },
      );

      if (success) {
        setLoading(false);
        props.setEditView(false);
        props.setEditedValue(`${props.adornment === '$' ? props.adornment : ''}${numberWithCommas(inputValue)}${props.adornment === '%' ? props.adornment : ''}`);
        dispatch(changeCampaignDetails('budget', inputValue));
        dispatch(openSnackbar(message, 'success'));
      } else {
        dispatch(openSnackbar(message || 'An error has happened.', 'error'));
        setHelperText(message);
        setLoading(false);
      }
    } catch (e) {
      dispatch(openSnackbar('Something went wrong', 'error'));
      setLoading(false);
    }
  };

  return updateBudget();
}

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  text: {
    paddingTop: 15,
    fontWeight: '600',
    borderBottom: 'solid 1px',
  },
}));

export default function DrawerTitle({ text }) {
  const { classes } = useStyles();
  return (
    <Typography
      variant="h6"
      align="center"
      gutterBottom
      className={classes.text}
    >
      {text}
    </Typography>
  );
}

DrawerTitle.propTypes = {
  text: PropTypes.string.isRequired,
};

import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { changeNonField } from '../../../actions/addCampaign';
import { newAdSet as newBlankAdSet } from '../../../reducers/addCampaign';
import { openSnackbar } from '../../../../../Widgets/actions/widgets';
import { validateAdSet } from './Validation/validationFunctions';
import AdSetDialog from './AdSetDialog';

const useStyles = makeStyles()(() => ({
  openModalButton: {
    width: 'calc(50% - 15px)',
    padding: '1rem',
    color: 'white',
    background: 'rgba(5,162,106,0.86)',
    border: 'none',
    borderRadius: 6,
    fontSize: 18,
    marginBottom: 12,
    marginLeft: 15,
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    cursor: 'pointer',
    transition: 'ease-in-out 200ms',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      background: 'rgba(5,162,106,0.93)',
    },
  },
  errorsList: {
    textAlign: 'left',
  },
  icon: {
    marginRight: 6,
  },
}));

export default function () {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const { newAdSet, adSets, settings } = useSelector((store) => store.addCampaign);
  const { budgetOptimization } = settings;
  const openDialog = () => setOpen(true);
  const closeDialog = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      dispatch(changeNonField('newAdSet', newBlankAdSet()));
    }
  };

  const renderError = (errors) => {
    dispatch(openSnackbar(
      (
        <div>
          Oops, looks like there were some issues with some of your ads. Please correct the following errors to continue:
          <ul className={classes.errorsList}>
            {errors.map((error) => <li>{error}</li>)}
          </ul>
        </div>
      ), 'error',
    ));
  };

  const createAdSet = () => {
    const valid = validateAdSet(newAdSet, budgetOptimization.value, renderError);
    if (valid) {
      dispatch(changeNonField('adSets', [...adSets, newAdSet]));
      closeDialog();
    }
  };

  return (
    <>
      <button
        className={classes.openModalButton}
        type="button"
        onClick={openDialog}
      >
        <AddCircleOutlineIcon className={classes.icon} />
        Create Ad Set
      </button>
      <AdSetDialog
        submit={createAdSet}
        closeDialog={closeDialog}
        open={open}
      />
    </>
  );
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SwipeableDrawer, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import DrawerTitle from './Title';
import LinearProgress from '../Loaders/LinearProgress';
import { closeRightDrawer } from '../actions/widgets';

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    paddingBottom: '3rem',
  },
  drawer: {
    flexGrow: 1,
    zIndex: '1300',
  },
  drawerPaper: {
    padding: '0 40px',
    minWidth: '30vw',
    borderLeft: '1px solid',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  loadingContainer: {
    height: 300,
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Drawer = React.memo(() => {
  const { classes } = useStyles();
  const {
    open, title, children, loading,
  } = useSelector((state) => state.widgets.rightDrawer);
  const dispatch = useDispatch();
  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      onClose={() => dispatch(closeRightDrawer())}
      onOpen={() => {}}
    >
      <Grid className={classes.root}>
        {title && <DrawerTitle text={title} />}
        {loading && <div className={classes.loadingContainer}><LinearProgress /></div>}
        <div style={loading ? { display: 'none' } : {}}>
          {children}
        </div>
      </Grid>
    </SwipeableDrawer>
  );
});

export default Drawer;

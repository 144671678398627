import { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Ad from './Ad';
import { usePrevious } from '../../../../../../../Helpers/CustomHooks';

const itemCount = 20;

const useStyles = makeStyles()(() => ({
  buttonContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px',
  },
  button: {
    margin: '3px',
    padding: '8px',
    background: 'white',
    border: 'solid 1px rgba(0,0,0,0.1)',
    outline: 'none!important',
    cursor: 'pointer',
    borderRadius: '4px',
    '&:hover': {
      border: 'solid 1px black',
      background: 'rgba(0,0,0,0.01)',
    },
  },
  active: {
    border: 'solid 1px black',
    background: 'rgba(0,0,0,0.01)',
  },
}));

export default function ({ list }) {
  const { classes } = useStyles();
  const [displayItems, setDisplayItems] = useState([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const prevList = usePrevious(list);

  useEffect(() => {
    if (!isEqual(prevList, list)) {
      setDisplayItems(list.slice(0, 10));
    }
  }, [list]);

  const updatePage = (page) => {
    setCurrentPageIndex(page);
    setDisplayItems(list.slice(page === 0 ? 0 : page * itemCount, (page + 1) * itemCount));
  };

  const renderPageButton = (index) => (
    <button
      className={`${classes.button} ${index === currentPageIndex ? classes.active : ''}`}
      type="button"
      onClick={() => updatePage(index)}
    >
      {index + 1}
    </button>
  );

  const renderPageButtons = () => {
    const pages = Math.ceil(list.length / itemCount);
    const buttons = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < pages; i++) {
      buttons.push(renderPageButton(i));
    }
    return buttons.length > 1 ? (
      <div className={classes.buttonContainer}>
        {buttons}
      </div>
    ) : '';
  };

  const ads = useMemo(
    () => (displayItems.map((ad, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Ad key={ad.title + index} ad={ad} />
    ))),
    [displayItems],
  );

  return (
    <>
      {ads}
      {renderPageButtons()}
    </>
  );
}

import React from 'react';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { closeDialog } from '../actions/widgets';
import { DialogConfirm } from './DialogConfirm';

const useStyles = makeStyles()((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    textAlign: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  warningTitle: {
    color: '#ff0000',
  },
  success: {
    color: '#4caf50',
  },
  dialogText: {
    color: 'inherit',
  },
  warning: {
    color: 'rgba(0, 0, 0, 0.5) !important',
  },
}));

function DialogContainer() {
  const {
    open,
    title,
    children,
    handleAction,
    confirmDialog,
    formDialog,
    warningDialog,
    confirmText,
    cancelText,
    cancelAction,
  } = useSelector((state) => state.widgets.dialog);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  return (
    <Dialog
      open={open}
      onClose={() => dispatch(closeDialog())}
    >
      <DialogTitle className={warningDialog ? `${classes.warningTitle} ${classes.title}` : ''}>
        {title}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => dispatch(closeDialog())}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={`${warningDialog && classes.warning} ${classes.dialogText}`}>
          { children }
        </DialogContentText>
        {formDialog}
      </DialogContent>
      {confirmDialog && (
      <DialogActions>
        <DialogConfirm
          handleAction={handleAction}
          confirmText={confirmText}
          cancelText={cancelText}
          cancelAction={cancelAction}
        />
      </DialogActions>
      )}
    </Dialog>
  );
}

export default DialogContainer;

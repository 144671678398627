import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FileCopyOutlined } from '@mui/icons-material';
import { openSnackbar } from '../../../Widgets/actions/widgets';

const useStyles = makeStyles()((theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.variantText,
    fontFamily: 'Arial, Helvetica, sans-serif',
    flexGrow: 1,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default function PreviewLink() {
  const { previewLink } = useSelector((state) => state.campaignDetails);
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const copyLink = () => {
    const textField = document.createElement('textarea');
    textField.innerText = previewLink;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    dispatch(openSnackbar('Link was copied to clipboard', 'success'));
  };

  return (
    <div className={classes.root}>
      <a href={previewLink} target="_blank" rel="noopener noreferrer" className={classes.link}>Preview Link</a>
      <Tooltip
        title="Click to copy link"
        placement="top"
      >
        <FileCopyOutlined onClick={copyLink} />
      </Tooltip>
    </div>
  );
}

import React from 'react';
import { LinearProgress as Progress, Grid } from '@mui/material';

function LinearProgress() {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={8}>
        <Progress />
      </Grid>
    </Grid>
  );
}

export default LinearProgress;

import {
  startOfDay, endOfDay, addDays, startOfMonth, endOfMonth, addMonths,
} from 'date-fns';

export const PREDEFINED_RANGES_NAMES = {
  today: 'today',
  yesterday: 'yesterday',
  daysAgo2: 'two_days_ago',
  last7Days: 'last_seven_days',
  thisMonth: 'this_month',
  last30Days: 'last_thirty_days',
  lastMonth: 'last_month',
  monthsAgo2: 'two_months_ago',
  allTime: 'all_time',
};

const endOfToday = endOfDay(new Date());

export const PREDEFINED_RANGES = {
  [PREDEFINED_RANGES_NAMES.today]: {
    label: 'Today',
    range: () => ({
      startDate: startOfDay(new Date()),
      endDate: endOfToday,
      range: PREDEFINED_RANGES_NAMES.today,
    }),
  },
  [PREDEFINED_RANGES_NAMES.yesterday]: {
    label: 'Yesterday',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -1)),
      endDate: endOfDay(addDays(new Date(), -1)),
      range: PREDEFINED_RANGES_NAMES.yesterday,
    }),
  },
  [PREDEFINED_RANGES_NAMES.daysAgo2]: {
    label: '2 Days Ago',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -2)),
      endDate: endOfDay(addDays(new Date(), -2)),
      range: PREDEFINED_RANGES_NAMES.daysAgo2,
    }),
  },
  [PREDEFINED_RANGES_NAMES.last7Days]: {
    label: 'Last 7 Days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -6)),
      endDate: endOfToday,
      range: PREDEFINED_RANGES_NAMES.last7Days,
    }),
  },
  [PREDEFINED_RANGES_NAMES.thisMonth]: {
    label: 'This Month',
    range: () => ({
      startDate: startOfMonth(new Date()),
      endDate: endOfToday,
      range: PREDEFINED_RANGES_NAMES.thisMonth,
    }),
  },
  [PREDEFINED_RANGES_NAMES.last30Days]: {
    label: 'Last 30 Days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -29)),
      endDate: endOfToday,
      range: PREDEFINED_RANGES_NAMES.last30Days,
    }),
  },
  [PREDEFINED_RANGES_NAMES.allTime]: {
    label: 'All Time',
    range: () => ({
      range: PREDEFINED_RANGES_NAMES.allTime,
      startDate: startOfDay(new Date('5/1/2020')),
      endDate: endOfToday,
    }),
  },
};

export const PREDEFINED_PERFORMANCE_RANGES = {
  [PREDEFINED_RANGES_NAMES.thisMonth]: {
    label: 'This Month',
    range: () => ({
      startDate: startOfMonth(new Date()),
      endDate: endOfToday,
      range: PREDEFINED_RANGES_NAMES.thisMonth,
    }),
  },
  [PREDEFINED_RANGES_NAMES.lastMonth]: {
    label: 'Last Month',
    range: () => ({
      startDate: startOfMonth(addMonths(new Date(), -1)),
      endDate: endOfMonth(addMonths(new Date(), -1)),
      range: PREDEFINED_RANGES_NAMES.lastMonth,
    }),
  },
  [PREDEFINED_RANGES_NAMES.monthsAgo2]: {
    label: 'Two Months Ago',
    range: () => ({
      startDate: startOfMonth(addMonths(new Date(), -2)),
      endDate: endOfMonth(addMonths(new Date(), -2)),
      range: PREDEFINED_RANGES_NAMES.monthsAgo2,
    }),
  },
};

import React, { useState } from 'react';
import PropTypes, { element } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconButton, CircularProgress, Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Edit, Check, Close } from '@mui/icons-material';
import Input from '../Widgets/Forms/Fields/Input';

const elementID = 'editInPlace-';

const useStyles = makeStyles()(() => ({
  glanceIconContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconContainer: {
    position: 'relative',
    display: 'inline-flex',
  },
  glanceIconButton: {
    position: 'absolute',
    right: -10,
    top: -10,
    opacity: '.6',
  },
  glanceIcon: {
    fontSize: '18px',
  },
  successProgress: {
    position: 'absolute',
    top: 3,
    left: 3,
    zIndex: 1,
    height: '35px!important',
    width: '35px!important',
  },
  inlineCheck: {
    display: 'inline-flex',
    marginLeft: '10px',
  },
  inlineField: {
    display: 'inline-flex',
    width: '70%',
  },
  inlineFieldContainer: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  input: {
    width: '100%',
  },
  tooltipContainer: {
    display: 'inline-flex',
    position: 'absolute',
    right: '0',
    top: '-25px',
  },
}));

export function EditView(props) {
  const {
    id, value, label, type, adornment, name, setEditView, editView,
    onSubmit, inputProps = {}, siteID, onCancel, noDisplayView, campaignID,
    nonDecimal, inlineInputComponent,
  } = props;
  const { campaignID: campaignDetailsID = false } = useSelector((state) => state.campaignDetails);
  const [inputValue, setInputValue] = useState(value);
  const [helperText, setHelperText] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const renderActionButtons = () => (
    <>
      <div className={`${classes.iconContainer} ${noDisplayView ? classes.inlineCheck : ''}`}>
        {loading && (
          <CircularProgress className={classes.successProgress} />
        )}
        <IconButton
          onClick={() => onSubmit(
            props,
            campaignDetailsID || campaignID,
            inputValue,
            setLoading,
            helperText,
            setHelperText,
            dispatch,
            siteID,
          )}
          disabled={loading}
          id="updateIcon"
        >
          <Check />
        </IconButton>
      </div>
      {!noDisplayView && (
        <div className={classes.iconContainer}>
          <IconButton
            onClick={() => {
              onCancel();
              setInputValue(value);
              setEditView(false);
            }}
          >
            <Close />
          </IconButton>
        </div>
      )}
    </>
  );

  function getOnSubmit() {
    return (e) => {
      onSubmit(
        props,
        campaignDetailsID || campaignID,
        inputValue,
        setLoading,
        helperText,
        setHelperText,
        dispatch,
        siteID,
      );
      e.preventDefault();
      return false;
    };
  }

  return !editView ? '' : (
    <div className={noDisplayView ? classes.inlineFieldContainer : ''}>
      <form onSubmit={getOnSubmit()} noValidate>
        <div className={noDisplayView ? classes.inlineField : classes.input}>
          <Input
            id={id}
            nonDecimal={nonDecimal}
            value={inputValue}
            label={label}
            type={type}
            adornment={adornment}
            onChange={setInputValue}
            helperText={helperText}
            name={name}
            inputProps={inputProps}
          />
          {inlineInputComponent && inlineInputComponent}
        </div>
        {renderActionButtons()}
      </form>
    </div>
  );
}

function DisplayView(props) {
  const { classes } = useStyles();
  const {
    value, setEditView, editView, overrideClass, tooltipText,
  } = props;
  return editView ? '' : (
    <div className={classes.glanceIconContainer}>
      {value}
      <Tooltip title={tooltipText}>
        <IconButton
          className={`${classes.glanceIconButton} ${overrideClass}`}
          onClick={() => setEditView(true)}
        >
          <Edit
            className={classes.glanceIcon}
          />
        </IconButton>
      </Tooltip>
    </div>
  );
}

function EditInPlace(props) {
  const {
    defaultView,
    noDisplayView,
    uniqueID,
    value,
    nonDecimal,
    label,
    displayValue,
    editClassName,
    displayClassName,
    type,
    adornment,
    onSubmit,
    campaignID,
    onCancel,
    name,
    inputProps,
    siteID,
    overrideClass,
    updateData,
    fieldName,
    tooltipText,
    inlineInputComponent,
  } = props;
  const [editView, setEditView] = useState(defaultView);
  const [editedValue, setEditedValue] = useState(displayValue);
  const formattedID = elementID + uniqueID;
  return (
    <div>
      {!noDisplayView && (
        <DisplayView
          value={editedValue}
          className={displayClassName}
          setEditView={setEditView}
          editView={editView}
          label={label}
          overrideClass={overrideClass}
          tooltipText={tooltipText}
        />
      )}
      <EditView
        value={value}
        onSubmit={onSubmit}
        className={editClassName}
        id={formattedID}
        label={label}
        type={type}
        adornment={adornment}
        name={name}
        nonDecimal={nonDecimal}
        setEditView={setEditView}
        setEditedValue={setEditedValue}
        inlineInputComponent={inlineInputComponent}
        editView={!noDisplayView ? editView : true}
        inputProps={inputProps}
        siteID={siteID}
        updateData={updateData}
        fieldName={fieldName}
        campaignID={campaignID}
        onCancel={onCancel}
        noDisplayView={noDisplayView}
      />
    </div>
  );
}

EditInPlace.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, element]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  updateData: PropTypes.func,
  fieldName: PropTypes.string,
  defaultView: PropTypes.bool,
  noDisplayView: PropTypes.bool,
  uniqueID: PropTypes.number,
  editClassName: PropTypes.string,
  nonDecimal: PropTypes.bool,
  campaignID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayClassName: PropTypes.string,
  type: PropTypes.string,
  adornment: PropTypes.string,
  name: PropTypes.string,
  siteID: PropTypes.string,
  inputProps: PropTypes.instanceOf(Object),
  overrideClass: PropTypes.string,
  tooltipText: PropTypes.string,
  inlineInputComponent: PropTypes.element,
};

EditInPlace.defaultProps = {
  label: '',
  campaignID: '',
  noDisplayView: false,
  defaultView: false,
  nonDecimal: false,
  uniqueID: Math.floor(Math.random() * 100000),
  editClassName: '',
  displayClassName: '',
  fieldName: '',
  type: '',
  adornment: '',
  name: '',
  siteID: '',
  inputProps: {},
  updateData: () => {},
  overrideClass: '',
  onCancel: () => {},
  tooltipText: '',
  inlineInputComponent: null,
};

EditView.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setEditView: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  adornment: PropTypes.string.isRequired,
  campaignID: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  editView: PropTypes.bool.isRequired,
  inlineInputComponent: PropTypes.element,
  nonDecimal: PropTypes.bool,
  noDisplayView: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  siteID: PropTypes.string.isRequired,
  inputProps: PropTypes.instanceOf(Object).isRequired,
};

EditView.defaultProps = {
  label: '',
  noDisplayView: false,
  onCancel: () => {},
  inlineInputComponent: null,
  nonDecimal: false,
};

DisplayView.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, element]).isRequired,
  setEditView: PropTypes.func.isRequired,
  editView: PropTypes.bool.isRequired,
  tooltipText: PropTypes.string,
  overrideClass: PropTypes.string,
};

DisplayView.defaultProps = {
  tooltipText: '',
  overrideClass: '',
};

export default EditInPlace;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  FormControl, FormHelperText, Grid, InputLabel, Select, MenuItem,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { usePrevious } from '../../../Helpers/CustomHooks';

const useStyles = makeStyles()((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  margin: {
    marginRight: '1rem',
  },
  loader: {
    textAlign: 'center',
  },
  helper: {
    color: 'red',
  },
  option: {
    padding: '8px 6px',
    '&:hover': {
      cursor: 'pointer',
      opacity: '.8',
      color: theme.palette.oppositeVariantText,
      backgroundColor: theme.palette.oppositeVariantBackground,
    },
  },
  selected: {
    '&:hover': {
      color: theme.palette.oppositeVariantBackground,
    },
  },
}));

const SelectField = React.memo((
  {
    label, helperText, fullWidth, onChange, noMargin, onBlurAction,
    value, name, className, validationFunc, options, multiple, tempOptions,
  },
) => {
  const [selectOptions, setSelectOptions] = useState([]);
  const { classes } = useStyles();
  const propOptions = { options };
  const prevPropOptions = usePrevious(propOptions);

  const getOptions = () => {
    if (typeof options === 'function') {
      setSelectOptions(tempOptions);
      options().then((data) => setSelectOptions(data));
      return;
    }
    setSelectOptions(options);
  };

  useEffect(() => {
    if (!_.isEqual(propOptions, prevPropOptions) && typeof options === 'object') {
      setSelectOptions(options);
    }
  }, [propOptions, options, prevPropOptions, setSelectOptions]);

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line
  }, []);

  const renderInputLabel = () => (
    <InputLabel
      htmlFor="outlined-age-native-simple"
      className={classes.labelStyle}
    >
      {label}
    </InputLabel>
  );

  const selectValue = (selectOptions.length && value) || '';

  const renderSelectField = () => (
    <Select
      label={label}
      fullWidth={fullWidth}
      value={selectValue}
      name={name}
      error={!!helperText}
      multiple={multiple}
      style={{ width: '100%' }}
      className={`${classes.margin} ${classes.textField} ${className}`}
      onChange={(e) => onChange(e.target.value, name, () => {})}
      onBlur={(e) => onBlurAction && onChange(e.target.value, name, validationFunc(e.target.value))}
    >
      {selectOptions.map((option) => (
        <MenuItem
          className={`${classes.option} ${selectValue === option.value ? classes.selected : ''}`}
          key={option.value}
          value={option.value}
          disabled={option.disabled || false}
        >
          {option.title}
        </MenuItem>
      ))}
    </Select>
  );

  const renderInput = () => (
    <FormControl variant="outlined" className={classes.formControl} size="small">
      {renderInputLabel()}
      {renderSelectField()}
      {helperText && <FormHelperText className={classes.helper}>{helperText}</FormHelperText>}
    </FormControl>
  );

  return (
    <Grid key={name} container justify="center" alignItems="center">
      <Grid item xs={12} className={`${noMargin ? '' : classes.margin}`}>
        {renderInput()}
      </Grid>
    </Grid>
  );
});

SelectField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  tempOptions: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  noMargin: PropTypes.bool,
  multiple: PropTypes.bool,
  onBlurAction: PropTypes.bool,
  validationFunc: PropTypes.func,
};

SelectField.defaultProps = {
  label: '',
  helperText: '',
  fullWidth: true,
  multiple: false,
  className: '',
  noMargin: false,
  onBlurAction: true,
  validationFunc: () => '',
};

export default SelectField;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import MenuIcon from '@mui/icons-material/Menu';
import { toggleMenu } from '../SideNavigation/actions/sideNavigation';

const useStyles = makeStyles()(() => ({
  menuButton: {
    marginRight: '16px',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.08)!important',
    },
  },
}));

export default function ToggleMenu() {
  const { classes } = useStyles();
  const open = useSelector((state) => state.sideNavigation);
  const dispatch = useDispatch();
  return (
    <IconButton
      edge="start"
      className={classes.menuButton}
      onClick={() => dispatch(toggleMenu(open && open.open))}
      color="inherit"
      aria-label="open drawer"
    >
      <MenuIcon />
    </IconButton>
  );
}

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import {
  Paper, Grid, Button, useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Stepper from './Steps/Stepper';
import {
  changeNonField, changeStep,
} from '../actions/addCampaign';
import StepContainer from './Steps/StepContainer';
import SubmitButton from '../../../Widgets/Forms/Buttons/SubmitButton';
import validateCampaignSettings from '../Validation/ValidateCampaignSettings';
import { openSnackbar } from '../../../Widgets/actions/widgets';
import validateLinks from '../Validation/ValidateLinks';
import { NetworkRequestPost } from '../../../Helpers/NetworkRequest';
import { createAddCampaignParams } from '../createAddCampaignParams';
import { notTestUser } from './Links/Links';

const useStyles = makeStyles()((theme) => ({
  paper: {
    flex: 1,
    padding: 24,
    minHeight: 'calc(100vh - 110px)',
    boxSizing: 'border-box',
    position: 'relative',
  },
  container: {
    height: '100%',
    marginBottom: '4rem',
  },
  stepperContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 0,
    width: 'calc(100% - 48px)',
  },
}));

function AddCampaignContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addCampaign } = useSelector((state) => state);
  const {
    currentStep,
    settings,
    presellLinks,
    landingPageLinks,
    links: { safePageLink, advancedTracking: { value: advancedTracking } },
    submitting,
    adSets,
  } = addCampaign;
  const { classes } = useStyles();

  useEffect(() => {
    dispatch(changeNonField('dayPartingLoaded', true));
    // eslint-disable-next-line
  }, []);

  const isFinalStepCount = () => (settings.setupDayparting.value ? 3 : 2);

  const isFinalStep = currentStep === isFinalStepCount();

  const goToNextStep = async () => {
    let errorMessage = '';
    if (currentStep === 0) {
      errorMessage = await validateCampaignSettings(settings);
    } else if (
      (!settings.setupDayparting.value && currentStep === 1)
      || (settings.setupDayparting.value && currentStep === 2)
    ) {
      errorMessage = validateLinks(presellLinks, landingPageLinks, safePageLink, advancedTracking);
    }
    if (!errorMessage) {
      dispatch(changeStep(currentStep + 1));
    } else {
      dispatch(openSnackbar(errorMessage, 'error'));
    }
    window.scrollTo(0, 0);
  };

  const validateAdSets = () => adSets && typeof adSets === 'object' && adSets.length > 0;

  const submitCampaign = async () => {
    dispatch(changeNonField('submitting', true));
    if (validateAdSets()) {
      const { data } = await NetworkRequestPost('addCampaign', createAddCampaignParams(addCampaign));
      if (notTestUser()) {
        if (data && data.success) {
          dispatch(openSnackbar('The campaign was successfully created!', 'success'));
          navigate(`/campaign/${data.campaign_name || settings.campaignName.value}`);
        } else {
          const message = (data.errorList && data.errorList.join('\n')) || data.message;
          dispatch(openSnackbar(message || 'We encountered an error while creating your campaign. Please check your values, and try again', 'error'));
        }
      } else {
        dispatch(openSnackbar('Campaign created successfully! Please wait for your campaign to be processed.', 'success'));
        navigate('/campaigns');
      }
    }
    dispatch(changeNonField('submitting', false));
  };

  const renderButtons = () => (
    <div className={classes.buttonContainer}>
      <Button
        variant="contained"
        onClick={() => dispatch(changeStep(currentStep - 1))}
        disabled={currentStep === 0}
        style={{ visibility: currentStep !== 0 ? 'visible' : 'hidden' }}
      >
        Previous
      </Button>
      <SubmitButton
        handleSubmit={() => (isFinalStep ? submitCampaign() : goToNextStep())}
        content={isFinalStep ? 'Submit' : 'Next'}
        isLoading={submitting}
      />
    </div>
  );

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <Paper className={classes.paper}>
      <Grid container justify="center" alignItems="flex-start" className={classes.container}>
        {!isMobile && (
          <Grid item sm={3} md={2} className={classes.stepperContainer}>
            <Stepper />
          </Grid>
        )}
        <Grid item sm={9} md={10} className={classes.stepContainer}>
          <StepContainer goToNextStep={goToNextStep} />
        </Grid>
      </Grid>
      {renderButtons()}
    </Paper>
  );
}

export default AddCampaignContainer;

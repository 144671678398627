export const CHANGE_FILTER = 'CHANGE_FILTER';

const localStorageMap = {
  status: 'campaignsStatusFilter',
  account_id: 'accountFilter',
  sub_account_id: 'subAccountFilter',
  user: 'userFilter',
};

export const changeFilter = (filter, value) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(localStorageMap[filter], value);
  }
  return {
    type: CHANGE_FILTER,
    filter,
    value,
  };
};

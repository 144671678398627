import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { NetworkRequestPost } from '../../Helpers/NetworkRequest';
import { changeCampaignDetails } from './actions/campaignDetails';
import LinearProgress from '../../Widgets/Loaders/LinearProgress';
import { updateRefreshedAt } from '../../Components/Navigation/SideNavigation/Filters/DateRange/actions/dateRange';
import { openSnackbar } from '../../Widgets/actions/widgets';
import CampaignView from './Views/CampaignView/CampaignView';
import AdSetView from './Views/AdSetView/AdSetView';
import AdView from './Views/AdView/AdView';
import Banner from './Banner/Banner';

const viewsMapping = {
  campaign: <CampaignView />,
  adSet: <AdSetView />,
  ad: <AdView />,
};

const useStyles = makeStyles()(() => ({
  container: {
    padding: 10,
  },
  loadingContainer: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function CampaignDetails() {
  const navigate = useNavigate();
  const { name } = useParams();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const {
    loading,
    campaignID,
    stripes,
    view,
  } = useSelector(({ campaignDetails }) => campaignDetails);

  async function getData() {
    try {
      const { data } = await NetworkRequestPost('initialData', {
        campaign_name: name,
      });
      if (data && data.success && data.data) {
        const {
          campaign_id: ID, status, preview_link: link,
          budget, advanced_tracking: advancedTracking, budget_optimization: budgetOptimization,
          bid_strategy: bidStrategy, bid_amount: bidAmount, ad_account_id: subAccountId,
        } = data.data;
        dispatch(changeCampaignDetails('campaignID', ID));
        dispatch(changeCampaignDetails('campaignStatus', status));
        dispatch(changeCampaignDetails('budgetOptimization', budgetOptimization === 'campaign' ? 'campaign' : 'ad_set'));
        dispatch(changeCampaignDetails('bidStrategy', bidStrategy));
        dispatch(changeCampaignDetails('bidAmount', bidAmount));
        dispatch(changeCampaignDetails('subAccountId', subAccountId));
        dispatch(changeCampaignDetails('previewLink', link));
        dispatch(changeCampaignDetails(
          'advancedTrackingReporting',
          advancedTracking === undefined ? false : (+advancedTracking === 1),
        ));
        dispatch(changeCampaignDetails('budget', budget));
      } else {
        dispatch(openSnackbar(data.message || 'The campaign you tried to visit does not exist. You\'ve been redirected to the campaigns page', 'error'));
        // eslint-disable-next-line
        navigate('/campaigns');
      }
      dispatch(changeCampaignDetails('loading', false));
    } catch (e) {
      console.error(e);
      dispatch(openSnackbar('There is an error while loading this campaign. Please try again.', 'error'));
      dispatch(changeCampaignDetails('loading', false));
      // eslint-disable-next-line
      navigate('/campaigns');
    }
  }

  useEffect(() => {
    dispatch(updateRefreshedAt());
    getData();
    return () => {
      dispatch(changeCampaignDetails('menuLabel', ''));
      dispatch(changeCampaignDetails('campaignID', ''));
      dispatch(changeCampaignDetails('adSetID', ''));
      dispatch(changeCampaignDetails('adID', ''));
      dispatch(changeCampaignDetails('table', 'ads'));
      dispatch(changeCampaignDetails('campaignStatus', 0));
      dispatch(changeCampaignDetails('burst', ''));
      dispatch(changeCampaignDetails('loading', true));
      dispatch(changeCampaignDetails('view', 'campaign'));
    };
    // eslint-disable-next-line
  }, [name]);

  return loading && !campaignID ? (
    <div className={classes.loadingContainer}>
      <LinearProgress />
    </div>
  )
    : (
      <>
        {stripes.map((stripe) => <Banner text={stripe} />)}
        {viewsMapping[view]}
      </>
    );
}

export default CampaignDetails;

import React from 'react';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from '../../../../../Widgets/Forms/Fields/Select';
import { updateRefreshedAt } from '../DateRange/actions/dateRange';
import { changeFilter } from '../actions/filters';

const useStyles = makeStyles()((theme) => ({
  select: {
    backgroundColor: 'transparent',
    color: theme.palette.variantText,
    width: '100%',
    height: '100%',
    justifyContent: 'flex-start',
    margin: 0,
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.variantText,
    },
  },
}));

const Filter = React.memo((props) => {
  const {
    name, label, filterKey, options, tempOptions, noMargin, callback, onBlurAction,
  } = props;
  const { [filterKey]: value } = useSelector((state) => state.filters);
  const { dateRange } = useSelector((state) => state.dateRange);
  const { classes } = useStyles();
  const dispatch = useDispatch();

  function updateValue(val) {
    dispatch(changeFilter(filterKey, val));
    if (filterKey === 'account_id') {
      dispatch(changeFilter('sub_account_id', 'all'));
    }
    dispatch(updateRefreshedAt());
    callback(val, dispatch, dateRange);
  }

  return (
    <SelectInput
      name={name}
      type={name}
      className={classes.select}
      label={label}
      value={value}
      onChange={(val) => updateValue(val)}
      options={options}
      tempOptions={tempOptions}
      noMargin={noMargin}
      onBlurAction={onBlurAction}
    />
  );
});

Filter.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  filterKey: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  tempOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  noMargin: PropTypes.bool,
  callback: PropTypes.func,
  onBlurAction: PropTypes.bool,
};

Filter.defaultProps = {
  tempOptions: [],
  noMargin: false,
  onBlurAction: true,
  callback: () => {},
};

export default Filter;

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SubmitButton from '../../Forms/Buttons/SubmitButton';

const useStyles = makeStyles()(() => ({
  root: {
    minWidth: 600,
  },
  widthLimited: {
    maxWidth: 600,
  },
}));

export default function FormDrawer({
  children, onSubmit, submitting, widthLimited,
}) {
  const { classes } = useStyles();
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      className={`${classes.root} ${(widthLimited && classes.widthLimited) || ''}`}
    >
      {children}
      <SubmitButton
        isLoading={submitting}
        handleSubmit={onSubmit}
      />
    </Grid>
  );
}

FormDrawer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  submitting: PropTypes.bool.isRequired,
  widthLimited: PropTypes.bool,
};

FormDrawer.defaultProps = {
  widthLimited: false,
};

import * as React from 'react';
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid';

export const CustomToolBar = (hideQuick = false, hideExport = false) => (
  <GridToolbarContainer>
    {!hideQuick && <GridToolbarQuickFilter />}
    <GridToolbarFilterButton />
    <GridToolbarDensitySelector />
    {!hideExport && <GridToolbarExport /> }
  </GridToolbarContainer>
);

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { capitalize } from 'lodash';
import { NetworkRequestPost, NetworkRequestPut } from '../../Helpers/NetworkRequest';
import Input from '../../Widgets/Forms/Fields/Input';
import * as validation from '../../Pages/Login/validation';
import { closeRightDrawer, openSnackbar } from '../../Widgets/actions/widgets';
import FormDrawer from '../../Widgets/Drawer/FormDrawer/FormDrawer';
import LinearProgress from '../../Widgets/Loaders/LinearProgress';
import { getDataUrl } from '../../Endpoints';

const useStyles = makeStyles()((theme) => ({
  buttonContainer: {
    margin: '3rem auto 0 auto',
    textAlign: 'center',
  },
  changeSettingsFormContainer: {
    justifyContent: 'center',
  },
  changeSettingsForm: {
    marginBottom: '1rem',
  },
  customSettingsForm: {
    marginBottom: '1rem',
    marginTop: '3rem',
    borderTop: 'solid 2px',
    borderTopColor: theme.variantColor('#000', '#fff'),
    paddingTop: '3rem',
    textAlign: 'center',
  },
  textField: {},
  selectInput: {
    padding: 7,
    marginRight: 0,
    textAlign: 'left',
  },
  selectContainer: {
    paddingTop: 15,
    paddingBottom: 10,
  },
  loadingContainer: {
    height: 300,
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  switchGrid: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    margin: '15px 0',
  },
  label: {
    display: 'inline-block',
    marginRight: '15px',
  },
}));

function validateUserSettings(name, email) {
  const errorMessages = [];
  if (!name.value || name.value.length < 2) {
    errorMessages.push('Please make sure the name is at least 2 letters long.');
  }
  if (!email.value || !email.value.includes('@') || !email.value.includes('.')) {
    errorMessages.push('Please enter a valid email address.');
  }

  return errorMessages;
}

export function UserSettings({ loading: defaultLoading }) {
  const [loading, setLoading] = useState(defaultLoading);
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState({ value: '', error: '' });
  const [email, setEmail] = useState({ value: '', error: '' });

  const { classes } = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    async function getUserData() {
      try {
        const { data } = await NetworkRequestPost('getUserSettings', {});
        if (data && data.success) {
          const {
            email: pulledEmail,
            name: pulledName,
          } = data.data;
          setName({ ...name, value: pulledName || '' });
          setEmail({ ...email, value: pulledEmail || '' });
        } else {
          dispatch(openSnackbar(data.message || 'There is a problem of getting your settings.', 'error'));
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }

    getUserData();
    // eslint-disable-next-line
  }, []);

  const handleChangeSettings = async () => {
    const validationErrors = validateUserSettings(name, email);
    if (validationErrors.length > 0) {
      const errors = (
        <div style={{ textAlign: 'left', lineHeight: '2rem' }}>
          {validationErrors.map((errorMessage) => <div key={errorMessage}>{errorMessage}</div>)}
        </div>
      );
      dispatch(openSnackbar(errors, 'error'));
      return;
    }

    setSubmitting(true);

    const { data: { success, message = '' } } = await NetworkRequestPut(
      'updateSettings',
      {
        email: email.value,
        name: name.value,
      },
    );
    if (success) {
      dispatch(closeRightDrawer());
      dispatch(
        openSnackbar(
          message || 'You have successfully updated your settings.',
          'success',
        ),
      );
    } else {
      dispatch(openSnackbar(message || 'There was a problem updating your settings.', 'error'));
    }
    setSubmitting(false);
  };

  const renderInput = (inputName, validFunc, state, handleChange) => (
    <Grid item xs={12}>
      <Input
        name={inputName}
        type={inputName}
        label={capitalize(inputName)}
        value={state.value}
        onChange={(value, fieldName, error) => handleChange({ value, error })}
        className={classes.textField}
        validationFunc={validFunc}
        helperText={state.error}
        error={state.error}
      />
    </Grid>
  );

  const renderChangeSettings = () => (
    <Grid item xs={12}>
      <Grid container justify="center" alignItems="center" className={classes.changeSettingsFormContainer}>
        <Grid item xs={9}>
          <form className={classes.changeSettingsForm} onSubmit={(e) => e.preventDefault()}>
            {renderInput('name', validation.notEmptyString, name, setName)}
            {renderInput('email', validation.emailValidation, email, setEmail)}
          </form>
        </Grid>
      </Grid>
    </Grid>
  );

  return loading ? <div className={classes.loadingContainer}><LinearProgress /></div> : (
    <Grid>
      <FormDrawer
        onSubmit={handleChangeSettings}
        submitting={submitting}
      >
        <Grid container justify="center" alignItems="center" className={classes.changeSettingsFormContainer}>
          {renderChangeSettings()}
        </Grid>
      </FormDrawer>

      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={9} className={classes.customSettingsForm}>
          <Button
            variant="contained"
            onClick={() => {
              window.location.href = `${getDataUrl().fbAuth}?token=${localStorage.getItem('token')}`;
            }}
          >
            Authorize Facebook Account
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

UserSettings.propTypes = {
  loading: PropTypes.bool,
};

UserSettings.defaultProps = {
  loading: true,
};

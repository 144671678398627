import { makeStyles } from 'tss-react/mui';
import { AppBar, Toolbar } from '@mui/material';
import React from 'react';
import LogoHome from '../../../Components/Navigation/TopNavigation/LogoHome';

const useStyles = makeStyles()(() => (
  {
    pageContainer: {
      minHeight: 'calc(100vh - 64px)',
      backgroundColor: '#F5F5F5',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingTop: '64px',
    },
    contentContainer: {
      width: '80rem',
      maxWidth: '100vw',
      borderRight: '1px solid #E0E0E0',
      borderLeft: '1px solid #E0E0E0',
      padding: '0 1rem 0 1rem',
    },
    textBodyMd: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    title: {
      fontSize: '2rem',
    },
    p1: {
      fontSize: '1rem',
    },
    boldHeading: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
  }
));

export default function Privacy() {
  const { classes } = useStyles();

  function getMainContent() {
    return (
      <div className={classes.textBodyMd}>
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
          <div className="text-center text-[#545454]">
            <h1 className={classes.title}>Privacy Policy</h1>
            <hr />
            <p className={classes.p1}>
              The website located at www.maximussocial.com is operated by Union Square Media Group
              (hereinafter referred to as "Company," "us," "we," and "our"). We are committed to
              respecting your privacy and recognizing your need for appropriate protections and management
              of personally identifying information you share with us. This privacy policy explains how we
              use your information that is collected through your use of this website. AS A CONDITION OF YOUR
              CONTINUED USE OF THIS WEBSITE YOU AGREE TO THE FOLLOWING PRIVACY POLICY:
            </p>

            <h2 className={classes.boldHeading}>Adults Only</h2>
            <p className={classes.p1}>
              If you are under the age of 18 you may not use this website for any purposes.
            </p>

            <h2 className={classes.boldHeading}>General</h2>
            <p className={classes.p1}>
              We may track the Internet domain address from which people visit the Website
              and analyze this data for trends and statistics, but individual users will remain
              anonymous unless they voluntarily tell us who they are. To access some areas of this website
              you must first register, examples are registration as an Advertiser or a Publisher/Affiliate; such
              registration will require you to provide personally identifying information which will be stored on our
              website and in our internal company records. Whether or not you choose to provide the information we request
              is entirely up to you. But if you choose not to provide the information which we request, you will be unable
              to access certain areas of this website.
            </p>

            <h2 className={classes.boldHeading}>
              Collection and Use of Personal Information
            </h2>
            <p className={classes.p1}>
              If you choose to provide information to us through our online registration,
              you may be asked to provide information, including your name, company name, physical
              address, billing address, telephone number, facsimile number, e-mail address, website address,
              tax payer ID number or social security number, billing or payment information, and other identifying
              information. If necessary, we may share your personal information which you provide with our affiliates,
              advertisers, outside accounting firms, legal counsel, state and federal tax services and any government
              or law enforcement agency that requests such information. We may use the personal information which you
              provide for any legally permissible purpose.

              Your personal information is necessary for us to provide you access to and use of our network.
            </p>

            <h2 className={classes.boldHeading}>
              Third-Party Hosting
            </h2>

            <p className={classes.p1}>
              Company may contract with a third party to maintain and host the Website.
              Therefore any information you submit, including personal information, may be
              placed and stored on a computer server maintained by this third-party host. You
              agree that Company is not liable for any breach of security due to the actions or inactions
              of the third-party host.
            </p>

            <h2 className={classes.boldHeading}>
              Links to Other Materials
            </h2>

            <p className={classes.p1}>
              The Website may contain links to other sites. Any such links are an accommodation to the respective
              third-party site owners and for your convince. Sites linked to and from the Website are not necessarily
              under the control of Company, and Company shall have no responsibility or liability whatsoever
              for the content or privacy practices of any linked sites, or any link or linking program at any time.
            </p>

            <h2 className={classes.boldHeading}>
              Security
            </h2>
            <p className={classes.p1}>
              This Website has security measures in place to protect the loss, misuse, and alteration of the information under our control. Effective Date and Changes

              This privacy policy is effective as of November 1, 2011. YOUR USE OF THE WEBSITE CONSTITUTES BINDING ACCEPTANCE OF THIS PRIVACY POLICY.

              We reserve the right to modify the terms of this privacy policy at any time and in our sole discretion, by posting a change notice
              on this page. YOUR CONTINUED USE OF THE WEBSITE FOLLOWING OUR POSTING OF A CHANGE NOTICE WILL CONSTITUTE BINDING ACCEPTANCE OF THOSE CHANGES.
            </p>

            <section id="CAPrivacyRights">
              <h2 className={classes.boldHeading}>
                My California Privacy Rights
              </h2>
            </section>
            <p className={classes.p1}>
              This policy is specific and applies only to
              Site visitors who are California residents.
            </p>

            <h2 className={classes.boldHeading}>California Shine the Light </h2>
            <p className={classes.p1}>
              We will not share any personal information about you with other companies
              to the extent prohibited by applicable California law or to the extent
              your prior consent to share is required by applicable California law.
              California residents may, no more than once per calendar year, request
              information about our disclosures of certain categories of personal
              information to third parties for direct marketing purposes. To be valid,
              requests must be submitted to us at social@maximusx.com. Within thirty days
              of receiving this request, we will provide a list of the categories of
              personal information disclosed for direct marketing purposes during the
              immediately preceding calendar year, along with the names and
              addresses of the third parties who have received such.
            </p>

            <h2 className={classes.boldHeading}>
              California Consumer Privacy Act of 2018 (“CCPA”)
            </h2>
            <p className={classes.p1}>
              Under the California Consumer Privacy Act of 2018 (“CCPA”), up to twice
              in a twelve (12) month period, a consumer has the right to request that a
              business disclose what personal information it collects, uses, discloses,
              and sells. This Privacy Policy contains important information regarding
              our collection, disclosure, and sale of personal information practices,
              in addition to instructions on how to exercise your rights regarding
              your personal information.
            </p>

            <h2 className={classes.boldHeading}>
              What Personal Information Do We Collect?
            </h2>
            <p className={classes.p1}>
              We collect, and have collected personal information about web visitors
              to the Site in the preceding 12 months in the following categories:
            </p>

            <div className={`container grid grid-cols-2 ${classes.tableContainer}`}>
              <div className={`${classes.tableBlock} ${classes.bolderTitles}`}>
                Categories of Personal Information
              </div>
              <div className={`${classes.tableBlock} ${classes.bolderTitles}`}>
                Specific Types of Personal Information Collected
              </div>
              <div className={`${classes.tableBlock}`}>
                Identifiers, or information that identifies, relates to, describes,
                or is capable of being associated with, a particular individual
              </div>
              <div className={`${classes.tableBlock} ${classes.spacedLines}`}>
                First name;
                <br />
                Last name;
                <br />
                Email address;
                <br />
                Phone number;
                <br />
                IP address;
                <br />
                Postal address;
                <br />
                Home details;
                <br />
                Level of education;
                <br />
                Account or financial information;
                <br />
                Vehicle and auto insurance information;
                <br />
                Date of birth
              </div>

              <div className={`${classes.tableBlock}`}>
                Characteristics of protected classifications under California or federal law
              </div>
              <div className={`${classes.tableBlock} ${classes.spacedLines}`}>
                Male/female
              </div>

              <div className={`${classes.tableBlock}`}>
                Internet or other electronic network activity information
              </div>
              <div className={`${classes.tableBlock} ${classes.spacedLines}`}>
                Referring website address;
                <br />
                Web browser type and version;
                <br />
                Geographic region based on web browser type;
                <br />
                Internet service provider;
                <br />
                Area code and zip code associated with your web server;
                <br />
                Time and date of your visit to the Site;
                <br />
                Time spent on the Site;
                <br />
                Behavioral data (opens, clicks)
              </div>

              <div className={`${classes.tableBlock}`}>
                Commercial information
              </div>
              <div className={`${classes.tableBlock} ${classes.spacedLines}`}>
                Products or services purchased, obtained, considered
              </div>

              <div className={`${classes.tableBlock}`}>
                Inferences drawn from any of the above information to create
                a profile about a consumer reflecting the consumer’s preferences,
                characteristics, behavior, etc.
              </div>
              <div className={`${classes.tableBlock} ${classes.spacedLines}`}>
                Inferences about an individual’s propensity to respond to a
                particular email advertisement
              </div>
            </div>

            <p className={classes.p1}>
              We collect the above personal information directly from you via the Site,
              including from cookies on the Site. We use and store cookies on your computer
              to facilitate and customize your use of our Site. A cookie is a small data
              text file, which is stored on your computer's hard drive (if your web browser permits)
              that can later be retrieved to identify you to us. Cookies make your use of the
              Site easier, make the Site run more smoothly, and help us to maintain a secure Site.
              The cookies may collect information about you, such as the referring website address,
              your IP address, your browser type and version, your Internet service provider, the
              area code and zip code associated with your server, the time and date of your visit,
              and the time spent on the Site.
            </p>

            <p className={classes.p1}>
              We collect personal information through your clicks on the Site, including
              whether you indicated a need for a particular service or product featured on
              the Site, and all personal information that you have submitted through the Site
              in conjunction with your request. We also collect information on whether you
              responded to a particular advertisement featured on the Site, and which third
              party advertisements and links you click through the Site.
            </p>

            <p className={classes.p1}>
              The collection of personal information in the category of “Identifiers,
              or information that identifies, relates to, describes, or is capable of
              being associated with, a particular individual” is used for the following
              business or commercial purposes: 1) provide you with the ability to access
              and interact with the Site; 2) to connect you with the particular services
              or products featured on the Site which you indicate a need for; and 3) to
              provide advertising services to you through Facebook and Criteo.
            </p>

            <p className={classes.p1}>
              The collection of personal information in the category of “Characteristics
              of protected classifications under California or federal law” is used for the
              following business or commercial purposes: 1) tracking consumer interaction
              with the Site; 2) advertising/marketing services; 3) analytics and internal
              research; and 4) and verifying or maintaining quality or safety.
            </p>

            <p className={classes.p1}>
              The collection of personal information in the category of “Internet or other
              electronic network activity information” is used for the following business or
              commercial purposes: 1) provide you with the ability to access and interact
              with the Site; 2) to connect you with the particular services or products featured
              on the Site which you indicate a need for; 3) tracking consumer interaction
              with the Site, detecting security incidents, or protecting against malicious,
              deceptive, fraudulent, or illegal activity, and debugging to identify and
              repair errors that affect functionality; 4) advertising/marketing services;
              5) analytics and internal research; and 6) and verifying or maintaining
              quality or safety.
            </p>

            <p className={classes.p1}>
              The collection of personal information in the category of “Commercial
              information” is used for the following business or commercial purposes:
              1) connect you with the particular services or products featured on
              the Site which you indicate a need for; 2) advertising/marketing services;
              and 3) analytics and internal research.
            </p>

            <p className={classes.p1}>
              The collection of personal information in the category of “Inferences
              drawn from any of the above information to create a profile about a
              consumer reflecting the consumer’s preferences, characteristics, behavior,
              etc.” is required for: 1) advertising/marketing services; and 2) analytics
              and internal research.
            </p>

            <h2 className={`${classes.boldHeading} ${classes.underlineTitle}`}>
              What Personal Information Do We Disclose?
            </h2>
            <p className={classes.p1}>
              We have disclosed personal information for a business purpose to third
              parties within the preceding 12 months in all of the above categories to
              our third party lead buyers and lead aggregators,
              including whether you indicated a need for a particular
              service or product featured on the Site, and all personal information
              that you have submitted through the Site in conjunction with your request.
              We also disclose personal information in all of the above categories to
              our third party advertising partners, namely the owners of the brands that
              are featured on the Site, as well as third party advertising networks. We
              also disclose personal information in all of the above categories to our
              third party Service Providers who log user experiences, assist with form
              population, and perform retargeting. We also disclose personal information
              in all of the above categories to our third party publishers for the purpose
              of tracking their clicks/conversions.
            </p>

            <p className={classes.p1}>
              In addition to the above, to the extent legally required to comply with
              applicable laws and regulations, or to respond to a lawful request, or to
              otherwise protect our rights, we may also disclose any of the above personal
              information to the requesting governmental agency, entity, or person. As we
              continue to develop our business, we might sell or buy assets. In such
              transactions, personal information collected through the Site may be one of
              the transferred business assets. Also, in the event that we, or
              substantially all of our assets, are acquired, such personal information
              will be included within the transferred assets.
            </p>

            <h2 className={`${classes.boldHeading} ${classes.underlineTitle}`}>
              What Personal Information Do We Sell?
            </h2>
            <p className={classes.p1}>
              Under the definitions of “Sell” and “Sale” in the CCPA, we do not sell
              your personal information.
            </p>

            <h2 className={`${classes.boldHeading} ${classes.underlineTitle}`}>
              Your Right to Request to Know and How to Submit a Verified Request to Know
            </h2>
            <p className={classes.p1}>
              A “request to know” is a request related to the personal information that
              a business has about such consumer. It may include a request for
              any or all of the following:
            </p>
            <p className={classes.p1}>
              1) specific pieces of personal information that we have collected about you;
            </p>
            <p className={classes.p1}>
              2) categories of personal information that we have collected about you;
            </p>
            <p className={classes.p1}>
              3) categories of sources from which the personal information was collected;
            </p>
            <p className={classes.p1}>
              4) categories of personal information that we have sold or disclosed
              for a business purpose about you;
            </p>
            <p className={classes.p1}>
              5) categories of third parties to whom the personal information was sold or
              disclosed for a business purpose; and
            </p>
            <p className={classes.p1}>
              6) the business or commercial purpose for collecting or selling the
              personal information.
            </p>
            <p className={classes.p1}>
              The CCPA permits requests to know from the same consumer up to twice in a
              twelve (12) month period.
            </p>

            <p className={classes.p1}>
              To submit a request to know, please email your request to social@maximusx.com.
              To process your request, we will require that you submit your name, email address,
              and/or phone number in conjunction with your request. The information which you
              provide will be used to verify your request and to attempt to identify you within
              our data records. If you elect to use an authorized agent to exercise your right
              to submit a request for information, we may require the following before processing
              the request: 1) provide proof of your written and signed permission with the
              authorized agent to act on your behalf; 2) directly verify your own identity with
              us; and 3) directly confirm with us that you provided the authorized agent permission
              to submit the request on your behalf. We will only use the information which you
              submit to us in conjunction with your request to know for the express purposes
              of verification and providing you with responsive information to your
              request, if required.
            </p>

            <h2 className={`${classes.boldHeading} ${classes.underlineTitle}`}>
              Your Right to Request Deletion and How to Submit a Verified Request to Delete
            </h2>
            <p className={classes.p1}>
              You have the right to request the deletion of any personal information which
              we have collected about you, or which we maintain. To submit a verifiable
              request to delete, please email your request to social@maximusx.com. To process your request,
              we will require that you submit your name, email address, and/or phone
              number in conjunction with your request. The information which you provide
              will be used to verify your request and to attempt to identify you within
              our data records. If you elect to use an authorized agent to exercise your
              right to submit a request for deletion, we may require the following
              before processing the request: a) provide proof of your written and signed
              permission with the authorized agent to act on your behalf; 2) directly verify
              your own identity with us; and 3) directly confirm with us that you provided
              the authorized agent permission to submit the request on your behalf. We will
              only use the information which you submit to us in conjunction with your
              request to know for the express purposes of verification and providing you
              with responsive information to your request, if required.
            </p>

            <p className={classes.p1}>
              We utilize a two-step process for online requests to delete, whereby you first
              submit your request to delete, and second, you separately confirm that you want
              your personal information deleted.
            </p>

            <p className={classes.p1}>
              To the extent that your personal information is necessary for us to maintain
              for a reason delineated in Cal. Civ. Code § 1798.105(d), including but not
              limited to complete a transaction for which the personal information was
              collected, provide a good or service you have requested, detect security
              incidents, protect against malicious, deceptive, fraudulent, or illegal
              activity, debug, etc., or to comply with a legal obligation, we are not
              required to comply with your request for deletion. For instance, we
              are not required to delete personal information which is necessary to
              maintain suppression files for those individuals who have opted out of
              receiving email marketing from us. If we are unable to verify your
              request, we will notify you as well.
            </p>

            <h2 className={`${classes.boldHeading} ${classes.underlineTitle}`}>
              Your Right to Non-Discrimination for the Exercise of Your Privacy Rights
            </h2>
            <p className={classes.p1}>
              California affords consumers with the right not to receive discriminatory treatment
              by a business for the exercise of their privacy rights conferred by the CCPA.
              This means that you cannot be denied goods or services; be charged different
              prices or rates; be provided with a different level or quality of goods or services;
              or have it suggested that you will receive a different price or rate or
              different level or quality of goods or services. However, a business may offer a
              financial incentive or price or service difference if it is reasonably related
              to the value of the consumer’s data. A business shall notify consumers of any
              financial incentive or price or service difference.
            </p>

            <h2 className={`${classes.boldHeading} ${classes.underlineTitle}`}>
              Need More Information or Having Difficulty Accessing this Information?
            </h2>
            <p className={classes.p1}>
              If you have any questions or concerns regarding your California privacy
              rights, or our privacy practices, please email social@maximusx.com
              , or
              contact us by mail at
              {' '}
              1732 1st Ave #20257, New York, NY 10128.
              . Further, if you have a disability which interferes with your ability to
              access this policy, accommodations can be made to present the policy in an
              alternative format, to assist you. If you would like to receive this
              information in another format that is accessible to your needs (for
              example: audio, large print, etc.) please email social@maximusx.com.
            </p>

            <p className={classes.p1}>
              Last Updated: February 10, 2021 | © 2023 www.maximussocial.com. All rights reserved.
            </p>
            <hr />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <LogoHome />
        </Toolbar>
      </AppBar>
      <div className={classes.pageContainer}>
        <div className={classes.contentContainer}>
          {getMainContent()}
        </div>
      </div>
    </>
  );
}

import React from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Settings from './Settings';
import Targeting from './Targeting';
import CreateAds from './Ads/CreateAdsBox';
import CopyAds from './Ads/CloneAdsTable/CloneAdsTable';

const useStyles = makeStyles()((theme) => ({
  icon: {
    marginRight: 8,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    textAlign: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  buttonContainer: {
    textAlign: 'right',
    width: '100%',
  },
  greenButton: {
    backgroundColor: '#4caf50',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#388e3c',
    },
    marginLeft: '15px',
  },
  dialogContent: {
    overflow: 'auto',
    overflowX: 'hidden',
    minHeight: '70vh',
  },
  sectionTitle: {
    marginTop: 12,
  },
  errorsList: {
    textAlign: 'left',
  },
}));

export default function (
  {
    submit,
    closeDialog,
    open,
    editMode,
    addMode,
    loading,
  },
) {
  const { classes } = useStyles();
  const renderSectionTitle = (title) => (
    <Typography className={classes.sectionTitle} gutterBottom>
      {title}
    </Typography>
  );

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle className={classes.title}>
        Create Ad Set
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className={classes.dialogContent}
      >
        {renderSectionTitle('Settings')}
        <Settings editMode={editMode} addMode={addMode} />
        {renderSectionTitle('Targeting')}
        <Targeting />
        {renderSectionTitle('Ads')}
        <CreateAds />
        {renderSectionTitle('Copy Ads')}
        <CopyAds />
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonContainer}>
          <Button
            onClick={closeDialog}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={submit}
            variant="contained"
            className={classes.greenButton}
            autoFocus
            disabled={loading || false}
          >
            {
              loading
                ? 'Submitting'
                : 'Create'
            }
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

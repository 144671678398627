import React from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import GlanceData from '../../../../../Widgets/GlanceData/Container/GlanceData';
import { NetworkRequestPost } from '../../../../../Helpers/NetworkRequest';

const blocksData = (data = {}) => [
  {
    title: 'Clicks',
    value: data.clicks || 0,
    colorStyle: false,
  }, {
    title: 'Sales',
    value: data.conversions || 0,
    colorStyle: false,
  }, {
    title: 'Spend',
    value: data.spent || '$0.00',
    colorStyle: false,
  }, {
    title: 'Revenue',
    value: data.revenue || '$0.00',
    colorStyle: false,
  }, {
    title: 'Profit',
    value: data.profit || '$0.00',
    colorStyle: true,
    tooltipData: data.profit_percent ? `Margin: ${data.profit_percent}` : '',
  }, {
    title: 'CVR',
    value: data.cvr ? `${data.cvr}%` : 'NA',
    colorStyle: false,
  },
  {
    title: 'CPA',
    value: data.cpa || '$0.00',
    colorStyle: false,
  },
  {
    title: 'CTR',
    value: data.ctr ? `${data.ctr}%` : '0.00%',
    colorStyle: false,
  },
];

export default function CampaignDetailsGlance() {
  const {
    campaignID = false,
    bidStrategy,
    bidAmount,
    advancedTrackingReporting,
    adSetID,
    adID,
  } = useSelector((state) => state.campaignDetails);
  const { startDate, endDate, dateRange } = useSelector((state) => state.dateRange);
  const filters = useSelector((state) => state.filters);
  const formattedStartDate = format(startDate, 'yyyy-MM-dd');
  const formattedEndDate = format(endDate, 'yyyy-MM-dd');

  let params = {
    campaign_id: campaignID,
    adset_id: adSetID,
    ad_id: adID,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    date_range: dateRange,
  };
  if (filters.weekDay !== '-1') {
    params = { ...params, week_days: filters.weekDay };
  }

  const getData = async () => NetworkRequestPost('adsGlance', params);

  const filterGlanceForAdvancedTracking = (data) => {
    const originalData = blocksData(data).map((glanceRow) => glanceRow);
    if (advancedTrackingReporting) {
      return originalData;
    }
    return originalData.map((glanceRow) => {
      const augmentedRow = { ...glanceRow };
      if (augmentedRow.title === 'Clicks') {
        augmentedRow.value = data.visits || 0;
        augmentedRow.tooltipData = false;
      }
      return augmentedRow;
    });
  };

  return (
    <GlanceData
      getData={getData}
      glanceMapping={filterGlanceForAdvancedTracking}
      additionalData={{ bidAmount, bidStrategy }}
    />
  );
}

import { FilterList, Web } from '@mui/icons-material';
import React from 'react';
import { iconStyle } from '../../Helpers/menuStyleHelper';
import DateRange from '../../Components/Navigation/SideNavigation/Filters/DateRange/DateRange';
import UserFilter from '../../Components/Navigation/SideNavigation/Filters/User/UserFilter';
import AccountFilter from '../../Components/Navigation/SideNavigation/Filters/Category/AccountFilter';
import SubAccountFilter from '../../Components/Navigation/SideNavigation/Filters/Category/SubAccountFilter';

export const menuMapping = () => ({
  filters: [
    {
      titleIcon: <FilterList style={iconStyle} />,
      title: 'Filters',
      items: [
        {
          title: 'Date Range', type: 'render', render: <DateRange />, noPadding: true,
        },
        {
          title: 'User', type: 'render', render: <UserFilter />,
        },
        {
          title: 'Account', type: 'render', render: <AccountFilter />, noPadding: true,
        },
        {
          title: 'Sub Account', type: 'render', render: <SubAccountFilter />, noPadding: true,
        },
      ],
    },
  ],
  linkSections: [
    {
      titleIcon: <Web style={iconStyle} />,
      title: 'Pages',
      items: [
        {
          title: 'Campaigns', type: 'page', link: '/campaigns', selected: true,
        },
        { title: 'Add Campaign', type: 'page', link: '/campaigns/add' },
      ],
    },
  ],
});

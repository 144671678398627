import React from 'react';
import { parse, isBefore } from 'date-fns';
import { createNewTimeObject } from './helper';
import { checkBudget } from '../../../AddCampaign/Validation/validations';

const formatErrors = (errorDays, otherErrors) => (
  <div>
    {!!errorDays && errorDays.length > 0 && (
      <div>
        These settings collide with settings on the following days:
        <ul>
          {errorDays.map((day) => (
            <li style={{ textTransform: 'capitalize' }}>{day}</li>
          ))}
        </ul>
        Please double check your current day parting settings and make the appropriate changes.
      </div>
    )}
    {!!otherErrors && otherErrors.length > 0 && (
      <div>
        The following errors have occurred:
        <ul>
          {otherErrors.map((error) => (
            <li>{error}</li>
          ))}
        </ul>
      </div>
    )}
  </div>
);

const formatDate = (date) => parse(date, 'h:mma', new Date());

export const isStartTimeBeforeEndTime = (startAt, endAt) => (
  isBefore(formatDate(startAt), formatDate(endAt))
);

export function getValidationErrors(originalData, days, startAt, endAt, startingBudget, enabledBudget, bidStrategy, bidAmount) {
  if (!days || days.length === 0) {
    return {
      dayParting: originalData,
      errors: formatErrors([], ['Please select at least one day to configure.']),
    };
  }
  if (startAt === '0' || endAt === '0') {
    return {
      dayParting: originalData,
      errors: formatErrors([], ["Please select a value for 'Pause At' and 'Start At.'"]),
    };
  }
  const { dayParting, errorDays } = createNewTimeObject(originalData, days, startAt, endAt, startingBudget, enabledBudget);
  if (!isStartTimeBeforeEndTime(startAt, endAt)) {
    return {
      dayParting,
      errors: formatErrors(errorDays, ['Please make sure that your start time is before your end time.']),
    };
  }
  if (enabledBudget) {
    const errorBudget = checkBudget(startingBudget, bidStrategy, bidAmount);
    if (errorBudget) {
      return {
        dayParting,
        errors: formatErrors([], [errorBudget]),
      };
    }
  }
  return {
    dayParting,
    errors: errorDays.length > 0 && formatErrors(errorDays, []),
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import {
  Grid, IconButton, Tooltip,
} from '@mui/material';
import { AddCircle, Delete } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { changeWeightedLink, addWeightedLink, removeWeightedLink } from '../../actions/addCampaign';
import {
  changeWeightedLink as changeCDLink,
  addWeightedLink as addCDLink,
  removeWeightedLink as removeCDLink,
} from '../../../CampaignDetails/actions/campaignDetails';
import Input from '../../../../Widgets/Forms/Fields/Input';
import { numberValidation, validateLink } from '../../Validation/validations';
import { notTestUser } from './Links';

const useStyles = makeStyles()(() => ({
  urlInput: {
    marginRight: 30,
    width: '60%',
  },
  weightInput: {
    width: '25%',
  },
  deleteButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '10%',
  },
  sectionTitle: {
    borderBottom: '1px solid rgba(255,255,255,0.25)',
    fontWeight: 500,
    fontSize: 16,
    paddingBottom: 8,
    marginTop: 0,
  },
}));

export default function WeightedLinks(props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const {
    linkTitle, sectionTitle, section, campaignDetails,
  } = props;
  const sectionState = useSelector((state) => (campaignDetails
    ? state.campaignDetails.links[section] : state.addCampaign[section]));

  const handleChangeField = (key, index, value, name, errorMessage) => dispatch(campaignDetails
    ? changeCDLink(key, index, value, section, errorMessage)
    : changeWeightedLink(key, index, value, section, errorMessage));

  const handleAddLink = () => dispatch(campaignDetails
    ? addCDLink(section)
    : addWeightedLink(section));

  const handleRemoveLink = (index) => dispatch(campaignDetails
    ? removeCDLink(index, section)
    : removeWeightedLink(index, section));

  const renderWeightedLink = (link, index) => (
    <Input
      label={`${linkTitle} Link`}
      name="link"
      onChange={(value, name, errorMessage) => handleChangeField('url', index, value.trim(), section, errorMessage)}
      value={link.url.value}
      helperText={link.url.error}
      fullWidth={false}
      className={classes.urlInput}
      validationFunc={validateLink}
    />
  );

  const renderLinkWeight = (link, index) => (
    <Input
      label="Weight"
      name="weight"
      onChange={(value, name, errorMessage) => handleChangeField('weight', index, value, section, errorMessage)}
      value={link.weight.value}
      helperText={link.weight.error}
      fullWidth={false}
      className={classes.weightInput}
      validationFunc={numberValidation}
    />
  );

  const renderAddAnotherLink = () => (
    <div>
      <IconButton onClick={handleAddLink} aria-label="Add">
        <AddCircle />
      </IconButton>
      {' '}
      Add another
      {` ${linkTitle} `}
      Link to this campaign
    </div>
  );

  const renderRemoveLink = (index) => (
    <Tooltip title="Click to remove this link">
      <div className={classes.deleteButton}>
        <IconButton
          onClick={() => handleRemoveLink(index)}
          aria-label="Delete"
        >
          <Delete />
        </IconButton>
      </div>
    </Tooltip>
  );

  const renderWeightedLinks = () => Object.keys(sectionState).map((linkID) => {
    const link = sectionState[linkID];
    return (
      <Grid key={linkID} container justify="flex-start">
        {renderWeightedLink(link, linkID)}
        {notTestUser() && renderLinkWeight(link, linkID)}
        {linkID > 0 && renderRemoveLink(linkID)}
      </Grid>
    );
  });

  return (
    <div>
      <div className={classes.sectionTitle}>
        {sectionTitle}
      </div>
      {renderWeightedLinks()}
      {notTestUser() && renderAddAnotherLink()}
    </div>
  );
}

WeightedLinks.defaultProps = {
  sectionTitle: '',
};
WeightedLinks.propTypes = {
  section: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string,
  linkTitle: PropTypes.string.isRequired,
  campaignDetails: PropTypes.bool.isRequired,
};

import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import {
  closeRightDrawer, openSnackbar, rightDrawerLoaded,
} from '../../../Widgets/actions/widgets';
import { validateAdSet } from '../../AddCampaign/render/AdSets/CreateAdSets/Validation/validationFunctions';
import { NetworkRequestPost, NetworkRequestPut } from '../../../Helpers/NetworkRequest';
import FormDrawer from '../../../Widgets/Drawer/FormDrawer/FormDrawer';
import Settings from '../../AddCampaign/render/AdSets/CreateAdSets/Settings';
import Targeting from '../../AddCampaign/render/AdSets/CreateAdSets/Targeting';
import { changeNonField } from '../../AddCampaign/actions/addCampaign';
import { newAdSet as blankAdSet } from '../../AddCampaign/reducers/addCampaign';

const useStyles = makeStyles()(() => ({
  errorsList: {
    textAlign: 'left',
  },
  sectionTitle: {
    marginTop: 12,
  },
  formWidth: {
    width: '1000px',
  },
}));

export default function (
  {
    adSetID,
  },
) {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const { newAdSet } = useSelector((store) => store.addCampaign);
  const { campaignID, budgetOptimization } = useSelector((store) => store.campaignDetails);

  const requestAdSetData = async () => {
    const { data } = await NetworkRequestPost('getAdSet', { adset_id: adSetID, campaign_id: campaignID });
    return data;
  };

  const setAdSetData = async (data) => {
    if (data && data.success) {
      const {
        name,
        budget,
        genderTargeting,
        includeLocations,
        age_min: minAge,
        age_max: maxAge,
        bid_strategy: bidStrategy,
        bid_amount: bidAmount,
        roas_floor: roasFloor,
      } = data.data;
      dispatch(changeNonField('newAdSet', {
        name,
        budget,
        bidAmount,
        ageTargeting: [minAge || blankAdSet().ageTargeting[0], maxAge || blankAdSet().ageTargeting[1]],
        genderTargeting: genderTargeting ? genderTargeting.map((gender) => gender.toString()) : blankAdSet().genderTargeting,
        includeLocations: includeLocations || blankAdSet().includeLocations,
        bidStrategy: bidStrategy || blankAdSet().bidStrategy,
        roasFloor: roasFloor || blankAdSet().roasFloor,
      }));
    } else {
      dispatch(openSnackbar(data.message || 'An error has happened.', 'error'));
    }
  };

  useEffect(() => {
    requestAdSetData()
      .then(setAdSetData)
      .then(() => dispatch(rightDrawerLoaded()));
    return () => {
      dispatch(changeNonField('newAdSet', blankAdSet()));
    };
  }, []);

  const handleSubmitRequest = async () => {
    setSubmitting(true);
    return NetworkRequestPut('saveAdSet', {
      name: newAdSet.name,
      genderTargeting: newAdSet.genderTargeting,
      ageTargeting: newAdSet.ageTargeting,
      includeLocations: newAdSet.includeLocations,
      budget: budgetOptimization === 'ad_set' ? newAdSet.budget : undefined,
      bidStrategy: budgetOptimization === 'ad_set' ? newAdSet.bidStrategy : undefined,
      roasFloor: budgetOptimization === 'ad_set' && newAdSet.bidStrategy === 'LOWEST_COST_WITH_MIN_ROAS' ? newAdSet.roasFloor : undefined,
      bidAmount: budgetOptimization === 'ad_set' ? newAdSet.bidAmount : undefined,
      adset_id: adSetID,
      campaign_id: campaignID,
    });
  };

  const handleSubmitResponse = ({ data }) => {
    setSubmitting(false);
    if (data.success) {
      dispatch(openSnackbar('Ad Set was edited successfully', 'success'));
      dispatch(closeRightDrawer());
    } else {
      dispatch(openSnackbar(data.message || 'An error has happened.', 'error'));
    }
  };

  const onSubmit = () => {
    const valid = validateAdSet(newAdSet, budgetOptimization, renderError, true);
    if (valid) {
      handleSubmitRequest()
        .then(handleSubmitResponse);
    }
  };

  const renderError = (errors) => {
    dispatch(openSnackbar(
      (
        <div>
          Oops, looks like there were some issues editing this Ad Set. Please correct the following errors to continue:
          <ul className={classes.errorsList}>
            {errors.map((error) => <li>{error}</li>)}
          </ul>
        </div>
      ), 'error',
    ));
  };

  const renderSectionTitle = (title) => (
    <Typography className={classes.sectionTitle} gutterBottom>
      {title}
    </Typography>
  );

  return (
    <FormDrawer
      onSubmit={onSubmit}
      submitting={submitting}
    >
      <div className={classes.formWidth}>
        {renderSectionTitle('Settings')}
        <Settings editMode />
        {renderSectionTitle('Targeting')}
        <Targeting />
      </div>
    </FormDrawer>
  );
}

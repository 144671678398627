import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import CloneAdSetsDialog from '../../AddCampaign/render/AdSets/CloneAdSets/CloneAdSetsDialog';
import { NetworkRequestPost } from '../../../Helpers/NetworkRequest';
import { formatAdSetForSubmit } from '../../AddCampaign/createAddCampaignParams';
import { openSnackbar } from '../../../Widgets/actions/widgets';

const useStyles = makeStyles()((theme) => ({
  menuButton: {
    textDecoration: 'none',
    flexGrow: 1,
    background: 'none',
    border: 'none',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    padding: '10px 0 10px 25px',
    fontSize: 16,
    color: theme.palette.text.primary,
    cursor: 'pointer',
    textTransform: 'capitalize',
  },
}));

export default function () {
  const dispatch = useDispatch();
  const { campaignID, budgetOptimization, bidAmount } = useSelector((store) => store.campaignDetails);
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const cloneSelectedAdSets = (selectedRows) => {
    NetworkRequestPost('copyAdSets', {
      ad_sets: selectedRows.map((row) => ({
        ...formatAdSetForSubmit(row, budgetOptimization, { value: bidAmount }),
      })),
      campaign_id: campaignID,
    }).then(({ data }) => {
      if (data.success) {
        dispatch(openSnackbar('Ad Set was created successfully', 'success'));
      } else {
        dispatch(openSnackbar(data.message, 'error'));
      }
    });
  };
  return budgetOptimization && (
    <>
      <button
        className={classes.menuButton}
        type="button"
        onClick={openDialog}
      >
        Clone Ad Sets
      </button>
      <CloneAdSetsDialog open={open} closeDialog={closeDialog} copyAds={cloneSelectedAdSets} />
    </>
  );
}

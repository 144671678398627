import { CHANGE_PAGE, CHANGE_USER_SETTING } from '../actions/page';

export const initialState = {
  page: 'campaigns',
  menuMapping: { filters: [], linkSections: [] },
  openCampaignNewTab: false,
};

export const page = (state = initialState, action = {}) => {
  switch (action.type) {
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.value,
        menuMapping: action.menuMapping,
      };
    case CHANGE_USER_SETTING:
      return {
        ...state,
        [action.name]: action.value,
      };
    default:
      return state;
  }
};

import {
  CurrencyCell,
  DefaultCell,
  NumberCell,
  PercentCell, RenderMargin, RenderProfit,
} from '../../../Widgets/Tables/TableContainer/DefaultCell';

export const columnsCommon = [{
  name: 'impressions', title: 'Impressions', width: 150, compare: 'priority',
}, {
  name: 'visits', title: 'Visits', width: 105, compare: 'priority',
}, {
  name: 'clicks', title: 'Clicks', width: 105, compare: 'priority',
}, {
  name: 'spend', title: 'Spend', width: 130, compare: 'priority', sort: 'desc',
}, {
  name: 'conversions', title: 'Sales', width: 130, compare: 'priority',
}, {
  name: 'revenue', title: 'Revenue', width: 130, compare: 'priority',
}, {
  name: 'profit', title: 'Profit', width: 130, compare: 'priority',
}, {
  name: 'margin_percent', title: 'Margin', width: 130, compare: 'priority',
}, {
  name: 'cpc', title: 'CPC', width: 100, compare: 'priority',
}, {
  name: 'ctr', title: 'CTR', width: 100, compare: 'priority',
}];

export const renderCommonColumns = (row, name) => {
  const value = row[name];
  switch (name) {
    case 'impressions':
    case 'visits':
    case 'conversions':
    case 'clicks':
      return NumberCell(value, 0);
    case 'cpc':
    case 'spend':
    case 'epc':
    case 'cpa':
    case 'spent':
    case 'revenue':
    case 'budget':
      return value ? CurrencyCell(value) : DefaultCell('NA');
    case 'ctr':
    case 'cvr':
      return value ? PercentCell(value) : DefaultCell('NA');
    case 'profit':
      return RenderProfit(value);
    case 'margin_percent':
      return RenderMargin(value);
    default:
      return null;
  }
};

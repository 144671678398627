import * as React from 'react';
import { parse } from 'date-fns';
import { find } from 'lodash';
import MuiTable from '../../../../../Widgets/Tables/MuiTables/TableContainer';

const columns = [
  {
    field: 'name', headerName: 'Ad Set Name', width: 200,
  },
  {
    field: 'budget', headerName: 'Budget', type: 'number', width: 200,
  },
  {
    field: 'numberOfAds', headerName: '# Of Ads', type: 'number', width: 200,
  },
  {
    field: 'date', headerName: 'Date Created', type: 'date', width: 200,
  },
];

export default function CloneAdSetsTable(props) {
  const {
    data, setSelectedRows, adSets, budgetOptimization,
  } = props;
  const rows = data.map((row, index) => ({
    id: index + 1,
    numberOfAds: row.ads.length || 0,
    date: parse(row.createdAt, 'y-MM-dd HH:mm:ss', new Date()),
    ...row,
  })).filter((row) => !find(adSets, ['copiedAdsetID', row.copiedAdsetID]));

  return (
    <MuiTable
      rows={rows}
      columns={columns}
      pageSize={20}
      rowsPerPageOptions={[5]}
      checkboxSelection
      filterToolbar
      hideQuick
      hideExport
      height="500px"
      sortModel={[{ field: 'name', sort: 'desc' }]}
      columnVisibilityModel={{ id: false, budget: budgetOptimization === 'ad_set' }}
      onSelectionModelChange={(selectionModel) => setSelectedRows(rows.filter((row) => selectionModel.includes(row.id)))}
    />
  );
}

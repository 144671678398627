import React, { useLayoutEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import {
  closeRightDrawer, openSnackbar,
} from '../../../Widgets/actions/widgets';
import { validateAdSetAds } from '../../AddCampaign/render/AdSets/CreateAdSets/Validation/validationFunctions';
import { NetworkRequestPost } from '../../../Helpers/NetworkRequest';
import FormDrawer from '../../../Widgets/Drawer/FormDrawer/FormDrawer';
import { changeNonField } from '../../AddCampaign/actions/addCampaign';
import { newAdSet as blankAdSet } from '../../AddCampaign/reducers/addCampaign';
import { formatAdsForSubmit } from '../../AddCampaign/createAddCampaignParams';
import CreateAdsBox from '../../AddCampaign/render/AdSets/CreateAdSets/Ads/CreateAdsBox';
import CloneAdsTable from '../../AddCampaign/render/AdSets/CreateAdSets/Ads/CloneAdsTable/CloneAdsTable';

const useStyles = makeStyles()(() => ({
  errorsList: {
    textAlign: 'left',
  },
  sectionTitle: {
    marginTop: 12,
  },
  formWidth: {
    width: '1150px',
  },
}));

export default function ({ adSetID }) {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const { newAdSet } = useSelector((store) => store.addCampaign);
  const { campaignID } = useSelector((store) => store.campaignDetails);

  const resetNewAdSet = () => {
    dispatch(changeNonField('newAdSet', blankAdSet()));
  };

  useLayoutEffect(() => {
    resetNewAdSet();
  }, []);

  const handleSubmitRequest = async () => {
    setSubmitting(true);
    return NetworkRequestPost('addAds', {
      ads: formatAdsForSubmit(newAdSet.ads),
      adset_id: adSetID,
      campaign_id: campaignID,
      addType: true,
    });
  };

  const handleSubmitResponse = ({ data }) => {
    setSubmitting(false);
    if (data.success) {
      dispatch(openSnackbar('Ads were added successfully', 'success'));
      dispatch(closeRightDrawer());
    } else {
      dispatch(openSnackbar(data.message, 'error'));
    }
  };

  const onSubmit = () => {
    const valid = validateAdSetAds(newAdSet.ads, renderError);
    if (valid) {
      handleSubmitRequest()
        .then(handleSubmitResponse);
    }
  };

  const renderError = (errors) => {
    dispatch(openSnackbar(
      (
        <div>
          Oops, looks like there were some issues adding ads to this ad set. Please correct the following errors to continue:
          <ul className={classes.errorsList}>
            {(errors || []).map((error) => <li>{error}</li>)}
          </ul>
        </div>
      ), 'error',
    ));
  };

  return newAdSet.ads ? (
    <FormDrawer
      onSubmit={onSubmit}
      submitting={submitting}
    >
      <div className={classes.formWidth}>
        <CreateAdsBox />
        <Typography className={classes.sectionTitle} gutterBottom>
          Copy Ads
        </Typography>
        <CloneAdsTable />
      </div>
    </FormDrawer>
  ) : '';
}

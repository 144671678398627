import { HANDLE_REFRESH } from '../actions/refresh';

export const initialState = {
  refreshAllData: false,
};

export const refresh = (state = initialState, action = () => {}) => {
  switch (action.type) {
    case HANDLE_REFRESH:
      return {
        ...state,
        refreshAllData: action.value,
      };
    default:
      return state;
  }
};

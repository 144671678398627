import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const styles = ((theme) => ({
  submitButton: {
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.colors.blue,
    marginTop: '1rem',
    marginBottom: '1rem',
    transition: 'all ease-in-out .25s',
    '&:hover': {
      color: theme.palette.colors.white,
      backgroundColor: theme.palette.colors.blue,
      opacity: '.8',
    },
  },
}));

function SubmitButton({
  isLoading, content, classes, handleSubmit, disabled, overrideClass, buttonType,
}) {
  const finalContent = isLoading
    ? <CircularProgress color="inherit" size={25} /> : content;

  return (
    <Button
      type={buttonType}
      id="submitButton"
      variant="contained"
      color="primary"
      className={`${classes.submitButton} ${overrideClass}`}
      onClick={handleSubmit}
      disabled={isLoading || disabled}
    >
      {finalContent}
    </Button>
  );
}

SubmitButton.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  content: PropTypes.string,
  overrideClass: PropTypes.string,
  disabled: PropTypes.bool,
  buttonType: PropTypes.string,
};

SubmitButton.defaultProps = {
  isLoading: false,
  content: 'Submit',
  overrideClass: '',
  disabled: false,
  buttonType: 'submit',
};

export default withStyles(SubmitButton, styles);

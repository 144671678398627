import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import ToggleSubmit from '../../../Widgets/Forms/ToggleSubmit';
import { changeCampaignDetails } from '../actions/campaignDetails';
import { submitCampaignStatus } from './CampaignStatusSubmit';

const useStyles = makeStyles()(() => ({
  container: {
    padding: '10px 0',
    paddingLeft: '25px',
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
}));

function CampaignStatus(props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { campaignID, campaignStatus } = useSelector((state) => state.campaignDetails);
  const { archive } = props;
  const activeStatus = [1, 3];
  const isActive = (status) => activeStatus.indexOf(+status) > -1;
  const statusValue = (status) => {
    if (archive) {
      return +status === 2;
    }
    return isActive(status);
  };

  function formatStatus(checked) {
    if (archive) {
      if (checked) {
        return 2;
      }
      return 0;
    }
    if (checked) {
      return 1;
    }
    return 0;
  }

  const submit = async (checked) => {
    const newStatus = formatStatus(checked);
    return submitCampaignStatus(campaignID, newStatus, campaignStatus, () => dispatch(changeCampaignDetails('campaignStatus', newStatus)));
  };

  const renderSettingName = () => (archive && 'Archive Status') || 'Campaign Status';

  const statusTexts = [['enable this campaign?', 'pause this campaign?'], ['archive this campaign?', 'update this campaign to no longer be archived?']];

  return (
    <div className={classes.container}>
      {renderSettingName()}
      {renderSettingName() ? ' ' : ''}
      <ToggleSubmit
        absoluteRoot
        campaignID={campaignID}
        onSubmit={submit}
        settingName={archive ? 'Archived Status' : 'Campaign Status'}
        inheritChecked={statusValue(campaignStatus)}
        confirmMessage={(checked) => `Are you sure that you want to 
        ${checked ? statusTexts[+archive][0] : statusTexts[+archive][1]}`}
      />
    </div>
  );
}

CampaignStatus.propTypes = {
  archive: PropTypes.bool,
};

CampaignStatus.defaultProps = {
  archive: false,
};

export default CampaignStatus;

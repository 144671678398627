import { comparePriority } from './comparePriority';
import { compareTimestamp } from './compareTimestamp';

export const numberWithCommas = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const currencyWithCommas = (number) => {
  if (!Number.isNaN(+number)) {
    const [whole, decimal] = number.toString().split('.');
    const formattedNumber = `${numberWithCommas(whole)}${decimal ? `.${decimal}` : ''}`;
    if (formattedNumber.includes('-')) {
      return `-$${formattedNumber.replace('-', '')}`;
    }
    return `$${formattedNumber}`;
  }
  return '';
};

export const convertToFormattedNumber = (value, decimals = 2) => {
  if (!Number.isNaN(+value)) return `${numberWithCommas(parseFloat(value).toFixed(decimals))}`;
  return value;
};

export const getColumnCompare = (columns) => {
  const columnCompare = [];
  columns.map(({ name, compare }) => {
    if (compare) {
      columnCompare.push({ columnName: name, compare: compare === 'priority' ? comparePriority : compareTimestamp });
    }
    return true;
  });
  return columnCompare;
};

export const getDefaultSortingOrder = (columns) => {
  const sortingOrder = [];
  columns.map(({ name, sort }) => {
    if (sort) {
      sortingOrder.push({ columnName: name, direction: sort });
    }
    return true;
  });
  return sortingOrder;
};

export const getTableFixedColumns = (columns) => {
  const fixedColumns = [];
  columns.map(({ name, fixed }) => {
    if (fixed) {
      fixedColumns.push(name);
    }
    return true;
  });
  return fixedColumns;
};

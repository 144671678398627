import React from 'react';
import { List, Whatshot } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TableCell, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import { CurrencyCell, DefaultCell, PercentCell } from '../../Widgets/Tables/TableContainer/DefaultCell';
import { ProfitFormatter } from '../../Widgets/Tables/TableContainer/TableUtilities/CellFormatters';
import PauseCampaign from './PauseCampaign';
import MarginFormatter from '../../Widgets/Tables/TableContainer/TableUtilities/CellFormatters/MarginFormatter';

const useStyles = makeStyles()((theme) => ({
  campaignNameLink: {
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '0px',
    fontSize: 16,
    fontWeight: '600',
    color: theme.palette.colors.blue,
    wordBreak: 'break-all',
    cursor: 'pointer',
    textAlign: 'left',
    transition: 'color ease-in-out .25s, background-color ease-in-out .25s',
    textDecoration: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  nameContainer: {
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    width: '100%',
  },
  editIcon: {
    position: 'relative',
    top: '-5px',
    opacity: '0',
    transition: 'opacity ease-in-out 300ms',
    backgroundColor: 'white',
    '&:hover': {
      opacity: '0.6',
    },
  },
  burstIcon: {
    color: '#ef450e',
    width: '.8em',
    height: '.8em',
    marginLeft: 3,
  },
}));

const columns = [
  {
    name: 'status', title: 'Status', width: 110, fixed: 'left', align: 'left',
  },
  {
    name: 'name', title: 'Name', width: 350, fixed: 'left', align: 'left',
  },
  {
    name: 'budget', title: 'Budget', width: 150, compare: 'priority',
  },
  {
    name: 'visits', title: 'Visits', width: 105, compare: 'priority',
  },
  {
    name: 'clicks', title: 'Clicks', width: 105, compare: 'priority',
  },
  {
    name: 'conversions', title: 'Sales', width: 100, compare: 'priority',
  },
  {
    name: 'spent', title: 'Spent', width: 130, compare: 'priority', sort: 'desc',
  },
  {
    name: 'revenue', title: 'Revenue', width: 130, compare: 'priority',
  },
  {
    name: 'profit', title: 'Profit', width: 140, compare: 'priority',
  },
  {
    name: 'margin_percent', title: 'Margin', width: 140, compare: 'priority',
  },
  {
    name: 'cpc', title: 'CPC', width: 100, compare: 'priority',
  },
  {
    name: 'epc', title: 'EPC', width: 100, compare: 'priority',
  },
  {
    name: 'ctr', title: 'CTR', width: 100, compare: 'priority',
  },
  {
    name: 'cpa', title: 'CPA', width: 100, compare: 'priority',
  },
  {
    name: 'cvr', title: 'CVR', width: 100, compare: 'priority',
  },
];

const totalsMapping = {
  name: { type: 'header' },
  visits: { type: 'number' },
  clicks: { type: 'number' },
  conversions: { type: 'number' },
  spent: { type: 'currency' },
  revenue: { type: 'currency' },
  profit: { type: 'profit' },
  margin_percent: { type: 'margin', dividend: 'revenue', divisor: 'spent' },
  cpc: { type: 'average_currency', dividend: 'spent', divisor: 'clicks' },
  epc: { type: 'average_currency', dividend: 'revenue', divisor: 'clicks' },
  ctr: { type: 'percent', dividend: 'visits', divisor: 'impressions' },
  cvr: { type: 'percent', dividend: 'conversions', divisor: 'clicks' },
  cpa: { type: 'average_currency', dividend: 'spent', divisor: 'conversions' },
};

const burstSignal = (classes) => (
  <Tooltip title="Burst is active for this campaign" placement="top">
    <Whatshot className={classes.burstIcon} />
  </Tooltip>
);

function LinkCell(props) {
  const { value, rowProp } = props;
  const { classes } = useStyles();
  const { openCampaignNewTab } = useSelector((state) => state.page);
  return (
    <div className={classes.nameContainer}>
      <Link
        to={`/campaign/${value}`}
        target={openCampaignNewTab ? '_blank' : ''}
        className={classes.campaignNameLink}
      >
        {value}
      </Link>
      {Number.parseFloat(rowProp.burst) > 0 && burstSignal(classes)}
    </div>
  );
}

const RenderName = React.memo(({ row }) => {
  const { name } = row;
  return (
    <TableCell style={{
      position: 'sticky',
      zIndex: 300,
      left: 0,
      backgroundClip: 'padding-box',
      borderRight: '1px solid rgba(0,0, 0, 0.1)',
      borderBottom: '1px solid rgba(0,0, 0, 0.1)',
    }}
    >
      <LinkCell
        value={name}
        rowProp={row}
      />
    </TableCell>
  );
});

let renderedTooltip = '';

const renderCell = ({ row, column: { name } }, rows, modifyData, data) => {
  const value = row[name];
  if (!renderedTooltip) {
    renderedTooltip = row.name;
  }
  const changeStatus = (status) => {
    const newRows = rows.map((currentRow) => {
      const newRow = { ...currentRow };
      if (newRow.name === row.name) {
        newRow.status = status;
      }
      return newRow;
    });
    modifyData(newRows);
  };

  const changeData = (fieldValue, fieldName) => {
    const newRows = rows.map((currentRow) => {
      const newRow = { ...currentRow };
      if (newRow[fieldName] === row[fieldName]) {
        newRow[fieldName] = fieldValue;
      }
      return newRow;
    });
    modifyData(newRows);
  };

  switch (name) {
    case 'status':
      return (
        DefaultCell(
          <PauseCampaign
            row={row}
            campaignID={row.campaign_id}
            status={Number(+row.status).toString()}
            changeStatus={changeStatus}
          />,
        )
      );
    case 'name':
      return (
        <RenderName
          row={row}
          updateData={changeData}
          changeStatus={changeStatus}
          modifyData={modifyData}
          data={data}
          fieldName="name"
        />
      );
    case 'profit':
      return DefaultCell(<ProfitFormatter value={value} />);
    case 'margin_percent':
      return DefaultCell(<MarginFormatter value={value} />);
    case 'visits':
      return DefaultCell(value || 'NA');
    case 'ctr':
      return value ? PercentCell(value) : DefaultCell('NA');
    case 'epc':
    case 'cpc':
    case 'cpa':
    case 'revenue':
    case 'budget':
    case 'spent':
      return value ? CurrencyCell(value) : DefaultCell('NA');
    case 'cvr':
      return PercentCell(value);
    default:
      return DefaultCell(value);
  }
};

RenderName.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
};

LinkCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rowProp: PropTypes.instanceOf(Object).isRequired,
};

export const campaignsTable = () => ({
  title: 'Campaigns',
  icon: <List className="tabIcon" />,
  localStorage: 'campaigns',
  renderCell,
  columns,
  action: 'campaigns',
  totalsMapping,
  leftColumns: ['name'],
});

import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { formatAdsForStore, returnImage } from '../../../CloneAdSets/CloneAdSetsDialog';
import { changeNonField } from '../../../../../actions/addCampaign';

const useStyles = makeStyles()(() => ({
  container: {
    width: 'calc(33.33% - 4px)',
    height: '15rem',
    border: 'solid 2px white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  adContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    maxWidth: 'calc(100%)',
    maxHeight: 'calc(15rem)',
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 1,
    color: 'white',
    display: 'none',
    opacity: '0',
    transition: 'all ease-in-out 0.25s',
    background: 'rgba(0, 0, 0, 0.5)',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    '&:hover': {
      opacity: '1',
    },
  },
  textWrapper: {
    position: 'relative',
    width: '100%',
    height: '60%',
  },
  textContainer: {
    width: 'calc(100% - 2rem)',
    height: 'calc(100% - 2rem)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '1rem',
  },
  title: {
    overflow: 'hidden',
    maxHeight: 55,
    width: '100%',
    marginBottom: 8,
  },
  body: {
    overflow: 'hidden',
    maxHeight: 55,
    width: '100%',
  },
  button: {
    height: '3rem',
    fontSize: '18px',
    fontFamily: 'Arial, Helvetica, sans-serif',
    padding: '.5rem',
    border: 'solid 1px white',
    background: 'transparent',
    color: 'white',
    cursor: 'pointer',
    borderRadius: 8,
    transition: 'background ease-in-out 0.5s',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.1)',
    },
  },
}));

export default function ({ ad }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { newAdSet } = useSelector((store) => store.addCampaign);
  const [buttonText, setButtonText] = useState('Copy Ad');

  useEffect(() => {
    const container = document.getElementById(`${ad.ad_id}_container`);
    const overlay = document.getElementById(`${ad.ad_id}_overlay`);
    container.addEventListener('mouseenter', () => {
      overlay.style.display = 'flex';
    });
    container.addEventListener('mouseleave', () => {
      overlay.style.display = 'none';
    });
    return () => {
      container.removeEventListener('mouseenter', () => {
        overlay.style.display = 'flex';
      });
      container.removeEventListener('mouseleave', () => {
        overlay.style.display = 'none';
      });
    };
  }, []);

  function copyAd() {
    const formattedAd = formatAdsForStore([ad]);
    const newAdsObject = [];
    if (
      newAdSet.ads.length > 1
      || newAdSet.ads[0].body
      || newAdSet.ads[0].title
      || newAdSet.ads[0].landscapeMedia.url
      || newAdSet.ads[0].squareMedia.url
      || newAdSet.ads[0].portraitMedia.url
    ) {
      newAdsObject.push(...newAdSet.ads);
    }
    newAdsObject.push(...formattedAd);
    dispatch(changeNonField('newAdSet', {
      ...newAdSet,
      ads: newAdsObject,
    }));
    setButtonText('Copied!');
    setTimeout(() => {
      setButtonText('Copy Ad');
    }, 1500);
  }

  return (
    <div id={`${ad.ad_id}_container`} className={classes.container}>
      <div className={classes.adContainer}>
        <img
          className={classes.image}
          src={returnImage(ad.media_url, ad.thumbnail_url)}
          alt="Ad Media"
        />
      </div>
      <div id={`${ad.ad_id}_overlay`} className={classes.overlay}>
        <div className={classes.textWrapper}>
          <div className={classes.textContainer}>
            <div className={classes.title}>
              <strong>Primary:</strong>
              {' '}
              {ad.primary_text}
            </div>
            <div className={classes.title}>
              <strong>Headline:</strong>
              {' '}
              {ad.title}
            </div>
            <div className={classes.body}>
              <strong>Body:</strong>
              {' '}
              {ad.description}
            </div>
          </div>
        </div>
        <button
          disabled={buttonText === 'Copied!'}
          className={classes.button}
          type="button"
          onClick={() => copyAd(ad)}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
}

import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Location from './Location';
import Gender from './Gender';
import Age from './Age';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  selectInputRoot: {
    padding: 0,
    margin: 0,
    minHeight: 55,
    height: 'inherit',
  },
  singleSelectInputRoot: {
    padding: 0,
    height: '55px !important',
    marginLeft: 20,
    display: 'flex',
    alignItems: 'center',
  },
  sliderContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'row',
  },
  slider: {
    width: '100%',
    marginLeft: '1.5rem',
    marginRight: '1.5rem',
  },
}));

export default function () {
  const { classes } = useStyles();
  return (
    <div>
      <div className={classes.container}>
        <Location />
        <Age />
      </div>
      <Gender />
    </div>
  );
}

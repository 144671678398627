import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const hidePageName = ['campaigns', 'add campaign'/* , 'creatives' */];

const useStyles = makeStyles()((theme) => ({
  page: {
    fontSize: 22,
    fontWeight: 400,
    marginLeft: '2.5rem',
    borderRight: '1px solid rgba(255,255,255,0.15)',
    paddingRight: '1rem',
    height: '90%',
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
    },
  },
  spacingDiv: {
    width: '2rem',
  },
}));

export default function PageName() {
  const { classes } = useStyles();
  const { page } = useSelector((state) => state.page);
  return hidePageName.indexOf(page.toLowerCase()) > -1 ? (
    <div className={classes.spacingDiv}> </div>
  ) : (
    <div className={classes.page}>
      {_.startCase(page)}
    </div>
  );
}

import React from 'react';
import { makeStyles } from 'tss-react/mui';
import maximusLogoDark from '../../../assets/images/Maximus_Social_Logo_White.svg';

const useStyles = makeStyles()((theme) => ({
  logo: {
    width: 150,
    [theme.breakpoints.down('lg')]: {
      width: 130,
    },
  },
}));

export default function LogoHome() {
  const { classes } = useStyles();
  return (
    <div className={classes.logoContainer}>
      <img className={classes.logo} src={maximusLogoDark} alt="Maximus Social Logo" />
    </div>
  );
}

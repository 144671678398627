import React from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  Stepper as MaterialStepper, Step, StepLabel,
} from '@mui/material';
import { useSelector } from 'react-redux';

const getSteps = (settings) => {
  const steps = ['Campaign Settings', 'Links', 'Ad Setup'];
  if (settings.setupDayparting.value) {
    steps.splice(1, 0, 'Dayparting');
  }
  return steps;
};

const useStyles = makeStyles()((theme) => ({
  stepLabel: {
    color: theme.variantColor('rgba(0, 0, 0, 0.54)', '#d0d0d0'),
  },
  completedLabel: {
    color: `${theme.variantColor('rgba(0, 0, 0, 0.54)', '#d0d0d0')} !important`,
  },
  activeLabel: {
    color: `${theme.variantColor('rgba(0, 0, 0, 0.87)', '#fff')} !important`,
  },
}));

export default function Stepper() {
  const { classes } = useStyles();
  const { currentStep, settings } = useSelector((state) => state.addCampaign);
  const steps = getSteps(settings);
  return (
    <MaterialStepper activeStep={currentStep} orientation="vertical">
      {steps.map((label, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Step key={index}>
          <StepLabel
            classes={{
              label: classes.stepLabel,
              active: classes.activeLabel,
              completed: classes.completedLabel,
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </MaterialStepper>
  );
}

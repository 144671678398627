import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import LoginView from './LoginView';
import { login, logout } from './Pages/Login/actions/login';
import { NetworkRequestPost } from './Helpers/NetworkRequest';
import LinearProgress from './Widgets/Loaders/LinearProgress';
import { openSnackbar } from './Widgets/actions/widgets';
import { openRoutes } from './Router/OpenRoutes';

const useStyles = makeStyles()((theme) => ({
  appContainer: {
    minHeight: 'calc(100vh - 64px)',
    backgroundColor: theme.appBackground.color,
    overflow: 'hidden',
    paddingTop: '64px',
    color: theme.palette.variantText,
  },
  noPadding: {
    paddingTop: 0,
  },
  loading: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.appBackground.color,
  },
}));

function App() {
  const [loading, setLoading] = React.useState(true);
  const { classes } = useStyles();
  const { loggedIn } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationSearch = useLocation().search;
  let checkIfLoggedInterval = 0;

  async function checkIfLoggedOut() {
    const { data: { success, data } } = await NetworkRequestPost('auth');
    if (success && data.token) {
      // eslint-disable-next-line no-undef
      localStorage.setItem('token', data.token);
    }

    if (success === false && loggedIn) {
      dispatch(logout(() => navigate('/login')));
      clearInterval(checkIfLoggedInterval);
    }
  }

  function checkFbSuccessAuth() {
    const fbAuthComplete = new URLSearchParams(locationSearch).get('fb_auth_complete');
    if (fbAuthComplete && fbAuthComplete === 'true') {
      dispatch(openSnackbar('You’ve successfully authorized your facebook account!', 'success'));
    }
  }

  async function checkAuth() {
    const { data: { success, data } } = await NetworkRequestPost('auth');
    if (success !== false && !loggedIn) {
      dispatch(login(success.groups));
    } else if (success === false && !loggedIn && !openRoutes.includes(window.location.pathname)) {
      window.location.href = '/login';
    }
    if (success && data.token) {
      localStorage.setItem('token', data.token);
    }
    setLoading(false);
  }

  useEffect(() => {
    checkFbSuccessAuth();
    checkAuth();
    if (loggedIn) {
      if (window.location.pathname === '/login') {
        navigate('/campaigns');
      }
      // eslint-disable-next-line
      checkIfLoggedInterval = setInterval(() => checkIfLoggedOut(), 120000);
    }
    // eslint-disable-next-line
  }, [loggedIn]);
  return loading
    ? (
      <div className={classes.loading}>
        <LinearProgress />
      </div>
    )
    : (
      <div className={`${classes.appContainer} ${!loggedIn ? classes.noPadding : ''} App`} data-testid="App">
        <LoginView />
      </div>
    );
}

export default App;

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const login = (groups) => ({
  type: LOGIN,
  groups,
});

export const logout = (redirect = () => {}) => {
  localStorage.removeItem('token');
  localStorage.removeItem('email');
  redirect();

  return ({
    type: LOGOUT,
  });
};

export const HANDLE_CAMPAIGN_DETAILS_CHANGE = 'HANDLE_CAMPAIGN_DETAILS_CHANGE';
export const HANDLE_CAMPAIGN_DETAILS_LINK_CHANGE = 'HANDLE_CAMPAIGN_DETAILS_LINK_CHANGE';
export const CHANGE_LINK = 'CHANGE_CAMPAIGN_DETAILS_LINK';
export const ADD_LINK = 'ADD_CAMPAIGN_DETAILS_LINK';
export const REMOVE_LINK = 'REMOVE_CAMPAIGN_DETAILS_LINK';
export const CHANGE_ADVANCED_TRACKING = 'CHANGE_ADVANCED_TRACKING';
export const LOAD_LINKS = 'LOAD_LINKS';
export const RESET_LINKS = 'RESET_LINKS';

const setValueObj = (value = '', error = '') => ({ value, error });

export const changeCampaignDetails = (state, value) => {
  if (state === 'table') {
    localStorage.setItem('campaignDetailsTable', value);
  }

  return {
    type: HANDLE_CAMPAIGN_DETAILS_CHANGE,
    state,
    value,
  };
};

export const changeLinkValue = (value, error) => ({
  type: HANDLE_CAMPAIGN_DETAILS_LINK_CHANGE,
  value,
  error,
});

export const changeAdvancedTracking = (value, error = '') => ({
  type: CHANGE_ADVANCED_TRACKING,
  value,
  error,
});

export const changeWeightedLink = (key, index, value, section, error) => ({
  type: CHANGE_LINK,
  key,
  index,
  value: setValueObj(value, error),
  section,
});

export const addWeightedLink = (section) => ({
  type: ADD_LINK,
  section,
});

export const removeWeightedLink = (index, section) => ({
  type: REMOVE_LINK,
  index,
  section,
});

export const loadLinks = (
  safePageLink,
  landingPageLinks,
  presellLinks,
  advancedTracking,
) => ({
  type: LOAD_LINKS,
  safePageLink,
  landingPageLinks,
  presellLinks,
  advancedTracking,
});

export const resetLinks = () => ({
  type: RESET_LINKS,
});

import { createTheme } from '@mui/material/styles';

export const usingDarkMode = localStorage.getItem('setDarkMode') && localStorage.getItem('setDarkMode') === 'true';

const toggleDarkMode = (lightColor, darkColor) => (usingDarkMode ? darkColor : lightColor);

export const colors = {
  50: '#f0f0f0',
  100: '#d9d9d9',
  200: '#c0c0c0',
  300: '#a6a6a6',
  400: '#939393',
  500: '#808080',
  600: '#787878',
  700: '#6d6d6d',
  800: '#636363',
  900: '#505050',
  A100: '#f8c0c0',
  A200: '#f39292',
  A400: '#ff5353',
  A700: '#000000',
  black: '#1c2025',
  contrastDefaultColor: 'light',
  blue: '#2c8fff',
  red: '#f44336',
  green: '#4caf50',
  white: '#e6e5e8',
  offWhite: 'rgb(0,0,0,0.02)',
  lightGray: '#f4f6f8',
  gray: '#939393',
  darkGray: '#282C34',
  profit: 'green',
  loss: 'red',
};

// eslint-disable-next-line import/prefer-default-export
export const theme = createTheme({
  appBackground: {
    color: toggleDarkMode(colors.lightGray, colors.black),
  },
  background: {
    default: toggleDarkMode(colors.lightGray, colors.darkGray),
    paper: toggleDarkMode(colors.lightGray, colors.darkGray),
    appBar: colors.darkGray,
    contentFrame: colors.lightGray,
  },
  RootBase: {
    root: {

    },
  },
  components: {
    MuiTableBody: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        filterForm: {
          alignItems: 'flex-end',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: 1,
          border: 0,
          color: 'black',
          padding: '0px',
          boxShadow: '0 3px 5px 2px rgba(0,0,0,0.2)',
        },
        colorPrimary: {
          color: colors.white,
          backgroundColor: colors.darkGray,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          focused: toggleDarkMode(colors.darkGray, colors.white),
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: toggleDarkMode(colors.darkGray, colors.white),
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        colorTextSecondary: {
          color: toggleDarkMode(colors.darkGray, colors.white),
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: toggleDarkMode(colors.darkGray, colors.white),
          '&$disabled': {
            color: toggleDarkMode(colors.darkGray, colors.white),
            opacity: '.5',
          },
          '&:hover': {
            backgroundColor: toggleDarkMode('rgba(0,0,0,0.04)', 'rgba(255,255,255,0.08)'),
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: toggleDarkMode(colors.white, colors.darkGray),
          margin: '2px',
          color: toggleDarkMode(colors.darkGray, colors.white),
          '&.Mui-disabled': {
            color: toggleDarkMode(colors.darkGray, colors.white),
          },
          '&$disabled': {
            color: toggleDarkMode(colors.darkGray, colors.white),
          },
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: toggleDarkMode('rgba(0,0,0,0.04)', 'rgba(255,255,255,0.08)'),
          },
        },
        contained: {
          '&:hover': {
            backgroundColor: toggleDarkMode(colors.darkGray, colors.white),
            color: toggleDarkMode(colors.white, colors.darkGray),
          },
          '&$disabled': {
            backgroundColor: toggleDarkMode(colors.darkGray, colors.white),
            color: toggleDarkMode(colors.white, colors.darkGray),
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          '&:hover': {
            backgroundColor: toggleDarkMode('rgba(0,0,0,0.04)', 'rgba(255,255,255,0.08)'),
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: toggleDarkMode(colors.darkGray, colors.white),
          },
          '&:after': {
            borderBottomColor: colors.blue,
          },
          '&:hover': {
            '&:not(.Mui-disabled)': {
              '&:before': {
                borderBottomColor: toggleDarkMode(colors.darkGray, colors.white),
              },
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          backgroundColor: 'transparent',
          color: toggleDarkMode(colors.darkGray, colors.white),
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          padding: '4px 0',
          color: `${toggleDarkMode(colors.darkGray, colors.white)}!important`,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: toggleDarkMode(colors.lightGray, colors.darkGray),
          color: `${toggleDarkMode(colors.darkGray, colors.lightGray)}!important`,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: toggleDarkMode(colors.darkGray, colors.lightGray),
          backgroundColor: toggleDarkMode(colors.lightGray, colors.black),
        },
        clickable: {
          '&:hover': {
            backgroundColor: toggleDarkMode(colors.lightGray, colors.darkGray),
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&$active': {
            color: `${colors.blue}`,
          },
          '&$completed': {
            color: `${colors.blue}`,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingLeft: '20px!important',
          paddingTop: '8px',
          paddingBottom: '8px',
        },
        body: {
          fontSize: '16px',
          fontWeight: 600,
          backgroundColor: toggleDarkMode('white', colors.darkGray),
          color: `${toggleDarkMode(colors.darkGray, 'white')}`,
          borderBottomColor: toggleDarkMode('rgba(224, 224, 224, 1)', 'rgba(255, 255, 255, 0.12)'),
          '&:first-of-type': {
            backgroundColor: toggleDarkMode('white', colors.darkGray),
          },
        },
        head: {
          fontSize: '16px',
          paddingTop: '15px',
          paddingBottom: '15px',
          fontWeight: 600,
          backgroundColor: toggleDarkMode('white', colors.darkGray),
          color: `${toggleDarkMode(colors.darkGray, 'white')}`,
          borderBottomColor: toggleDarkMode('rgba(224, 224, 224, 1)', 'rgba(255, 255, 255, 0.12)'),
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: `${toggleDarkMode(colors.darkGray, colors.white)}`,
          '&$active': {
            color: `${toggleDarkMode(colors.darkGray, colors.white)}`,
          },
          '&:hover': {
            color: `${toggleDarkMode(colors.darkGray, colors.white)}`,
          },
        },
        icon: {
          color: `${toggleDarkMode(colors.darkGray, colors.white)}!important`,
          '&$active': {
            color: `${toggleDarkMode(colors.darkGray, colors.white)}`,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingTop: 8,
          paddingBottom: 0,
        },
        icon: {
          color: `${toggleDarkMode(colors.darkGray, colors.white)}`,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&$focused': {
            borderColor: colors.blue,
          },
        },
        notchedOutline: {
          borderColor: `${toggleDarkMode(colors.darkGray, colors.white)}!important`,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          color: `${toggleDarkMode(colors.darkGray, 'white')}`,
          borderColor: `${toggleDarkMode(colors.darkGray, 'white')}`,
          '&$checked': {
            color: `${toggleDarkMode(colors.darkGray, 'white')}`,
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: colors.blue,
        },
        markLabel: {
          color: `${toggleDarkMode(colors.darkGray, colors.white)}`,
        },
        markLabelActive: {
          color: colors.blue,
        },
        mark: {
          backgroundColor: 'transparent',
        },
        markActive: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colors.blue,
        },
        barColorPrimary: {
          backgroundColor: toggleDarkMode(colors.white, colors.darkGray),
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: toggleDarkMode('white', colors.darkGray),
          color: toggleDarkMode(colors.darkGray, colors.white),
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: toggleDarkMode('white', colors.darkGray),
          color: toggleDarkMode(colors.darkGray, colors.white),
        },
        paperAnchorDockedLeft: {
          borderRightColor: `${toggleDarkMode('rgba(0,0,0,0.12)', 'rgba(255,255,255,0.12)')}!important`,
        },
        paperAnchorDockedRight: {
          borderLeftColor: `${toggleDarkMode('rgba(0,0,0,0.12)', 'rgba(255,255,255,0.12)')}!important`,
        },
      },
    },
  },
  button: {
    primary: colors.gray,
    secondary: '',
  },
  palette: {
    colors,
    variantBackground: toggleDarkMode(colors.white, colors.darkGray),
    variantText: toggleDarkMode(colors.darkGray, colors.white),
    oppositeVariantBackground: toggleDarkMode(colors.darkGray, colors.white),
    oppositeVariantText: toggleDarkMode(colors.white, colors.darkGray),
  },
  zIndex: {
    tooltip: 1200,
  },
  typography: {
    useNextVariants: true,
  },
  variantColor: toggleDarkMode,
});

import { TOGGLE_MENU } from '../actions/sideNavigation';

export const initialState = {
  open: true,
};

export const sideNavigation = (state = initialState, action = {}) => {
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        open: action.value,
      };
    default:
      return state;
  }
};

import { LOGIN, LOGOUT } from '../actions/login';

export const initialState = {
  loggedIn: false,
  groups: [],
};

export const login = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loggedIn: true,
        groups: action.groups || [],
      };
    case LOGOUT:
      return {
        ...state,
        loggedIn: false,
      };
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { updateRefreshedAt } from '../../Filters/DateRange/actions/dateRange';

const useStyles = makeStyles()((theme) => ({
  sectionItem: {
    margin: '2px 0',
    padding: '10px 0',
    paddingLeft: '25px',
    backgroundColor: 'transparent',
    textAlign: 'left',
    border: 'none',
    borderRadius: '4px',
    fontSize: 16,
    color: theme.palette.variantText,
    cursor: 'pointer',
    textTransform: 'capitalize',
    transition: 'color ease-in-out .25s, background-color ease-in-out .25s',
    '&:hover': {
      backgroundColor: theme.variantColor('rgba(0, 0, 0, 0.1)', 'rgba(230, 229, 232, 0.08)'),
    },
    '&:focus': {
      outline: 'none',
    },
  },
  noPadding: {
    padding: 0,
  },
  button: {
    width: '100%',
  },
  selected: {
    color: '#2c8fff',
  },
}));

const SectionItems = React.memo(({ items }) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  function renderItem(item) {
    switch (item.type) {
      case 'page':
        return (
          <button
            key={item.title}
            type="button"
            onClick={() => {
              navigate(item.link);
              dispatch(updateRefreshedAt());
            }}
            disabled={!!item.selected}
            className={`
              ${classes.sectionItem} ${item.selected && classes.selected} ${classes.button}
            `}
          >
            {item.title}
          </button>
        );
      case 'action':
        return (
          <button
            key={item.title}
            type="button"
            onClick={() => item.action()}
            disabled={!!item.selected}
            className={`
              ${classes.sectionItem} ${item.selected && classes.selected} ${classes.button}
            `}
          >
            {item.title}
          </button>
        );
      case 'render':
        return (
          <div
            key={item.title}
            className={`${classes.sectionItem} ${item.noPadding && classes.noPadding}`}
          >
            {item.render}
          </div>
        );
      default:
        return (
          <div
            key={item.title}
            className={classes.sectionItem}
          >
            {item.title}
          </div>
        );
    }
  }

  return !items || items.length < 1 ? false : (
    <div className={classes.sectionItems}>
      {items.map((item) => (renderItem(item)))}
    </div>
  );
});

SectionItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

export default SectionItems;

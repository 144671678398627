export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_USER_SETTING = 'CHANGE_USER_SETTING';

export const changePage = (value, menuMapping) => ({
  type: CHANGE_PAGE,
  value,
  menuMapping,
});

export const changeUserSetting = (name, value) => ({
  type: CHANGE_USER_SETTING,
  name,
  value,
});

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Snackbar as SnackBarMaterial, IconButton,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Close from '@mui/icons-material/Close';
import { closeSnackbar } from '../actions/widgets';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '80%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  other: {
    '& > div': {
      backgroundColor: '#777d80',
    },
  },
  error: {
    '& > div': {
      backgroundColor: theme.palette.colors.red,
    },
  },
  success: {
    '& > div': {
      backgroundColor: theme.palette.colors.green,
    },
  },
  common: {
    width: '100%',
    minHeight: '3rem',
    fontWeight: '500',
    color: theme.palette.variantText,
    textAlign: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
  closeIconButton: {
    color: theme.palette.colors.white,
  },
}));

export const errorsRender = (errors, defaultError) => (
  <div style={{ textAlign: 'left', lineHeight: '2rem' }}>
    {typeof (errors || defaultError) === 'string'
      ? <div>{errors || defaultError}</div>
      : errors.map((errorMessage) => <div>{errorMessage}</div>)}
  </div>
);

const Snackbar = React.memo(() => {
  const { classes } = useStyles();
  const {
    open, children, severity,
  } = useSelector((state) => state.widgets.snackbar);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeSnackbar());
  };

  return (
    <SnackBarMaterial
      autoHideDuration={8000}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      className={`${classes.root} ${classes[severity]} ${classes.common}`}
      message={children}
      action={(
        <IconButton onClick={() => dispatch(closeSnackbar())} className={classes.closeIconButton}>
          <Close className={classes.closeIcon} />
        </IconButton>
      )}
    />
  );
});

export default Snackbar;

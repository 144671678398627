export const CHANGE_DATE = 'CHANGE_DATE';
export const UPDATE_REFRESHED_AT = 'UPDATE_REFRESHED_AT';

export const changeDateRange = ({ startDate, endDate, dateRange }) => {
  localStorage.setItem(
    localStorage.getItem('email') || 'filters',
    JSON.stringify({ startDate, endDate, dateRange }),
  );

  return {
    type: CHANGE_DATE,
    startDate,
    endDate,
    dateRange,
  };
};
export const updateRefreshedAt = () => ({
  type: UPDATE_REFRESHED_AT,
});

import React from 'react';
import {
  Web, Edit, TableChart, FilterList,
} from '@mui/icons-material';
import { iconStyle } from '../../../../Helpers/menuStyleHelper';
import { openRightDrawer } from '../../../../Widgets/actions/widgets';
import { changeCampaignDetails } from '../../actions/campaignDetails';
import DateRange from '../../../../Components/Navigation/SideNavigation/Filters/DateRange/DateRange';
import CampaignStatus from '../../StatusToggles/CampaignStatus';
import EditLinks, { GetLinks } from '../../Drawers/Links';
import AddAdSets from '../../Drawers/AddAdSets';
import PreviewLink from '../../Forms/PreviewLink';
import Dayparting from '../../Drawers/Dayparting/Dayparting';
import CopyAdSet from '../../Drawers/CopyAdSet';

const statusToggles = (campaignStatus) => {
  if (+campaignStatus === 1 || +campaignStatus === 3) {
    return [
      {
        title: 'Campaign Status', type: 'render', render: <CampaignStatus />, noPadding: true,
      },
    ];
  }
  return [
    {
      title: 'Campaign Status', type: 'render', render: <CampaignStatus />, noPadding: true,
    },
    {
      title: 'Archive Status', type: 'render', render: <CampaignStatus archive />, noPadding: true,
    },
  ];
};

const renderConditionalTables = (dispatch, table, advancedTracking) => {
  if (!advancedTracking) {
    return [{
      title: 'Ads',
      selected: table === 'ads',
      type: 'action',
      action: () => dispatch(changeCampaignDetails('table', 'ads')),
    }, {
      title: 'Ad Set',
      selected: table === 'adSet',
      type: 'action',
      action: () => dispatch(changeCampaignDetails('table', 'adSet')),
    }, {
      title: 'Age',
      selected: table === 'age',
      type: 'action',
      action: () => dispatch(changeCampaignDetails('table', 'age')),
    }, {
      title: 'State',
      selected: table === 'state',
      type: 'action',
      action: () => dispatch(changeCampaignDetails('table', 'state')),
    }, {
      title: 'Gender',
      selected: table === 'gender',
      type: 'action',
      action: () => dispatch(changeCampaignDetails('table', 'gender')),
    }, {
      title: 'User Log',
      selected: table === 'userLog',
      type: 'action',
      action: () => dispatch(changeCampaignDetails('table', 'userLog')),
    }];
  }
  return [{
    title: 'Ads',
    selected: table === 'ads',
    type: 'action',
    action: () => dispatch(changeCampaignDetails('table', 'ads')),
  }, {
    title: 'Ad Set',
    selected: table === 'adSet',
    type: 'action',
    action: () => dispatch(changeCampaignDetails('table', 'adSet')),
  }, {
    title: 'Age',
    selected: table === 'age',
    type: 'action',
    action: () => dispatch(changeCampaignDetails('table', 'age')),
  }, {
    title: 'State',
    selected: table === 'state',
    type: 'action',
    action: () => dispatch(changeCampaignDetails('table', 'state')),
  }, {
    //   title: 'Presells',
    //   selected: table === 'presells',
    //   type: 'action',
    //   action: () => dispatch(changeCampaignDetails('table', 'presells')),
    // },
    // {
    //   title: 'Landing Page',
    //   selected: table === 'landingPage',
    //   type: 'action',
    //   action: () => dispatch(changeCampaignDetails('table', 'landingPage')),
    // },
    // {
    //   title: 'Recent Visits',
    //   selected: table === 'visits',
    //   type: 'action',
    //   action: () => dispatch(changeCampaignDetails('table', 'visits')),
    // },
    // {
    //   title: 'Recent Clicks',
    //   selected: table === 'clicks',
    //   type: 'action',
    //   action: () => dispatch(changeCampaignDetails('table', 'clicks')),
    // },
    // {
    //   title: 'Recent Sales',
    //   selected: table === 'sales',
    //   type: 'action',
    //   action: () => dispatch(changeCampaignDetails('table', 'sales')),
    // },
    title: 'Gender',
    selected: table === 'gender',
    type: 'action',
    action: () => dispatch(changeCampaignDetails('table', 'gender')),
  }, {
    title: 'User Log',
    selected: table === 'userLog',
    type: 'action',
    action: () => dispatch(changeCampaignDetails('table', 'userLog')),
  }];
};

export const menuMapping = (
  dispatch,
  table,
  campaignID,
  campaignStatus,
  advancedTracking,
) => ({
  filters: [
    {
      titleIcon: <FilterList style={iconStyle} />,
      title: 'Filters',
      items: [
        {
          title: 'Date Range', type: 'render', render: <DateRange />, noPadding: true,
        },
      ],
    },
  ],
  linkSections: [
    {
      titleIcon: <Web style={iconStyle} />,
      title: 'Pages',
      items: [
        { title: 'Campaigns', type: 'page', link: '/campaigns' },
        { title: 'Add Campaign', type: 'page', link: '/campaigns/add' },
      ],
    },
  ],
  editCampaign: [
    {
      titleIcon: <Edit style={iconStyle} />,
      title: 'Campaign Settings',
      collapsible: true,
      collapsed: false,
      items: [
        ...statusToggles(campaignStatus),
        {
          title: 'Add Ad Sets',
          type: 'render',
          render: <AddAdSets />,
          noPadding: true,
        },
        {
          title: 'Copy Ad Sets',
          type: 'render',
          render: <CopyAdSet />,
          noPadding: true,
        },
        {
          title: 'Edit Links',
          type: 'action',
          action: async () => {
            dispatch(openRightDrawer('Edit Links', <EditLinks />, true));
            GetLinks(campaignID, dispatch);
          },
        },
        {
          title: 'Campaign Dayparting',
          type: 'action',
          action: () => dispatch(openRightDrawer('Campaign Dayparting', <Dayparting />, false)),
        },
        {
          title: 'Clone Campaign',
          type: 'page',
          link: `/campaigns/clone/${campaignID}`,
        },
        {
          title: 'Preview Link',
          type: 'render',
          render: <PreviewLink />,
          noPadding: false,
        },
      ],
    },
  ],
  tables: [
    {
      titleIcon: <TableChart style={iconStyle} />,
      title: 'Reporting',
      collapsible: true,
      collapsed: false,
      items: [...renderConditionalTables(dispatch, table, advancedTracking)],
    },
  ],
});

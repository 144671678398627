import { makeStyles } from 'tss-react/mui';
import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';

const useStyles = makeStyles()(() => ({
  icon: {},
  button: {},
}));

export default function ({ params: { id }, onRemove }) {
  const { classes } = useStyles();
  const handleClick = () => {
    onRemove(id);
  };
  return (
    <IconButton
      className={classes.button}
      onClick={handleClick}
    >
      <Delete className={classes.icon} />
    </IconButton>
  );
}

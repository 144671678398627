import React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { newAd } from '../../../../../reducers/addCampaign';
import { changeNonField } from '../../../../../actions/addCampaign';

const useStyles = makeStyles()(() => ({
  container: {
    minHeight: '5rem',
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addButton: {
    color: '#282C34',
    background: 'none',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    transition: 'ease-in-out 200ms',
    '&:hover': {
      opacity: 0.8,
    },
  },
  icon: {
    width: 35,
    height: 35,
  },
}));

export default function () {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { newAdSet } = useSelector((store) => store.addCampaign);
  const onClick = (event) => {
    event.preventDefault();
    const adSet = JSON.parse(JSON.stringify(newAdSet));
    adSet.ads.push(newAd());
    dispatch(changeNonField('newAdSet', adSet));
  };

  return (
    <div className={classes.container}>
      <button className={classes.addButton} onClick={onClick} type="button">
        <AddCircleOutlineIcon className={classes.icon} />
      </button>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Switch from './Switch/Switch';
import { openDialog, openSnackbar } from '../actions/widgets';

function ToggleSubmit(props) {
  const {
    inheritChecked, onSubmit, campaignID, settingName, confirmMessage, absoluteRoot, disabled,
  } = props;
  const [checked, setChecked] = useState(inheritChecked);
  const dispatch = useDispatch();

  useEffect(() => {
    setChecked(inheritChecked);
  }, [setChecked, inheritChecked]);

  async function submit() {
    const newCheckedValue = !checked;
    setChecked(newCheckedValue);
    const { data } = await onSubmit(newCheckedValue, campaignID);
    function successMessage() {
      const isArchive = settingName === 'Archived Status';
      const isImage = settingName === 'Image Status';
      const statusMsg = `This campaign is now ${newCheckedValue ? 'active' : 'paused'}`;
      const archiveMsg = `This campaign is now ${newCheckedValue ? 'archived' : 'no longer archived'}`;
      const imageStatusMsg = `This image is now ${newCheckedValue ? 'active' : 'paused'}`;
      if (isArchive) {
        return archiveMsg;
      } if (isImage) {
        return imageStatusMsg;
      }
      return statusMsg;
    }
    if (data && data.success) {
      dispatch(openSnackbar(
        data.message || successMessage(),
        'success',
      ));
    } else {
      dispatch(openSnackbar(
        data.message || 'There was an error processing your request. Please try again.',
        'error',
      ));
      setChecked(checked);
    }
  }

  const onChange = () => {
    dispatch(openDialog('Please Confirm', confirmMessage(!checked), () => submit(), true));
  };

  return (
    <Switch
      absoluteRoot={absoluteRoot}
      checked={checked}
      onChange={onChange}
      name={settingName}
      disabled={disabled}
    />
  );
}

ToggleSubmit.propTypes = {
  campaignID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  inheritChecked: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  confirmMessage: PropTypes.func.isRequired,
  settingName: PropTypes.string.isRequired,
  absoluteRoot: PropTypes.bool,
  disabled: PropTypes.bool,
};

ToggleSubmit.defaultProps = {
  absoluteRoot: false,
  disabled: false,
};

export default ToggleSubmit;

import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { IconButton } from '@mui/material';
import { changeNonField } from '../../../../../../actions/addCampaign';

const useStyles = makeStyles()(() => ({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1000,
  },
  iconButton: {
    color: 'white',
    padding: '5px',
  },
  icon: {
    height: 18,
    width: 18,
  },
}));

export default function ({ adIndex, dimension }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { newAdSet } = useSelector((store) => store.addCampaign);

  const removeMedia = () => {
    const adSet = JSON.parse(JSON.stringify(newAdSet));
    adSet.ads[adIndex][dimension].url = '';
    adSet.ads[adIndex][dimension].width = 0;
    adSet.ads[adIndex][dimension].height = 0;
    adSet.ads[adIndex][dimension].thumbnail = '';
    const urls = Object.values(adSet.ads[adIndex]).map((ad) => {
      if (ad.url) return ad.url;
      return '';
    });
    const validURLs = urls.filter((url) => url !== '');
    if (validURLs.length === 0) {
      adSet.ads[adIndex].type = '';
    }
    dispatch(changeNonField('newAdSet', adSet));
  };

  return newAdSet.ads && newAdSet.ads[adIndex] && newAdSet.ads[adIndex][dimension] && newAdSet.ads[adIndex][dimension].url === '' ? '' : (
    <div className={classes.container}>
      <IconButton className={classes.iconButton} onClick={removeMedia}>
        <RemoveCircleIcon className={classes.icon} />
      </IconButton>
    </div>
  );
}

import React from 'react';
import { List } from '@mui/icons-material';
import { DefaultCell } from '../../../Widgets/Tables/TableContainer/DefaultCell';

const columns = [
  {
    name: 'name', title: 'User', width: 230,
  },
  {
    name: 'created_at', title: 'Date', width: 230, compare: 'timestamp',
  },
  {
    name: 'message', title: 'Message', width: 600,
  },
];

const renderCell = ({ row, column: { name } }) => DefaultCell(row[name]);

export const userLogTable = () => ({
  title: 'User Log',
  icon: <List className="tabIcon" />,
  localStorage: 'userLog',
  renderCell,
  columns,
  action: 'userLog',
});

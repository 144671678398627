import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { changeCampaignDetails } from '../actions/campaignDetails';

const useStyles = makeStyles()((theme) => ({
  button: {
    margin: 0,
    padding: '10px 10px 10px 25px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: theme.palette.variantText,
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'capitalize',
    transition: 'color ease-in-out .25s',
  },
}));

export default function (
  { campaign },
) {
  const { classes } = useStyles();
  const { table, adSetID } = useSelector(({ campaignDetails }) => campaignDetails);
  const { adSetNames } = useSelector(({ widgets }) => widgets);
  const dispatch = useDispatch();

  const backToAdSet = () => {
    dispatch(changeCampaignDetails('view', 'adSet'));
    dispatch(changeCampaignDetails('adID', ''));
    if (table === 'adSets') {
      dispatch(changeCampaignDetails('table', 'age'));
    }
    adSetNames.forEach(({ title, value }) => {
      if (value === adSetID) {
        dispatch(changeCampaignDetails('menuLabel', title));
      }
    });
  };

  const backToCampaign = () => {
    dispatch(changeCampaignDetails('view', 'campaign'));
    dispatch(changeCampaignDetails('menuLabel', ''));
    dispatch(changeCampaignDetails('adSetID', ''));
    dispatch(changeCampaignDetails('adID', ''));
  };

  return campaign
    ? (
      <button className={classes.button} type="button" onClick={backToCampaign}>View Campaign</button>
    ) : (
      <button className={classes.button} type="button" onClick={backToAdSet}>View Ad Set</button>
    );
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  capitalize, lowerCase, isEqual, uniq,
} from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Input from '../../../../../../../../Widgets/Forms/Fields/Input';
import { changeAdField } from '../../../../../../actions/addCampaign';
import {
  descriptionLength,
  headlineLength,
  nameLength,
  primaryTextLength,
  validAdDescription,
  validAdTitle,
  validateName,
  validPrimaryText,
} from '../../../Validation/validationFunctions';
import { usePrevious } from '../../../../../../../../Helpers/CustomHooks';
import SelectInput from '../../../../../../../../Widgets/Forms/Fields/Select';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: 12,
    paddingBottom: 0,
  },
  select: {
    marginTop: 0,
    marginBottom: 8,
  },
  fieldContainer: {
    display: 'flex',
    maxWidth: '30%',
    flexBasis: '30%',
    flexGrow: 0,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    '&>div': {
      marginTop: 8,
    },
  },
}));

const callToActionsValues = [
  'OPEN_LINK', 'LIKE_PAGE', 'SHOP_NOW', 'PLAY_GAME', 'INSTALL_APP', 'USE_APP', 'CALL',
  'INSTALL_MOBILE_APP', 'USE_MOBILE_APP', 'MOBILE_DOWNLOAD', 'BOOK_TRAVEL', 'WATCH_VIDEO',
  'LEARN_MORE', 'SIGN_UP', 'DOWNLOAD', 'WATCH_MORE', 'NO_BUTTON', 'VISIT_PAGES_FEED',
  'CALL_NOW', 'APPLY_NOW', 'CONTACT', 'BUY_NOW', 'GET_OFFER', 'GET_OFFER_VIEW', 'BUY_TICKETS',
  'UPDATE_APP', 'GET_DIRECTIONS', 'BUY', 'SEND_UPDATES', 'MESSAGE_PAGE', 'DONATE', 'SUBSCRIBE',
  'SAY_THANKS', 'SELL_NOW', 'SHARE', 'DONATE_NOW', 'GET_QUOTE', 'CONTACT_US', 'ORDER_NOW',
  'START_ORDER', 'ADD_TO_CART', 'REFER_FRIENDS', 'REQUEST_TIME', 'LISTEN_NOW', 'RAISE_MONEY',
  'EVENT_RSVP', 'FOLLOW_NEWS_STORYLINE', 'SEE_MORE', 'PAY_TO_ACCESS', 'PURCHASE_GIFT_CARDS',
  'FOLLOW_PAGE', 'SEND_A_GIFT', 'SWIPE_UP_SHOP', 'SWIPE_UP_PRODUCT', 'SEND_GIFT_MONEY',
  'PLAY_GAME_ON_FACEBOOK', 'GET_STARTED', 'OPEN_INSTANT_APP',
];

const fieldValidation = {
  title: {
    length: headlineLength,
    validation: validAdTitle,
  },
  primaryText: {
    length: primaryTextLength,
    validation: validPrimaryText,
  },
  body: {
    length: descriptionLength,
    validation: validAdDescription,
  },
  name: {
    length: nameLength,
    validation: validateName,
  },
};

export default function ({ index }) {
  const { classes } = useStyles();
  const [errors, setErrors] = useState({});
  const [focusedFields, setFocusedFields] = useState([]);
  const dispatch = useDispatch();
  const { newAdSet } = useSelector((store) => store.addCampaign);
  const ad = newAdSet.ads[index];
  const prevAdSet = usePrevious(newAdSet);

  const validateFields = (value, name) => {
    if (!focusedFields.includes(name)) return;
    const { length, validation } = fieldValidation[name];
    const valid = validation({ [name]: value });
    const message = valid
      ? `Characters: ${value.length}`
      : `Must be between 1 - ${length} characters in length. Current character count: ${value.length}`;
    if (errors[name] !== message) {
      setErrors((prev) => (
        {
          ...prev,
          [name]: message,
        }));
    }
  };

  useEffect(() => {
    if (!isEqual(prevAdSet, newAdSet)) {
      Object.keys(fieldValidation).forEach((key) => {
        validateFields(newAdSet.ads[index][key], key);
      });
    }
  }, [newAdSet]);
  const updateSingleValues = (value, name) => {
    if (!isEqual(value, ad[name])) {
      setFocusedFields(uniq([...focusedFields, name]));
      const adSet = JSON.parse(JSON.stringify(newAdSet));
      adSet.ads[index][name] = value;
      dispatch(changeAdField(index, name, value));
    }
  };

  const renderInput = (name, label, value) => (
    <div className={classes.fieldContainer}>
      <Input
        name={name}
        label={label}
        value={value}
        onChange={updateSingleValues}
        onBlur={updateSingleValues}
        validationFunc={() => {}}
        multiline
        rows="2"
        helperText={errors[name]}
      />
    </div>
  );

  return (
    <div className={classes.container}>
      {renderInput('name', 'Ad Name', ad.name)}
      {renderInput('primaryText', 'Primary Text', ad.primaryText)}
      {renderInput('title', 'Headline', ad.title)}
      {renderInput('body', 'Description', ad.body)}
      <div className={`${classes.fieldContainer} ${classes.select}`}>
        <SelectInput
          name="callToAction"
          label="Call To Action"
          value={ad.callToAction}
          onChange={updateSingleValues}
          options={callToActionsValues.map((val) => ({ value: val, title: capitalize(lowerCase(val)) }))}
          noMargin
          tempOptions={[]}
        />
      </div>
      <div className={classes.fieldContainer} />
    </div>
  );
}

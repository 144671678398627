import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { NetworkRequestPost } from '../../../../Helpers/NetworkRequest';
import SelectInput from '../../../../Widgets/Forms/Fields/Select';
import { changeCampaignDetails } from '../../actions/campaignDetails';
import {
  updateRefreshedAt,
} from '../../../../Components/Navigation/SideNavigation/Filters/DateRange/actions/dateRange';
import { refreshData } from '../../../../Components/Navigation/SideNavigation/Filters/RefreshButton/actions/refresh';

const useStyles = makeStyles()((theme) => ({
  select: {
    backgroundColor: 'transparent',
    color: theme.palette.variantText,
    width: '100%',
    height: '100%',
    justifyContent: 'flex-start',
    margin: 0,
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.variantText,
    },
  },
}));

const failedOptions = [
  { title: 'Select Ad Set', value: '-1' },
];

export default function AdSetFilter() {
  const [options, setOptions] = useState([]);
  const { adSetID, campaignID } = useSelector(({ campaignDetails }) => campaignDetails);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  async function getAdSetsList() {
    let adSets = [];
    try {
      const { data = { success: false, data: [], message: '' } } = await NetworkRequestPost('campaignAdSetsList', { campaign_id: campaignID });
      if (data && data.success && data.data.length > 0) {
        data.data.forEach((adSet) => {
          if (adSet && adSet.name && adSet.adset_id) {
            adSets.push({ title: adSet.name, value: adSet.adset_id });
          }
        });
        if (!adSets.length) {
          adSets = failedOptions;
        }
      } else {
        adSets = failedOptions;
      }
    } catch (error) {
      adSets = failedOptions;
    }
    setOptions(adSets);
  }
  useEffect(() => {
    getAdSetsList();
    // eslint-disable-next-line
  }, []);

  function updateValue(val) {
    dispatch(changeCampaignDetails('adSetID', val));
    options.forEach(({ title, value }) => {
      if (value === val) {
        dispatch(changeCampaignDetails('menuLabel', title));
      }
    });
    dispatch(refreshData(true));
    dispatch(updateRefreshedAt());
  }

  return (
    <SelectInput
      name="adSetID"
      className={classes.select}
      label="Ad Set"
      value={adSetID}
      onChange={(val) => updateValue(val)}
      options={options}
      tempOptions={failedOptions}
    />
  );
}

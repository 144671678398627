import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { closeDialog } from '../actions/widgets';

const useStyles = makeStyles()(() => ({
  buttonContainer: {
    textAlign: 'right',
    width: '100%',
  },
  greenButton: {
    backgroundColor: '#4caf50',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#388e3c',
    },
    marginLeft: '15px',
  },
}));

export function DialogConfirm(props) {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const {
    handleAction, cancelText, confirmText, cancelAction, disabled = false,
  } = props;
  return (
    <div className={classes.buttonContainer}>
      <Button
        onClick={() => (cancelAction ? cancelAction() : dispatch(closeDialog()))}
        variant="contained"
        color="secondary"
      >
        {cancelText}
      </Button>
      <Button
        onClick={() => {
          dispatch(closeDialog());
          handleAction();
        }}
        variant="contained"
        className={classes.greenButton}
        autoFocus
        disabled={disabled}
      >
        {confirmText}
      </Button>
    </div>
  );
}

DialogConfirm.propTypes = {
  handleAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
};

DialogConfirm.defaultProps = {
  confirmText: 'Confirm',
  cancelText: 'Cancel',
};

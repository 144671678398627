import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import CampaignSettings from '../CampaignSettings/CampaignSettings';
import Links from '../Links/Links';
import AddDayParting from '../DaypartingContainer';
import AdSets from '../AdSets';

const useStyles = makeStyles()((theme) => ({
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: 20,
    minHeight: '30rem',
  },
  stepContainer: {
    overflow: 'auto',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  toolTipText: {
    fontSize: 10,
    color: theme.palette.colors.blue,
  },
}));

const getSteps = (settings) => {
  const steps = [
    {
      title: 'Campaign Settings',
      content: <CampaignSettings />,
    },
    {
      title: 'Links',
      content: <Links />,
    },
    {
      title: 'Ad Sets',
      content: <AdSets />,
      helpLink: '',
    },
  ];
  if (settings.setupDayparting.value) {
    steps.splice(1, 0, {
      title: 'Dayparting',
      content: <AddDayParting />,
    });
  }
  return steps;
};

export default function StepContainer() {
  const { classes } = useStyles();
  const { currentStep, settings } = useSelector((state) => state.addCampaign);

  const steps = getSteps(settings);
  return (
    <Grid container justify="center" className={classes.stepContainer}>
      <Grid item xs={12}>
        <Typography align="center" variant="h6">
          {steps[currentStep].title}
        </Typography>
        <div className={classes.formContainer}>
          {steps[currentStep].content}
        </div>
      </Grid>
    </Grid>
  );
}

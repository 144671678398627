import axios from 'axios';
import { getDataUrl } from '../Endpoints';

const getToken = () => {
  const token = localStorage.getItem('token');
  return {
    Authorization: token ? `Bearer Token ${token}` : '',
  };
};

const setParams = (params, action) => {
  const returnParams = { ...params };
  if (!returnParams.action && action) returnParams.action = action;
  return returnParams;
};

const catchErrorHandler = (error) => {
  const { response } = error;
  return {
    data: {
      success: false,
      message: response.data.message || 'An error has occurred. Please try again and reach out to support is the problem persists.',
    },
    error,
  };
};

const createFormData = (file, fileName, params) => {
  const formData = new FormData();
  formData.append(fileName, file);

  Object.keys(params).map((key) => {
    let item = params[key];
    item = key === 'params' ? JSON.stringify(params[key]) : item;
    formData.append(key, item);
    return true;
  });
  return formData;
};

export const NetworkRequestPost = async (endpoint = '', params = {}, action = '') => (
  axios.post(
    getDataUrl()[endpoint],
    { ...setParams(params, action) },
    { headers: { ...getToken() } },
  )
    .then((data) => data)
    .catch(catchErrorHandler)
);

export const NetworkRequestGet = async (endpoint = '', params = {}, action = '') => (
  axios.get(
    getDataUrl()[endpoint],
    {
      params: { ...setParams(params, action) },
      headers: { ...getToken() },
    },
  )
    .then((data) => data)
    .catch(catchErrorHandler)
);

export const NetworkRequestPut = async (endpoint = '', params = {}, action = '') => (
  axios.put(
    getDataUrl()[endpoint],
    { ...setParams(params, action) },
    { headers: { ...getToken() } },
  )
    .then((data) => data)
    .catch(catchErrorHandler)
);

export const NetworkRequestDelete = async (endpoint = '', params = {}, action = '') => (
  axios.delete(
    getDataUrl()[endpoint],
    {
      headers: { ...getToken() },
      data: { ...setParams(params, action) },
    },
  )
    .then((data) => data)
    .catch(catchErrorHandler)
);

export const NetworkRequestMultipart = async (endpoint = '', params = {}, fileName = '', action = '') => {
  const file = params[fileName];
  const formattedParams = setParams(params, action);
  delete formattedParams[fileName];
  const formData = createFormData(
    file,
    fileName,
    {
      ...formattedParams,
    },
  );
  return axios.post(getDataUrl()[endpoint], formData, { headers: { ...getToken() } })
    .then((data) => data)
    .catch(catchErrorHandler);
};

import React from 'react';
import { List, Edit, AddCircleOutline } from '@mui/icons-material';
import { Tooltip, IconButton, TableCell } from '@mui/material';
import { DefaultCell } from '../../../../Widgets/Tables/TableContainer/DefaultCell';
import AdSetStatus from '../../StatusToggles/AdSetStatus';
import NameLink from './NameLink';
import EditAdSet from '../../Drawers/EditAdSet';
import { openRightDrawer } from '../../../../Widgets/actions/widgets';
import AddAds from '../../Drawers/AddAds';
import { columnsCommon, renderCommonColumns } from '../commonColumns';

const columns = (showBudgetColumn = false) => {
  const columnsArr = [{
    name: 'is_active', title: 'Status', width: 120,
  }, {
    name: 'name', title: 'Name', width: 300,
  },
  ...columnsCommon,
  {
    name: 'edit', title: 'Edit', width: 100, compare: 'priority',
  }, {
    name: 'add', title: 'Add Ads', width: 130, compare: 'priority',
  }];
  if (showBudgetColumn) {
    columnsArr.splice(5, 0, {
      name: 'budget', title: 'Budget', width: 130, compare: 'priority',
    });
  }
  return columnsArr;
};

const renderCell = ({ row, column: { name } }, rows, modifyData, campaignID, dispatch) => {
  const cell = renderCommonColumns(row, name);
  if (cell) {
    return cell;
  }
  const value = row[name];
  const changeStatus = (adSetStatus, adSetID) => {
    const newRows = rows.map((currentRow) => {
      const newRow = { ...currentRow };
      if (newRow.adset_id === adSetID) {
        newRow.is_active = adSetStatus;
      }
      return newRow;
    });
    modifyData(newRows);
  };
  switch (name) {
    case 'is_active':
      return DefaultCell(
        <AdSetStatus
          id={row.adset_id}
          adSetStatus={row.is_active}
          changeStatus={changeStatus}
          campaignID={campaignID}
          ads={rows}
        />,
      );
    case 'name':
      return (
        <TableCell style={{
          position: 'sticky',
          zIndex: 300,
          left: 0,
          backgroundClip: 'padding-box',
          borderRight: '1px solid rgba(0,0, 0, 0.1)',
          borderBottom: '1px solid rgba(0,0, 0, 0.1)',
        }}
        >
          <NameLink name={value} adSetID={row.adset_id} />
        </TableCell>
      );
    case 'edit':
      return DefaultCell(editAdSetsButton(row, dispatch));
    case 'add':
      return DefaultCell(addAdsButton(row, dispatch));
    default:
      return DefaultCell(value);
  }
};
const editAdSetsButton = (row, dispatch) => (
  <Tooltip title="Edit Ad Set">
    <IconButton
      onClick={() => dispatch(
        openRightDrawer(
          'Edit Ad Set',
          <EditAdSet adSetID={row.adset_id} />,
          false,
        ),
      )}
    >
      <Edit />
    </IconButton>
  </Tooltip>
);
const addAdsButton = (row, dispatch) => (
  <Tooltip title="Add Ads to Ad Set">
    <IconButton
      onClick={() => dispatch(
        openRightDrawer(
          'Add Ads to Ad Set',
          <AddAds adSetID={row.adset_id} />,
          false,
        ),
      )}
    >
      <AddCircleOutline />
    </IconButton>
  </Tooltip>
);

export const adSetReport = () => ({
  title: 'Ad Set',
  icon: <List className="tabIcon" />,
  localStorage: 'adSets',
  renderCell,
  columns,
  action: 'adSetReport',
  leftColumns: ['name'],
});

import React from 'react';
import {
  setErrorMessage, validateLink, numberValidation,
} from './validations';
import { notTestUser } from '../render/Links/Links';

export default function validateLinks(
  presellLinks,
  landingPageLinks,
  safePageLink,
  advancedTracking,
) {
  let errorFields = [];
  const linkSettings = [presellLinks];
  if (advancedTracking) {
    linkSettings.push(landingPageLinks);
  }
  linkSettings.forEach((links, index) => {
    const name = index === 0 ? 'Presell' : 'Landing Page';
    const linksArray = Object.keys(links).map((key) => links[key]);
    linksArray.forEach((link) => {
      errorFields = setErrorMessage(link.url.value, `${name} Link`, validateLink, errorFields);
      errorFields = setErrorMessage(link.weight.value, `${name} Link Weight`, numberValidation, errorFields);
    });
  });

  if (notTestUser()) {
    errorFields = setErrorMessage(safePageLink.value, 'Safe Page Link', validateLink, errorFields);
  }

  return !errorFields.length ? '' : (
    <div style={{ textAlign: 'left', lineHeight: '2rem' }}>
      {errorFields.map((errorMessage) => <div>{errorMessage}</div>)}
    </div>
  );
}

import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { changeNonField } from '../../AddCampaign/actions/addCampaign';
import { newAdSet as newBlankAdSet } from '../../AddCampaign/reducers/addCampaign';
import { openSnackbar } from '../../../Widgets/actions/widgets';
import { validateAdSet } from '../../AddCampaign/render/AdSets/CreateAdSets/Validation/validationFunctions';
import AdSetDialog from '../../AddCampaign/render/AdSets/CreateAdSets/AdSetDialog';
import { NetworkRequestPost } from '../../../Helpers/NetworkRequest';
import { formatAdSetForSubmit } from '../../AddCampaign/createAddCampaignParams';

const useStyles = makeStyles()((theme) => ({
  errorsList: {
    textAlign: 'left',
  },
  menuButton: {
    textDecoration: 'none',
    flexGrow: 1,
    background: 'none',
    border: 'none',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    padding: '10px 0 10px 25px',
    fontSize: 16,
    color: theme.palette.text.primary,
    cursor: 'pointer',
    textTransform: 'capitalize',
  },
}));

export default function () {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { newAdSet } = useSelector((store) => store.addCampaign);
  const { campaignID, budgetOptimization, bidAmount } = useSelector((store) => store.campaignDetails);
  const openDialog = () => setOpen(true);
  const closeDialog = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      dispatch(changeNonField('newAdSet', newBlankAdSet()));
    }
  };

  const renderError = (errors) => {
    dispatch(openSnackbar(
      (
        <div>
          Oops, looks like there were some issues with some of your ads. Please correct the following errors to continue:
          <ul className={classes.errorsList}>
            {(errors || []).map((error) => <li>{error}</li>)}
          </ul>
        </div>
      ), 'error',
    ));
  };

  const createAdSet = () => {
    const valid = validateAdSet(newAdSet, budgetOptimization, renderError);
    if (valid) {
      setLoading(true);
      NetworkRequestPost('createAdSet', {
        ...formatAdSetForSubmit(newAdSet, budgetOptimization, { value: bidAmount }),
        campaign_id: campaignID,
      }).then(({ data }) => {
        setLoading(false);
        if (data.success) {
          dispatch(openSnackbar('Ad Set was created successfully', 'success'));
          closeDialog();
        } else {
          dispatch(openSnackbar(data.message, 'error'));
        }
      }).catch(() => setLoading(false));
    }
  };

  return (
    <>
      <button
        className={classes.menuButton}
        onClick={openDialog}
        type="button"
      >
        Add Ad Set
      </button>
      <AdSetDialog
        submit={createAdSet}
        closeDialog={closeDialog}
        open={open}
        addMode
        loading={loading}
      />
    </>
  );
}

import { CHANGE_FILTER } from '../actions/filters';

const updateLocalStorage = (key) => {
  if (typeof window !== 'undefined') {
    localStorage.getItem(key);
  }
};

export const initialState = {
  user: updateLocalStorage('userFilter') || '-1',
  weekDay: updateLocalStorage('weekDayFilter') || '-1',
  account_id: updateLocalStorage('accountFilter') || 'all',
  sub_account_id: updateLocalStorage('subAccountFilter') || 'all',
  status: updateLocalStorage('campaignsStatusFilter') || 'all',
  adset_ids: [],
};

export const filters = (state = initialState, action = () => {}) => {
  switch (action.type) {
    case CHANGE_FILTER:
      return {
        ...state,
        [action.filter]: action.value,
      };
    default:
      return state;
  }
};

import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { openRightDrawer } from '../../../../Widgets/actions/widgets';
import AddAds from '../../Drawers/AddAds';

const useStyles = makeStyles()((theme) => ({
  menuButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: theme.palette.variantText,
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'capitalize',
    transition: 'color ease-in-out .25s',
    padding: '10px 10px 10px 25px',
  },
}));

export default function () {
  const { classes } = useStyles();
  const { adSetID } = useSelector(({ campaignDetails }) => campaignDetails);
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(
      openRightDrawer(
        'Add Ads to Ad Set',
        <AddAds adSetID={adSetID} />,
        false,
      ),
    );
  };
  return (
    <button
      className={classes.menuButton}
      onClick={onClick}
      type="button"
    >
      Add Ads
    </button>
  );
}

export const conditionalMenuRender = (menu = {}, section = 'linkSections', replacement = { title: '', find: '', inItems: false }) => {
  const newMenu = { ...menu };
  const sectionList = newMenu[section];
  if (replacement.inItems) {
    let removeIndex;
    const { items } = sectionList;
    items.forEach((item, index) => {
      if (item[replacement.title] === replacement.find) {
        removeIndex = index;
      }
    });
    if (removeIndex !== undefined) {
      items.splice(removeIndex, 1);
    }
  } else {
    let removeIndex;
    sectionList.forEach((currentSection, index) => {
      if (currentSection[replacement.title] === replacement.find) {
        removeIndex = index;
      }
    });
    if (removeIndex !== undefined) {
      sectionList.splice(removeIndex, 1);
    }
  }
  return menu;
};

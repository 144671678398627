import React, { useEffect, useState } from 'react';
import { startCase } from 'lodash';
import Filter from '../render/Filter';
import { NetworkRequestPost } from '../../../../../Helpers/NetworkRequest';

const failedOptions = [
  { title: 'All Users', value: '-1' },
];

export default function UserFilter() {
  const [options, setOptions] = useState([]);
  async function getUsers() {
    let users = [];
    try {
      const { data = { success: false, data: [], message: '' } } = await NetworkRequestPost('getUsersFilter', {});
      if (data && data.success && data.data.length > 0) {
        data.data.forEach((user) => {
          if (user && user.name && user.id) {
            users.push({ title: startCase(user.name), value: user.id });
          }
        });
        if (users.length > 0) {
          users = [{ title: 'All Users', value: '-1' }, ...users];
        } else {
          users = failedOptions;
        }
      } else {
        users = failedOptions;
      }
    } catch (error) {
      users = failedOptions;
    }
    setOptions(users);
  }
  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);
  return (
    <Filter name="user" label="User Filter" filterKey="user" options={options} tempOptions={failedOptions} />
  );
}

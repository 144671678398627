import React from 'react';
import { Switch } from '@mui/material';
import { withStyles } from 'tss-react/mui';

export default withStyles(({
  classes, checked, name = '', onChange, absoluteRoot = false, disabled = false,
}) => (
  <Switch
    disableRipple
    classes={{
      root: absoluteRoot ? classes.absoluteRoot : classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    checked={checked}
    name={name}
    onChange={onChange}
    disabled={disabled}
  />
), (theme, _params, classes) => ({
  root: {
    width: 32,
    height: 18,
    padding: 0,
    margin: theme.spacing(1),
    overflow: 'inherit',
  },
  absoluteRoot: {
    width: 32,
    height: 18,
    padding: 0,
    margin: 10,
    overflow: 'inherit',
    position: 'absolute',
    right: 0,
    top: 0,
  },
  switchBase: {
    padding: 0,
    [`&.${classes.checked}`]: {
      transform: 'translateX(14px)',
      color: `${theme.palette.common.white}!important`,
      [`& + .${classes.track}`]: {
        backgroundColor: `${theme.palette.colors.green}!important`,
        opacity: '1!important',
      },
    },
    [`&.${classes.thumb}`]: {
      color: `${theme.palette.colors.green}!important`,
    },
  },
  thumb: {
    width: 17,
    height: 17,
  },
  track: {
    borderRadius: 16 / 2,
    backgroundColor: theme.palette.colors.red,
    opacity: 1,
    transition: theme.transitions.create(['background-color']),
  },
  checked: {},
  focusVisible: {},
}));

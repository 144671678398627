import { makeStyles } from 'tss-react/mui';
import { AppBar, Toolbar } from '@mui/material';
import React from 'react';
import LogoHome from '../../../Components/Navigation/TopNavigation/LogoHome';

const useStyles = makeStyles()(() => (
  {
    pageContainer: {
      minHeight: 'calc(100vh - 64px)',
      backgroundColor: '#F5F5F5',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingTop: '64px',
    },
    contentContainer: {
      width: '80rem',
      maxWidth: '100vw',
      borderRight: '1px solid #E0E0E0',
      borderLeft: '1px solid #E0E0E0',
      padding: '0 1rem 0 1rem',
    },
    textBodyMd: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    title: {
      fontSize: '2rem',
    },
    p1: {
      fontSize: '1rem',
    },
    boldHeading: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
  }
));

export default function Terms() {
  const { classes } = useStyles();

  function getMainContent() {
    return (
      <div className={classes.textBodyMd}>
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
          <div className="text-center text-[#545454]">
            <h1 className={classes.title}>TERMS & CONDITIONS</h1>
            <hr />
            <p className={classes.p1}>
              By using the Internet site located at www.maximussocial.com (the "Site") or any services provided in connection
              with the Site (the "Service"), you agree to abide by these Terms of Use, as they may be amended by the Company
              from time to time in its sole discretion. Company will post a notice on the Site any time these Terms of Use have been changed or
              otherwise updated. It is your responsibility to review these Terms of Use periodically, and if at any time you find these Terms of Use
              unacceptable, you must immediately leave the Site and cease all use of the Service and the Site.

              BY USING THIS SITE YOU REPRESENT THAT YOU ARE AT LEAST 18 YEARS OLD AND THAT YOU ARE LEGALLY ABLE TO ENTER INTO THIS AGREEMENT.
            </p>
            <h2 className={classes.boldHeading}>PRIVACY POLICY</h2>
            <p className={classes.p1}>
              The Company respects your privacy and permits you to control the treatment of your personal information.
              A complete statement of Company's current privacy policy can be found by
              {' '}
              <a
                href="/privacy"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Privacy opens in new window"
              >
                clicking here.
              </a>
              {' '}
              The Company's privacy policy is expressly incorporated into this Agreement by this reference.
            </p>
            <h2 className={classes.boldHeading}>REGISTRATION</h2>
            <p className={classes.p1}>
              If you are required to open an account to use or access the Site or Service, you must complete the
              registration process by providing the complete and accurate information requested on the registration form.
              You will also be asked to provide a user name and password. You are entirely responsible for maintaining the
              confidentiality of your password. You may not use the account, username, or password of someone else at any time.
              You agree to notify Company immediately on any unauthorized use of your account, user name, or password. Company
              shall not be liable for any loss that you incur as a result of someone else using your password, either with or
              without your knowledge. You may be held liable for any losses incurred by Company, its affiliates, officers, directors,
              employees, consultants, agents, and representatives due to someone else's use of your account or password.
            </p>
            <h2 className={classes.boldHeading}>COMPLIANCE WITH INTELLECTUAL PROPERTY LAWS</h2>
            <p className={classes.p1}>
              When accessing the Site or using the Service, you agree to obey the law and to respect the intellectual
              property rights of others. Your use of the Service and the Site is at all times governed by and subject
              to laws regarding copyright ownership and use of intellectual property. You agree not to upload,
              download, display, perform, transmit, or otherwise distribute any information or content (collectively, "Content")
              in violation of any third party's copyrights, trademarks, or other intellectual property or proprietary rights.
              You agree to abide by laws regarding copyright ownership and use of intellectual property, and you shall be solely
              responsible for any violations of any relevant laws and for any infringements of third party rights caused by any
              Content you provide or transmit, or that is provided or transmitted using your User ID. The burden of proving that
              any Content does not violate any laws or third party rights rests solely with you.
            </p>
            <h2 className={classes.boldHeading}>ALLEGED VIOLATIONS</h2>
            <p className={classes.p1}>
              Company reserves the right to terminate your use of the Service and/or the Site.
              To ensure that Company provides a high quality experience for you and for other users
              of the Site and the Service, you agree that Company or its representatives may access your
              account and records on a case-by-case basis to investigate complaints or allegations of abuse,
              infringement of third party rights, or other unauthorized uses of the Site or the Service. Company
              does not intend to disclose the existence or occurrence of such an investigation unless required by law,
              but Company reserves the right to terminate your account or your access to the Site immediately, with or
              without notice to you, and without liability to you, if Company believes that you have violated any of the Terms of Use,
              furnished Company with false or misleading information, or interfered with use of the Site or the Service by others.
            </p>
            <h2 className={classes.boldHeading}>NO WARRANTIES</h2>
            <p className={classes.p1}>
              COMPANY HEREBY DISCLAIMS ALL WARRANTIES. COMPANY IS MAKING THE SITE AVAILABLE "AS IS" WITHOUT WARRANTY OF
              ANY KIND. YOU ASSUME THE RISK OF ANY AND ALL DAMAGE OR LOSS FROM USE OF, OR INABILITY TO USE,
              THE SITE OR THE SERVICE. TO THE MAXIMUM EXTENT PERMITTED BY LAW, COMPANY EXPRESSLY DISCLAIMS
              ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE SITE, INCLUDING, BUT NOT LIMITED TO,
              ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT.
              COMPANY DOES NOT WARRANT THAT THE SITE OR THE SERVICE WILL MEET YOUR REQUIREMENTS OR
              THAT THE OPERATION OF THE SITE OR THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE.
            </p>

            <h2 className={classes.boldHeading}>LIMITED LIABILITY</h2>
            <p className={classes.p1}>
              COMPANY'S LIABILITY TO YOU IS LIMITED. TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL
              COMPANY BE LIABLE FOR DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, SPECIAL, INCIDENTAL, OR
              CONSEQUENTIAL DAMAGES, LOST PROFITS, OR LOST DATA, REGARDLESS OF THE FORESEEABILITY OF THOSE DAMAGES)
              ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SITE OR ANY OTHER MATERIALS OR SERVICES PROVIDED
              TO YOU BY COMPANY. This limitation shall apply regardless of whether the damages arise out of breach
              of contract, tort, or any other legal theory or form of action.
            </p>

            <h2 className={classes.boldHeading}>AFFILIATED SITES</h2>
            <p className={classes.p1}>
              Company has no control over, and no liability for any third party websites or materials. Company
              works with a number of partners and affiliates whose Internet sites may be linked with the Site.
              Because neither Company nor the Site has control over the content and performance of these partner
              and affiliate sites; Company makes no guarantees about the accuracy, currency, content, or quality
              of the information provided by such sites, and Company assumes no responsibility for unintended,
              objectionable, inaccurate, misleading, or unlawful content that may reside on those sites. Similarly,
              from time to time in connection with your use of the Site, you may have access to content items
              (including, but not limited to, websites) that are owned by third parties. You acknowledge and agree
              that Company makes no guarantees about, and is not responsible for, the accuracy, currency, content,
              or quality of any services or downloads you receive from a third party, and that, Company is not
              affiliated with any third party that you may link to through this site. You expressly agree to indemnify
              Company from any claims you may have against a third party that you linked to or accessed via Company's website.
            </p>

            <h2 className={classes.boldHeading}>PROHIBITED USES</h2>
            <p className={classes.p1}>
              Company imposes certain restrictions on your permissible use of the Site and the Service.
              You are prohibited from violating or attempting to violate any security features of the Site
              or Service, including, without limitation, (a) accessing content or data not intended for you,
              or logging onto a server or account that you are not authorized to access; (b) attempting to
              probe, scan, or test the vulnerability of the Service, the Site, or any associated system or
              network, or to breach security or authentication measures without proper authorization; (c)
              interfering or attempting to interfere with service to any user, host, or network, including,
              without limitation, by means of submitting a virus to the Site or Service, overloading, "flooding,"
              "spamming," "mail bombing," or "crashing;" (d) using the Site or Service to send unsolicited e-mail,
              including, without limitation, promotions, or advertisements for products or services; (e) forging any
              TCP/IP packet header or any part of the header information in any e-mail or in any posting using
              the Service; or (f) attempting to modify, reverse-engineer, decompile, disassemble, or otherwise
              reduce or attempt to reduce to a human-perceivable form any of the source code used by Company
              in providing the Site or Service. Any violation of system or network security may subject you
              to civil and/or criminal liability.
            </p>

            <h2 className={classes.boldHeading}>INDEMNITY</h2>
            <p className={classes.p1}>
              You agree to indemnify Company for certain of your acts and omissions.
              You agree to indemnify, defend, and hold harmless Company, its affiliates,
              officers, directors, employees, consultants, agents, and representatives
              from any and all third party claims, losses, liability, damages, and/or costs
              (including reasonable attorney fees and costs) arising from your access to or use of
              the Site, your violation of these Terms of Use, or your infringement, or infringement
              by any other user of your account, of any intellectual property or other right of any
              person or entity. Company will notify you promptly of any such claim, loss, liability,
              or demand, and will provide you with reasonable assistance, at your expense, in defending
              any such claim, loss, liability, damage, or cost.
            </p>

            <h2 className={classes.boldHeading}>COPYRIGHT</h2>
            <p className={classes.p1}>
              All contents of Site or Service are: Copyright © 2012 Union Square Media Group. All rights reserved.
            </p>

            <h2 className={classes.boldHeading}>GOVERNING LAW</h2>
            <p className={classes.p1}>
              These Terms of Use shall be construed in accordance with and governed by the laws of the United States
              and the State of New York, without reference to their rules regarding conflicts of law.
              You hereby irrevocably consent to the exclusive jurisdiction of the state or federal courts
              New York, New York, USA in all disputes arising out of or related to the use of the Site or Service.
            </p>

            <h2 id="applicable-laws" className={classes.boldHeading}>SEVERABILITY; WAIVER</h2>
            <p className={classes.p1}>
              If, for whatever reason, a court of competent jurisdiction finds any term or condition in these
              Terms of Use to be unenforceable, all other terms and conditions will remain unaffected and
              in full force and effect. No waiver of any breach of any provision of these Terms of Use shall
              constitute a waiver of any prior, concurrent, or subsequent breach of the same or any other
              provisions hereof, and no waiver shall be effective unless made in writing and signed by an
              authorized representative of the waiving party.
            </p>

            <h2 className={classes.boldHeading}>NO LICENSE</h2>
            <p className={classes.p1}>
              Nothing contained on the Site should be understood as granting you a license to
              use any of the trademarks, service marks, or logos owned by Company or by any third party.
            </p>

            <h2 className={classes.boldHeading}>NEW YORK USE ONLY</h2>
            <p className={classes.p1}>
              The Site is controlled and operated by Company from its offices in the State of New York.
              Company makes no representation that any of the materials or the services to which you
              have been given access are available or appropriate for use in other locations. Your use
              of or access to the Site should not be construed as Company's purposefully availing itself
              of the benefits or privilege of doing business in any state or jurisdiction other than New York.
            </p>

            <h2 className={classes.boldHeading}>MODIFICATIONS</h2>
            <p className={classes.p1}>
              Company may, in its sole discretion and without prior notice, (a) revise these Terms
              of Use; (b) modify the Site and/or the Service; and (c) discontinue the Site and/or Service
              at any time. Company shall post any revision to these Terms of Use to the Site, and the
              revision shall be effective immediately on such posting. You agree to review these Terms
              of Use and other online policies posted on the Site periodically to be aware of any revisions.
              You agree that, by continuing to use or access the Site following notice of any revision,
              you shall abide by any such revision.
            </p>

            <h2 className={classes.boldHeading}>ACKNOWLEDGEMENT</h2>
            <p className={classes.p1}>
              BY USING THE SERVICE OR ACCESSING THE SITE, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF USE AND AGREE TO BE BOUND BY THEM.
            </p>
            <hr />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <LogoHome />
        </Toolbar>
      </AppBar>
      <div className={classes.pageContainer}>
        <div className={classes.contentContainer}>
          {getMainContent()}
        </div>
      </div>
    </>
  );
}

export const formattedColumns = (table, columns) => columns.filter((column) => (column.name !== 'ctr') && (column.name !== 'visits'));

export const dataBeforeFilter = (rows, table) => rows[table] || [];

const blockedValueMapping = {
  platforms: 'is_blocked',
  sites: 'blocked',
};

export const formattedTableData = (table, rows, hideBlocked, customFilter, customFilters) => {
  let data = dataBeforeFilter(rows, table);
  if (customFilters.length) {
    customFilters.forEach((filter) => {
      data = data.filter((item) => {
        const value = item[filter.name].toString();
        switch (filter.compare) {
          case 'equals':
            return value.toLowerCase() === filter.value.toString().toLowerCase();
          case 'equalTo':
            return parseFloat(value.replace(/[|&;$%@"<>()+,]/g, '')) === parseFloat(filter.value);
          case 'contains':
            return value.toLowerCase().includes(filter.value.toString().toLowerCase());
          case 'greaterThan':
            return parseFloat(value.replace(/[|&;$%@"<>()+,]/g, '')) > filter.value;
          case 'lessThan':
            return parseFloat(value.replace(/[|&;$%@"<>()+,]/g, '')) < filter.value;
          default:
            return true;
        }
      });
    });
  }
  if (Object.keys(blockedValueMapping).includes(table) && hideBlocked && hideBlocked !== '-1') {
    const blockedRowName = blockedValueMapping[table];
    if (hideBlocked === '1') {
      return data.filter((row) => {
        if (typeof row[blockedRowName] === 'boolean') {
          return !!row[blockedRowName];
        }
        return +row[blockedRowName] === 0;
      });
    }
    return data.filter((row) => +row[blockedRowName] !== 0);
  }
  return data;
};

export const setFormattedFilters = (table, filtersKeys, filters) => {
  const formattedFilters = {};

  filtersKeys.forEach((filter) => {
    const filterFormatted = (filter === 'weekDay' && 'week_days') || filter;
    if (filters[filter] !== '-1') {
      formattedFilters[filterFormatted] = filters[filter];
    }
  });
  return formattedFilters;
};

export const modifyDataHelper = (rows, newRows, table, setRows) => setRows({ ...rows, [table]: newRows });

export const modifyMultiLaterDataHelper = (params, rows, table, setRows) => {
  const {
    newRows, idName, idValue, parentTable, keyToUpdate, fieldToUpdate = '', fieldValue = '',
  } = params;
  if (fieldToUpdate) {
    setRows({
      ...rows,
      [parentTable]: rows[parentTable].map((row) => (row[idName] === idValue && ({
        ...row,
        [keyToUpdate]: newRows,
        [fieldToUpdate]: fieldValue,
      })) || row),
      [table]: newRows,
    });
  } else {
    setRows({
      ...rows,
      [parentTable]: rows[parentTable]
        .map((row) => (row[idName] === idValue && ({ ...row, [keyToUpdate]: newRows })) || row),
      [table]: newRows,
    });
  }
};

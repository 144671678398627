import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@mui/material';
import { stripNonNumericWithoutParse } from '../../../Helpers/NumberFormatters';

export default function Input({
  label, helperText, fullWidth, onChange, type,
  value, name, className, validationFunc, id,
  adornment, inputProps, permanentText, multiline,
  nonDecimal, onBlur, rows, disabled, showError,
}, ref) {
  const typeWithoutNumber = type === 'number' ? 'text' : type;
  const formattedOnChange = (val) => {
    const validDecimal = /^\d*\.?\d*$/;
    if (type === 'number') {
      const changeVal = stripNonNumericWithoutParse(val);
      if (!nonDecimal) {
        if (validDecimal.test(val)) {
          onChange(changeVal, name, validationFunc(changeVal));
        }
      }
      if (nonDecimal && !Number.isNaN(+changeVal)) {
        onChange(Math.round(+changeVal), name, validationFunc(Math.round(+changeVal)));
      }
    } else {
      onChange(val, name, validationFunc(val));
    }
  };

  return useMemo(() => (
    <TextField
      id={id}
      type={typeWithoutNumber}
      variant="outlined"
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
      value={value}
      label={label}
      name={name}
      disabled={disabled}
      placeholder={label}
      multiline={multiline}
      maxRows={rows || 4}
      helperText={helperText || permanentText}
      error={showError && helperText && !(helperText || '').includes('Characters:')}
      fullWidth={fullWidth}
      className={className}
      onChange={(e) => formattedOnChange(e.target.value)}
      onBlur={(e) => {
        onBlur(e.target.value, name, validationFunc(e.target.value), ref);
        onChange(e.target.value, name, validationFunc(e.target.value));
      }}
      inputProps={{ ...inputProps }}
      // eslint-disable-next-line
      InputProps={{
        startAdornment: adornment === '$' ? (
          <InputAdornment position="start">$</InputAdornment>) : null,
        endAdornment: adornment === '%' ? (
          <InputAdornment position="end">%</InputAdornment>) : null,
      }}
    />
  ), [
    label, helperText, fullWidth, type,
    value, name, className, id,
    adornment, permanentText, multiline,
    nonDecimal, disabled, showError,
  ]);
}

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string,
  id: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  label: PropTypes.string,
  helperText: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  multiline: PropTypes.bool,
  adornment: PropTypes.string,
  permanentText: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  showError: PropTypes.bool,
  nonDecimal: PropTypes.bool,
  inputProps: PropTypes.instanceOf(Object),
  validationFunc: PropTypes.func,
};

Input.defaultProps = {
  label: '',
  id: '',
  name: '',
  helperText: '',
  permanentText: '',
  nonDecimal: false,
  fullWidth: true,
  multiline: false,
  showError: true,
  className: '',
  type: 'text',
  disabled: false,
  adornment: '',
  inputProps: {},
  onBlur: () => '',
  onChange: () => '',
  validationFunc: () => '',
};

import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { FilterList } from '@mui/icons-material';
import {
  bool, func, instanceOf, oneOfType, string,
} from 'prop-types';
import FilterRowFilter from './FilterRowFilter';

const useStyles = makeStyles()((theme) => ({
  filterIcon: {
    color: theme.palette.colors.blue,
  },
  filterRow: {
    display: 'flex',
    height: 40,
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 23,
  },
}));

export default function FiltersRow(
  {
    filterList,
    filterTitle,
    filterName,
    customOnChange,
    customValue,
    filterCallback,
  },
) {
  const { classes } = useStyles();
  return (
    <div className={classes.filterRow}>
      <FilterList className={classes.filterIcon} />
      <FilterRowFilter
        filterTitle={filterTitle}
        filterList={filterList}
        filterName={filterName}
        filterCallback={filterCallback}
        customOnChange={customOnChange}
        customValue={customValue}
      />
    </div>
  );
}

FiltersRow.propTypes = {
  filterList: instanceOf(Object).isRequired,
  filterTitle: string.isRequired,
  filterName: string.isRequired,
  customOnChange: oneOfType([func, bool]),
  customValue: string,
};

FiltersRow.defaultProps = {
  customOnChange: false,
  customValue: '',
};

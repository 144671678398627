import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { CustomToolBar } from './CustomFilterBar';
import { CustomNoRowsOverlay } from './CustomNoRows';

function MuiTable(
  {
    rows,
    columns,
    pageSize,
    rowsPerPage,
    height,
    width,
    filterToolbar,
    checkboxSelection,
    hideQuick,
    hideExport,
    columnVisibilityModel,
    sortModel,
    ...miscOptions
  },
) {
  const components = {
    NoRowsOverlay: CustomNoRowsOverlay,
  };
  if (filterToolbar) {
    components.Toolbar = () => CustomToolBar(hideQuick, hideExport);
  }
  return (
    <div style={{ height, width }}>
      <DataGrid
        initialState={{
          columns: {
            columnVisibilityModel,
          },
          sorting: {
            sortModel,
          },
        }}
        rows={rows}
        columns={columns}
        pageSize={pageSize || 5}
        rowsPerPageOptions={[rowsPerPage || 5]}
        components={components}
        checkboxSelection={checkboxSelection}
        {...miscOptions}
      />
    </div>
  );
}

MuiTable.defaultProps = {
  rows: [],
  columns: [],
  columnVisibilityModel: { id: false },
  sortModel: [],
  pageSize: 5,
  rowsPerPageOptions: 5,
  height: 400,
  width: '100%',
  filterToolbar: true,
  checkboxSelection: false,
  hideQuick: false,
  hideExport: false,
};

export default MuiTable;

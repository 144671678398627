import { useMemo, useState } from 'react';
import PropTypes, { object, oneOfType } from 'prop-types';
import { Plugin, Getter, Template } from '@devexpress/dx-react-core';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { TableCell } from '@mui/material';
import { isEqual } from 'lodash';
import { calculateTotals } from '../Widgets/Tables/TableContainer/TableUtilities/calculateGrandTotals';
import { numberWithCommas, convertToFormattedNumber } from '../Widgets/Tables/TableContainer/TableUtilities/columnFormatter';
import { ProfitFormatter } from '../Widgets/Tables/TableContainer/TableUtilities/CellFormatters';
import MarginFormatter from '../Widgets/Tables/TableContainer/TableUtilities/CellFormatters/MarginFormatter';

function GrandTotals(props) {
  const {
    totalsMapping,
  } = props;
  const [totals, setTotals] = useState({ timestamp: new Date().getTime() });
  const [tableRows, setTableRows] = useState();

  const setTotalsCallback = (newTotals) => {
    setTotals(newTotals);
  };

  const getTotals = (time, rows) => {
    if (time > totals.timestamp) {
      calculateTotals(rows, totalsMapping, setTotalsCallback);
    }
  };

  const renderTemplateRow = () => (
    <Template
      name="tableRow"
      predicate={({ tableRow: { type } }) => type === 'totals'}
    >
      {/* eslint-disable-next-line */}
      {(params) => <VirtualTable.Row {...params} />}
    </Template>
  );

  const renderDefaultCell = (value) => (
    <TableCell
      style={{
        paddingLeft: '9px',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
      }}
    >
      {value}
    </TableCell>
  );

  const renderTemplateCell = useMemo(() => (
    <Template
      name="tableCell"
      predicate={({ tableRow, tableColumn }) => tableRow.type === 'totals' && tableColumn.type.toString() === Symbol('data').toString()}
    >
      {({ tableColumn: { column: { name: columnName } } }) => {
        const type = totalsMapping[columnName]
          ? totalsMapping[columnName].type
          : 'default';
        switch (type) {
          case 'header':
            return renderDefaultCell(<b>Grand Totals:</b>);
          case 'number':
            return renderDefaultCell(`${numberWithCommas(totals[columnName] || 0)}`);
          case 'average':
            return renderDefaultCell(`${convertToFormattedNumber(totals[columnName] || 0)}`);
          case 'average_currency':
          case 'currency':
          case 'per_mile':
            return renderDefaultCell(`$${convertToFormattedNumber(totals[columnName] || 0)}`);
          case 'margin':
            return renderDefaultCell(<MarginFormatter value={totals[columnName] || 0} />);
          case 'profit':
            return renderDefaultCell(<ProfitFormatter value={totals[columnName] || 0} />);
          case 'percent':
            return renderDefaultCell(`${convertToFormattedNumber(totals[columnName] || 0)}%`);
          default:
            return renderDefaultCell('');
        }
      }}
    </Template>
  ), [totals]);

  const renderTotalsRows = ({ tableBodyRows }) => {
    if (!tableRows && !isEqual(tableRows, tableBodyRows)) {
      const filtered = tableBodyRows.map((obj) => obj.row);
      setTableRows(tableBodyRows);
      const now = Date.now();
      setTimeout(() => getTotals(now, filtered), 100);
    }
    return [].concat([{
      key: 'totals',
      type: 'totals',
    }], tableBodyRows);
  };

  return (
    <Plugin>
      <Getter
        name="tableBodyRows"
        computed={renderTotalsRows}
      />
      {Object.keys(totals).length > 0 && (
        <>
          {renderTemplateRow()}
          {renderTemplateCell}
        </>
      )}
    </Plugin>
  );
}

GrandTotals.propTypes = {
  totalsMapping: oneOfType([PropTypes.arrayOf(PropTypes.instanceOf(Object)), object]),
};

GrandTotals.defaultProps = {
  totalsMapping: [],
};

export default GrandTotals;

import React from 'react';
import {
  Grid, Typography, Paper,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import { getPositiveOrNegative } from '../../../Helpers/NumberFormatters';
import TooltipLink from '../../TooltipLink/TooltipLink';

const useStyles = makeStyles()((theme) => ({
  value: {
    color: theme.variantColor('rgba(0, 0, 0, 0.8)', theme.palette.colors.white),
    wordBreak: 'break-word',
    // eslint-disable-next-line no-undef
    fontSize: window.window.innerWidth > 1400 ? '25px' : '1.7rem',
    fontWeight: 500,
    paddingTop: '5px',
  },
  paper: {
    padding: 10,
  },
  positive: {
    color: theme.palette.colors.profit,
  },
  negative: {
    color: theme.palette.colors.loss,
  },
  grid: {
    padding: 5,
    textAlign: 'center',
    flexBasis: '25%!important',
    maxWidth: '25%!important',
  },
  title: {
    color: theme.variantColor('rgba(0, 0, 0, 0.5)', theme.palette.colors.white),
    fontSize: '16px',
    fontWeight: 500,
    opacity: theme.variantColor('.9', '.7'),
  },
  progress: {
    height: '5rem',
    marginTop: '3rem',
  },
  tooltipContainer: {
    display: 'inline-flex',
    position: 'absolute',
  },
}));

const DataRender = React.memo((props) => {
  const { classes } = useStyles();
  const {
    value, title, colorStyle, customRender, tooltipData, tooltipLink,
  } = props;
  return (
    <Grid item xs={6} sm={6} md={2} className={classes.grid}>
      <Paper className={classes.paper}>
        <Typography
          align="center"
          variant="h5"
          className={classes.title}
        >
          {title}
          <br />
        </Typography>
        <Typography
          className={`${classes.value} ${colorStyle && value && classes[getPositiveOrNegative(value)]}`}
          align="center"
          variant="h4"
        >
          {customRender || value}
          {tooltipData && (
            <div className={classes.tooltipContainer}>
              <TooltipLink
                text={tooltipData}
                link={tooltipLink}
              />
            </div>
          )}
        </Typography>
      </Paper>
    </Grid>
  );
});

DataRender.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  colorStyle: PropTypes.bool.isRequired,
  customRender: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  tooltipData: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  tooltipLink: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

DataRender.defaultProps = {
  customRender: '',
  tooltipData: '',
  tooltipLink: '',
};

export default DataRender;

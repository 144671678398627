import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import DataRender from './DataRender';
import { usePrevious } from '../../../Helpers/CustomHooks';
import LinearProgress from '../../Loaders/LinearProgress';
import { refreshData } from '../../../Components/Navigation/SideNavigation/Filters/RefreshButton/actions/refresh';
import { openSnackbar } from '../../actions/widgets';
import { changeCampaignDetails } from '../../../Pages/CampaignDetails/actions/campaignDetails';

const useStyles = makeStyles()(() => ({
  container: {
    flex: 1,
    minHeight: '170px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const GlanceData = React.memo((props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { getData, glanceMapping, additionalData } = props;

  const { startDate, endDate, dateRange } = useSelector((state) => state.dateRange);
  const filters = useSelector((state) => state.filters);
  const { refreshAllData } = useSelector((state) => state.refresh);
  const { budget, view } = useSelector((state) => state.campaignDetails);

  const [processing, setProcessing] = React.useState(true);
  const [glanceData, setGlanceData] = React.useState({});
  const requestTime = useRef(0);

  const formattedStartDate = format(startDate, 'yyyy-MM-dd');
  const formattedEndDate = format(endDate, 'yyyy-MM-dd');
  const regularTriggers = {
    formattedStartDate, formattedEndDate, dateRange, filters, budget,
  };

  const previousRegularTriggers = usePrevious(regularTriggers);

  const getRequestTime = () => requestTime.current;

  React.useEffect(() => {
    async function getGlanceData() {
      const time = (new Date()).getTime();
      requestTime.current = time;
      try {
        setProcessing(true);
        dispatch(refreshData(false));
        const { data } = await getData();
        if (getRequestTime() === time) {
          if (data.success) {
            setGlanceData({ ...data.data, ...additionalData });
            if (view === 'adSet' && data.data && data.data.name) {
              dispatch(changeCampaignDetails('menuLabel', `${data.data.name}`));
            }
          } else {
            dispatch(openSnackbar(data.message || 'An error has happened.', 'error'));
          }
          setProcessing(false);
        }
      } catch (e) {
        setProcessing(false);
      }
    }
    if (!isEqual(regularTriggers, previousRegularTriggers) || refreshAllData) {
      getGlanceData();
    }
    // eslint-disable-next-line
  }, [previousRegularTriggers, regularTriggers, refreshAllData]);

  const renderGridBlock = (item) => !item.hidden && (
    <DataRender
      key={item.title}
      title={item.title}
      value={item.value}
      colorStyle={item.colorStyle}
      customRender={item.customRender}
      tooltipData={item.tooltipData}
    />
  );

  const data = useMemo(() => glanceMapping(glanceData), [glanceData]);
  const renderGridBlocks = () => data.map(renderGridBlock);

  return (
    <div className={classes.container}>
      {
        !processing
          ? (
            <Grid container alignItems="center" justifyContent="center">
              {renderGridBlocks()}
            </Grid>
          )
          : <LinearProgress />
      }
    </div>
  );
});

GlanceData.propTypes = {
  getData: PropTypes.func.isRequired,
  glanceMapping: PropTypes.func.isRequired,
};

export default GlanceData;

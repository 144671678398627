import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  IconButton, Button, FormHelperText, FormControl, Checkbox,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AddOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkIfDaysType, daysMapping, renderLaterTimes, weekDays, weekEnds,
} from './helper';
import Select from '../../../../Widgets/Forms/Fields/Select';
import { getValidationErrors } from './validation';
import { openDialog, openSnackbar } from '../../../../Widgets/actions/widgets';
import Input from '../../../../Widgets/Forms/Fields/Input';

const useStyles = makeStyles()((theme) => ({
  container: {
    marginTop: 18,
    marginBottom: 18,
  },
  formTitle: {
    width: '100%',
    textAlign: 'center',
    paddingBottom: '2rem',
    fontSize: 18,
  },
  formContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '1rem',
    border: 'solid 2px',
    borderColor: theme.palette.variantText,
    borderRadius: 8,
  },
  weekdayButtons: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    border: 'solid 2px',
    borderRight: 'none',
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: theme.palette.variantText,
    borderColor: theme.palette.variantText,
    marginBottom: 18,
  },
  weekdayButton: {
    backgroundColor: theme.variantColor('white', theme.palette.oppositeVariantText),
    margin: 0,
    height: '100%',
    borderRadius: 0,
    marginRight: 2,
    '&:hover': {
      backgroundColor: theme.variantColor('white', theme.palette.oppositeVariantText),
    },
  },
  selectedButton: {
    backgroundColor: theme.palette.colors.blue,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.colors.blue,
      color: 'white',
    },
  },
  actionButtons: {
    textAlign: 'center',
    marginTop: '2rem',
    marginBottom: '2rem',
    width: '100%',
  },
  createButton: {
    marginLeft: 15,
    backgroundColor: theme.palette.colors.blue,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.colors.blue,
      color: 'white',
    },
  },
  select: {
    width: 150,
  },
  timeInputs: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  iconButtonContainer: {
    textAlign: 'left',
  },
  helper: {
    marginBottom: '1em',
  },
  checkBox: {
    marginRight: 12,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function AddSettings({
  setSettings, settings, loading, fullWidth, addCampaign,
}) {
  const { classes } = useStyles();
  const { bidAmount, bidStrategy } = useSelector(
    (store) => (addCampaign ? store.addCampaign.settings : store.campaignDetails),
  );
  const dispatch = useDispatch();
  const [open, setOpen] = useState(!Object.keys(settings).length && !loading);
  const [days, setDays] = useState([]);
  const [startAt, setStartAt] = useState('0');
  const [endAt, setEndAt] = useState('0');
  const [budget, setBudget] = useState(0);
  const [enabledBudget, setEnabledBudget] = useState(true);

  async function save(dayParting) {
    setEnabledBudget(true);
    setOpen(false);
    setDays([]);
    setStartAt('0');
    setEndAt('0');
    setBudget(0);
    setSettings(dayParting);
    dispatch(openSnackbar(`Your settings have been added to the table below. Click "${addCampaign ? 'Next' : 'Submit'}" when you're ready to save these changes.`, 'success'));
  }

  const validate = () => {
    const { dayParting, errors } = getValidationErrors(settings, days, startAt, endAt, budget, enabledBudget, bidStrategy, bidAmount);
    if (errors) {
      dispatch(openDialog('Error creating settings', errors));
      return false;
    }
    return save(dayParting);
  };

  function formatSetDay(selectedDay) {
    if (days.indexOf(selectedDay) > -1) {
      setDays(days.filter((day) => day !== selectedDay));
    } else {
      setDays([...days, selectedDay]);
    }
  }

  function setDaysByType(daysType) {
    if (daysType === 'week days') {
      if (_.isEqual(days.sort(), weekDays.sort())) {
        setDays([]);
      } else {
        setDays(weekDays);
      }
      return true;
    }
    if (_.isEqual(days.sort(), weekEnds.sort())) {
      setDays([]);
    } else {
      setDays(weekEnds);
    }
    return true;
  }

  function renderAddButton() {
    return (
      <div className={classes.iconButtonContainer}>
        <IconButton onClick={() => setOpen(true)}>
          <AddOutlined />
        </IconButton>
        Add New Dayparting Settings
      </div>
    );
  }

  const renderWeekdayButton = (title, selected, onClick) => (
    <Button
      key={title}
      onClick={onClick}
      className={`${classes.weekdayButton} ${selected ? classes.selectedButton : ''}`}
    >
      {title}
    </Button>
  );

  const renderTimeInput = (title, name, value, onChange) => (
    <Select
      label={title}
      name={name}
      onChange={onChange}
      value={value}
      options={renderLaterTimes(startAt, name === 'endAt')}
      tempOptions={{}}
    />
  );

  function renderForm() {
    return (
      <div className={classes.formContainer} style={fullWidth ? { width: '100%' } : {}}>
        <div className={classes.formTitle}>
          Create New Dayparting Setting
        </div>
        <FormControl variant="outlined" className={classes.formControl}>
          <FormHelperText className={classes.helper}>
            Pause the campaign during these days/times:
          </FormHelperText>
        </FormControl>
        <div className={classes.weekdayButtons}>
          {Object.values(daysMapping).map((day) => (
            renderWeekdayButton(day, days.indexOf(day) > -1, () => formatSetDay(day))
          ))}
        </div>
        <div className={classes.weekdayButtons}>
          {['week ends', 'week days'].map((daysType) => renderWeekdayButton(daysType, checkIfDaysType(days, daysType), () => setDaysByType(daysType)))}
        </div>
        <div className={classes.timeInputs}>
          {renderTimeInput('Pause At', 'startAt', startAt, (value) => {
            setStartAt(value);
            setEndAt('0');
          })}
          {renderTimeInput('Start At', 'endAt', endAt, setEndAt)}
        </div>
        <div className={classes.timeInputs}>
          <div className={classes.checkBox}>
            <Checkbox
              checked={enabledBudget}
              onChange={() => setEnabledBudget(!enabledBudget)}
              color="primary"
            />
          </div>
          <Input
            name="budget"
            value={budget}
            label="Starting Budget (optional)"
            adornment="$"
            type="number"
            onChange={setBudget}
            disabled={!enabledBudget}
            helperText={enabledBudget ? 'Enabled - Click checkbox to disable' : 'Disabled - Click checkbox to enable'}
            showError={false}
          />
        </div>
        <div className={classes.actionButtons}>
          <Button
            onClick={() => setOpen(false)}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            onClick={validate}
            className={classes.createButton}
          >
            Create Settings
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container} style={fullWidth ? { width: '100%' } : {}}>
      {!open && renderAddButton()}
      {open && renderForm()}
    </div>
  );
}

AddSettings.propTypes = {
  settings: PropTypes.instanceOf(Object).isRequired,
  setSettings: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  addCampaign: PropTypes.bool,
};

AddSettings.defaultProps = {
  addCampaign: false,
};

import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import ToggleSubmit from '../../../../Widgets/Forms/ToggleSubmit';
import { NetworkRequestPut } from '../../../../Helpers/NetworkRequest';
import { openSnackbar } from '../../../../Widgets/actions/widgets';
import { changeCampaignDetails } from '../../actions/campaignDetails';

const useStyles = makeStyles()(() => ({
  text: {
    marginRight: '2rem',
  },
  container: {
    padding: '10px 0 10px 25px',
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  statusHelpIcon: {
    color: '#939393',
    fontSize: '17px',
    marginLeft: '10px',
  },
}));

export default function AdSetStatus() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const {
    adSetStatus, campaignID, adSetID,
  } = useSelector((state) => state.campaignDetails);

  const handleError = (statusVal) => {
    dispatch(changeCampaignDetails('adSetStatus', statusVal === 0 ? 1 : 0));
    dispatch(openSnackbar(data.message || 'An error has happened.', 'error'));
  };

  const submit = async (checked) => {
    const statusVal = ((checked && 1) || 0);
    dispatch(changeCampaignDetails('adStatus', statusVal));
    try {
      const { data } = await NetworkRequestPut(
        'toggleAdSetStatus',
        {
          campaign_id: campaignID,
          adset_id: adSetID,
          status: statusVal,
        },
      );
      if (data.success) {
        dispatch(openSnackbar(data.message, 'success'));
        return;
      }
      handleError(statusVal);
    } catch (e) {
      handleError(statusVal);
    }
  };

  const renderToggle = () => (
    <ToggleSubmit
      campaignID={campaignID}
      onSubmit={submit}
      adSetID={adSetID}
      settingName="Ad Set Status"
      inheritChecked={Boolean(+adSetStatus === 1 ? 1 : 0)}
      confirmMessage={(checked) => `Are you sure that you want to ${checked
        ? 'activate' : 'pause'} this Ad Set?`}
    />
  );

  return (
    <div className={classes.container}>
      <div className={classes.text}>Ad Set Status</div>
      {' '}
      {renderToggle()}
    </div>
  );
}

import { convertToFormattedNumber } from '../columnFormatter';

const formatPercentString = (profit, profitColor) => {
  const value = profit.replace(/([%$-,])/g, '');
  const formattedValue = convertToFormattedNumber(value).replace(/([$-])/g, '');
  if (profitColor === 'red') {
    return `-$${formattedValue}`;
  }
  return `$${formattedValue}`;
};

const ProfitFormatter = ({ value }) => {
  const getProfitColor = () => {
    value = value.toString();
    value = value.replace(/([%$-,])/g, '');
    return value < 0 ? 'red' : 'green';
  };
  return (
    <p style={{ color: getProfitColor() }}>
      {formatPercentString(value, getProfitColor())}
    </p>
  );
};

export default ProfitFormatter;

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ToggleSubmit from '../../../Widgets/Forms/ToggleSubmit';
import { NetworkRequestPut } from '../../../Helpers/NetworkRequest';
import { openSnackbar } from '../../../Widgets/actions/widgets';

const useStyles = makeStyles()(() => ({
  container: {
    padding: '10px 0',
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  statusHelpIcon: {
    color: '#939393',
    fontSize: '17px',
    marginLeft: '10px',
  },
}));

function AdSetStatus(props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const {
    id, adSetStatus, changeStatus, campaignID,
  } = props;
  const status = +adSetStatus === 1 ? 1 : 0;
  const disabled = +adSetStatus === 2;

  const submit = async (checked) => {
    const statusVal = ((checked && 1) || 0);
    changeStatus(statusVal !== 0 ? 1 : 0, id);
    try {
      const { data } = await NetworkRequestPut(
        'toggleAdSetStatus',
        {
          adset_id: id,
          campaign_id: campaignID,
          status: statusVal,
        },
      );
      if (data.success) {
        dispatch(openSnackbar(data.message, 'success'));
        return;
      }
      dispatch(openSnackbar(data.message || 'An error has happened.', 'error'));
      changeStatus(statusVal !== 0 ? 0 : 1, id);
    } catch (e) {
      changeStatus(statusVal !== 0 ? 0 : 1, id);
    }
  };

  const renderToggle = () => (
    <ToggleSubmit
      campaignID={campaignID}
      onSubmit={submit}
      settingName="Ad Set Status"
      inheritChecked={Boolean(status)}
      disabled={disabled}
      confirmMessage={(checked) => `Are you sure that you want to ${checked
        ? 'activate' : 'pause'} this Ad Set?`}
    />
  );

  const renderTooltip = () => (
    disabled
      ? (
        <Tooltip
          title="This ad set has been automatically paused by Maximus Social and can not be enabled"
          placement="top"
        >
          <div>
            {renderToggle()}
          </div>
        </Tooltip>

      )
      : renderToggle()
  );

  return (
    <div className={classes.container}>
      {renderTooltip()}
    </div>
  );
}

AdSetStatus.propTypes = {
  id: PropTypes.string,
  campaignID: PropTypes.string.isRequired,
  changeStatus: PropTypes.func,
  adSetStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AdSetStatus.defaultProps = {
  id: '',
  changeStatus: {},
  adSetStatus: '',
};

export default AdSetStatus;

import React from 'react';
import { TableCell } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { convertToFormattedNumber } from './TableUtilities/columnFormatter';
import ProfitFormatter from './TableUtilities/CellFormatters/ProfitFormatter';
import MarginFormatter from './TableUtilities/CellFormatters/MarginFormatter';

const useStyles = makeStyles()(() => ({
  defaultCell: {
    paddingLeft: '9px',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
}));

export function DefaultCell(value) {
  const { classes } = useStyles();
  return (
    <TableCell className={classes.defaultCell}>
      {value}
    </TableCell>
  );
}

export const RenderProfit = (value) => DefaultCell(
  <ProfitFormatter
    value={value}
  />,
);

export const RenderMargin = (value) => DefaultCell(
  <MarginFormatter
    value={value}
  />,
);

export function CurrencyCell(value) {
  return DefaultCell(`$${convertToFormattedNumber(value)}`);
}

export function PercentCell(value) {
  return DefaultCell(`${convertToFormattedNumber(value)}%`);
}

export function NumberCell(value, decimals = 2) {
  return DefaultCell(`${convertToFormattedNumber(value, decimals)}`);
}

import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  MenuItem,
  Menu,
  Button,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  button: {
    marginRight: 8,
  },
  iconButton: {
    marginRight: 8,
    color: 'inherit',
  },
  invertColors: {
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.08)!important',
    },
  },
  placeholder: {
    padding: 5,
    paddingLeft: 16,
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    opacity: '.65',
  },
}));

function CustomMenu(props) {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const chooseItem = (callBack) => {
    if (callBack) {
      callBack();
    }
    handleMenuClose();
  };

  const {
    menuItems,
    menuId,
    buttonContent,
    label,
    customMenuStyle,
    icon,
    invertColors,
    customItem,
    headerElem,
  } = props;

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      getContentAnchorEl={null}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {headerElem}
      {/* eslint-disable-next-line */}
      {menuItems.map((item) => customItem ? customItem(item, chooseItem) : (
        item.placeholder
          ? (<div key={item.name} className={classes.placeholder}>{item.name}</div>)
          : (
            <MenuItem
              key={item.name}
              onClick={() => chooseItem(item.onClick)}
            >
              {item.name}
            </MenuItem>
          )
      ))}
    </Menu>
  );

  const renderIconButton = (
    <IconButton
      className={`${classes.iconButton} ${customMenuStyle} ${invertColors && classes.invertColors}`}
      edge="end"
      aria-label={label || null}
      aria-controls={menuId}
      aria-haspopup="true"
      onClick={handleProfileMenuOpen}
      color="inherit"
    >
      {buttonContent}
    </IconButton>
  );

  const renderTextButton = (
    <Button
      className={`${classes.button} ${customMenuStyle} ${invertColors && classes.invertColors}`}
      edge="end"
      aria-label={label || null}
      aria-controls={menuId}
      aria-haspopup="true"
      onClick={handleProfileMenuOpen}
      color="inherit"
    >
      {buttonContent}
    </Button>
  );

  return (
    <div>
      {icon ? renderIconButton : renderTextButton}
      {renderMenu}
    </div>
  );
}

CustomMenu.propTypes = {
  buttonContent: PropTypes.node.isRequired,
  menuItems: PropTypes.instanceOf(Object).isRequired,
  customMenuStyle: PropTypes.string,
  menuId: PropTypes.string.isRequired,
  label: PropTypes.string,
  icon: PropTypes.bool,
  invertColors: PropTypes.bool,
  customItem: PropTypes.func,
  headerElem: PropTypes.node,
};

CustomMenu.defaultProps = {
  label: '',
  customMenuStyle: '',
  icon: false,
  invertColors: false,
  customItem: null,
  headerElem: null,
};

export default CustomMenu;

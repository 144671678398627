import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid, useMediaQuery, useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import TopNavigation from './Components/Navigation/TopNavigation/TopNavigation';
import SideNavigation, { drawerWidth } from './Components/Navigation/SideNavigation/SideNavigation';
import OpenRoutes from './Router/OpenRoutes';
import ProtectedRoutes from './Router/ProtectedRoutes';
import Drawer from './Widgets/Drawer/Drawer';
import Snackbar from './Widgets/Snackbar/Snackbar';
import DialogContainer from './Widgets/Dialogs/DialogContainer';
import { toggleMenu } from './Components/Navigation/SideNavigation/actions/sideNavigation';

const useStyles = makeStyles()(() => ({
  mainContent: {
    paddingLeft: 'inherit',
    transition: 'padding-left ease-in-out .15s',
    width: '100%',
  },
  paddedSection: {
    paddingLeft: drawerWidth,
  },
}));

function LoginView() {
  const { classes } = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { loggedIn } = useSelector((state) => state.login);
  const { open } = useSelector((state) => state.sideNavigation);
  const dispatch = useDispatch();

  useEffect(() => {
    if (matches) {
      dispatch(toggleMenu(true));
    }
  }, [matches, dispatch]);

  return (
    <>
      {loggedIn && (
        <>
          <TopNavigation />
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} className={`${classes.mainContent} ${open && classes.paddedSection}`}>
              <ProtectedRoutes sideNavigation={<SideNavigation />} />
            </Grid>
          </Grid>
        </>
      )}
      {!loggedIn && <OpenRoutes />}
      <Drawer />
      <Snackbar />
      <DialogContainer />
    </>
  );
}

export default LoginView;

export const campaignStatusList = [
  { itemValue: '0,1,2,3', title: 'All' },
  { itemValue: '1,3', title: 'Active Campaigns' },
  { itemValue: '2', title: 'Archived Campaigns' },
];

export const siteStatusList = [
  { itemValue: '-1', title: 'All' },
  { itemValue: '1', title: 'All enabled' },
  { itemValue: '2', title: 'All blocked' },
];

export const platformStatusList = [
  { itemValue: '-1', title: 'All' },
  { itemValue: '1', title: 'All enabled' },
  { itemValue: '2', title: 'All blocked' },
];

import React from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import GlanceData from '../../../../../Widgets/GlanceData/Container/GlanceData';
import EditInPlace from '../../../../../Helpers/EditInPlaceField';
import { stripNonNumeric } from '../../../../../Helpers/NumberFormatters';
import { submitBudget } from './EditBudget';
import { NetworkRequestPost } from '../../../../../Helpers/NetworkRequest';
import HelpOverlay from '../../../../../Widgets/HelpOverlay/HelpOverlay';
import {
  currencyWithCommas,
  numberWithCommas,
} from '../../../../../Widgets/Tables/TableContainer/TableUtilities/columnFormatter';

const formatData = (data, type) => {
  switch (type) {
    case 'clicks':
      return data.clicks ? numberWithCommas(data.clicks) : 0;
    case 'conversions':
      return data.conversions ? numberWithCommas(data.conversions) : 0;
    case 'budget':
      return data.budget ? `$${numberWithCommas(data.budget)}` : '$0.00';
    case 'spent':
      return data.spent ? `$${numberWithCommas(data.spent)}` : '$0.00';
    case 'revenue':
      return data.revenue ? `$${numberWithCommas(data.revenue)}` : '$0.00';
    case 'profit':
      return data.profit ? `${currencyWithCommas(data.profit)}` : '$0.00';
    case 'cvr':
      return data.cvr ? `${data.cvr}%` : 'NA';
    case 'cpa':
      return data.cpa ? `$${numberWithCommas(data.cpa)}` : 'NA';
    default:
      return '';
  }
};

const blocksData = (data = {}, budget = 0) => [
  {
    title: 'Clicks',
    value: formatData(data, 'clicks'),
    colorStyle: false,
  }, {
    title: 'Sales',
    value: formatData(data, 'conversions'),
    colorStyle: false,
  }, {
    title: 'Budget',
    value: data.budget ? formatData(data, 'budget') : formatData({ budget }, 'budget'),
    colorStyle: false,
    hidden: false,
    customRender: (
      <HelpOverlay title="Click the pencil icon here to edit this campaigns budget" placement="bottom">
        <div>
          <EditInPlace
            displayValue={data.budget ? formatData(data, 'budget') : formatData({ budget }, 'budget')}
            value={stripNonNumeric(data.budget || budget || 0)}
            onSubmit={(props, ...args) => submitBudget({ ...props, bidAmount: data.bidAmount, bidStrategy: data.bidStrategy }, ...args)}
            type="number"
            adornment="$"
            name="Budget"
            nonDecimal
            tooltipText="Click to update campaign Budget"
          />
        </div>
      </HelpOverlay>
    ),
  }, {
    title: 'Spend',
    value: formatData(data, 'spent'),
    colorStyle: false,
  }, {
    title: 'Revenue',
    value: formatData(data, 'revenue'),
    colorStyle: false,
  }, {
    title: 'Profit',
    value: formatData(data, 'profit'),
    colorStyle: true,
  },
  {
    title: 'CVR',
    value: formatData(data, 'cvr'),
    colorStyle: false,
  },
  {
    title: 'CPA',
    value: formatData(data, 'cpa'),
    colorStyle: false,
  },
];

export default function CampaignDetailsGlance() {
  const {
    campaignID = false, budgetOptimization, bidStrategy, bidAmount,
    budget,
  } = useSelector((state) => state.campaignDetails);
  const { startDate, endDate, dateRange } = useSelector((state) => state.dateRange);
  const filters = useSelector((state) => state.filters);
  const formattedStartDate = format(startDate, 'yyyy-MM-dd');
  const formattedEndDate = format(endDate, 'yyyy-MM-dd');

  let params = {
    campaign_id: campaignID,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    date_range: dateRange,
  };
  if (filters.weekDay !== '-1') {
    params = { ...params, week_days: filters.weekDay };
  }

  const getData = async () => NetworkRequestPost('glance', params);

  const filterGlanceForAdvancedTracking = (data) => blocksData(data, budget).map((glanceRow) => {
    if (glanceRow.title === 'Budget' && budgetOptimization !== 'campaign') {
      glanceRow.hidden = true;
    }
    return glanceRow;
  });

  return (
    <GlanceData
      getData={getData}
      glanceMapping={filterGlanceForAdvancedTracking}
      additionalData={{ bidAmount, bidStrategy }}
    />
  );
}

import React from 'react';
import Proptypes from 'prop-types';
import {
  campaignNameValidation, checkBidAmount, checkBudget, checkEmptySelect, checkROASFloor, setErrorMessage,
} from './validations';

export default async function validateCampaignSettings(
  {
    campaignName,
    budget,
    roasFloor,
    bidStrategy,
    budgetOptimization,
    account,
    bidAmount,
    subAccount,
  },
) {
  let errorFields = [];
  errorFields = setErrorMessage(campaignName.value, 'Campaign Name', campaignNameValidation, errorFields);
  if (budgetOptimization.value === 'campaign') {
    errorFields = setErrorMessage(budget.value, 'Budget', () => checkBudget(budget.value, bidStrategy.value, bidAmount.value), errorFields);
    if (bidStrategy.value === 'LOWEST_COST_WITH_MIN_ROAS') {
      errorFields = setErrorMessage(roasFloor.value, 'ROAS Floor', checkROASFloor, errorFields);
    }
    if (bidStrategy.value !== 'LOWEST_COST_WITHOUT_CAP') {
      errorFields = setErrorMessage(bidAmount.value, 'Bid Amount', checkBidAmount, errorFields);
    }
  }
  errorFields = setErrorMessage(account.value, 'Account', checkEmptySelect, errorFields);
  errorFields = setErrorMessage(subAccount.value, 'Sub Account', checkEmptySelect, errorFields);

  return !errorFields.length ? '' : (
    <div style={{ textAlign: 'left', lineHeight: '2rem' }}>
      {errorFields.map((errorMessage) => <div>{errorMessage}</div>)}
    </div>
  );
}

validateCampaignSettings.propTypes = {
  campaignName: Proptypes.instanceOf(Object).isRequired,
  budget: Proptypes.instanceOf(Object).isRequired,
};

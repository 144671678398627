import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import FormDrawer from '../../../Widgets/Drawer/FormDrawer/FormDrawer';
import { changeCampaignDetails, loadLinks, resetLinks } from '../actions/campaignDetails';
import { NetworkRequestPut, NetworkRequestPost } from '../../../Helpers/NetworkRequest';
import { closeRightDrawer, openSnackbar, rightDrawerLoaded } from '../../../Widgets/actions/widgets';
import Links from '../../AddCampaign/render/Links/Links';
import validateLinks from '../../AddCampaign/Validation/ValidateLinks';
import { refreshData } from '../../../Components/Navigation/SideNavigation/Filters/RefreshButton/actions/refresh';
import { updateRefreshedAt } from '../../../Components/Navigation/SideNavigation/Filters/DateRange/actions/dateRange';

const emptyLinkSetup = () => ([
  {
    url: '',
    weight: 100,
  },
]);

const useStyles = makeStyles()(() => ({
  formWidth: {
    width: '600px',
  },
}));

export const GetLinks = async (campaignID, dispatch) => {
  const { data: { data, success, message } } = await NetworkRequestPost('getLinks', {
    campaign_id: campaignID,
  });
  dispatch(rightDrawerLoaded());
  if (success) {
    dispatch(
      loadLinks(
        data.safePageLink,
        typeof data.landingPageLinks === 'object' && data.landingPageLinks.length === 0
          ? emptyLinkSetup()
          : data.landingPageLinks,
        typeof data.presellLinks === 'object' && data.presellLinks.length === 0
          ? emptyLinkSetup()
          : data.presellLinks,
        data.advancedTracking,
      ),
    );
  } else {
    dispatch(openSnackbar(message || 'An error has happened.', 'error'));
  }
};

export default function EditLinks() {
  const {
    links: {
      safePageLink, landingPageLinks, presellLinks, uuid,
    },
    campaignID,
    table,
  } = useSelector((state) => state.campaignDetails);
  const {
    advancedTracking:
      { value: advancedTracking },
  } = useSelector((state) => state.campaignDetails.links);
  const { classes } = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [initialAdvancedTracking] = useState(advancedTracking);
  const dispatch = useDispatch();

  const submitLinks = async () => {
    setSubmitting(true);
    const { data: { success, message } } = await NetworkRequestPut('updateLinks', {
      campaign_id: campaignID,
      uuid,
      safePageLink: safePageLink.value,
      advancedTracking: advancedTracking ? 1 : 0,
      landingPageLinks: advancedTracking ? Object.keys(landingPageLinks).map((linkID) => ({
        url: landingPageLinks[linkID].url.value,
        weight: landingPageLinks[linkID].weight.value,
      })) : [],
      presellLinks: Object.keys(presellLinks).map((linkID) => ({
        url: presellLinks[linkID].url.value,
        weight: presellLinks[linkID].weight.value,
      })),
    });

    if (success) {
      setSubmitting(false);
      dispatch(changeCampaignDetails('advancedTrackingReporting', advancedTracking));
      dispatch(openSnackbar('Campaign link settings have been updated.', 'success'));
      dispatch(closeRightDrawer());
      dispatch(resetLinks());
      if (initialAdvancedTracking !== advancedTracking) {
        dispatch(refreshData(true));
        dispatch(updateRefreshedAt());
        if (table === 'clicks' && !advancedTracking) {
          dispatch(changeCampaignDetails('table', 'visits'));
        }
      }
    } else {
      dispatch(openSnackbar(message || 'An error has occurred.', 'error'));
      setSubmitting(false);
    }
  };

  const validate = () => {
    const errorMsg = validateLinks(presellLinks, landingPageLinks, safePageLink, advancedTracking);
    if (!errorMsg) {
      submitLinks();
    } else {
      dispatch(openSnackbar(errorMsg, 'error'));
    }
  };

  return (
    <FormDrawer
      onSubmit={validate}
      submitting={submitting}
    >
      <div className={classes.formWidth}>
        <Links campaignDetails />
      </div>
    </FormDrawer>
  );
}

import {
  Grid,
} from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import AdSetTable from './AdSetTable';
import CreateAdSets from './CreateAdSets';
import CloneAdSets from './CloneAdSets';
import { changeNonField } from '../../actions/addCampaign';

export default function AdSets() {
  const { newAdSet } = useSelector((state) => state.addCampaign);
  const dispatch = useDispatch();
  useEffect(() => {
    const adSet = JSON.parse(JSON.stringify(newAdSet));
    adSet.startAt = format(new Date(), 'yyyy-MM-dd');
    dispatch(changeNonField('newAdSet', adSet));
  }, []);
  return (
    <Grid container justify="center" alignItems="center">
      <CloneAdSets />
      <CreateAdSets />
      <AdSetTable />
    </Grid>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Routes } from 'react-router-dom';
import Logout from '../Pages/Login/Logout';
import Campaigns from '../Pages/Campaigns/Campaigns';
import AddCampaign from '../Pages/AddCampaign/AddCampaign';
// import Creatives from '../Pages/Creatives/Creatives';
import CampaignDetails from '../Pages/CampaignDetails/CampaignDetails';
import CloneCampaign from '../Pages/AddCampaign/CloneCampaign';

const ProtectedRoutes = ({ sideNavigation }) => (
  <>
    {sideNavigation}
    <Routes>
      <Route exact path="/" element={<Campaigns />} />
      <Route exact path="/campaigns" element={<Campaigns />} />
      <Route exact path="/campaigns/add" element={<AddCampaign />} />
      {/* <Route exact path="/creatives" element={<Creatives />} /> */}
      <Route exact path="/campaign/:name" element={<CampaignDetails />} />
      <Route exact path="/campaigns/clone/:id" element={<CloneCampaign />} />
      <Route exact path="/logout" element={<Logout />} />
    </Routes>
  </>
);

ProtectedRoutes.propTypes = {
  sideNavigation: PropTypes.node.isRequired,
};

export default ProtectedRoutes;

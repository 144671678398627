import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { submitCampaignStatus } from '../../Helpers/CampaignStatusSubmit';
import ToggleSubmit from '../../Widgets/Forms/ToggleSubmit';

const CampaignToggle = React.memo((props) => {
  const {
    campaignID, status, changeStatus,
  } = props;
  const activeStatus = ['1', '3', 1, 3, true];
  const formatStatus = (overrideStatus) => {
    let statusBool;
    if (overrideStatus === undefined) {
      statusBool = activeStatus.indexOf(status) > -1;
    } else {
      statusBool = activeStatus.indexOf(overrideStatus) > -1;
    }
    return statusBool ? 1 : 0;
  };
  const [displayStatus, setDisplayStatus] = useState(formatStatus());

  useEffect(() => {
    if (formatStatus(status) !== displayStatus) {
      setDisplayStatus(formatStatus());
    }
  }, [status]);

  const submit = async (updatedStatus) => submitCampaignStatus(campaignID, formatStatus(updatedStatus), () => changeStatus(formatStatus(updatedStatus)));

  return (
    <Tooltip title={`Click to ${displayStatus ? 'pause' : 'start'}  this campaign`}>
      <ToggleSubmit
        checked={displayStatus}
        campaignID={campaignID}
        onSubmit={submit}
        settingName="Campaign Status"
        inheritChecked={Boolean(displayStatus)}
        confirmMessage={(checked) => `Are you sure that you want to ${checked
          ? 'activate' : 'pause'} this Campaign?`}
      />
    </Tooltip>
  );
});

export default CampaignToggle;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import Input from '../../../../../../Widgets/Forms/Fields/Input';
import {
  checkAdSetName, checkBidAmount, checkROASFloor, checkStartDate, checkBudget,
} from '../../../../Validation/validations';
import RenderSection from '../../../RenderSection';
import { changeAdSetField, changeNonField } from '../../../../actions/addCampaign';
import { bidStrategyOptions } from '../../../CampaignSettings/CampaignSettings';
import SelectField from '../../../../../../Widgets/Forms/Fields/Select';
import { NetworkRequestPost } from '../../../../../../Helpers/NetworkRequest';
import { openDialog } from '../../../../../../Widgets/actions/widgets';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  select: {
    height: '55px',
  },
  selectContainer: {
    paddingTop: '16px',
  },
}));

export default function ({ editMode, addMode }) {
  const { classes } = useStyles();
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { newAdSet, settings, pixelOptions } = useSelector((store) => store.addCampaign);
  let budgetOptimization;
  let subAccountId;
  if (addMode || editMode) {
    const { campaignDetails } = useSelector((store) => store);
    budgetOptimization = {
      value: campaignDetails.budgetOptimization,
    };
    subAccountId = campaignDetails.subAccountId;
  } else {
    budgetOptimization = settings.budgetOptimization;
    subAccountId = settings.subAccount.value;
  }
  const {
    name,
    startAt,
    budget,
    bidStrategy,
    roasFloor,
    pixelID,
    bidAmount,
  } = newAdSet;

  useEffect(() => {
    if (!editMode) {
      getPixels();
    }
  }, []);

  const getPixels = async () => {
    try {
      const { data } = await NetworkRequestPost('pixelsDropdown', { subAccountId });
      if (data && data.success) {
        const { data: pixels } = data;
        dispatch(changeNonField(
          'pixelOptions',
          [
            { value: 'Select', title: 'Please select a pixel' },
            ...pixels.map(({ pixel, name: pixelName }) => ({ value: pixel, title: pixelName })),
          ],
        ));
      } else {
        dispatch(openDialog('Error', 'An error has occurred when retrieving settings. Please try closing and reopening the "create ad set" dialog. Contact support if this error persists.'));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateSingleValues = (value, fieldName, error) => {
    setErrors((prev) => ({ ...prev, [fieldName]: error }));
    dispatch(changeAdSetField(fieldName, value));
  };

  return (
    <Grid container justify="center" alignItems="center">
      <RenderSection>
        <Input
          name="name"
          value={name}
          label="Ad Set Name"
          onBlur={updateSingleValues}
          onChange={updateSingleValues}
          validationFunc={checkAdSetName}
          helperText={errors.name}
        />
      </RenderSection>
      {!editMode ? (
        <>
          <RenderSection>
            <Input
              name="startAt"
              value={startAt}
              label="Start At"
              type="date"
              id="start_at"
              onChange={updateSingleValues}
              validationFunc={checkStartDate}
              helperText={errors.startAt}
            />
          </RenderSection>
          <RenderSection>
            <div className={classes.selectContainer}>
              <SelectField
                onChange={updateSingleValues}
                tempOptions={pixelOptions}
                name="pixelID"
                options={pixelOptions}
                value={pixelID}
                helperText={errors.pixelID}
                label="Pixel"
                fullWidth
                className={classes.select}
                noMargin
              />
            </div>
          </RenderSection>
        </>
      ) : ''}
      {budgetOptimization.value === 'ad_set' ? (
        <>
          <RenderSection>
            <Input
              name="budget"
              label="Budget"
              type="number"
              adornment="$"
              value={budget}
              id="ad_set_budget"
              onBlur={updateSingleValues}
              onChange={updateSingleValues}
              validationFunc={(value) => checkBudget(value, bidStrategy, bidAmount)}
              helperText={errors.budget}
            />
          </RenderSection>
          <RenderSection>
            <div className={classes.selectContainer}>
              <SelectField
                onChange={updateSingleValues}
                tempOptions={bidStrategyOptions}
                name="bidStrategy"
                options={bidStrategyOptions}
                value={bidStrategy}
                helperText={errors.bidStrategy}
                label="Bid Strategy"
                fullWidth
                className={classes.select}
                noMargin
              />
            </div>
          </RenderSection>
          {bidStrategy === 'LOWEST_COST_WITH_MIN_ROAS' && (
            <RenderSection>
              <Input
                name="roasFloor"
                value={roasFloor}
                label="ROAS Floor"
                adornment="$"
                type="number"
                onChange={updateSingleValues}
                validationFunc={checkROASFloor}
              />
            </RenderSection>
          )}
          {bidStrategy !== 'LOWEST_COST_WITHOUT_CAP' && (
            <RenderSection>
              <Input
                name="bidAmount"
                value={bidAmount}
                label="Bid Amount"
                adornment="$"
                type="number"
                onChange={updateSingleValues}
                validationFunc={checkBidAmount}
              />
            </RenderSection>
          )}
        </>
      ) : ''}
    </Grid>
  );
}

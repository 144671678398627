import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../Pages/Login/Login';
import Terms from '../Pages/AddCampaign/Legal/terms';
import Privacy from '../Pages/AddCampaign/Legal/privacy';

export const openRoutes = [
  '/',
  '/login',
  '/terms',
  '/privacy',
];

export default () => (
  <Routes>
    <Route exact path="/" element={<Login />} />
    <Route path="/login" element={<Login />} />
    <Route path="/terms" element={<Terms />} />
    <Route path="/privacy" element={<Privacy />} />
  </Routes>
);

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import MuiSlider from '@mui/material/Slider';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
  },
  margin: {
    height: theme.spacing(3),
  },
  textCentered: {
    textAlign: 'center',
  },
}));

function valuetext(value) {
  return value;
}

function Slider(props) {
  const { classes } = useStyles();
  const {
    marks,
    title,
    value,
    defaultValue,
    step,
    valueTextFunc,
    max,
    min,
    style,
    onChange,
    onBlur,
    displayLabel,
    titleCentered,
    labeledBy,
  } = props;

  return (
    <div className={classes.root} style={style}>
      {title && (
        <Typography id="discrete-slider-custom" gutterBottom className={(titleCentered && classes.textCentered) || ''}>
          {title}
        </Typography>
      )}
      <MuiSlider
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue={defaultValue}
        getAriaValueText={(val) => {
          if (valueTextFunc && typeof valueTextFunc === 'function') {
            return valueTextFunc(val);
          }
          return valuetext(val);
        }}
        valueLabelFormat={(val) => {
          if (valueTextFunc && typeof valueTextFunc === 'function') {
            return valueTextFunc(val);
          }
          return valuetext(val);
        }}
        aria-labelledby={labeledBy ? 'age-label' : 'discrete-slider-custom'}
        step={step}
        valueLabelDisplay={(displayLabel && 'auto') || 'off'}
        marks={marks}
        max={max}
        min={min}
      />
    </div>
  );
}

Slider.propTypes = {
  marks: PropTypes.instanceOf(Object).isRequired,
  style: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  step: PropTypes.number.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  valueTextFunc: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  displayLabel: PropTypes.bool,
  titleCentered: PropTypes.bool,
};

Slider.defaultProps = {
  valueTextFunc: false,
  displayLabel: true,
  title: '',
  titleCentered: false,
  style: {},
  max: 100,
  min: 0,
};

export default Slider;

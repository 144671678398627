import { changeNonField, handleAddCampaignChange } from './actions/addCampaign';
import {
  formatAdSetsForStore,
} from './render/AdSets/CloneAdSets/CloneAdSetsDialog';

const setValueObj = (value = '', error = '') => ({ value, error });

const storeValidValue = (value, name, updateFunction, dispatch) => {
  if (value !== '' && value !== undefined && typeof updateFunction === 'function') {
    dispatch(updateFunction(name, value));
  }
};

const setCampaignSettings = (data, dispatch) => {
  const {
    campaign_name: campaignName,
    budget,
    budgetOptimization,
    bid_strategy: bidStrategy,
    bid_amount: bidAmount,
    roas_floor: roasFloor,
    account_id: account,
    sub_account_id: subAccount,
    start_date: startDate,
  } = data;

  const updateGenericCampaignSettings = (name, value) => handleAddCampaignChange('settings', name, value);
  const formatCloneCampaignName = (name) => {
    if (!name.toLowerCase().includes('_clone')) {
      return `${name}_Clone`;
    }
    return name;
  };
  storeValidValue(formatCloneCampaignName(campaignName), 'campaignName', updateGenericCampaignSettings, dispatch);
  storeValidValue(+(budget || 0), 'budget', updateGenericCampaignSettings, dispatch);
  storeValidValue(budgetOptimization, 'budgetOptimization', updateGenericCampaignSettings, dispatch);
  storeValidValue(bidStrategy, 'bidStrategy', updateGenericCampaignSettings, dispatch);
  storeValidValue(+(bidAmount || 0), 'bidAmount', updateGenericCampaignSettings, dispatch);
  storeValidValue(+(roasFloor || 0), 'roasFloor', updateGenericCampaignSettings, dispatch);
  storeValidValue(account, 'account', updateGenericCampaignSettings, dispatch);
  storeValidValue(subAccount, 'subAccount', updateGenericCampaignSettings, dispatch);
  storeValidValue(startDate, 'startDate', updateGenericCampaignSettings, dispatch);
};

const setCampaignLinks = (data, dispatch) => {
  const { links } = data;
  const {
    landingPageLinks,
    presellLinks,
    safePageLink,
    advancedTracking,
  } = links || ({
    safePageLink: '',
    presellLinks: [{ url: '', weight: 100 }],
    advancedTracking: 0,
    landingPageLinks: [{ url: '', weight: 100 }],
  }
  );
  const updateGenericCampaignLinks = (name, value) => changeNonField(name, value);
  const generateLinksObject = (cloneLinks = []) => {
    const newLinks = {};
    cloneLinks.forEach((linkObject, index) => {
      newLinks[index] = {
        url: setValueObj(linkObject.url || ''),
        weight: setValueObj(linkObject.weight),
      };
    });
    return newLinks;
  };
  if (presellLinks && presellLinks.length) {
    storeValidValue(generateLinksObject(presellLinks), 'presellLinks', updateGenericCampaignLinks, dispatch);
  }
  if (landingPageLinks && landingPageLinks.length) {
    storeValidValue(generateLinksObject(landingPageLinks), 'landingPageLinks', updateGenericCampaignLinks, dispatch);
  }
  storeValidValue(safePageLink, 'safePageLink', (name, value) => handleAddCampaignChange('links', name, value), dispatch);
  storeValidValue(+advancedTracking === 1, 'advancedTracking', (name, value) => handleAddCampaignChange('links', name, value), dispatch);
};

const setCampaignAdSets = (data, dispatch) => {
  const { adSets } = data;
  const newAdSets = formatAdSetsForStore(adSets);
  dispatch(changeNonField('adSets', newAdSets));
};

// const setDayparting = (data, dispatch) => {
//   const { dayparting = [] } = data;
//   if (dayparting && dayparting.length) {
//     const formattedSettings = takeDataAndOrganizeIt(dayparting || []);
//     dispatch(handleAddCampaignChange('settings', 'setupDayparting', true));
//     dispatch(handleAddCampaignChange('dayPartingData', 'dayparting', dayparting));
//     dispatch(handleAddCampaignChange('dayPartingData', 'settingsTable', formattedSettings));
//   }
// };

export function prefillCloneValues(data, dispatch) {
  setCampaignSettings(data, dispatch);
  setCampaignLinks(data, dispatch);
  setCampaignAdSets(data, dispatch);
  // setDayparting(data, dispatch);
}

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'inline-flex',
  },
  link: {
    textAlign: 'center',
    flexBasis: '90%',
  },
  image: {
    maxWidth: '90%',
    maxHeight: '15rem',
  },
}));

export default function ImageFormatter(props) {
  const { value } = props;
  const { classes } = useStyles();
  const thumbnail = !value.includes('http://') && !value.includes('https://')
    ? `http://${value}` : value;

  return (
    <div className={classes.container}>
      <a
        className={classes.link}
        href={thumbnail}
        rel="noopener noreferrer"
        target="_blank"
        title="Click for Full Size"
      >
        <img
          id={thumbnail}
          alt=" "
          className={classes.image}
          src={thumbnail}
        />
      </a>
    </div>
  );
}

ImageFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import RenderSection from '../../../RenderSection';
import ChipSelectInput from '../../../../../../Components/ReusableComponents/ChipSelectInput';
import { changeNonField } from '../../../../actions/addCampaign';
import { NetworkRequestPost } from '../../../../../../Helpers/NetworkRequest';
import { openSnackbar } from '../../../../../../Widgets/actions/widgets';
// import { groupPullEndpoint } from '../../../../../../Widgets/Forms/AllowDenyList/helper';

const useStyles = makeStyles()(() => ({
  selectInputRoot: {
    padding: 0,
    margin: 0,
    minHeight: 55,
    height: 'inherit',
  },
  singleSelectInputRoot: {
    padding: 0,
    height: '55px !important',
    marginLeft: 20,
    display: 'flex',
    alignItems: 'center',
  },
}));
// const locationOptions = [{ value: '1234', label: 'Florida' }, { value: '4567', label: 'California' }];

export default () => {
  const { classes } = useStyles();
  const [locationOptions, setLocationOptions] = useState([]);
  const dispatch = useDispatch();
  const { newAdSet } = useSelector((store) => store.addCampaign);
  useEffect(() => {
    pullLocations();
  }, []);
  const pullLocations = () => {
    NetworkRequestPost('regionDropdown', {})
      .then(({ data }) => {
        if (data.success) {
          const regions = data.data;
          setLocationOptions(regions.map(({ id, name }) => ({ value: id.toString(), label: name })));
        } else {
          dispatch(openSnackbar(data.message || 'There is a problem of getting locations.', 'error'));
        }
      });
  };
  const {
    includeLocations,
  } = newAdSet;
  const updateSingleValues = (name, value) => {
    dispatch(changeNonField('newAdSet', {
      ...newAdSet,
      [name]: value,
    }));
  };
  return (
    <RenderSection>
      <ChipSelectInput
        name="includeLocations"
        value={locationOptions.filter((option) => includeLocations.includes(option.value.toString()))}
        label="Target States (optional)"
        options={locationOptions}
        onChange={(name, value) => updateSingleValues(name, value.map((val) => val.value))}
        classes={classes}
      />
    </RenderSection>
  );
};

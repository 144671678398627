import React, { useEffect, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Grid, MenuItem, TextField } from '@mui/material';
import Input from '../../../../Widgets/Forms/Fields/Input';
import { changeNonField, handleAddCampaignChange } from '../../actions/addCampaign';
import {
  campaignNameValidation, checkBidAmount, checkBudget, checkROASFloor, checkStartDate,
} from '../../Validation/validations';
import LinearProgress from '../../../../Widgets/Loaders/LinearProgress';
import RenderSection from '../RenderSection';
import { NetworkRequestPost } from '../../../../Helpers/NetworkRequest';
import CampaignSettingsCheckboxes from './CampaignSettingsCheckboxes';
import { openDialog } from '../../../../Widgets/actions/widgets';

export const bidStrategyOptions = [
  { value: 'COST_CAP', title: 'Cost Cap' },
  { value: 'LOWEST_COST_WITH_BID_CAP', title: 'Bid Cap' },
  { value: 'LOWEST_COST_WITHOUT_CAP', title: 'No Cap' },
  // { value: 'LOWEST_COST_WITH_MIN_ROAS', title: 'Min ROAS' },
];

const budgetOptimizationOptions = [
  { value: 'campaign', title: 'Campaign' },
  { value: 'ad_set', title: 'Ad Set' },
];

const useStyles = makeStyles()(() => ({
  loadingContainer: {
    height: 300,
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  centerGrids: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectInput: {
    padding: 0,
    marginRight: 0,
    display: 'flex',
  },
  selectInputRoot: {
    padding: 0,
    margin: 0,
    minHeight: 55,
    height: 'inherit',
  },
  singleSelectInputRoot: {
    padding: 0,
    height: '55px !important',
    marginLeft: 20,
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function CampaignSettings() {
  const bidStrategyRef = createRef();
  const budgetOptimizationRef = createRef();
  const accountRef = createRef();
  const subAccountRef = createRef();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const {
    settings,
    cloneLoading,
    accountOptions,
    subAccountOptions,
    isClone,
    clonedOptimization,
    pulledSubAccounts,
  } = useSelector((state) => state.addCampaign);
  const {
    budgetOptimization,
    startDate,
    campaignName,
    bidStrategy,
    roasFloor,
    account,
    subAccount,
    bidAmount,
    budget,
  } = settings;

  useEffect(() => {
    pullAccounts();
  }, []);

  useEffect(() => {
    if (isClone && account.value !== 'Select' && pulledSubAccounts === false) {
      dispatch(changeNonField('pulledSubAccounts', true));
      pullSubAccounts(account.value, true);
    }
  }, [account.value, pulledSubAccounts]);

  const pullAccounts = async () => {
    dispatch(changeNonField('accountOptions', [{ title: 'Loading...', value: 'Select' }]));
    const { data: { success, data: accounts } } = await NetworkRequestPost('getAccounts');
    const options = [{ title: 'Please select an account', value: 'Select' }];
    if (!success) {
      return dispatch(changeNonField('accountOptions', [...options, { title: 'Test Account', value: 'test' }]));
    }
    accounts.map(({ name: title, id: value }) => options.push({ value, title }));
    return dispatch(changeNonField('accountOptions', options));
  };

  const pullSubAccounts = async (value, fromClone = false) => {
    dispatch(changeNonField('subAccountOptions', [{ title: 'Loading...', value: 'Select' }]));
    if (!fromClone) {
      dispatch(handleAddCampaignChange('settings', 'subAccount', 'Select', ''));
    }
    const { data: { success, data: accounts } } = await NetworkRequestPost('getSubAccounts', { accountId: value });
    const options = [{ title: 'Please select a sub account', value: 'Select' }];
    if (!success) {
      return dispatch(changeNonField('subAccountOptions', [...options, { title: 'Test Sub Account', value: 'test' }]));
    }
    accounts.map(({ name: title, id }) => options.push({ value: id, title }));
    return dispatch(changeNonField('subAccountOptions', options));
  };

  const updateClonedCampaign = () => {
    dispatch(handleAddCampaignChange('settings', 'budgetOptimization', 'ad_set', ''));
    dispatch(changeNonField('adSets', []));
  };

  const updateSingleValues = (value, name, error) => {
    if (name === 'account') {
      pullSubAccounts(value);
    }
    if (name === 'budgetOptimization' && clonedOptimization && clonedOptimization !== value) {
      dispatch(openDialog('Warning', 'Changing a cloned campaigns budget optimization is not supported yet. If you choose to continue, you\'ll be asked to create new ad sets for this campaign. Do you want to continue? ', updateClonedCampaign, true));
      return;
    }
    dispatch(handleAddCampaignChange('settings', name, value, error));
  };

  const renderSelect = (ref, name, field, options, label) => (
    <RenderSection>
      <TextField
        fullWidth
        value={field.value}
        onChange={(event) => updateSingleValues(event.target.value, name)}
        select
        placeholder="Please Select"
        name={name}
        variant="outlined"
        label={label}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        helperText={field.error}
        error={!!field.error}
        className={classes.selectInput}
        InputProps={{
          inputProps: {
            instanceId: name,
            id: name,
            ref,
            options,
            className: classes.singleSelectInputRoot,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.title}
          </MenuItem>
        ))}
      </TextField>
    </RenderSection>
  );

  const campaignNameInput = () => (
    <RenderSection>
      <Input
        name="campaignName"
        value={campaignName.value}
        helperText={campaignName.error}
        label="Campaign Name"
        onChange={updateSingleValues}
        validationFunc={campaignNameValidation}
      />
    </RenderSection>
  );

  const dateInput = () => (
    <RenderSection>
      <Input
        name="startDate"
        value={startDate.value}
        helperText={startDate.error}
        label="Start Date"
        type="date"
        id="start_date"
        autoComplete="text"
        onChange={updateSingleValues}
        validationFunc={checkStartDate}
      />
    </RenderSection>
  );

  const budgetInput = () => (
    <RenderSection>
      <Input
        name="budget"
        value={budget.value}
        label="Budget"
        adornment="$"
        type="number"
        onChange={updateSingleValues}
        validationFunc={checkBudget}
      />
    </RenderSection>
  );

  const roasFloorInput = () => (
    <RenderSection>
      <Input
        name="roasFloor"
        value={roasFloor.value}
        label="ROAS Floor"
        adornment="$"
        type="number"
        onChange={updateSingleValues}
        validationFunc={checkROASFloor}
      />
    </RenderSection>
  );

  const bidAmountInput = () => (
    <RenderSection>
      <Input
        name="bidAmount"
        value={bidAmount.value}
        label="Bid Amount"
        adornment="$"
        type="number"
        onChange={updateSingleValues}
        validationFunc={checkBidAmount}
      />
    </RenderSection>
  );

  const budgetOptimizationInput = () => renderSelect(budgetOptimizationRef, 'budgetOptimization', budgetOptimization, budgetOptimizationOptions, 'Budget Optimization');

  const bidStrategyInput = () => renderSelect(bidStrategyRef, 'bidStrategy', bidStrategy, bidStrategyOptions, 'Bid Strategy');

  const accountsSelect = () => renderSelect(accountRef, 'account', account, accountOptions, 'Account');

  const subAccountsSelect = () => renderSelect(subAccountRef, 'subAccount', subAccount, subAccountOptions, 'Sub Account');

  return cloneLoading
    ? <div className={classes.loadingContainer}><LinearProgress /></div>
    : (
      <Grid container justify="center" alignItems="center">
        {campaignNameInput()}
        {dateInput()}
        {accountsSelect()}
        {subAccountsSelect()}
        {budgetOptimizationInput()}
        {budgetOptimization.value === 'campaign' && (
          <>
            {budgetInput()}
            {bidStrategyInput()}
            {bidStrategy.value === 'LOWEST_COST_WITH_MIN_ROAS' && roasFloorInput()}
            {bidStrategy.value !== 'LOWEST_COST_WITHOUT_CAP' && bidAmountInput()}
          </>
        )}
        <CampaignSettingsCheckboxes classes={classes} />
      </Grid>
    );
}

import React, { useEffect, useState } from 'react';
import PropTypes, { bool, object, oneOfType } from 'prop-types';
import {
  SortingState, SelectionState, FilteringState, PagingState, GroupingState,
  IntegratedFiltering, IntegratedGrouping, IntegratedPaging, IntegratedSorting, RowDetailState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table as DevexTable, TableHeaderRow, TableFilterRow, TableGroupRow,
  PagingPanel, Toolbar, GroupingPanel, DragDropProvider,
  TableColumnVisibility, ColumnChooser, TableColumnResizing,
  TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui';
import { isEqual, map, difference } from 'lodash';
import LinearProgress from '../../Loaders/LinearProgress';
import { saveToLocalStorage, getFromLocalStorage } from '../../../Helpers/SaveStateHelper';
import GrandTotals from '../../../Helpers/GrandTotals';
import '../../../assets/react-datagrid.css';
import { usePrevious } from '../../../Helpers/CustomHooks';

export default function Table(props) {
  const {
    loading,
    rows,
    columns,
    defaultFilters,
    defaultSortingOrder,
    getColumnCompare,
    integratedFilteringColumnExtensions,
    height,
    defaultColumnWidths,
    cellComponent,
    tableColumnLocalStorageName,
    filters,
    allowedPageSizes,
    grouping,
    columnChooser,
    leftColumns,
    renderRowDetail,
    totalsMapping,
  } = props;
  const prevColumns = usePrevious(columns);
  // const [showColumnMessage, setShowColumnMessage] = useState(false);
  const [columnWidths, setColumnWidths] = useState(defaultColumnWidths);

  useEffect(() => {
    if (!isEqual(columns, prevColumns)) {
      setColumnWidths(defaultColumnWidths);
    }
  }, [columns, prevColumns]);
  function columnFilterChange(event) {
    // const changedColumns = getFromLocalStorage(tableColumnLocalStorageName, []);
    saveToLocalStorage(tableColumnLocalStorageName, event);

    // if (changedColumns !== '') {
    //   setShowColumnMessage(true);
    // }
  }

  function columnFilterWarning() {
    if (getFromLocalStorage(tableColumnLocalStorageName, []).length > 0) {
      return (
        <div style={{
          color: 'red', fontSize: '12px', textAlign: 'left', marginTop: '1.5rem', marginLeft: '1.5rem',
        }}
        >
          Your column filter is enabled
        </div>
      );
    }
    return null;
  }

  const checkColumnsWidths = () => difference(map(columns, 'name'), map(columnWidths, 'columnName')).length > 0;
  const getRowId = (row) => row.id;
  return loading
    ? (
      <div style={{
        minHeight: '700px', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <LinearProgress />
      </div>
    ) : (
      <div>
        <div>
          {columnFilterWarning()}
        </div>
        <div>
          <Grid rows={rows.map((row, index) => ({ id: index, ...row }))} columns={columns} getRowId={getRowId}>
            <FilteringState defaultFilters={defaultFilters || []} />
            <SortingState defaultSorting={defaultSortingOrder} />
            <IntegratedSorting columnExtensions={getColumnCompare} />
            <GroupingState />
            <RowDetailState defaultExpandedRowIds={[]} />
            <PagingState defaultCurrentPage={0} defaultPageSize={25} />
            <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
            <IntegratedGrouping />
            <IntegratedPaging />
            <SelectionState defaultSelection={[1, 3, 18]} />
            <DragDropProvider />
            <DevexTable
              height={height}
              columnExtensions={defaultColumnWidths}
              cellComponent={cellComponent}
            />
            <TableColumnResizing
              columnWidths={checkColumnsWidths() ? [] : columnWidths}
              onColumnWidthsChange={setColumnWidths}
            />
            <TableHeaderRow showSortingControls allowDragging />
            <TableColumnVisibility
              onHiddenColumnNamesChange={
                (event) => columnFilterChange(event)
              }
              defaultHiddenColumnNames={getFromLocalStorage(tableColumnLocalStorageName, [])}
            />
            {filters && <TableFilterRow />}
            <PagingPanel pageSizes={allowedPageSizes} />
            {grouping && <TableGroupRow />}
            {(columnChooser || grouping) && <Toolbar />}
            {columnChooser && <ColumnChooser />}
            {grouping && <GroupingPanel showSortingControls allowDragging />}
            {typeof renderRowDetail === 'function' && renderRowDetail()}
            {Object.keys(totalsMapping).length > 0
              && <GrandTotals totalsMapping={totalsMapping} />}
            { leftColumns.length > 0 && (
              <TableFixedColumns leftColumns={leftColumns} />
            )}
          </Grid>
        </div>
      </div>
    );
}

Table.propTypes = {
  allowedPageSizes: PropTypes.arrayOf(
    PropTypes.oneOfType(
      [PropTypes.string, PropTypes.number],
    ),
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  loading: PropTypes.bool.isRequired,
  cellComponent: PropTypes.func.isRequired,
  columnChooser: PropTypes.bool,
  grouping: PropTypes.bool,
  filters: PropTypes.bool,
  defaultFilters: oneOfType([PropTypes.arrayOf(PropTypes.instanceOf(Object)), bool]),
  totalsMapping: oneOfType([PropTypes.arrayOf(PropTypes.instanceOf(Object)), object]),
  renderRowDetail: oneOfType([PropTypes.func, bool]),
  integratedFilteringColumnExtensions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  defaultColumnWidths: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  defaultSortingOrder: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  leftColumns: PropTypes.object,
  getColumnCompare: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  tableColumnLocalStorageName: PropTypes.string.isRequired,
  height: PropTypes.number,
};

Table.defaultProps = {
  height: 650,
  defaultFilters: false,
  columnChooser: true,
  grouping: true,
  filters: true,
  renderRowDetail: false,
  totalsMapping: [],
  leftColumns: [],
  integratedFilteringColumnExtensions: [],
};

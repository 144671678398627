import { NetworkRequestPut } from './NetworkRequest';

export async function submitCampaignStatus(campaignID, status, callbackOnSuccess = () => {}) {
  const failedResponse = {
    data: {
      success: false, message: 'Something went wrong. The campaign status wasn\'t updated.',
    },
  };
  try {
    const { data } = await NetworkRequestPut(
      'campaignStatus',
      {
        campaign_id: campaignID,
        status,
      },
    );
    if (Object.keys(data).indexOf('success') !== -1) {
      if (data.success === true) {
        callbackOnSuccess();
      }
      return { data };
    }
    return { data };
  } catch (e) {
    return failedResponse;
  }
}

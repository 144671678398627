import React from 'react';

// import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../Pages/Login/actions/login';
import CustomMenu from '../../../Widgets/Menu/Menu';
import { openRightDrawer } from '../../../Widgets/actions/widgets';
import { UserSettings } from '../../Settings/UserSetting';
import { ChangePassword } from '../../Settings/ChangePassword';

const useStyles = makeStyles()((theme) => ({
  customMenuStyle: {
    color: 'inherit',
    fontSize: 16,
    fontWeight: 400,
    textTransform: 'none',
    backgroundColor: 'transparent',
    transition: 'color ease-in-out .25s, background-color ease-in-out .25s',
    '&:hover': {
      backgroundColor: theme.variantColor('rgba(0, 0, 0, 0)', 'rgba(230, 229, 232, 0)'),
      '&::after': {
        visibility: 'visible',
        width: '50%',
        left: '50%',
        marginLeft: '-25%',
        transform: 'scaleX(1)',
      },
    },
    '&:focus': {
      outline: 'none',
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '2px',
      bottom: '0px',
      left: '0',
      backgroundColor: '#ffffff',
      visibility: 'hidden',
      transform: 'scaleX(0)',
      transition: 'all 0.3s ease-in-out 0s',
    },
  },
}));

export default function UserName() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const menuItems = [
    { name: 'User Settings', onClick: () => dispatch(openRightDrawer('User Settings', (<UserSettings />))) },
    { name: 'Change Password', onClick: () => dispatch(openRightDrawer('Change Password', (<ChangePassword />))) },
    { name: 'Logout', onClick: () => dispatch(logout(() => navigate('/login'))) },
  ];

  const name = localStorage.getItem('email') || 'User Settings';

  return (
    <div>
      <CustomMenu
        menuId="aria-account-settings"
        buttonContent={name}
        menuItems={menuItems}
        customMenuStyle={classes.customMenuStyle}
      />
    </div>
  );
}

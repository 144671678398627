import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import { useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { changePage } from '../../../../Components/Navigation/actions/page';
import { menuMapping } from './menuMapping';
import CampaignDetailsGlance from './Glance/CampaignDetailsGlance';
import RenderTables from '../../../../Widgets/Tables/TableContainer/RenderTables';
import { tables } from '../../Tables/Tables';
import { conditionalMenuRender } from '../../../../Helpers/conditionalMenuRender';

const useStyles = makeStyles()(() => ({
  container: {
    padding: 10,
  },
  loadingContainer: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function CampaignDetails() {
  const { name } = useParams();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const {
    table,
    campaignID,
    campaignStatus,
    advancedTrackingReporting,
  } = useSelector(({ campaignDetails }) => campaignDetails);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // async function getStripesData(ID) {
  //   const { data } = await NetworkRequestPost('campaignStripes', { campaign_id: ID });
  //   if (data && data.success) {
  //     dispatch(changeCampaignDetails('stripes', data.data));
  //   }
  // }

  useEffect(() => {
    let conditionalMenu = {
      ...menuMapping(
        dispatch,
        table,
        campaignID,
        campaignStatus,
        advancedTrackingReporting,
      ),
    };
    if (!isMobile) {
      conditionalMenu = conditionalMenuRender(conditionalMenu, 'linkSections', { title: 'title', find: 'Pages' });
    }
    dispatch(changePage(name, conditionalMenu));
  }, [
    name,
    table,
    dispatch,
    campaignID,
    isMobile,
    campaignStatus,
    advancedTrackingReporting,
  ]);

  return (
    <div className={classes.container}>
      <CampaignDetailsGlance />
      <RenderTables pageReducer="campaignDetails" tables={() => tables({ advancedTrackingReporting })} />
    </div>
  );
}

export default CampaignDetails;

import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import UploadIcon from '@mui/icons-material/Upload';
import { useDispatch, useSelector } from 'react-redux';
import { NetworkRequestMultipart } from '../../../../../../../../Helpers/NetworkRequest';
import { changeNonField } from '../../../../../../actions/addCampaign';
import { usePrevious } from '../../../../../../../../Helpers/CustomHooks';
import { CircularProgress } from '../../../../../../../../Widgets/Loaders/CircularLoader';
import { openSnackbar } from '../../../../../../../../Widgets/actions/widgets';
import RemoveMediaButton from './RemoveMediaButton';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 12,
  },
  dragContainer: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dragDrop: {
    width: '80%',
    height: '9rem',
    padding: 12,
    border: 'dashed 2px rgba(44,143,255,0.25)',
    borderRadius: 5,
    textAlign: 'center',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'ease-in-out 200ms',
    '&:hover': {
      background: 'rgba(44,143,255,0.03)',
    },
  },
  dropTitle: {
    fontSize: 18,
  },
  dropText: {
    color: '#2c8fff',
    marginTop: 12,
    fontSize: 15,
  },
  dropButton: {
    padding: 15,
    background: 'white',
    borderRadius: '50%',
    boxShadow: '0px 1px 6px -3px rgb(0 0 0 / 30%), 0px 2px 2px 0px rgb(0 0 0 / 20%), 0px 1px 5px 0px rgb(0 0 0 / 15%)',
    width: '2rem',
    height: '2rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  uploadIcon: {
    color: '#2c8fff',
  },
  thumbnails: {
    width: '50%',
    padding: 12,
    height: '9rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&>div': {
      width: '33%',
      height: '100%',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  squareMedia: { // 1:1 aspect ratio
    fontSize: 12,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'solid 1px #282C34',
    width: 80,
    height: 80,
    position: 'relative',
  },
  portraitMedia: { // 9:16 aspect ratio
    fontSize: 12,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'solid 1px #282C34',
    width: 70,
    height: 124,
    position: 'relative',
  },
  landscapeMedia: { // 16:9 aspect ratio
    fontSize: 12,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'solid 1px #282C34',
    width: 90,
    height: 51,
    position: 'relative',
  },
  loader: {
    width: 25,
    height: 25,
    color: '#2c8fff',
  },
  canvas: {
    position: 'absolute',
    display: 'none',
  },
  video: {
    position: 'absolute',
    width: '1080px',
    height: '1080px',
  },
}));

function getFileDimensions(src, file, index, callback) {
  if (file.type.split('/')[0] === 'image') {
    const newFile = new Image();
    newFile.onload = function () {
      const { height } = newFile;
      const { width } = newFile;
      const fileName = `[IMG] ${file.name.split('.')[0]}`;
      callback(src, fileName, height, width, index, 'image', file);
    };
    newFile.src = src;
  } else {
    const video = document.createElement('video');
    video.addEventListener('loadedmetadata', function () {
      const height = this.videoHeight;
      const width = this.videoWidth;
      const fileName = `[VID] ${file.name.split('.')[0]}`;
      callback(src, fileName, height, width, index, 'video', file);
    }, false);
    video.src = src;
  }
}

const dimensionMapping = {
  '1080x1080': 'squareMedia',
  '1080x1920': 'portraitMedia',
  '1920x1080': 'landscapeMedia',
};

export default function ({ index: adIndex }) {
  const [square, setSquare] = useState({
    url: '', width: '', height: '',
  });
  const [landscape, setLandscape] = useState({
    url: '', width: '', height: '',
  });
  const [portrait, setPortrait] = useState({
    url: '', width: '', height: '',
  });
  const previousPortrait = usePrevious(portrait);
  const previousLandscape = usePrevious(landscape);
  const previousSquare = usePrevious(square);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { newAdSet } = useSelector((store) => store.addCampaign);
  const ad = newAdSet.ads[adIndex];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const dropArea = document.getElementById(`drop-area${adIndex}`);
    function preventDefaults(e) {
      e.preventDefault();
      e.stopPropagation();
    }
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      dropArea.addEventListener(eventName, preventDefaults, false);
    });
    dropArea.addEventListener('drop', handleDrop, false);
    dropArea.addEventListener('click', handleClick, false);
  }, []);

  useEffect(() => {
    if (previousSquare && (square.url !== previousSquare.url || square.thumbnail !== previousSquare.thumbnail)) {
      storeToRedux(square);
    }
    if (previousLandscape && (landscape.url !== previousLandscape.url || landscape.thumbnail !== previousLandscape.thumbnail)) {
      storeToRedux(landscape);
    }
    if (previousPortrait && (portrait.url !== previousPortrait.url || portrait.thumbnail !== previousPortrait.thumbnail)) {
      storeToRedux(portrait);
    }
  }, [square, landscape, portrait]);

  function handleClick(event) {
    event.preventDefault();
    if (loading) {
      return false;
    }
    const hiddenInput = document.getElementById(`fileInput${adIndex}`);
    return hiddenInput.click();
  }

  function handleDrop(event) {
    if (loading) {
      return false;
    }
    const { dataTransfer } = event;
    const { files } = dataTransfer;
    return handleUpload(files);
  }

  const uploadFile = (file) => NetworkRequestMultipart('uploadMedia', { file }, 'media');

  const validateAds = (fileArray) => {
    let adType = ad.type;
    let validAd = true;
    if (loading) {
      validAd = false;
    }
    if (fileArray.length > 3) {
      dispatch(openSnackbar('You can only upload 3 files at a time', 'error'));
    }
    fileArray.forEach((file) => {
      const type = file.type.split('/')[0];
      if (adType) {
        validAd = adType === type;
      } else {
        adType = type;
      }
    });
    if (!validAd) {
      dispatch(openSnackbar("You're trying to upload a mix of Images and Videos. Please make sure you're not mixing media and try again", 'error'));
    }
    return validAd;
  };

  const handleUpload = async (files) => {
    const fileArray = Object.values(files);
    const validAd = validateAds(fileArray);
    if (!validAd) {
      return false;
    }
    setLoading(true);
    const promises = fileArray.map(async (file) => {
      const { data } = await uploadFile(file);
      return { data, file };
    });
    return handlePromises(promises);
  };

  const handlePromises = async (promises) => {
    Promise.all(promises).then((responses) => {
      responses.forEach(({ data, file }, index) => {
        if (data.success) {
          return getFileDimensions(data.file_url, file, index, handleResponse);
        }
        return dispatch(openSnackbar('Error uploading files. Please check your files and try again', 'error'));
      });
      setLoading(false);
    });
  };

  const handleResponse = async (src, fileName, height, width, index, fileType, file) => {
    const dimension = dimensionMapping[`${width}x${height}`];
    setTimeout(() => {
      switch (dimension) {
        case 'squareMedia':
          return setSquare({
            url: src, height, width, dimension, fileName, fileType, file,
          });
        case 'portraitMedia':
          return setPortrait({
            url: src, height, width, dimension, fileName, fileType, file,
          });
        case 'landscapeMedia':
          return setLandscape({
            url: src, height, width, dimension, fileName, fileType, file,
          });
        default:
          break;
      }
      return dispatch(openSnackbar('At least one file had incorrect dimensions. Please check file dimensions and try again.', 'error'));
    }, (index + 1) * 50);
  };

  function getVideoCover(file, seekTo = 0.0) {
    return new Promise((resolve, reject) => {
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('crossorigin', 'anonymous');
      videoPlayer.setAttribute('src', URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener('error', (ex) => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('error when loading video file', ex);
      });
      videoPlayer.addEventListener('loadedmetadata', () => {
        if (videoPlayer.duration < seekTo) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('video is too short.');
          return;
        }
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        videoPlayer.addEventListener('seeked', () => {
          const canvas = document.createElement('canvas');
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          ctx.canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            'image/jpeg',
            1,
          );
        });
      });
    });
  }

  const createThumbnail = async (file, dimension) => {
    const cover = await getVideoCover(file, 0.1);
    const newFile = new File([cover], 'fileName.jpg', { type: 'image/jpeg' });
    const { data } = await uploadFile(newFile);
    if (data.success) {
      switch (dimension) {
        case 'squareMedia':
          return setSquare({ ...square, thumbnail: data.file_url });
        case 'portraitMedia':
          return setPortrait({ ...portrait, thumbnail: data.file_url });
        case 'landscapeMedia':
          return setLandscape({ ...landscape, thumbnail: data.file_url });
        default:
          break;
      }
    }
    return true;
  };

  const storeToRedux = (
    {
      url, width, height, dimension, fileName, fileType, thumbnail, file,
    },
  ) => {
    if (dimension) {
      const adSet = JSON.parse(JSON.stringify(newAdSet));
      adSet.ads[adIndex][dimension].url = url;
      adSet.ads[adIndex][dimension].width = width;
      adSet.ads[adIndex][dimension].height = height;
      if (thumbnail) {
        adSet.ads[adIndex][dimension].thumbnail = thumbnail;
      }
      if (!adSet.ads[adIndex].name) {
        adSet.ads[adIndex].name = fileName;
      }
      adSet.ads[adIndex].type = fileType;
      dispatch(changeNonField('newAdSet', adSet));
      if (fileType === 'video' && !thumbnail) {
        createThumbnail(file, dimension);
      }
    }
  };

  const renderAdPreview = (url, width, height, type) => {
    if (type === 'image') {
      return <img src={url} width={width} height={height} alt="media" />;
    }
    // eslint-disable-next-line jsx-a11y/media-has-caption
    return <video src={url} width={width} height={height} />;
  };

  return (
    <div className={classes.container}>
      <input
        style={{ display: 'none' }}
        type="file"
        id={`fileInput${adIndex}`}
        multiple
        accept="video/*, image/png, image/jpg, image/gif, image/webp"
        onChange={(event) => handleUpload(event.target.files)}
      />
      <div className={classes.dragContainer}>
        <div className={classes.dragDrop} id={`drop-area${adIndex}`}>
          <div className={classes.dropTitle}>
            Upload Files
          </div>
          <div className={classes.dropText}>
            drag & drop or click here to upload files
          </div>
          <div className={classes.dropButton}>
            {loading
              ? CircularProgress(25, classes)
              : <UploadIcon className={classes.uploadIcon} />}
          </div>
        </div>
      </div>
      <div className={classes.thumbnails}>
        <div>
          <div className={classes.squareMedia}>
            <RemoveMediaButton adIndex={adIndex} dimension="squareMedia" />
            {ad.squareMedia && ad.squareMedia.url ? renderAdPreview(ad.squareMedia.url, 80, 80, ad.type) : '1080x1080'}
          </div>
        </div>
        <div>
          <div className={classes.portraitMedia}>
            <RemoveMediaButton adIndex={adIndex} dimension="portraitMedia" />
            {ad.portraitMedia && ad.portraitMedia.url ? renderAdPreview(ad.portraitMedia.url, 70, 124, ad.type) : '1080x1920'}
          </div>
        </div>
        <div>
          <div className={classes.landscapeMedia}>
            <RemoveMediaButton adIndex={adIndex} dimension="landscapeMedia" />
            {ad.landscapeMedia && ad.landscapeMedia.url ? renderAdPreview(ad.landscapeMedia.url, 90, 51, ad.type) : '1920x1080'}
          </div>
        </div>
      </div>
    </div>
  );
}

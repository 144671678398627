import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Toolbar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  container: {
    backgroundColor: 'rgb(66, 133, 244)',
    borderRadius: 22,
    padding: '1rem',
    color: 'white',
    fontWeight: 600,
  },
}));

export default function Banner(props) {
  const { text } = props;
  const { classes } = useStyles();

  return (
    <Grid item xs={12}>
      <Toolbar>
        <Grid container className={classes.container} justify="center" align="center">
          <Grid item xs={12}>
            {text}
          </Grid>
        </Grid>
      </Toolbar>
    </Grid>
  );
}

Banner.propTypes = {
  text: PropTypes.string.isRequired,
};

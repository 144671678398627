import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LinearProgress from '../../../Widgets/Loaders/LinearProgress';
import AddSettings from '../../CampaignDetails/Drawers/Dayparting/AddSettings';
import SettingsTable from '../../CampaignDetails/Drawers/Dayparting/SettingsTable';
import { handleAddCampaignChange } from '../actions/addCampaign';
import { formatDataForBE } from '../../CampaignDetails/Drawers/Dayparting/helper';
import { openDialog, openSnackbar } from '../../../Widgets/actions/widgets';

const useStyles = makeStyles()(() => ({
  formContainer: {
    maxWidth: '75%',
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  loadingContainer: {
    height: 300,
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: 700,
    textAlign: 'center',
  },
  removeAllButton: {
    display: 'block',
    margin: '0 auto',
    marginBottom: '3rem',
  },
}));

export default function AddDayParting() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { settingsTable } = useSelector((state) => state.addCampaign.dayPartingData);
  const { dayPartingLoaded } = useSelector((state) => state.addCampaign);
  const { page } = useSelector((state) => state.page);
  const [loading, setLoading] = useState(true);
  const [removeSubmitting] = useState(false);

  const changeField = (field, value) => dispatch(handleAddCampaignChange('dayPartingData', field, value));
  const changeDayparting = (value) => {
    changeField('dayparting', formatDataForBE(value));
    changeField('settingsTable', value);
  };

  async function removeAll() {
    const message = page === 'add campaign'
      ? 'You have selected to clear all dayparting settings for this campaign.'
      : 'You have selected to clear all dayparting settings for this campaign. Please click "Submit" when you\'re ready to save this change.';
    dispatch(openDialog(
      'Are you sure?',
      'Please confirm that you would like to clear all dayparting settings for this campaign.',
      () => {
        changeDayparting({});
        dispatch(openSnackbar(message, 'success'));
      },
      true,
    ));
  }

  useEffect(() => {
    if (dayPartingLoaded) {
      setLoading(false);
    }
  }, [dayPartingLoaded]);

  return loading
    ? <div className={classes.loadingContainer}><LinearProgress /></div>
    : (
      <Grid className={classes.formContainer}>
        <AddSettings
          settings={settingsTable.value ? settingsTable.value : {}}
          setSettings={(value) => changeDayparting(value)}
          loading={loading}
          fullWidth
          addCampaign
        />
        <SettingsTable
          settings={settingsTable.value ? settingsTable.value : {}}
          setSettings={(value) => changeDayparting(value)}
          fullWidth
        />
        {(Object.keys(settingsTable.value ? settingsTable.value : {}).length > 0) && (
          <Button
            variant="contained"
            className={classes.removeAllButton}
            // eslint-disable-next-line
            onClick={removeAll}
            disabled={removeSubmitting}
          >
            Clear All Dayparting Settings
          </Button>
        )}
      </Grid>
    );
}

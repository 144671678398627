const localStorageEmail = () => localStorage.getItem('email');

export const saveToLocalStorage = (stateName, stateValue) => {
  if (localStorageEmail()) {
    localStorage.setItem(localStorageEmail(), JSON.stringify(
      { ...JSON.parse(localStorage.getItem(localStorageEmail())), [stateName]: stateValue },
    ));
  }
  return stateValue;
};

export const getFromLocalStorage = (stateName, defaultValue) => {
  if (
    !!localStorageEmail()
    && !!localStorage.getItem(localStorageEmail())
    && JSON.parse(localStorage.getItem(localStorageEmail()))[stateName] !== undefined
  ) {
    return JSON.parse(localStorage.getItem(localStorageEmail()))[stateName];
  } if (localStorage.getItem(stateName)) {
    return localStorage.getItem(stateName);
  }
  return defaultValue;
};

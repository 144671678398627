import { HANDLE_CAMPAIGNS_CHANGE } from '../actions/campaigns';

export const initialState = {
  table: 'campaigns',
};

export const campaigns = (state = initialState, action = {}) => {
  switch (action.type) {
    case HANDLE_CAMPAIGNS_CHANGE:
      return {
        ...state,
        [action.state]: action.value,
      };
    default:
      return state;
  }
};

import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import RenderSection from '../../../RenderSection';
import ChipSelectInput from '../../../../../../Components/ReusableComponents/ChipSelectInput';
import { changeNonField } from '../../../../actions/addCampaign';
import { validGender } from '../Validation/validationFunctions';

const useStyles = makeStyles()(() => ({
  selectInputRoot: {
    padding: 0,
    margin: 0,
    minHeight: 55,
    height: 'inherit',
  },
  singleSelectInputRoot: {
    padding: 0,
    height: '55px !important',
    marginLeft: 20,
    display: 'flex',
    alignItems: 'center',
  },
}));

const genderOptions = [{ value: '1', label: 'Male' }, { value: '2', label: 'Female' }];

export default () => {
  const { classes } = useStyles();
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const { newAdSet } = useSelector((store) => store.addCampaign);
  const {
    genderTargeting,
  } = newAdSet;
  const updateSingleValues = (name, value) => {
    const valid = validGender(value);
    setError(valid ? '' : 'Please choose at least one gender to target');
    dispatch(changeNonField('newAdSet', {
      ...newAdSet,
      [name]: value,
    }));
  };
  return (
    <RenderSection>
      <ChipSelectInput
        name="genderTargeting"
        value={genderOptions.filter((option) => genderTargeting.includes(option.value.toString()))}
        label="Gender Targeting"
        options={genderOptions}
        onChange={(name, value) => updateSingleValues(name, value.map((val) => val.value))}
        classes={classes}
        helperTex={error}
      />
    </RenderSection>
  );
};

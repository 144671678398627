import React, { useEffect, useState } from 'react';
import { differenceInMinutes } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import SyncIcon from '@mui/icons-material/Sync';
import { refreshData } from './actions/refresh';
import { updateRefreshedAt } from '../DateRange/actions/dateRange';
import HelpOverlay from '../../../../../Widgets/HelpOverlay/HelpOverlay';

const useStyles = makeStyles()(() => ({
  refreshIcon: {
    fontSize: '1.25rem',
  },
  button: {
    marginRight: 12,
    backgroundColor: 'transparent',
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 400,
    color: 'inherit',
    minWidth: 'auto',
  },
}));

const formatTimeString = (time, title) => {
  if (time === 0) {
    return 'Now';
  }
  if (time) {
    return (`${time} ${title}${time !== 1 ? 's' : ''} Ago`);
  }
  return false;
};

function differenceFormatter(originalTime) {
  const now = new Date();
  const minutes = differenceInMinutes(now, originalTime);
  const hours = minutes && minutes > 59 ? Math.trunc(minutes / 60) : false;
  const day = hours && hours > 23 ? Math.trunc(hours / 24) : false;
  return formatTimeString(day, 'Day') || formatTimeString(hours, 'Hour') || formatTimeString(minutes, 'Minute');
}

const RefreshButton = React.memo(() => {
  const [refreshedDifference, setRefreshedDifference] = useState(1);
  const [updateInterval, setUpdateInterval] = useState(1);
  const { refreshedAt } = useSelector((state) => state.dateRange);
  const { classes } = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    setRefreshedDifference(differenceFormatter(refreshedAt));
    setTimeout(() => setUpdateInterval(updateInterval + 1), 60000);
  }, [refreshedAt, updateInterval]);

  function refreshClicked() {
    dispatch(refreshData(true));
    dispatch(updateRefreshedAt());
  }

  return (
    <HelpOverlay title={`Last Updated: ${refreshedDifference}`} placement="bottom" allowHover>
      <IconButton className={classes.button} onClick={() => refreshClicked()} name={refreshedDifference}>
        <SyncIcon className={classes.refreshIcon} />
      </IconButton>
    </HelpOverlay>
  );
});

export default RefreshButton;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import WeightedLinks from './WeightedLinks';
import Input from '../../../../Widgets/Forms/Fields/Input';
import { handleAddCampaignChange } from '../../actions/addCampaign';
import { validateLink } from '../../Validation/validations';
import { changeLinkValue } from '../../../CampaignDetails/actions/campaignDetails';
import AdvancedTrackingToggle from './AdvancedTrackingToggle';

export const notTestUser = () => !!(localStorage.getItem('email') && localStorage.getItem('email').toLowerCase() !== 'test@facebook.com');

const useStyles = makeStyles()((theme) => ({
  fieldText: {
    margin: '2rem 0rem',
    fontSize: '.9rem',
  },
  fieldHeading: {
    margin: '1rem 0 0 0',
  },
  macroHeading: {
    margin: '1rem 0',
  },
  toolTipText: {
    fontSize: 10,
    color: theme.palette.colors.blue,
  },
}));

export default function Links({ campaignDetails }) {
  const { classes } = useStyles();
  const { safePageLink, advancedTracking: { value: advancedTracking } } = useSelector(
    (state) => (campaignDetails
      ? state.campaignDetails.links : state.addCampaign.links),
  );
  const dispatch = useDispatch();
  const handleLinkChange = (value, name, error) => dispatch(campaignDetails
    ? changeLinkValue(value, error)
    : handleAddCampaignChange('links', name, value, error));

  const fieldHeading = (headingText) => (
    <div>
      <h3 className={classes.fieldHeading}>
        {headingText}
      </h3>
      <hr />
    </div>
  );
  const fieldText = () => (
    <Grid container justify="center" className={classes.fieldText}>
      <Grid item xs={12}>
        <h3 className={classes.macroHeading}>Click Macros</h3>
        <p>{'{network_click_id} should be used to pass the Facebook click id in other fields. It is automatically passed as fbclid in URL parameters.'}</p>
        <p>
          {'{clickid} - Maximus Social click ID that needs passed back in Maximus Social pixels.'}
        </p>
        <p>
          {'{ref} - A URL encoded version of the referring site (when available).'}
        </p>
      </Grid>
      <Grid item xs={12}>
        <h3 className={classes.macroHeading}>
          Maximus Social supports postback, image and iframe pixels.
        </h3>
        <p>
          {'Maximus Social tracks clicks by using a click id. You can control where the click id '
          + 'is passed by using a token of {clickid} inside a campaign’s Link Setup.'}
        </p>
        <p>
          For Everflow, the click id token is automatically passed in sub5. It’s also automatically appended as max_cid
        </p>
        <p>
          {'Iframe pixel:<iframe src="https://ucyfx.com/p/?cid=#s5#&payout=#price#" height="1" width="1"> </iframe>'}
        </p>
        <p>
          {'#s5# should be equal to the tracking system’s token for the Maximus Social Click ID. '
          + '#price# should be a number equal to the value of the conversion'}
        </p>
        <p>
          {'Images: <img src="https://ucyfx.com/p/?cid=#s5#&payout=#price#&i=1" width=1 height=1>'}
        </p>

        <p>
          Postback: https://ucyfx.com/p/?cid=#s5#&payout=#price#
        </p>
      </Grid>

      <Grid item xs={12}>
        <h3 className={classes.macroHeading}>Global:</h3>
        <p>
          {'Global Pixel: <iframe src="https://ucyfx.com/p/?payout=#price#" border=0 width=1 height=1>< /iframe>'}
        </p>
      </Grid>
      <Grid item xs={12}>
        <h3 className={classes.macroHeading}>Maximus Social Step Pixels:</h3>
        <p>
          Maximus Social Step Pixels are used to track multiple conversions for one user. The step name must be unique.
        </p>
        <p>
          {'<iframe src="https://ucyfx.com/p/?cid=##MAXIMUSSOCIAL_CLICK_ID##&step=##STEP_NAME##&payout=##PAYOUT##" border=0 width=1 height=1></iframe>'}
        </p>
      </Grid>
    </Grid>
  );
  return (
    <Grid container justify="center">
      {notTestUser() && (
        <AdvancedTrackingToggle campaignDetails={campaignDetails} />
      )}
      <Grid item xs={12}>
        {notTestUser() && (
          <>
            {fieldHeading(
              'Safe Page Link Setup',
            )}
            <Input
              name="safePageLink"
              value={safePageLink.value}
              label="Safe Page Link"
              onChange={
              (value, name, errorMessage) => handleLinkChange(value.trim(), name, errorMessage)
            }
              validationFunc={validateLink}
              helperText={safePageLink.error}
            />
          </>
        )}
        {fieldHeading(
          'Presell Page Link Setup',
        )}
        <WeightedLinks
          section="presellLinks"
          linkTitle="Presell Page"
          campaignDetails={campaignDetails}
        />
        {advancedTracking ? (
          <>
            {fieldHeading(
              'Landing Page Link Setup',
            )}
            <WeightedLinks
              section="landingPageLinks"
              linkTitle="Landing Page"
              campaignDetails={campaignDetails}
            />
          </>
        ) : ''}
      </Grid>
      <Grid item xs={12}>
        {fieldText()}
      </Grid>
    </Grid>
  );
}

Links.propTypes = {
  campaignDetails: PropTypes.bool,
};

Links.defaultProps = {
  campaignDetails: false,
};

import React from 'react';
import { List } from '@mui/icons-material';
import { TableCell } from '@mui/material';
import { DefaultCell } from '../../../Widgets/Tables/TableContainer/DefaultCell';
import ImageFormatter from '../../../Widgets/Tables/TableContainer/TableUtilities/ImageFormatter';
import ImageStatus from '../StatusToggles/ImageStatus';
import { columnsCommon, renderCommonColumns } from './commonColumns';
import NameLink from './AdSetReport/NameLink';
import { returnImage } from '../../AddCampaign/render/AdSets/CloneAdSets/CloneAdSetsDialog';

const columns = [
  {
    name: 'status', title: 'Status', width: 120,
  }, {
    name: 'media_url', title: 'Media', width: 220,
  }, {
    name: 'name', title: 'Name', width: 200,
  }, {
    name: 'primary_text', title: 'Primary Text', width: 250,
  }, {
    name: 'title', title: 'Headline', width: 200,
  }, {
    name: 'description', title: 'Description', width: 200,
  },
  ...columnsCommon,
];

const renderCell = ({ row, column: { name } }, rows, modifyData, campaignID) => {
  const cell = renderCommonColumns(row, name);
  if (cell) {
    return cell;
  }
  const value = row[name];
  const changeStatus = (adStatus, adID) => {
    const newRows = rows.map((currentRow) => {
      const newRow = { ...currentRow };
      if (newRow.ad_id === adID) {
        newRow.status = adStatus;
      }
      return newRow;
    });
    modifyData(newRows);
  };
  switch (name) {
    case 'status':
      return DefaultCell(
        <ImageStatus
          id={row.ad_id}
          adStatus={row.status}
          changeStatus={changeStatus}
          campaignID={campaignID}
          adSetId={row.adset_id}
          ads={rows}
        />,
      );
    case 'media_url':
      return (
        <TableCell style={{
          position: 'sticky',
          zIndex: 900,
          left: 0,
          backgroundClip: 'padding-box',
          borderRight: '1px solid rgba(0,0, 0, 0.1)',
          borderBottom: '1px solid rgba(0,0, 0, 0.1)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100px',
        }}
        >
          {value ? <ImageFormatter value={returnImage(value, row.thumbnail_url)} /> : value}
        </TableCell>
      );
    case 'name':
      return (
        <TableCell style={{
          position: 'sticky',
          zIndex: 300,
          left: 0,
          backgroundClip: 'padding-box',
          borderRight: '1px solid rgba(0,0, 0, 0.1)',
          borderBottom: '1px solid rgba(0,0, 0, 0.1)',
        }}
        >
          <NameLink name={value} adID={row.ad_id} adSetID={row.adset_id} />
        </TableCell>
      );
    default:
      return DefaultCell(value);
  }
};

export const adsReport = () => ({
  title: 'Ads',
  icon: <List className="tabIcon" />,
  localStorage: 'ads',
  renderCell,
  columns,
  action: 'adsReport',
  leftColumns: ['media_url'],
});

import { convertToFormattedNumber } from '../columnFormatter';

const formatPercentString = (margin, marginColor) => {
  const value = margin.replace(/([%$-,])/g, '');
  const formattedValue = convertToFormattedNumber(value).replace(/([%-])/g, '');
  if (marginColor === 'red') {
    return `-${formattedValue}%`;
  }
  return `${formattedValue}%`;
};

const MarginFormatter = ({ value }) => {
  const getMarginColor = () => {
    value = (value || 0).toString();
    value = value.replace(/([%$-,])/g, '');
    return value < 0 ? 'red' : 'green';
  };
  return (
    <p style={{ color: getMarginColor() }}>
      {formatPercentString(value, getMarginColor())}
    </p>
  );
};

export default MarginFormatter;

import { Grid } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

const useStyles = makeStyles()(() => ({
  section: {
    padding: 8,
  },
  loadingContainer: {
    height: 300,
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  centerGrids: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

RenderSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string,
  halfWidth: PropTypes.bool,
};
RenderSection.defaultProps = {
  className: '',
  halfWidth: false,
};

export default function RenderSection(props) {
  const { children, className, halfWidth } = props;
  const { classes } = useStyles();
  return (
    <Grid item className={`${classes.section} ${className}`} xs={halfWidth ? 6 : 12} sm={halfWidth ? 6 : 12} md={halfWidth ? 3 : 6}>
      {children}
    </Grid>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import AdRow from './AdRow';
import AddRowButton from './AddRowButton';

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    minHeight: '15rem',
    maxHeight: '50rem',
    overflow: 'auto',
    border: 'solid 1px #282C34',
    borderRadius: 4,
  },
}));

export default function () {
  const { classes } = useStyles();
  // const dispatch = useDispatch();
  const { newAdSet } = useSelector((store) => store.addCampaign);
  const {
    ads,
  } = newAdSet;

  return (
    <div className={classes.container}>
      {ads.map((ad, index) => <AdRow index={index} />)}
      <AddRowButton />
    </div>
  );
}

import React, { useMemo } from 'react';
import {
  bool,
  func,
  instanceOf, oneOfType,
  string,
} from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import TableOptions from './TableUtilities/TableOptionMenu';
// import HelpOverlay from '../../HelpOverlay/HelpOverlay';
import FiltersRow from '../FiltersRow';
import { formattedColumns } from './RenderTablesUtilities';
import { campaignStatusList, platformStatusList } from './FilterLists';
import CustomFiltering from '../CustomFiltering';

const useStyles = makeStyles()(() => ({
  titleOptionsBar: {
    padding: '5px 10px 0px 5px',
    display: 'flex',
    alignItems: 'center',
  },
  tableTitle: {
    flex: '1 1 auto',
    paddingLeft: '20px',
    fontSize: '22px',
    fontWeight: 600,
    opacity: '.7',
    display: 'inline-flex',
    alignItems: 'center',
  },
  statusDropdown: {
    width: 300,
    marginLeft: 23,
    marginTop: 10,
  },
}));

const TableOptionsBar = React.memo((
  {
    title,
    table,
    data,
    columns,
    localStorage,
    hideBlocked,
    setCustomFilter,
    setHideBlocked,
  },
) => {
  const { classes } = useStyles();

  const renderFilters = useMemo(() => (
    <>
      {(table === 'campaigns') && (
        <FiltersRow
          filterList={campaignStatusList}
          filterCallback={(value) => setCustomFilter(value)}
          filterTitle="Campaign
          Status"
          filterName="status"
        />
      )}
      {(table === 'platforms') && (
        <FiltersRow
          filterList={platformStatusList}
          filterTitle="Platform Status"
          filterName="status"
          customOnChange={(value) => setHideBlocked(value)}
          customValue={hideBlocked}
        />
      )}
      <CustomFiltering
        columns={columns}
        localStorage={localStorage}
      />
    </>
  ), [table]);

  return (
    <div className={classes.titleOptionsBar}>
      <div className={classes.tableTitle}>
        {title}
        {renderFilters}
      </div>
      <div>
        <TableOptions
          columns={formattedColumns(table, columns)}
          data={data}
          title={title}
          localStorage={localStorage}
        />
      </div>
    </div>
  );
});

TableOptionsBar.propTypes = {
  title: string.isRequired,
  table: string.isRequired,
  data: instanceOf(Object).isRequired,
  columns: instanceOf(Object).isRequired,
  hideBlocked: oneOfType([bool, string]).isRequired,
  setHideBlocked: func.isRequired,
  localStorage: string.isRequired,
};

export default TableOptionsBar;

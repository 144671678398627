import { combineReducers } from 'redux';
import { refresh } from '../../Components/Navigation/SideNavigation/Filters/RefreshButton/reducers/refresh';
import { login } from '../../Pages/Login/reducers/login';
import { sideNavigation } from '../../Components/Navigation/SideNavigation/reducers/sideNavigation';
import { page } from '../../Components/Navigation/reducers/page';
import { campaignDetails } from '../../Pages/CampaignDetails/reducers/campaignDetails';
import { widgets } from '../../Widgets/reducers/widgets';
import { dateRange } from '../../Components/Navigation/SideNavigation/Filters/DateRange/reducers/dateRange';
import { campaigns } from '../../Pages/Campaigns/reducers/campaigns';
import { creatives } from '../../Pages/Creatives/reducers/creatives';
import { filters } from '../../Components/Navigation/SideNavigation/Filters/reducers/filters';
import { addCampaign } from '../../Pages/AddCampaign/reducers/addCampaign';

export default combineReducers({
  login,
  refresh,
  sideNavigation,
  page,
  campaignDetails,
  widgets,
  dateRange,
  campaigns,
  addCampaign,
  creatives,
  filters,
});

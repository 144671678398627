import React from 'react';
import {
  Typography, MenuItem, Chip, useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Select from 'react-select';
import {
  ArrowDropUp as ArrowDropUpIcon,
  Clear as ClearIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';

const ITEM_HEIGHT = 45;

const useStyles = makeStyles()((theme) => ({
  chip: {
    margin: theme.spacing.unit / 4,
    color: 'black',
    cursor: 'pointer',
  },
}));

const customStyles = () => ({
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'black' : 'black',
    padding: 10,
    height: ITEM_HEIGHT,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: '100%',
    display: 'inline-flex',
    height: 55,
    backgroundColor: 'transparent!important',
    cursor: 'pointer',
  }),
  ValueContainer: () => ({
    flex: 1,
    height: 55,
    backgroundColor: 'transparent!important',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  multiValue: (provided) => ({
    ...provided,
    color: 'black',
    cursor: 'pointer',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 1000,
    color: 'black!important',
  }),
});

function Option(props) {
  function handleClick(event) {
    props.onSelect(props.option.value, event);
  }
  const {
    children, isFocused, isSelected, onFocus,
  } = props;
  return (
    <MenuItem
      onFocus={onFocus}
      selected={isFocused}
      // eslint-disable-next-line
      onClick={handleClick}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {children}
    </MenuItem>
  );
}

const ChipSelect = React.forwardRef((props, ref) => {
  const { classes } = useStyles();
  const { ...other } = props;
  const theme = useTheme();

  React.useImperativeHandle(ref, () => ({
    focus: () => {},
  }));

  const changeArrow = (arrowProps) => {
    if (arrowProps.isOpen) {
      return (<ArrowDropUpIcon />);
    }
    return (<ArrowDropDownIcon />);
  };

  function renderValue(valueProps) {
    const { value, children, onRemove } = valueProps;
    const onDelete = (event) => {
      event.preventDefault();
      event.stopPropagation();
      onRemove(value);
    };
    if (onRemove) {
      return (
        <Chip
          tabIndex={-1}
          label={children}
          className={classes.chip}
          deleteIcon={<CancelIcon onTouchEnd={onDelete} />}
          onDelete={onDelete}
        />
      );
    }
    return <div className="Select-value">{children}</div>;
  }

  return (
    <Select
      isMulti
      styles={customStyles(theme)}
      optionComponent={Option}
      noResultsText={<Typography>No results found</Typography>}
      arrowRenderer={changeArrow}
      clearRenderer={<ClearIcon />}
      valueComponent={(valueProps) => renderValue(valueProps)}
      {...other}
    />
  );
});

export default ChipSelect;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import SectionTitle from './SectionTitle';
import SectionItems from './SectionItems';

const useStyles = makeStyles()(() => ({
  sectionContainer: {
    overflowY: 'hidden',
    padding: '0 15px',
    maxHeight: '50rem',
    transition: 'max-height ease-in-out .25s',
  },
  collapsed: {
    maxHeight: '45px',
  },
}));

const Section = React.memo(({ section }) => {
  const [collapsed, setCollapsed] = React.useState(section.collapsed || false);
  const { classes } = useStyles();
  return (
    <div className={`${classes.sectionContainer} ${collapsed && classes.collapsed}`}>
      <SectionTitle
        title={section.title}
        titleIcon={section.titleIcon}
        setCollapsed={(set) => setCollapsed(set)}
        collapsed={collapsed}
        collapsible={section.collapsible || false}
      />
      <SectionItems items={section.items} />
    </div>
  );
});

Section.propTypes = {
  section: PropTypes.instanceOf(Object).isRequired,
};

export default Section;

import { HANDLE_CREATIVES_CHANGE } from '../actions/creatives';

export const initialState = {
  table: 'creatives',
};

export const creatives = (state = initialState, action = {}) => {
  switch (action.type) {
    case HANDLE_CREATIVES_CHANGE:
      return {
        ...state,
        [action.state]: action.value,
      };
    default:
      return state;
  }
};

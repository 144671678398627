import { isBefore } from 'date-fns';

const formatDate = (...args) => {
  let datesArray = [];
  if (typeof args === 'object') {
    args.forEach((date) => {
      date = date.toLowerCase().includes('pm') ? formatDatePM(date) : formatDateAM(date);
      datesArray.push(date);
    });
  } else {
    datesArray = args;
  }
  return datesArray;
};

const formatDatePM = (date) => {
  date = date.split(' ');
  // eslint-disable-next-line prefer-const
  let [hour, minutes] = date.pop().split(':');
  date = date.join(' ');
  hour = `${hour}` === '12' ? hour : parseInt(hour, 10) + 12;
  return `${date} ${hour}:${minutes.replace('PM', '')}`;
};

const formatDateAM = (date) => {
  date = date.split(' ');
  // eslint-disable-next-line prefer-const
  let [hour, minutes] = date.pop().split(':');
  date = date.join(' ');
  hour = `${hour}` === '12' ? '0' : parseInt(hour, 10);
  return `${date} ${hour}:${minutes.replace('AM', '')}`;
};

export const compareTimestamp = (a, b) => {
  [a, b] = formatDate(a, b);

  if (a === b) {
    return 0;
  }

  return (isBefore(new Date(a), new Date(b))) ? -1 : 1;
};

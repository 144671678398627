import { isStartTimeBeforeEndTime } from './validation';

export const daysMapping = {
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
  7: 'sunday',
};

const timeHours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
];

const timeMinutes = [0, 15, 30, 45];

const formatTimeOptions = (restrictHour = { minHour: 0, minMinute: 0, origTime: '' }) => {
  const returnTimes = [];
  timeHours.forEach((hour) => {
    let time = '';
    if (!restrictHour || hour >= restrictHour.minHour) {
      const amOrPm = hour < 12 ? 'am' : 'pm';
      const formattedHour = (hour > 12 ? hour - 12 : hour) || 0;
      timeMinutes.forEach((minute) => {
        if (!restrictHour || hour !== restrictHour.minHour || minute >= restrictHour.minMinute) {
          time = `${formattedHour || 12}:${minute.toString().length > 1 ? minute : `${minute}0`}${amOrPm}`;
          if (time) {
            if (time !== restrictHour.origTime) {
              returnTimes.push(time);
            }
            time = '';
          }
        }
      });
      if (hour === 23) {
        returnTimes.push('11:59pm');
      }
    }
  });
  return returnTimes;
};

export const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
export const weekEnds = ['saturday', 'sunday'];

export const isDayValid = (day) => !!(daysMapping[day]);

export const encodeTimeString = (startAt, endAt) => btoa(`${startAt}${endAt}`);

export const convertTimeTo24HR = (time) => {
  if (time.includes('12')) {
    if (time.includes('am')) {
      return 0;
    }
    return 12;
  }
  return (
    time.toLowerCase().includes('am')
      ? +(time.toLowerCase().replace('am', '').split(':')[0])
      : +(time.toLowerCase().replace('pm', '').split(':')[0]) + 12
  );
};

export const renderLaterTimes = (startTime, shouldFilter) => {
  let times;
  if (shouldFilter) {
    const restriction = {
      minHour: convertTimeTo24HR(startTime),
      minMinute: startTime.replace('am', '').replace('pm', '').split(':')[1],
      origTime: startTime,
    };
    times = formatTimeOptions(restriction);
  } else {
    times = formatTimeOptions();
  }
  return [{ title: 'Select a time', value: '0' }, ...times.map((time) => ({ title: time, value: time }))];
};

export const sortDayByTimes = (data) => (
  data.sort((a, b) => convertTimeTo24HR(a.startAt) - convertTimeTo24HR(b.startAt))
);

export const checkIfDaysType = (days, weekType) => {
  const daysCheck = [];
  if (weekType === 'week days') {
    days.forEach((day) => {
      if (weekDays.includes(day)) {
        daysCheck.push(day);
      }
    });
    return daysCheck.length === 5;
  }
  days.forEach((day) => {
    if (!weekDays.includes(day)) {
      daysCheck.push(day);
    }
  });
  return daysCheck.length === 2;
};

export const returnRemovedDataCode = (data, encodedTime) => {
  const returnedDayParting = {};
  Object.keys(data).forEach((key) => {
    const dayRow = data[key];
    dayRow.forEach((timeRow) => {
      const {
        startAt, endAt, dataCode, budget,
      } = timeRow;
      if (encodedTime !== dataCode) {
        returnedDayParting[key] = [
          ...(returnedDayParting[key] || []),
          {
            startAt, endAt, budget, dataCode: encodeTimeString(startAt, endAt),
          },
        ];
      }
    });
  });
  return returnedDayParting;
};

export const createNewTimeObject = (originalData, days, startAt, endAt, budget, enabledBudget) => {
  const dayParting = { ...originalData };
  const errorDays = [];
  days.forEach((day) => {
    const originalDataDay = originalData[day] || [];
    let validTimes = true;
    const encodedTimes = encodeTimeString(startAt, endAt);
    originalDataDay.forEach(({ startAt: originalStart, endAt: originalEnd, dataCode }) => {
      // TODO: check this IF code once BE submits are working. Some changes were required, but were unable to be tested yet.
      if (
        !isStartTimeBeforeEndTime(startAt, endAt)
        || encodedTimes === dataCode
        || startAt === originalStart
        || endAt === originalEnd
      ) {
        validTimes = false;
      }
    });
    if (validTimes) {
      dayParting[day] = [
        ...(originalData[day] || []),
        {
          startAt, endAt, budget: enabledBudget ? budget : 0, dataCode: encodedTimes,
        },
      ];
    } else {
      errorDays.push(day);
    }
  });
  return { dayParting, errorDays };
};

export const returnRemoveOneDayTime = (data, day, encodedTime) => {
  const returnedDayParting = {};
  Object.keys(data).forEach((key) => {
    const dayRow = data[key];
    dayRow.forEach((timeRow) => {
      const {
        startAt, endAt, dataCode, budget,
      } = timeRow;
      if (!(key === day && encodedTime === dataCode)) {
        returnedDayParting[key] = [
          ...(returnedDayParting[key] || []),
          {
            startAt, endAt, dataCode: encodeTimeString(startAt, endAt), budget,
          },
        ];
      }
    });
  });
  return returnedDayParting;
};

export const returnRelatedDays = (data, encodedTime) => {
  const relatedDays = [];
  Object.keys(data).forEach((key) => {
    const dayRow = data[key];
    dayRow.forEach((timeRow) => {
      const { dataCode } = timeRow;
      if (encodedTime === dataCode) {
        relatedDays.push(key);
      }
    });
  });
  return relatedDays;
};

export const formatDataForBE = (data) => {
  const dataCodeTimes = {};
  Object.keys(data).forEach((key) => {
    const dayRow = data[key];
    dayRow.forEach((timeRow) => {
      const {
        startAt, endAt, dataCode, budget,
      } = timeRow;
      const day = Object.values(daysMapping).indexOf(key) + 1;
      const days = dataCodeTimes[dataCode] && dataCodeTimes[dataCode].days ? `${dataCodeTimes[dataCode].days},${day}` : `${day}`;
      dataCodeTimes[dataCode] = {
        days, start_at: startAt, end_at: endAt, budget,
      };
    });
  });
  return Object.values(dataCodeTimes);
};

export const takeDataAndOrganizeIt = (data) => {
  const dayparting = {};
  data.forEach((row) => {
    const {
      days, start_at: startAt, end_at: endAt, budget,
    } = row;
    const daysArray = days.split(',');
    daysArray.forEach((day) => {
      if (isDayValid(day)) {
        dayparting[daysMapping[day]] = [
          ...(dayparting[daysMapping[day]] || []),
          {
            startAt, endAt, dataCode: encodeTimeString(startAt, endAt), budget,
          },
        ];
      }
    });
  });
  return dayparting;
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { find, startCase } from 'lodash';
import {
  Button, Menu, MenuItem, Chip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { arrayOf, instanceOf, string } from 'prop-types';
import Input from '../Forms/Fields/Input';
import { customizeDataGrid } from './TableContainer/dataGridCustomizationHelper';
import { updateTable, updateWidget } from '../actions/widgets';
import { changeFilter } from '../../Components/Navigation/SideNavigation/Filters/actions/filters';
import { NetworkRequestPost } from '../../Helpers/NetworkRequest';
import { usePrevious } from '../../Helpers/CustomHooks';

const availableFilters = [
  {
    name: 'name',
    filter: 'text',
  }, {
    name: 'site',
    filter: 'text',
  }, {
    name: 'budget',
    filter: 'number',
  }, {
    name: 'rpm',
    filter: 'number',
  }, {
    name: 'epc',
    filter: 'number',
  }, {
    name: 'profit',
    filter: 'number',
  }, {
    name: 'cpm_bid',
    filter: 'number',
  }, {
    name: 'avg_floor',
    filter: 'number',
  }, {
    name: 'adset_id',
    filter: 'custom',
  },
];

const useStyles = makeStyles()((theme) => ({
  filterIcon: {
    color: theme.palette.colors.blue,
  },
  filterRow: {
    display: 'flex',
    height: 40,
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 8,
  },
  menu: {
    '&>div>ul': {
      padding: 0,
    },
  },
  selectedItem: {
    borderLeft: '2px solid',
    borderLeftColor: theme.palette.colors.blue,
    backgroundColor: theme.variantColor('rgba(0,0,0,0.04)', 'rgba(255,255,255,0.08)'),
  },
  titleItem: {
    height: 40,
    color: 'white',
    backgroundColor: theme.palette.colors.blue,
    fontWeight: 600,
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
    minWidth: '13rem',
    padding: 8,
    marginBottom: 6,
  },
  inputDiv: {
    fontSize: 16,
    color: theme.palette.colors.blue,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    borderRadius: 20,
    padding: 7,
    paddingLeft: '0',
    paddingRight: '1rem',
    transition: 'all ease-in-out .25s',
    display: 'inline-flex',
    alignItems: 'center',
  },
  applyButton: {
    marginBottom: '1rem',
  },
  chip: {
    color: '#2c8fff',
    fontSize: 16,
    fontWeight: 500,
  },
  menuContainer: {
    display: 'table-caption',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  menuColumn: {
    width: '100%',
    display: 'table-cell',
    minWidth: '13rem',
    borderRight: 'solid 1px rgba(0,0,0,0.12)',
  },
  rightColumn: {
    borderRight: 'none',
  },
  formContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  filterForm: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: '80%',
  },
  filterValue: {
    fontWeight: 900,
  },
  addFilter: {
    fontWeight: 700,
    fontSize: 17,
    marginLeft: '1rem',
    background: 'rgba(0,0,0,0.05)',
    '&:hover': {
      background: 'rgba(0,0,0,0.15)',
    },
  },
  adSetList: {
    maxHeight: '10rem',
    overflowY: 'auto',
  },
}));

const textCompareOptions = ['equals', 'contains'];
const numberCompareOptions = ['greaterThan', 'lessThan', 'equalTo'];

export default function CustomFiltering(
  {
    columns,
    localStorage,
  },
) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { dataGridCustomization, adSetNames } = useSelector((state) => state.widgets);
  const { adset_ids: adSetIDs } = useSelector((state) => state.filters);
  const { campaignID } = useSelector((state) => state.campaignDetails);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedColumn, selectColumn] = React.useState(null);
  const [compareWay, selectCompareWay] = React.useState(null);
  const [selectedAdSet, setSelectedAdSet] = React.useState(null);
  const [showAdSetDropdown, setShowAdSetDropdown] = React.useState(false);
  const [inputValue, handleInput] = React.useState('');
  const [compareOptions, setCompareOptions] = React.useState(null);
  const [moreFiltersCardAnchor, setMoreFiltersCardAnchor] = React.useState(false);
  const prevTable = usePrevious(localStorage);

  async function pullAdSetList() {
    try {
      if (localStorage === 'ads') {
        const { data: { data, success } } = await NetworkRequestPost('campaignAdSetsList', { campaign_id: campaignID });
        if (success) {
          const list = [];
          data.forEach((adSet) => {
            list.push({
              value: adSet.adset_id,
              title: adSet.name,
            });
          });
          dispatch(updateWidget('adSetNames', list));
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (prevTable !== localStorage) {
      pullAdSetList();
    }
  }, [prevTable, localStorage]);

  const toggleMenu = (anchor = null) => setAnchorEl(anchor);

  const filters = availableFilters.map((filter) => {
    const foundColumn = find(columns, { name: filter.name });
    if (foundColumn) {
      return {
        ...filter,
        title: foundColumn.title,
      };
    }
    if (filter.name === 'adset_id' && localStorage === 'ads') {
      return {
        ...filter,
        title: 'Ad Set',
      };
    }
    return null;
  }).filter((f) => f);

  const selectColumnAndType = (column) => {
    selectColumn(column);
    if (column.filter !== 'custom') {
      setShowAdSetDropdown(false);
      setCompareOptions(column.filter === 'number' ? numberCompareOptions : textCompareOptions);
      selectCompareWay(null);
      handleInput('');
    } else if (column.name === 'adset_id') {
      setShowAdSetDropdown(true);
    }
  };

  const updateDataGrid = (newFilters) => {
    const newDataGrid = {
      ...dataGridCustomization,
      filters: {
        ...(dataGridCustomization.filters || {}),
        [localStorage]: newFilters,
      },
    };
    dispatch(updateTable(newDataGrid));
    customizeDataGrid(newDataGrid);
  };

  const applyChanges = () => {
    const customFilters = (dataGridCustomization.filters || {})[localStorage]
      ? [...(dataGridCustomization.filters || {})[localStorage]] : [];
    const newFilter = {
      name: selectedColumn.name,
      compare: compareWay,
      value: inputValue,
      title: selectedColumn.title,
    };
    updateDataGrid([...customFilters, newFilter]);
    selectColumn(null);
    selectCompareWay(null);
    setShowAdSetDropdown(false);
    handleInput('');
    toggleMenu();
  };

  const adSetClick = (adSet, display) => {
    setSelectedAdSet(adSet);
    const customFilters = (dataGridCustomization.filters || {})[localStorage]
      ? [...(dataGridCustomization.filters || {})[localStorage]] : [];
    const newFilter = {
      name: selectedColumn.name,
      compare: null,
      value: adSet,
      title: selectedColumn.title,
      display,
    };
    updateDataGrid([...customFilters, newFilter]);
    selectColumn(null);
    selectCompareWay(null);
    setShowAdSetDropdown(false);
    handleInput('');
    toggleMenu();
    dispatch(changeFilter('adset_ids', [...adSetIDs, adSet]));
  };

  const renderMenu = () => (
    <Menu
      className={classes.menu}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={() => toggleMenu()}
    >
      <div className={classes.menuContainer}>
        <div className={classes.menuColumn}>
          <div style={{ display: 'flex' }}>
            <div className={classes.titleItem}>
              Select Column
            </div>
          </div>
          {filters.map((filter) => (
            <MenuItem
              className={selectedColumn && selectedColumn.name === filter.name ? classes.selectedItem : ''}
              onClick={() => selectColumnAndType(filter)}
              key={filter.name}
            >
              {filter.title}
            </MenuItem>
          ))}
        </div>
        {selectedColumn && !showAdSetDropdown && (
          <div className={classes.menuColumn}>
            <div style={{ display: 'flex' }}>
              <div className={classes.titleItem}>
                Select Type
              </div>
            </div>
            {compareOptions.map((type) => (
              <MenuItem
                className={compareWay === type ? classes.selectedItem : ''}
                onClick={() => selectCompareWay(type)}
                key={type}
              >
                {startCase(type)}
              </MenuItem>
            ))}
          </div>
        )}
        {showAdSetDropdown && (
          <div className={classes.menuColumn}>
            <div style={{ display: 'flex' }}>
              <div className={classes.titleItem}>
                Select Ad Set
              </div>
            </div>
            <div className={classes.adSetList}>
              {adSetNames.map(({ title, value }) => (
                <MenuItem
                  className={value === selectedAdSet ? classes.selectedItem : ''}
                  onClick={() => adSetClick(value, title)}
                  key={value}
                >
                  {title}
                </MenuItem>
              ))}
            </div>
          </div>
        )}
        {compareWay && (
          <div className={`${classes.menuColumn} ${classes.rightColumn}`}>
            <div style={{ display: 'flex' }}>
              <div className={classes.titleItem}>
                Filter Value
              </div>
            </div>
            <div className={classes.formContainer}>
              <form className={classes.filterForm} onSubmit={(e) => { e.preventDefault(); inputValue && applyChanges(); }}>
                <Input
                  name="value"
                  value={inputValue}
                  label="Value"
                  adornment={selectedColumn.filter === 'number' ? '$' : ''}
                  type={selectedColumn.filter}
                  onChange={handleInput}
                />
                {inputValue && <Button className={classes.applyButton} onClick={applyChanges}>Apply Filter</Button>}
              </form>
            </div>
          </div>
        )}
      </div>
    </Menu>
  );

  const handleFilterDelete = (customFilters, filter, index) => {
    const newFilters = [...customFilters];
    newFilters.splice(index, 1);
    updateDataGrid(newFilters);
    if (filter.name === 'adset_id') {
      dispatch(changeFilter('adset_ids', adSetIDs.filter((adSet) => adSet !== filter.value)));
    }
  };

  const renderFilterButton = () => {
    const customFilters = (dataGridCustomization.filters || {})[localStorage]
      ? [...(dataGridCustomization.filters || {})[localStorage]] : [];
    const allFilters = [...customFilters];
    let visibleFilters = [...allFilters];
    if (customFilters.length > 1) {
      visibleFilters = allFilters.splice(0, 1);
    }
    return (
      // eslint-disable-next-line
      <div className={classes.inputDiv}>
        <div>
          {visibleFilters.map((filter, index) => (
            <Chip
              className={classes.chip}
              label={(
                <span>
                  {filter.title}
                  {' '}
                  {startCase(filter.compare).toLowerCase()}
                  {' '}
                  <span className={classes.filterValue}>{filter.title === 'Ad Set' ? filter.display : filter.value}</span>
                </span>
              )}
              onDelete={() => handleFilterDelete(customFilters, filter, index)}
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
            />
          ))}
          {customFilters.length > 1 && (
            <Chip
              className={classes.chip}
              label={`+ ${allFilters.length} more`}
              onClick={(e) => setMoreFiltersCardAnchor(e.currentTarget)}
            />
          )}
          {customFilters.length > 1 && (
            <Menu
              className={classes.menu}
              anchorEl={moreFiltersCardAnchor}
              open={!!moreFiltersCardAnchor}
              onClose={() => setMoreFiltersCardAnchor(null)}
            >
              {allFilters.map((filter, index) => (
                <MenuItem>
                  <Chip
                    className={classes.chip}
                    label={(
                      <span>
                        {filter.title}
                        {' '}
                        {startCase(filter.compare).toLowerCase()}
                        {' '}
                        <span className={classes.filterValue}>{filter.title === 'Ad Set' ? filter.display : filter.value}</span>
                      </span>
                    )}
                    onDelete={() => handleFilterDelete(customFilters, filter, index + 1)}
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={index}
                  />
                </MenuItem>
              ))}
            </Menu>
          )}
        </div>
        <Button className={classes.addFilter} onClick={(event) => toggleMenu(event.currentTarget)}>Add Filter</Button>
      </div>
    );
  };

  return (filters && filters.length > 0)
    ? (
      <div className={classes.filterRow}>
        {renderFilterButton()}
        {renderMenu()}
      </div>
    ) : null;
}

CustomFiltering.propTypes = {
  columns: arrayOf(instanceOf(Object)).isRequired,
  localStorage: string.isRequired,
};
